import React, { useEffect } from 'react'
import { Card, CardImg, CardBody, CardTitle, CardText, Button } from 'reactstrap'
import '../css/tools.css'
import { useNavigate } from 'react-router-dom';
import cost_benefit from '../img/sm-cost_benefit_tool.png'
import seed_selection from '../img/sm-seed_selection_tool.png';
import about_card from '../img/sm-score_card.png';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function About() {
    return (
        <>

            <div className='container-fluid'>
                <div className='col-16'>
                    <Card className="cardsize" >
                        <CardImg
                            alt="About Image"
                            className="tool-image-wrapper"
                            src={about_card}
                        />
                        <CardBody>
                            <CardTitle tag="h5">
                                About
                            </CardTitle>
                            <CardText>
                                <span>
                                    This four-year project, funded by the U.S. Department of Energy Solar Energy Technology Office, brings
                                    together leading researchers and large-scale solar developers to investigate the ecological and economic
                                    benefits as well as performance impacts of co-located pollinator plantings at large, utility-scale photovoltaic (PV) facilities.
                                    <br />  <br />

                                    <Link to={"../Acknowledgements"}>
                                        Click Here for the Full List of Acknowledgements
                                    </Link>

                                    <br />  <br />

                                    The project will:
                                    <ol>
                                        <li>Consider the impacts of scale and configuration of pollinator plantings on PV performance, installation and operational costs, and ecological benefits.</li>
                                        <li>Focus on large-scale solar facilities that have capacities of 10 MW or greater.</li>
                                        <li>Perform field research and tool development to inform decisions on if, when, and how to incorporate pollinator plantings into solar projects.</li>
                                        <li>Engage solar industry partners and share findings with broader industry stakeholders.</li>                    </ol>
                                </span>


                            </CardText>
                        </CardBody>
                    </Card>
                </div>

               <br /><br /><br /><br /><br /><br />
        </div>
    </>
  )
}

export default About