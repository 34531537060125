import React from 'react'

function Home() {
    return (
        <>
            <div className='container-fluid'>
            <p>
                Tools developed under the U.S. Department of Energy Solar Energy Technology Office funded PHASE
                (Pollinator Habitat Aligned with Solar Energy) project help project developers, site owners, regulators,
                and collaboration stakeholders make decisions regarding if, when, where, and how to incorporate pollinator
                habitat into utility-scale solar energy projects.
            </p>
            <p>
            Four tools are being developed through this project: 
            </p>
            <span>
                <ul>
                    <li>Pollinator Vegetation Implementation Manual helps answer questions like, "Should I incorporate pollinator vegetation into my
                        project?" and, "How might I consider including pollinator habitat?" It provides a comprehensive primer on pollinator vegetation
                        management at large-scale solar facilities, describing overall goals and key considerations for planning, design, installation, and maintenance.</li>

                    <li>Cost Comparison Calculator allows users to test different sizes and types of vegetation alongside considerations for site layout, design
                        features, and planned maintenance. The calculator yields a side-by-side comparison of approaches, their anticipated costs, and benefits provided.</li>

                    <li>Seed Selection Tool assists users in determining which vegetation is compatible with site design and operations, while also helping
                        achieve other objectives for pollinator habitat or other environmental benefits. This tools provides users with a list of species based
                        on the defined constraints identified, which will aid in seed or plant procurement.</li>

                    <li>Habitat Assessment Module is a voluntary self-assessment tool that allows users to quantify the improvements made to pollinator habitat
                        as a result of vegetation established. The assessment module can be used to document changes over time, before/after conditions, and
                        communicate benefits of vegetation management.</li>
                </ul>
            </span>
            <p>
                Each tool has been developed in collaboration with representatives from the solar energy industry, regulatory agencies, non-governmental
                conservation organizations, academic researchers, and field practitioners. Insights from across this array of users has helped ensure the
                tools provide a useful resource for any utility-scale solar project.
            </p>
        </div>
        </>
    )
}

export default Home