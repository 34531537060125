import React,{useEffect} from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import { cost_benefit_actions } from "../../../../store/cost_benefit_slice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { zipcelx } from 'zipcelx';
import JSZip from 'jszip';
import { csv_columns_array, csv_columns_cost_npv } from "../utils/csvColumns";
import GenerateCostDetailedData from '../utils/GenerateCostDetailedData';
import GenerateNpvData from '../utils/GenerateNpvData'
import GenerateYearlyData from '../utils/GenerateYearlyData'
import axios from "axios";
import api from '../../../../axiosConfig';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


function GenerateCSV() {

  //constants for the component
  const [csvData, setCsvData] = React.useState([]);

  const [csvDataBuffer, setCsvDataBuffer] = React.useState([]);

  const [csvDataNPV, setCsvDataNPV] = React.useState([]);


  //fetch data from redux store

  //user-inputs-array
  const user_inputs_array_store = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs
  );

  //user-inputs-buffer

  const user_inputs_buffer_store = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs_buffer
  );

  // cost npv

  const annualCostNPV_store = useSelector(
    (state) => state.cost_benefit_slice.annual_cost_yearly,
  );

  const annualCostNPV_cummulative_store = useSelector(
    (state) => state.cost_benefit_slice.cummulative_discount_factor,
  );

  const detailedSummaryArrayStore = useSelector(
    (state) => state.cost_benefit_slice.cost_detailed_summary_array_values,
  );

  const detailedSummaryBufferStore = useSelector(
    (state) => state.cost_benefit_slice.cost_detailed_summary_buffer_values,
  );


  const costDetailedDataArray = GenerateCostDetailedData("array");
  const costDetailedDataBuffer = GenerateCostDetailedData("buffer");
  const costNpvData = GenerateNpvData();
  const costYearlyData = GenerateYearlyData();


  useEffect(() => {
    //for each object in the user_inputs_array_store, check if option_name is not empty, then push it to the csvData array

    Object.keys(user_inputs_array_store).forEach((key) => {
      if (user_inputs_array_store[key].array_size !== "") {
        setCsvData((csvData) => [...csvData, user_inputs_array_store[key]]);
      }
    }
    );
  }, [user_inputs_array_store]);

  useEffect(() => {
    //for each object in the user_input_buffer_store, check if option_name is not empty, then push it to the csvData array

    Object.keys(user_inputs_buffer_store).forEach((key) => {
      if (user_inputs_buffer_store[key].array_size !== "") {
        setCsvDataBuffer((csvDataBuffer) => [...csvDataBuffer, user_inputs_buffer_store[key]]);
      }
    }
    );
  }
    , [user_inputs_buffer_store]);

  useEffect(() => {
    //for each item in the annualCostNPV_store array, set the year as index starting from 0 and total_cost_incurred_in_year as the value
    Object.keys(annualCostNPV_store).forEach((key) => {
      setCsvDataNPV((csvDataNPV) => [...csvDataNPV, annualCostNPV_store[key]]);
    }
    );
  }, [annualCostNPV_store]);

  useEffect(() => {
    //for each item in the annualCostNPV_store array, check if the year is not empty, then push it to the csvData array as total_value
    Object.keys(annualCostNPV_cummulative_store).forEach((key) => {
      if(annualCostNPV_cummulative_store[key].year !== ""){
        setCsvDataNPV((csvDataNPV) => [...csvDataNPV, annualCostNPV_cummulative_store[key]]);
      }
    }
    );
  }, [annualCostNPV_cummulative_store]);

  const handleDownload = async () => {
    // Create a zip file containing both CSV files
    const zip = new JSZip();

    // Convert blob to xlsx format

    // Define column names
    const columnNames = csv_columns_array.map(col => col.label).join(',');

    // Create CSV content for user_input_array.csv
    const userInputArrayCSV = [columnNames, ...csvData.map(row => csv_columns_array.map(col => row[col.key]).join(','))];

    // Create CSV content for user_input_buffer.csv
    const userInputBufferCSV = [columnNames, ...csvDataBuffer.map(row => csv_columns_array.map(col => row[col.key]).join(','))];

    //send axios request
    try {
      const costDetailedDataArrayResponse = await api.post('/Excel/DetailedSummary', costDetailedDataArray, { responseType: 'arraybuffer' });
      const costDetailedDataBufferResponse = await api.post('/Excel/DetailedSummary', costDetailedDataBuffer, { responseType: 'arraybuffer' });
      //const costNpvDataResponse = await api.post('/Excel/CostNpv', costNpvData, { responseType: 'arraybuffer' });
      const costYearlyDataResponse = await api.post('/Excel/CostYearly', costYearlyData, { responseType: 'arraybuffer' });

      // Convert array buffer to blob
      const costDetailedDataArrayBlob = new Blob([costDetailedDataArrayResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const costDetailedDataBufferBlob = new Blob([costDetailedDataBufferResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //const costNpvDataBlob = new Blob([costNpvDataResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const costYearlyDataBlob = new Blob([costYearlyDataResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      zip.file('cost_detailed_array.xlsx', costDetailedDataArrayBlob);
      zip.file('cost_detailed_buffer.xlsx', costDetailedDataBufferBlob);
      //zip.file('cost_npv.xlsx', costNpvDataBlob);
      zip.file('cost_yearly.xlsx', costYearlyDataBlob);
      zip.file('user_input_array.csv', userInputArrayCSV.join('\n'));
      zip.file('user_input_buffer.csv', userInputBufferCSV.join('\n'));
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      let year = new Date().getFullYear();
      let hour = new Date().getHours();
      let minute = new Date().getMinutes();

      let mdyt = month + "_" + day + "_" + year + "_" + hour + minute;

      zip.generateAsync({ type: 'blob' })
        .then((content) => {
          const zipLink = document.createElement('a');
          zipLink.href = URL.createObjectURL(content);
          zipLink.download = 'CostComparisonSheets_' + mdyt + '.zip';
          zipLink.click();
        });
    } catch (error) {
      console.log('error', error);
    }
  };


  return (
    <>
      {csvData.length > 0 && csvDataBuffer.length > 0 ? (
          <button className="btn btn-primary " onClick={()=>handleDownload()}>Download CSV</button>      ) : null}
    </>
  )
}

export default GenerateCSV