import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { seed_selection_actions } from "../../../store/seed_selection_slice";
import { useEffect } from "react";
import SoilMoistureList from "./SoilMoistureList";
import { Input } from "antd";
import "../../../css/main.css";
import { Slider } from "antd";
import Title from "antd/lib/skeleton/Title";
import api from "../../../axiosConfig";
import { GlassMagnifier } from "react-image-magnifiers";
import smallEcoRegionMap from "../../../img/small-Eco_Level_III_US.png";
import largeEcoRegionMap from "../../../img/large-Eco_Level_III_US.png";
import soilTypesImage from "../../../img/soiltypes.png";
import cancelIcon from "../../../img/cancel_icon.png";
import Tooltip from "react-tooltip-lite";
import questionMark from "../../../img/sm-red-question-mark.png";
import { UilTimes } from "@iconscout/react-unicons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function SeedPlantingZoneFilters() {
  //constants used for the component
  const [checked, setChecked] = React.useState([false, false, false]);
  const [ecoRegions, setEcoRegions] = React.useState([]);
  const [SelectedEcoRegions, setSelectedEcoRegions] = React.useState([]);

  const selected_ecoregions_store = useSelector(
    (state) => state.seed_selection.selected_ecoregions
  );

  const planting_zone_filters_default_values = {
    soil_types: [],
    soil_moisture: [],
    light_exposure: [],
    soil_tolerance: [],
    soil_pH: [],
    soil_pH_min: 0,
    soil_pH_max: 0,
    ecoRegions: {}
  };

  const [planting_zone_values, setPlantingZoneValues] = React.useState(
    planting_zone_filters_default_values
  );

  const sliderMarks = {
    0: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    14: "14"
  };

  //redux
  const dispatch = useDispatch();
  const planting_zone_filters_store = useSelector(
    (state) => state.seed_selection.planting_zone_filters
  );
  const planting_zone_checkboxes_store = useSelector(
    (state) => state.seed_selection.planting_zone_filters_checkboxes
  );
  const undefined_checkboxes_store = useSelector(
    (state) => state.seed_selection.undefined_checkboxes
  );

  const handleValueChange = (e) => {
    //console.log('input value inside the planting zone component',e.target.name , e.target.value);
    const { name, value } = e.target;
    //console.log('name', name);
    //console.log('value', value);
    if (Array.isArray(planting_zone_values[name])) {
      setPlantingZoneValues({
        ...planting_zone_values,
        [name]: [...planting_zone_values[name], value]
      });
      //send a dispatch to the redux store to update the planting_zone_checkboxes store
      if (!planting_zone_values[name].includes(value)) {
        //console.log('value does not exist in the array');
        setPlantingZoneValues({
          ...planting_zone_values,
          [name]: [...planting_zone_values[name], value]
        });
        dispatch(
          seed_selection_actions.update_planting_zone_filters_checkboxes({
            name,
            value
          })
        );
      } else {
        //console.log('value exists in the array');
        //remove the value from the array
        setPlantingZoneValues({
          ...planting_zone_values,
          [name]: planting_zone_values[name].filter((item) => item !== value)
        });
        dispatch(
          seed_selection_actions.update_planting_zone_filters_checkboxes({
            name,
            value
          })
        );
      }
    } else {
      setPlantingZoneValues({ ...planting_zone_values, [name]: value });
    }
  };

  const handleUndefinedChange = (e) => {
    const { name, value } = e.target;
    //console.log('name', name);
    //console.log('value', value);
    if (Array.isArray(planting_zone_values[name])) {
      setPlantingZoneValues({
        ...planting_zone_values,
        [name]: [...planting_zone_values[name], value]
      });
      //send a dispatch to the redux store to update the planting_zone_checkboxes store
      if (!planting_zone_values[name].includes(value)) {
        //console.log('value does not exist in the array');
        setPlantingZoneValues({
          ...planting_zone_values,
          [name]: [...planting_zone_values[name], value]
        });
        dispatch(
          seed_selection_actions.update_undefined_checkboxes({ name, value })
        );
      } else {
        //console.log('value exists in the array');
        //remove the value from the array
        setPlantingZoneValues({
          ...planting_zone_values,
          [name]: planting_zone_values[name].filter((item) => item !== value)
        });
        dispatch(
          seed_selection_actions.update_undefined_checkboxes({ name, value })
        );
      }
    } else {
      setPlantingZoneValues({ ...planting_zone_values, [name]: value });
    }
  };

  const handlePhChange = (e) => {
    setPlantingZoneValues({ ...planting_zone_values, soil_pH: e });
    dispatch(
      seed_selection_actions.set_planting_zone_filters(planting_zone_values)
    );
  };

  const handleCancelClick = () => {
    setPlantingZoneValues({
      ...planting_zone_values,
      soil_pH: []
    });
    dispatch(seed_selection_actions.reset_ph());
  };

  useEffect(() => {
    //console.log('ue', planting_zone_values);
    dispatch(
      seed_selection_actions.set_planting_zone_filters(planting_zone_values)
    );
  }, [planting_zone_values]);

  useEffect(() => {
    api
      .get("/Ecoregions")
      .then((response) => {
        //console.log('response from the api', response.data);
        setEcoRegions(response.data);
      })
      .catch((error) => console.log(error.response));
    setPlantingZoneValues(planting_zone_filters_store);
    setSelectedEcoRegions(selected_ecoregions_store);
  }, []);

  useEffect(() => {
    //console.log('planting zone values', planting_zone_values['ecoRegions']);
    ecoRegions.map((region) => {
      const regionName = region.ecoregionId;
      setPlantingZoneValues({
        ...planting_zone_values,
        ecoRegions: {
          ...planting_zone_values["ecoRegions"],
          [regionName]: false
        }
      });
    });
    //setPlantingZoneValues(planting_zone_values);
  }, [ecoRegions]);

  useEffect(() => {
    dispatch(
      seed_selection_actions.set_selected_ecoregions(SelectedEcoRegions)
    );
  }, [SelectedEcoRegions]);

  const handleEcoRegion = (e) => {
    const { name, value } = e.target;
    //console.log('selected ecoregions', SelectedEcoRegions);
    //console.log('name eco region', name);
    //toggle the value of the checkbox for the eco region
    setPlantingZoneValues({
      ...planting_zone_values,
      ecoRegions: {
        ...planting_zone_values["ecoRegions"],
        [name]: !planting_zone_values["ecoRegions"][name]
      }
    });
    //dispatch(seed_selection_actions.update_planting_zone_filters_checkboxes({ name, value }));
    //if ecoregion does not exist in the selected ecoregions array, add it
    if (!SelectedEcoRegions.includes(name)) {
      //console.log('adding ecoregion to the array')
      setSelectedEcoRegions([...SelectedEcoRegions, name]);
      dispatch(
        seed_selection_actions.set_selected_ecoregions(SelectedEcoRegions)
      );
    }
  };

  return (
    <React.Fragment>
      <strong>
        <span style={{ fontSize: "30px", color: "red" }}>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; *{" "}
        </span>
        Hover over the words for descriptions
      </strong>
      <div className="container-fluid main-container black-border">
        <table>
          <tbody>
            <tr>
              <td width="25%">
                <FormGroup>
                  <Typography className="row" variant="h6" gutterBottom>
                    <Tooltip
                      content={
                        <>
                          <TableContainer className="tip-list">
                            <Table style={{ border: 1 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ color: "white" }}>
                                    Characteristic Soil
                                    <br /> Texture Group
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "white" }}
                                    colspan="3"
                                  >
                                    Corresponding Soil Texture Classes from the
                                    Soil Texture Triangle
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ color: "white" }}>
                                    Coarse
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Sand <br />
                                    Loamy coarse sand <br />
                                    Very fine sand
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Coarse sand <br />
                                    Loamy fine sand
                                    <br />
                                    Loamy sand
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Fine sand <br />
                                    Loamy fine sand
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ color: "white" }}>
                                    Medium
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Silt
                                    <br />
                                    Silty clay loam
                                    <br />
                                    Fine sandy clay loam
                                    <br />
                                    Clay loam
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Sandy clay loam
                                    <br />
                                    Silt loam
                                    <br />
                                    Sandy loam
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Very fine sandy loam
                                    <br />
                                    Loam
                                    <br />
                                    Coarse sandy loam
                                    <br />
                                    &nbsp;
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ color: "white" }}>
                                    Fine
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Sandy clay
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {" "}
                                    Silty clay
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    Clay
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <span
                            style={{
                              fontStyle: "italic",
                              fontSize: "13px",
                              color: "white"
                            }}
                          >
                            **Soil texture classes are specified by the Soil
                            Science Society of America with additional
                            categorization by NRCS.
                          </span>
                        </>
                      }
                    >
                      <img src={questionMark} height="20px" /> Soil Type:
                    </Tooltip>
                  </Typography>

                  <FormControlLabel
                    className="col-1"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.coarse}
                        onChange={(e) => handleValueChange(e)}
                        name="soil_types"
                        value="Coarse"
                        color="primary"
                        label="Array Zone"
                      />
                    }
                    label="Coarse"
                  />
                  <FormControlLabel
                    className="col-1"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.medium}
                        onChange={(e) => handleValueChange(e)}
                        name="soil_types"
                        value="Medium"
                        color="primary"
                      />
                    }
                    label="Medium"
                  />
                  <FormControlLabel
                    className="col-2"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.fine}
                        onChange={(e) => handleValueChange(e)}
                        name="soil_types"
                        value="Fine"
                        color="primary"
                      />
                    }
                    label="Fine"
                  />
                  <FormControlLabel
                    className="col-2"
                    control={
                      <Checkbox
                        checked={undefined_checkboxes_store.soil_types}
                        onChange={(e) => handleUndefinedChange(e)}
                        name="soil_types"
                        value="Undefined"
                        color="primary"
                      />
                    }
                    label="Undefined"
                  />
                </FormGroup>
              </td>
              <td width="25%">
                <FormGroup>
                  <Typography className="row" variant="h6" gutterBottom>
                    <Tooltip
                      content={
                        <ul className="tip-list">
                          <li>
                            Sun: Area receives direct sun for most of the day.
                          </li>
                          <li>
                            Partial: Area can receive shade for a portion of the
                            day.
                          </li>
                          <li>
                            Shade: Area receives little or no direct sun during
                            the day. Should only be selected for zones under
                            heavy tree canopy.
                          </li>
                          <li>
                            Undefined: No information available. Includes blank
                            or NULL values in the database.
                          </li>
                        </ul>
                      }
                    >
                      <img src={questionMark} height="20px" /> Light Exposure:
                    </Tooltip>
                  </Typography>
                  <FormControlLabel
                    className="col-1"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.sun}
                        onChange={(e) => handleValueChange(e)}
                        name="light_exposure"
                        value="Sun"
                        color="primary"
                      />
                    }
                    label="Sun"
                  />
                  <FormControlLabel
                    className="col-2"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.shade}
                        onChange={(e) => handleValueChange(e)}
                        name="light_exposure"
                        value="Shade"
                        color="primary"
                      />
                    }
                    label="Shade"
                  />
                  <FormControlLabel
                    className="col-7"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.pshade}
                        onChange={(e) => handleValueChange(e)}
                        name="light_exposure"
                        value="pshade"
                        color="primary"
                      />
                    }
                    label="Part Shade"
                  />
                  <FormControlLabel
                    className="col-3"
                    control={
                      <Checkbox
                        checked={undefined_checkboxes_store.light_exposure}
                        onChange={(e) => handleUndefinedChange(e)}
                        name="light_exposure"
                        value="Undefined"
                        color="primary"
                      />
                    }
                    label="Undefined"
                  />
                </FormGroup>
              </td>
              <td valign="top" width="25%">
                <FormGroup>
                  <Typography variant="h6" gutterBottom>
                    <Tooltip
                      content={
                        <ul className="tip-list">
                          <li>
                            Dry/Moist/Wet: Relative moisture of the soil between
                            rain events.
                          </li>
                          <li>
                            Undefined: No information available. Includes blank
                            or NULL values in the database.
                          </li>
                        </ul>
                      }
                    >
                      <img src={questionMark} height="20px" /> Soil Moisture:
                    </Tooltip>
                  </Typography>

                  <FormControlLabel
                    className="col-1"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.dry}
                        onChange={(e) => handleValueChange(e)}
                        name="soil_moisture"
                        value="Dry"
                        color="primary"
                      />
                    }
                    label="Dry"
                  />
                  <FormControlLabel
                    className="col-2"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.moist}
                        onChange={(e) => handleValueChange(e)}
                        name="soil_moisture"
                        value="Moist"
                        color="primary"
                      />
                    }
                    label="Moist"
                  />
                  <FormControlLabel
                    className="col-7"
                    control={
                      <Checkbox
                        checked={planting_zone_checkboxes_store.wet}
                        onChange={(e) => handleValueChange(e)}
                        name="soil_moisture"
                        value="Wet"
                        color="primary"
                      />
                    }
                    label="Wet"
                  />
                  <FormControlLabel
                    className="col-3"
                    control={
                      <Checkbox
                        checked={undefined_checkboxes_store.soil_moisture}
                        onChange={(e) => handleUndefinedChange(e)}
                        name="soil_moisture"
                        value="Undefined"
                        color="primary"
                      />
                    }
                    label="Undefined"
                  />
                </FormGroup>
              </td>
              <td valign="top" width="25%">
                <div className="col-md-6">
                  {/* one dropdown with options yes/no */}
                  <FormGroup>
                    <Typography variant="h6" gutterBottom>
                      <Tooltip
                        content={
                          <ul>
                            <li>
                              None: tolerant to a soil with an electrical
                              conductivity of the soil solution extract of 0-2
                              dS/m.
                            </li>
                            <li>Low: tolerant to 2.1-4.0 dS/m.</li>
                            <li>Medium: tolerant to 4.1-8.0 dS/m.</li>
                            <li>High: tolerant to greater than 8.0 dS/m.</li>
                            <li>Undefined: No information available. Includes blank or NULL values in the database.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Salt Tolerance:
                      </Tooltip>
                    </Typography>

                    <FormControlLabel
                      className="col-1"
                      control={
                        <Checkbox
                          checked={planting_zone_checkboxes_store.low}
                          onChange={(e) => handleValueChange(e)}
                          name="soil_tolerance"
                          value="Low"
                          color="primary"
                        />
                      }
                      label="Low"
                    />
                    <FormControlLabel
                      className="col-2"
                      control={
                        <Checkbox
                          checked={planting_zone_checkboxes_store.med}
                          onChange={(e) => handleValueChange(e)}
                          name="soil_tolerance"
                          value="Medium"
                          color="primary"
                        />
                      }
                      label="Medium"
                    />
                    <FormControlLabel
                      className="col-7"
                      control={
                        <Checkbox
                          checked={planting_zone_checkboxes_store.high}
                          onChange={(e) => handleValueChange(e)}
                          name="soil_tolerance"
                          value="High"
                          color="primary"
                        />
                      }
                      label="High"
                    />
                    <FormControlLabel
                      className="col-3"
                      control={
                        <Checkbox
                          checked={undefined_checkboxes_store.soil_tolerance}
                          onChange={(e) => handleUndefinedChange(e)}
                          name="soil_tolerance"
                          value="Undefined"
                          color="primary"
                        />
                      }
                      label="Undefined"
                    />
                  </FormGroup>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <br />
        <div
          className="d-flex justify-content-end mb-2"
          id="cancel__icon__container"
        >
          <a onClick={handleCancelClick}>
            <button className="btn btn-primary ">Reset pH</button>
          </a>
        </div>
        <div className="row">
          <div className="col-2">
            <Typography variant="h6" gutterBottom>
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>
                      Soil pH Minimum: The minimum soil pH of the top 12 inches
                      of soil within the plant's known geographical range. For
                      cultivars, the geographical range is defined as the area
                      to which the cultivar is well adapted rather than
                      marginally adapted.{" "}
                    </li>
                    <li>
                      Soil pH Maximum: The maximum soil pH of the top 12inches
                      of soil within the plant's known geographical range. For
                      cultivars, the geographical range is defined as the area
                      to which the cultivar is well adapted rather than
                      marginally adapted.{" "}
                    </li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> Soil pH:
              </Tooltip>
            </Typography>
          </div>

          <div className="col-10">
            <Slider
              range
              name="soil_ph"
              value={planting_zone_values.soil_pH}
              defaultValue={
                planting_zone_filters_store.soil_pH.length > 0
                  ? planting_zone_filters_store.soil_pH
                  : []
              }
              onChange={(e) => handlePhChange(e)}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              marks={sliderMarks}
              min={0}
              max={14}
              step={0.1}
            />
          </div>
        </div>

        {/* <br /><br />
                <hr></hr> */}

        {/* <table>
                    <tr>
                        <td valign="top">
                            <strong><center><span style={{ fontSize: "30px" }}> (Hover Over Map To Magnify Image)</span></center></strong>
                            <br />

                            <GlassMagnifier
                                imageSrc={smallEcoRegionMap}
                                imageAlt="U.S. Eco Regions"
                                allowOverflow="false"
                                largeImageSrc={largeEcoRegionMap}
                                magnifierBorderColor="Red"
                                magnifierBorderSize="4"
                                magnifierBackgroundColor="Black"
                                magnifierOffsetX="130"
                                magnifierOffsetY="130"
                                magnifierSize="25%"
                                square="true"

                            />
                        </td>
                        <td>

                                <div className='black-border'>
                                    <ul
                                        style={
                                            {
                                                listStyle: 'none',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexWrap: 'wrap'
                                            }
                                        }
                                    >
                                        {ecoRegions.map((ecoRegion, index) => {
                                            return (
                                                <li key={index}
                                                    style={
                                                        {
                                                            order: ecoRegion.ecoregionCode,
                                                        }
                                                    }
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={(planting_zone_filters_store['ecoRegions'] && planting_zone_filters_store['ecoRegions'][ecoRegion.ecoregion1]) ? planting_zone_filters_store['ecoRegions'][ecoRegion.ecoregion1] : false}
                                                                onChange={e => handleEcoRegion(e)}
                                                                name={ecoRegion.ecoregion1}
                                                                value={ecoRegion.ecoregion1 || ''}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={ecoRegion.ecoregion1 + ' ' + '( ' + ecoRegion.ecoregionCode + ')'}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>

                        </td>
                    </tr>
                </table> */}
      </div>
    </React.Fragment>
  );
}

export default SeedPlantingZoneFilters;
