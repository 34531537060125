import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Spin } from "antd";
import { Space, Typography } from "antd";
import CostSummary from "./CostSummary";
import CostDetailedSummary from "./CostDetailedSummary";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CostYearly from "./CostYearly";
import CostNPV from "./costNPV";


const { Title } = Typography;

function CostResults() {
  const [alignment, setAlignment] = React.useState("");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    setAlignment("results");
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-center">
           {/* <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="detailed_summary">
              Detailed Summary
            </ToggleButton>
            <ToggleButton value="30_year">30 Year</ToggleButton>
             <ToggleButton value="npv">NPV</ToggleButton>
            <ToggleButton value="results">Results</ToggleButton>
          </ToggleButtonGroup> */}
        </div>
        <div className="mt-4">
          {/* {alignment === "detailed_summary" && <CostDetailedSummary />}
          {alignment === "30_year" && <CostYearly />} */}
          {/* {alignment === "npv" && <CostNPV />} */}
          {alignment === "results" && <CostSummary />}
        </div>
      </div>
    </>
  );
}

export default CostResults;
