import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import axios from "axios";
import api from "../../../axiosConfig";

function SeedTableModal({ record, onClose }) {
  const [imageUrl, setImageUrl] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    if (record.scientificName) {
      // Construct the URL to fetch the Wikipedia image based on the scientificName
      const wikipediaUrl = `/Wikipedia?scientificName=${encodeURIComponent(
        record.scientificName
      )}`;

      // Fetch the Wikipedia image URL
      api
        .get(wikipediaUrl)
        .then((response) => {
        //console.log('response', response)
        const pages = response.data.query.pages;
        const imageSource = pages[Object.keys(pages)[0]].original.source;
        setImageUrl(imageSource);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [record.scientificName]);

  const handleImageLoad = () => {
    // Image has loaded, remove the blur effect class
    setImageLoaded(true);
  };

  return (
    <Modal
      title="Seed Information"
      visible={true} // You can manage the visibility using this prop
      onCancel={onClose}
      footer={null}
    >
      <h3>{record.scientificName}</h3>
      {imageUrl && (
        <img
          src={imageUrl}
          alt={record.scientificName}
          className={`image ${imageLoaded ? '' : 'blur-image'}`}
          style={{ maxWidth: "100%" }}
          onLoad={handleImageLoad}
        />
      )}
      <ul className="mt-2">
        <li>
          <strong>Accepted Symbol:</strong>{" "}
          <a
            href={`https://plants.usda.gov/core/profile?symbol=${record.acceptedSymbol}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.acceptedSymbol}
          </a>
        </li>
        <li>
          <strong>Common Name:</strong> {record.commonName}
        </li>
        <li>
          <strong>Duration:</strong> {record.duration}
        </li>
        <li>
          <strong>Growth Habit:</strong> {record.growthHabit}
        </li>
        <li>
          <strong>Native Status:</strong> {record.nativeStatus}
        </li>
        <li>
          <strong>Flower Color:</strong> {record.flowerColor}
        </li>
        <li>
          <strong>Bloom Period:</strong> {record.bloomPeriod}
        </li>
        <li>
          <strong>Sun Exposure:</strong> {record.sunExposure}
        </li>
        <li>
          <strong>Distribution in USA:</strong> {record.distributionInUsa}
        </li>
        <li>
          <strong>Level 3 Ecoregions:</strong> {record.level3Ecoregions}
        </li>
        <li>
          <strong>Soil Texture:</strong> {record.soilTexture}
        </li>
        <li>
          <strong>Moisture Use:</strong> {record.moistureUse}
        </li>
        <li>
          <strong>Soil Moisture:</strong> {record.soilMoisture}
        </li>
        <li>
          <strong>Soil pH:</strong> {record.soilPH}
        </li>
        <li>
          <strong>Palatable Browse Animal:</strong>{" "}
          {record.palatableBrowseAnimal}
        </li>
        <li>
          <strong>Palatable Graze Animal:</strong> {record.palatableGrazeAnimal}
        </li>
        <li>
          <strong>Salt Tolerance:</strong> {record.saltTolerance}
        </li>
        <li>
          <strong>Mature Height (Ft):</strong> {record.matureHeightFt}
        </li>
        <li>
          <strong>Maximum Mature Height (Ft):</strong>{" "}
          {record.maximumMatureHeightFt}
        </li>
        <li>
          <strong>Resprout Ability:</strong> {record.resproutAbility}
        </li>
        <li>
          <strong>Drought Tolerance:</strong> {record.droughtTolerance}
        </li>
        <li>
          <strong>Fire Tolerance:</strong> {record.fireTolerance}
        </li>
        <li>
          <strong>Benefits to Pollinators:</strong>{" "}
          {record.benefitsToPollinators}
        </li>
        <li>
          <strong>Pollinator Value:</strong> {record.pollinatorValue}
        </li>
        <li>
          <strong>Pollinators:</strong> {record.pollinators}
        </li>
        <li>
          <strong>Propagation:</strong> {record.propagation}
        </li>
        <li>
          <strong>Seed Commercial Availability:</strong>{" "}
          {record.seedCommercialAvailability}
        </li>
        <li>
          <strong>Relative Price:</strong> {record.relativePrice}
        </li>
        <li>
          <strong>Toxicity:</strong> {record.toxicity}
        </li>
      </ul>
    </Modal>
  );
}

export default SeedTableModal;
