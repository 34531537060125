import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../store/index";
import { Provider } from "react-redux";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "CAPEX and OPEX Cost by Option Evaluated",
      font: {
        size: 18
      }
    }
  },
  scales: {
    y: {
      ticks: {
        callback: function (value, index, values) {
          return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    }
  }
};

const handleGenerateData = (data) => {
  let labels = [
    "CAPEX Cost(Installation + Establishment)",
    "OPEX Cost(Maintenance)"
  ];
  let dataset1 = [];
  let dataset2 = [];
  let dataset3 = [];

  dataset1.push(
    data[1].array.total_cost_installation_phase +
      data[1].array.total_array_establishment_cost
  );
  dataset1.push(data[1].array.total_array_maintenance_cost);

  dataset2.push(
    data[2].array.total_cost_installation_phase +
      data[2].array.total_array_establishment_cost
  );
  dataset2.push(data[2].array.total_array_maintenance_cost);

  dataset3.push(
    data[3].array.total_cost_installation_phase +
      data[3].array.total_array_establishment_cost
  );
  dataset3.push(data[3].array.total_array_maintenance_cost);

  let datasets = [
    {
      label: "Option 1",
      data: dataset1,
      borderColor: "rgb(242,112,51)",
      backgroundColor: "rgba(242, 112, 51, 0.8)"
    },
    {
      label: "Option 2",
      data: dataset2,
      borderColor: "rgb(0, 85, 191)",
      backgroundColor: "rgba(0, 85, 191, 0.8)"
    },
    {
      label: "Option 3",
      data: dataset3,
      borderColor: "rgb(0, 122, 45)",
      backgroundColor: "rgba(0, 122, 45, 0.8)"
    }
  ];
  data = {
    labels,
    datasets
  };
  return data;
};

function BarGraphCapOpxCost() {
  //constants for the component

  const [graphData, setGraphData] = React.useState();

  //redux constants
  const cost_totals_store = useSelector(
    (state) => state.cost_benefit_slice.cost_total_values
  );

  useEffect(() => {
    let data = handleGenerateData(cost_totals_store);
    setGraphData(data);
  }, [cost_totals_store]);

  //   useEffect(() => {
  //     console.log('saving graph image in BarGraphCapOpxCostWrapper')
  //     //save as image
  //     let graphElementCapOpx = document.getElementById('graphCapOpx');
  //     html2canvas(graphElementCapOpx).then(function (canvas) {
  //         let graphImageCapOpx = canvas.toDataURL();
  //         //set the item in local storage, if exists , then overwrite it
  //         if (sessionStorage.getItem('BarGraphCapOpxCostWrapper')) {
  //             sessionStorage.removeItem('BarGraphCapOpxCostWrapper');
  //         }
  //         sessionStorage.setItem("BarGraphCapOpxCostWrapper", graphImageCapOpx);
  //     }
  //     );
  // }, [graphData]);

  //if grapphData is not null, then render the graph
  if (graphData) {
    return <Bar data={graphData} options={options} id="graphCapOpx" />;
  } else {
    return <div>Loading...</div>;
  }
}

const BarGraphCapOpxCostWrapper = () => {
  return (
    <Provider store={store}>
      <BarGraphCapOpxCost />
    </Provider>
  );
};

export default BarGraphCapOpxCostWrapper;
