export const submenuItems = [
    {
        title: 'Home',
        url: 'https://rightofway.erc.uic.edu/phase',
        external: true,
    },
    {
        title: 'About',
        url: 'https://rightofway.erc.uic.edu/wp-content/uploads/PHASE-Large-Scale-Solar-Research-Project-Overview_041321.pdf',
        external: true,
    },
    {
        title: 'Tools',
        url: 'https://rightofway.erc.uic.edu/phase-toolkits/',
        // submenu: [
        //     {
        //         title: 'Cost-Benefit Calculator',
        //         url: '/CostBenefit',
        //     },
        //     {
        //         title: 'Seed Selection Tool',
        //         url: '/SeedSelection',
        //     },
        // ],
        external: true
    },{
        title: 'News and Updates',
        url: 'https://rightofway.erc.uic.edu/phase-project-newsandupdates',
        external: true
    },
    {
        title: 'Related Research',
        submenu: [
            {
                title: 'AgriSolar Clearinghouse',
                url: 'https://web.evs.anl.gov/evssolar/',
                external: true
            },
            {
                title: 'Argonne Solar Pollinator Research',
                url: 'https://web.evs.anl.gov/evssolar/',
                external: true
            },
            {
                title: 'InSPIRE',
                url: 'https://openei.org/wiki/InSPIRE/Project',
                external: true
            },
            {
                title: 'SCAPES Agrivoltaic Research',
                url: 'https://scapes.illinois.edu/',
                external: true
            }
        ]
    }
];