function calculateCostSummaryValues(
  index,
  cost_detailed_summary_array_values,
  cost_detailed_summary_buffer_values,
  cost_detailed_summary_total_values,
  cost_input_array,
  cost_input_buffer,
  cost_information_store
) {
  /*
      function to calculate the cost summary values for the cost summary table
        @params
            index: option of the vegetation type
            cost_detailed_summary_values: array of cost summary values
            cost_detailed_summary_buffer: array of cost summary values for the buffer
            cost_detailed_summary_total_values: array of cost summary values for the total for the option
            cost_input_array: array of cost input values
            cost_input_buffer: array of cost input values for the buffer
            cost_information_store: object of cost information values from redux store

        @calculations
        installation phase maintenance

        pre-seeding --> {
            quantity: 2,
            complexity_multiplier: 0%,
            unit_cost: pre_seeding_cost[input] * array_size[input] * quantity
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        seed_mix_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: seed_mix_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        seed_installation_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: seed_installation_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        fertilizer_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: fertilizer_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        soil_amendments_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: soil_amendments_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        decompaction_or_tillage_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: decompaction_or_tillage_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        temporary_stablization_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: temporary_stablization_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        custom_input_cost_1 --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: custom_input_cost_1[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        custom_input_2_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: custom_input_2_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }
        custom_input_3_cost --> {
            quantity: ( 1 * array_size[input] )
            complexity_multiplier: 0%
            unit_cost: custom_input_3_cost[input]
            total_cost: (unit_cost * quantity) + (complexity_multiplier * unit_cost * quantity)
        }

        @returns
            cost_detailed_summary_array_values_copy: array of cost summary values after calculation
            cost_detailed_summary_buffer_values_copy: array of cost summary values for the buffer after calculation
            cost_detailed_summary_total_values_copy: array of cost summary values for the total for the option after calculation
    */

  //make copy of the passed params

  let cost_detailed_summary_array_values_copy = {
    ...cost_detailed_summary_array_values
  };
  let cost_detailed_summary_buffer_values_copy = {
    ...cost_detailed_summary_buffer_values
  };
  let cost_detailed_summary_total_values_copy = {
    ...cost_detailed_summary_total_values
  };

  cost_detailed_summary_array_values_copy = {
    ...cost_detailed_summary_array_values_copy,
    [index + 1]: {
      pre_seeding: {
        quantity: parseInt(2),
        complexity_multiplier: parseInt(0),
        unit_cost:
          parseInt(cost_input_array[index].pre_seeding_cost) *
          parseInt(cost_input_array[index].array_size),
        total_cost:
          parseInt(cost_input_array[index].pre_seeding_cost) *
            parseInt(cost_input_array[index].array_size) *
            parseInt(2) +
          parseInt(0) *
            parseInt(cost_input_array[index].pre_seeding_cost) *
            parseInt(cost_input_array[index].array_size) *
            parseInt(2)
      },
      seed_mix_cost: {
        quantity: parseInt(1) * parseInt(cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].seed_mix_cost_array),
        total_cost:
          parseInt(cost_input_array[index].seed_mix_cost_array) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_array[index].seed_mix_cost_array) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size))
      },
      seed_installation_cost: {
        quantity: parseInt(1) * parseInt(cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].seed_installation_cost),
        total_cost:
          parseInt(cost_input_array[index].seed_installation_cost) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_array[index].seed_installation_cost) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size))
      },
      fertilizer_cost: {
        quantity: parseInt(1) * parseInt(cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].fertilizer_cost_array),
        total_cost:
          parseInt(cost_input_array[index].fertilizer_cost_array) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_array[index].fertilizer_cost_array) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size))
      },
      soil_amendment_cost: {
        quantity: parseInt(1) * parseInt(cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(
          cost_input_array[index].soil_amendements_cost_array
        ),
        total_cost:
          parseInt(cost_input_array[index].soil_amendements_cost_array) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_array[index].soil_amendements_cost_array) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size))
      },
      decompaction_or_tillage_cost: {
        quantity: parseInt(1) * parseInt(cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(
          cost_input_array[index].decompation_or_tillage_cost
        ),
        total_cost:
          parseInt(cost_input_array[index].decompation_or_tillage_cost) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_array[index].decompation_or_tillage_cost) *
            (parseInt(1) * parseInt(cost_input_array[index].array_size))
      },
      temporary_stabilization_cost: {
        quantity: parseInt(1 * cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(
          cost_input_array[index].temporary_stabilization_cost
        ),
        total_cost:
          parseInt(cost_input_array[index].temporary_stabilization_cost) *
            parseInt(1 * cost_input_array[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_array[index].temporary_stabilization_cost) *
            parseInt(1 * cost_input_array[index].array_size)
      },
      custom_input_1_cost: {
        quantity: parseInt(1 * cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].custom_input_cost_1),
        total_cost:
          parseInt(cost_input_array[index].custom_input_cost_1) *
            parseInt(1 * cost_input_array[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_array[index].custom_input_cost_1) *
            parseInt(1 * cost_input_array[index].array_size)
      },
      custom_input_2_cost: {
        quantity: parseInt(1 * cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].custom_input_cost_2),
        total_cost:
          parseInt(cost_input_array[index].custom_input_cost_2) *
            parseInt(1 * cost_input_array[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_array[index].custom_input_cost_2) *
            parseInt(1 * cost_input_array[index].array_size)
      },
      custom_input_3_cost: {
        quantity: parseInt(1 * cost_input_array[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].custom_input_cost_3),
        total_cost:
          parseInt(cost_input_array[index].custom_input_cost_3) *
            parseInt(1 * cost_input_array[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_array[index].custom_input_cost_3) *
            parseInt(1 * cost_input_array[index].array_size)
      },
      no_of_mowing_treatments_establishment: {
        quantity: parseInt(
          cost_input_array[index].no_of_mowing_treatments_establishment
        ),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_array[index].mowing_treatment_cost),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_mowing_treatments_establishment
          ) *
          parseInt(cost_input_array[index].mowing_treatment_cost) *
          parseInt(cost_input_array[index].array_size) *
          parseInt(1)
      },
      no_of_mowing_treatments_maintenance: {
        quantity: parseInt(
          cost_input_array[index].no_of_mowing_treatments_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(cost_input_array[index].mowing_treatment_cost),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_mowing_treatments_maintenance
          ) *
          parseInt(cost_input_array[index].mowing_treatment_cost) *
          parseInt(cost_input_array[index].array_size) *
          1
      },
      no_of_herbicide_applications_establishment: {
        quantity: parseInt(
          cost_input_array[index].no_of_herbicide_treatments_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(cost_input_array[index].herbicide_treatment_cost),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_herbicide_treatments_establishment
          ) *
          parseInt(cost_input_array[index].herbicide_treatment_cost) *
          parseInt(cost_input_array[index].array_size) *
          1
      },
      no_of_herbicide_applications_maintenance: {
        quantity: parseInt(
          cost_input_array[index].no_of_herbicide_treatments_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(cost_input_array[index].herbicide_treatment_cost),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_herbicide_treatments_maintenance
          ) *
          parseInt(cost_input_array[index].herbicide_treatment_cost) *
          parseInt(cost_input_array[index].array_size) *
          1
      },
      no_of_supplemental_seedings_establishment: {
        quantity: parseInt(
          cost_input_array[index].no_of_supplemental_seeding_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseFloat(
          cost_input_array[index].supplemental_seeding_cost
        ),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_supplemental_seeding_establishment
          ) *
          parseFloat(cost_input_array[index].supplemental_seeding_cost) *
          cost_input_array[index].array_size *
          1
      },
      no_of_supplemental_seedings_maintenance: {
        quantity: parseInt(
          cost_input_array[index].no_of_supplemental_seeding_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseFloat(
          cost_input_array[index].supplemental_seeding_cost
        ),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_supplemental_seeding_maintenance
          ) *
          parseFloat(cost_input_array[index].supplemental_seeding_cost) *
          cost_input_array[index].array_size *
          1
      },
      grazing_management_services_establishment: {
        quantity: parseInt(
          cost_input_array[index].no_of_grazing_management_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseFloat(
          cost_input_array[index].grazing_management_service_cost
        ),
        total_cost:
          parseInt(
            cost_input_array[index].no_of_grazing_management_establishment
          ) *
          parseFloat(cost_input_array[index].grazing_management_service_cost) *
          cost_input_array[index].array_size *
          1
      },
      grazing_management_services_maintenance: {
        quantity: parseInt(
          cost_input_array[index].no_of_grazing_management_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(
          cost_input_array[index].grazing_management_service_cost
        ),
        total_cost: parseFloat(
          cost_input_array[index].no_of_grazing_management_maintenance *
            cost_input_array[index].grazing_management_service_cost *
            cost_input_array[index].array_size *
            1
        )
      },
      inspections_and_monitoring_establishment: {
        quantity: parseInt(
          cost_input_array[index].no_of_inspections_and_monitoring_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(
          cost_input_array[index].inspections_and_monitor_cost
        ),
        total_cost: parseFloat(
          cost_input_array[index]
            .no_of_inspections_and_monitoring_establishment *
            cost_input_array[index].inspections_and_monitor_cost *
            cost_input_array[index].array_size *
            1
        )
      },
      inspections_and_monitoring_maintenance: {
        quantity: parseInt(
          cost_input_array[index].no_of_inspections_and_monitoring_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(
          cost_input_array[index].inspections_and_monitor_cost
        ),
        total_cost: parseFloat(
          cost_input_array[index].no_of_inspections_and_monitoring_maintenance *
            cost_input_array[index].inspections_and_monitor_cost *
            cost_input_array[index].array_size *
            1
        )
      }
    }
  };

  cost_detailed_summary_buffer_values_copy = {
    ...cost_detailed_summary_buffer_values_copy,
    [index + 1]: {
      pre_seeding: {
        quantity: parseInt(2),
        complexity_multiplier: parseInt(0),
        unit_cost:
          parseInt(cost_input_buffer[index].pre_seeding_cost) *
          parseInt(cost_input_buffer[index].array_size),
        total_cost:
          parseInt(cost_input_buffer[index].pre_seeding_cost) *
            parseInt(cost_input_buffer[index].array_size) *
            parseInt(2) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].pre_seeding_cost) *
            parseInt(cost_input_buffer[index].array_size) *
            parseInt(2)
      },
      seed_mix_cost: {
        quantity: parseInt(1) * parseInt(cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].seed_mix_cost_array),
        total_cost:
          parseInt(cost_input_buffer[index].seed_mix_cost_array) *
            (parseInt(1) * parseInt(cost_input_buffer[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].seed_mix_cost_array) *
            (parseInt(1) * parseInt(cost_input_buffer[index].array_size))
      },
      seed_installation_cost: {
        quantity: parseInt(1) * parseInt(cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].seed_installation_cost),
        total_cost:
          parseInt(cost_input_buffer[index].seed_installation_cost) *
            (parseInt(1) * parseInt(cost_input_buffer[index].array_size)) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].seed_installation_cost) *
            (parseInt(1) * parseInt(cost_input_buffer[index].array_size))
      },
      fertilizer_cost: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].fertilizer_cost_array),
        total_cost:
          parseInt(cost_input_buffer[index].fertilizer_cost_array) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].fertilizer_cost_array) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      soil_amendment_cost: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(
          cost_input_buffer[index].soil_amendements_cost_array
        ),
        total_cost:
          parseInt(cost_input_buffer[index].soil_amendements_cost_array) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].soil_amendements_cost_array) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      decompaction_or_tillage_cost: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(
          cost_input_buffer[index].decompation_or_tillage_cost
        ),
        total_cost:
          parseInt(cost_input_buffer[index].decompation_or_tillage_cost) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].decompation_or_tillage_cost) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      temporary_stabilization_cost: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(
          cost_input_buffer[index].temporary_stabilization_cost
        ),
        total_cost:
          parseInt(cost_input_buffer[index].temporary_stabilization_cost) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].temporary_stabilization_cost) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      custom_input_cost_1: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].custom_input_cost_1),
        total_cost:
          parseInt(cost_input_buffer[index].custom_input_cost_1) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].custom_input_cost_1) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      custom_input_2_cost: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].custom_input_cost_2),
        total_cost:
          parseInt(cost_input_buffer[index].custom_input_cost_2) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].custom_input_cost_2) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      custom_input_3_cost: {
        quantity: parseInt(1 * cost_input_buffer[index].array_size),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].custom_input_cost_3),
        total_cost:
          parseInt(cost_input_buffer[index].custom_input_cost_3) *
            parseInt(1 * cost_input_buffer[index].array_size) +
          parseInt(0) *
            parseInt(cost_input_buffer[index].custom_input_cost_3) *
            parseInt(1 * cost_input_buffer[index].array_size)
      },
      no_of_mowing_treatments_establishment: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_mowing_treatments_establishment
        ),
        complexity_multiplier: parseInt(0),
        unit_cost: parseInt(cost_input_buffer[index].mowing_treatment_cost),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_mowing_treatments_establishment
          ) *
          parseInt(cost_input_buffer[index].mowing_treatment_cost) *
          parseInt(cost_input_buffer[index].array_size) *
          parseInt(1)
      },
      no_of_mowing_treatments_maintenance: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_mowing_treatments_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(cost_input_buffer[index].mowing_treatment_cost),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_mowing_treatments_maintenance
          ) *
          parseInt(cost_input_buffer[index].mowing_treatment_cost) *
          parseInt(cost_input_buffer[index].array_size) *
          1
      },
      no_of_herbicide_applications_establishment: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_herbicide_treatments_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(cost_input_buffer[index].herbicide_treatment_cost),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_herbicide_treatments_establishment
          ) *
          parseInt(cost_input_buffer[index].herbicide_treatment_cost) *
          parseInt(cost_input_buffer[index].array_size) *
          1
      },
      no_of_herbicide_applications_maintenance: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_herbicide_treatments_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(cost_input_buffer[index].herbicide_treatment_cost),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_herbicide_treatments_maintenance
          ) *
          parseInt(cost_input_buffer[index].herbicide_treatment_cost) *
          parseInt(cost_input_buffer[index].array_size) *
          1
      },
      no_of_supplemental_seedings_establishment: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_supplemental_seeding_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseFloat(
          cost_input_buffer[index].supplemental_seeding_cost
        ),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_supplemental_seeding_establishment
          ) *
          parseFloat(cost_input_buffer[index].supplemental_seeding_cost) *
          cost_input_buffer[index].array_size *
          1
      },
      no_of_supplemental_seedings_maintenance: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_supplemental_seeding_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseFloat(
          cost_input_buffer[index].supplemental_seeding_cost
        ),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_supplemental_seeding_maintenance
          ) *
          parseFloat(cost_input_buffer[index].supplemental_seeding_cost) *
          cost_input_buffer[index].array_size *
          1
      },
      grazing_management_services_establishment: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_grazing_management_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseFloat(
          cost_input_buffer[index].grazing_management_service_cost
        ),
        total_cost:
          parseInt(
            cost_input_buffer[index].no_of_grazing_management_establishment
          ) *
          parseFloat(cost_input_buffer[index].grazing_management_service_cost) *
          cost_input_buffer[index].array_size *
          1
      },
      grazing_management_services_maintenance: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_grazing_management_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(
          cost_input_buffer[index].grazing_management_service_cost
        ),
        total_cost: parseFloat(
          cost_input_buffer[index].no_of_grazing_management_maintenance *
            cost_input_array[index].grazing_management_service_cost *
            cost_input_array[index].array_size *
            1
        )
      },
      inspections_and_monitoring_establishment: {
        quantity: parseInt(
          cost_input_buffer[index]
            .no_of_inspections_and_monitoring_establishment
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(
          cost_input_buffer[index].inspections_and_monitor_cost
        ),
        total_cost: parseFloat(
          cost_input_buffer[index]
            .no_of_inspections_and_monitoring_establishment *
            cost_input_buffer[index].inspections_and_monitor_cost *
            cost_input_buffer[index].array_size *
            1
        )
      },
      inspections_and_monitoring_maintenance: {
        quantity: parseInt(
          cost_input_buffer[index].no_of_inspections_and_monitoring_maintenance
        ),
        complexity_multiplier: 0,
        unit_cost: parseInt(
          cost_input_buffer[index].inspections_and_monitor_cost
        ),
        total_cost: parseFloat(
          cost_input_buffer[index]
            .no_of_inspections_and_monitoring_maintenance *
            cost_input_buffer[index].inspections_and_monitor_cost *
            cost_input_buffer[index].array_size *
            1
        )
      }
    }
  };
  //add the value of sum of all the total costs until custom_input_3_cost for the cost_detailed_summary_array_values_copy and add it to cost_detailed_summary_total_default_values.array.total_cost_installation_phase
  let total_installation_cost_array =
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].pre_seeding.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .decompaction_or_tillage_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .temporary_stabilization_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
        .total_cost
    );

  let total_installation_cost_buffer =
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .decompaction_or_tillage_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .temporary_stabilization_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
        .total_cost
    );

  let total_array_establishment_cost =
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_mowing_treatments_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_herbicide_applications_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_supplemental_seedings_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .grazing_management_services_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .inspections_and_monitoring_establishment.total_cost
    );

  let total_array_maintenance_cost =
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_mowing_treatments_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_herbicide_applications_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_supplemental_seedings_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .grazing_management_services_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .inspections_and_monitoring_maintenance.total_cost
    );

  let total_buffer_establishment_cost =
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_mowing_treatments_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_herbicide_applications_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_supplemental_seedings_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .grazing_management_services_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .inspections_and_monitoring_establishment.total_cost
    );

  let total_buffer_maintenance_cost =
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_mowing_treatments_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_herbicide_applications_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_supplemental_seedings_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .grazing_management_services_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .inspections_and_monitoring_maintenance.total_cost
    );

  cost_detailed_summary_total_values_copy = {
    ...cost_detailed_summary_total_values_copy,
    [index + 1]: {
      array: {
        total_cost_installation_phase: total_installation_cost_array,
        total_array_establishment_cost:
          total_array_establishment_cost *
          cost_information_store.establishment_phase,
        total_array_maintenance_cost:
          total_array_maintenance_cost *
          cost_information_store.maintenance_phase
      },
      buffer: {
        total_cost_installation_phase: total_installation_cost_buffer,
        total_buffer_establishment_cost:
          total_buffer_establishment_cost *
          cost_information_store.establishment_phase,
        total_buffer_maintenance_cost:
          total_buffer_maintenance_cost *
          cost_information_store.maintenance_phase
      }
    }
  };

  return [
    cost_detailed_summary_array_values_copy,
    cost_detailed_summary_buffer_values_copy,
    cost_detailed_summary_total_values_copy
  ];
}

// Path: source/SETOTools/ClientApp/src/components/seto_tools/cost_benefit_components/utils/calculateCostSummaryValues.js

export default calculateCostSummaryValues;
