import React, { Component } from 'react';
import { Layout } from './components/Layout';
import  Home from './components/Home';
import Tools from './components/Tools';
import About from './components/About';
import Contact from './components/Contact';
import Help from './components/Help';
import CostBenefit from './components/seto_tools/CostBenefit';
import SeedSelection from './components/seto_tools/SeedSelection';
import Acknowledgements from './components/Acknowledgements';
//import PollinatorImplementManual from './components/seto_tools/PollinatorImplementManual';
//import HabitatAssessModule from './components/seto_tools/HabitatAssessModule';
import './custom.css'
import {cost_benefit_actions} from '../src/store/cost_benefit_slice'

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Tools" element={<Tools />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Help" element={<Help />} />
            <Route path="/Acknowledgements" element={<Acknowledgements />} />
            <Route path="/CostComparison" element={<CostBenefit />} />
            <Route path="/SeedSelection" element={<SeedSelection />} />
            {/*<Route path="/PollinatorImplementManual" element={<PollinatorImplementManual />} />*/}
            {/*<Route path="/HabitatAssessModule" element={<HabitatAssessModule />} />*/}
          </Routes>
      </Layout>
    );
  }
}

export {Router};
