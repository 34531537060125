import React from 'react'

function Contact() {
  return (
    <>
      <div className='container-fluid'>
         <p>
            Contact Component
         </p>
      </div>
    </>
  )
}

export default Contact