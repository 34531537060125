import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { seed_selection_actions } from "../../../store/seed_selection_slice";
import { Slider } from "antd";
import Tooltip from 'react-tooltip-lite';
import questionMark from "../../../img/sm-red-question-mark.png";



function Refinements() {
  //constants used for the component

  const [checked, setChecked] = React.useState([false, false, false]);

  const refinements_default_values = {
    pollinator_benefits: [],
    seeding_season: [],
    growth_form:[],
    bloom_period_earliest: "",
    bloom_period: [],
    bloom_period_latest: "",
    seed_availability: "",
    quick_germination: [],
    grazing_toxicity:"",
    relative_price:[]
  };

  const [refinements_values, setRefinementsValues] = React.useState(
    refinements_default_values
  );

  const sliderMarks = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
    };


  //redux
  const dispatch = useDispatch();
  const refinements_store = useSelector(
    (state) => state.seed_selection.refinements
  );
  const refinements_checkboxes_store = useSelector(
    (state) => state.seed_selection.refinements_checkboxes
  );

  const undefined_checkboxes_store = useSelector(state => state.seed_selection.undefined_checkboxes);


  const handleChange = (event, newValue) => {};

  const handleChangeCheckbox = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setChecked({ ...checked, [name]: value });
  };

  const handleValueChange = (e) => {
    //console.log('input value inside refinements component',e.target.name , e.target.value);
    const { name, value } = e.target;
    if (Array.isArray(refinements_values[name])) {
      if(!refinements_values[name].includes(value)){
        //console.log('value not in array')
        setRefinementsValues({
          ...refinements_values,
          [name]: [...refinements_values[name], value],
        });
        //send a dispatch to the redux store to update the refinements_checkboxes store
        dispatch(
          seed_selection_actions.update_refinements_checkboxes({ name, value })
        );
      }
      else{
        //console.log('value in array')
        setRefinementsValues({
          ...refinements_values,
          [name]: refinements_values[name].filter(item => item !== value),
        });
        //send a dispatch to the redux store to update the refinements_checkboxes store
        dispatch(
          seed_selection_actions.update_refinements_checkboxes({ name, value })
        );
      }
    } else {
      setRefinementsValues({ ...refinements_values, [name]: value });
    }
    //send a dispatch to the reducer to update the initial inputs
  };

  const handleUndefinedChange = (e) => {
    const { name, value } = e.target;
    //console.log('name', name);
    //console.log('value', value);
    if (Array.isArray(planting_zone_values[name])) {
        setPlantingZoneValues({ ...planting_zone_values, [name]: [...planting_zone_values[name], value] });
        //send a dispatch to the redux store to update the planting_zone_checkboxes store
        if(!planting_zone_values[name].includes(value)){
            //console.log('value does not exist in the array');
            setPlantingZoneValues({ ...planting_zone_values, [name]: [...planting_zone_values[name], value] });
            dispatch(seed_selection_actions.update_undefined_checkboxes({ name, value }));
        }
        else{
            //console.log('value exists in the array');
            //remove the value from the array
            setPlantingZoneValues({ ...planting_zone_values, [name]: planting_zone_values[name].filter(item => item !== value) });
            dispatch(seed_selection_actions.update_undefined_checkboxes({ name, value }));
        }
    }
    else {
        setPlantingZoneValues({ ...planting_zone_values, [name]: value });
    }
}

  const handleSliderChange = (value) => {
    //console.log("slider value", value);
    setRefinementsValues({ ...refinements_values, bloom_period: value });
    dispatch(seed_selection_actions.set_refinements(refinements_values));
    // setRefinementsValues({...refinements_values, bloom_period_earliest: value[0], bloom_period_latest: value[1]});
    // dispatch(seed_selection_actions.set_refinements(refinements_values));
  };

  useEffect(() => {
    setRefinementsValues(refinements_store);
  }, []);

  useEffect(() => {
    //console.log("refinements_values", refinements_values);
    dispatch(seed_selection_actions.set_refinements(refinements_values));
  }, [refinements_values]);

  useEffect(() => {
    //console.log("refinements store", refinements_store);
  }, [refinements_store]);


    ///////////////////////////////////////////////////////////////////////////////////////////
    //Moved Additional Refinements to this page

    //const refinements_default_values = {
    //    quick_germination: []
    //};


    //const [refinements_values, setRefinementsValues] = React.useState(refinements_default_values)

    //redux
    //const refinements_store = useSelector(state => state.seed_selection.refinements);
    //const refinements_checkboxes_store = useSelector(state => state.seed_selection.refinements_checkboxes);

    const handleValueChange2 = (e) => {
        //console.log('input value inside refinements component',e.target.name , e.target.value);
        const { name, value } = e.target;
        if (Array.isArray(refinements_values[name])) {
            if (!refinements_values[name].includes(value)) {
                setRefinementsValues({ ...refinements_values, [name]: [...refinements_values[name], value] });
                dispatch(seed_selection_actions.update_refinements_checkboxes({ name, value }));
            }
            else {
                setRefinementsValues({ ...refinements_values, [name]: refinements_values[name].filter(item => item !== value) });
                dispatch(seed_selection_actions.update_refinements_checkboxes({ name, value }));
            }
        }
        else {
            setRefinementsValues({ ...refinements_values, [name]: value });
        }
        //send a dispatch to the reducer to update the initial inputs
    }

    const handleCheckboxChange = (props) => {
        const arr_value = props.target.arr_value;
        //check if the arr_value exists in the array store
        if (refinements_store.arr_name.includes(arr_value)) {
            return true;
        }
        else {
            return false;
        }

    }

    useEffect(() => {
        //console.log('refinements_values', refinements_values);
        dispatch(seed_selection_actions.set_refinements(refinements_values));
    }, [refinements_values]);

    useEffect(() => {
        setRefinementsValues(refinements_store);
    }, []);


  return (
<React.Fragment>
  <strong>
    <span style={{ fontSize: '30px', color: 'red' }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; * </span>
    Hover over the words for descriptions
  </strong>
  <div className="container-fluid main-container black-border">
    <div className="row mt-2">
      <div className="col-md-3">
        <Typography variant="h6" gutterBottom>
          <Tooltip content={(<ul className="tip-list">
              <li>Specific benefits to pollinators, including:</li>
              <li>Larval food: Plants that act as host plants for larval stage feeding requirements for insect pollinators.</li>
              <li>Adult food: Plants that supply pollen (protein) and/or nectar (carbohydrate) resources for adult insect pollinators.</li>
              <li>Nesting and structure (bees): Plants that provide nesting, egg laying, or other structural requirements for bees and other insect pollinators.</li>
            </ul>)}>

          <img src={questionMark} height="20px" /> Pollinator Benefits:&nbsp;
        </Tooltip>
        </Typography>
      </div>
      <div className="col-md-8 d-flex">
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.larval}
                onChange={(e) => handleValueChange(e)}
                name="pollinator_benefits"
                value="larval"
                color="primary"
              />
            }
            label="Larval Food"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.nesting}
                onChange={(e) => handleValueChange(e)}
                name="pollinator_benefits"
                value="nesting"
                color="primary"
              />
            }
            label="Nesting and Structure (Bees)"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.adult}
                onChange={(e) => handleValueChange(e)}
                name="pollinator_benefits"
                value="adult"
                color="primary"
              />
            }
            label="Adult Food"
          />
        </div>
      </div>
    </div>
    {/* <div className="row mt-4">
      <div className="col-md-3">
        <Typography variant="h6" gutterBottom>
          <Tooltip content={(<ul className="tip-list">
              <li>The season in which the area is to be seeded/sown.</li>
            </ul>)}>

          <img src={questionMark} height="20px" /> Seeding Season:&nbsp;
        </Tooltip>
        </Typography>
      </div>
      <div className="col-md-8 d-flex">
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.spring}
                onChange={(e) => handleValueChange(e)}
                name="seeding_season"
                value="Spring"
                color="primary"
              />
            }
            label="Spring"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.summer}
                onChange={(e) => handleValueChange(e)}
                name="seeding_season"
                value="Summer"
                color="primary"
              />
            }
            label="Summer"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.fall}
                onChange={(e) => handleValueChange(e)}
                name="seeding_season"
                value="Fall"
                color="primary"
              />
            }
            label="Fall"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.winter}
                onChange={(e) => handleValueChange(e)}
                name="seeding_season"
                value="Winter"
                color="primary"
              />
            }
            label="Winter"
          />
        </div>
      </div>
    </div> */}
    <div className="row mt-4">
      <div className="col-md-3">
      <Typography variant="h6" gutterBottom>
          <Tooltip content={(<ul className="tip-list">
              <li>Grass/Graminoid: Grass or grass-like plant, including grasses, sedges, rushes.</li>
              <li>Forb: Non-woody plants including flowering plants and ferns.</li>
              <li>Shrub: Perennial, multi-stemmed woody plant that are usually less than 4 to 5 meters (13 to 16 feet) in mature height.</li>
              <li>Tree: Perennial, woody plant with a singular stem, normally greater than 4 to 5 meters (13 to 16 feet) in mature height.</li>
              <li>Vine: Crawling/climbing plant with relatively long stems, can be woody or herbaceous.</li>
              <li>Undefined: Plants that did not have a growth form specified.</li>
            </ul>)}>
          <img src={questionMark} height="20px" /> Growth Form:&nbsp;
        </Tooltip>
      </Typography>
      </div>
      <div className="col-md-8">
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.grass}
                onChange={(e) => handleValueChange(e)}
                name="growth_form"
                value="grass"
                color="primary"
              />
            }
            label="Grass/Graminoid"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.forb}
                onChange={(e) => handleValueChange(e)}
                name="growth_form"
                value="forb"
                color="primary"
              />
            }
            label="Forb"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.shrub}
                onChange={(e) => handleValueChange(e)}
                name="growth_form"
                value="shrub"
                color="primary"
              />
            }
            label="Shrub"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.tree}
                onChange={(e) => handleValueChange(e)}
                name="growth_form"
                value="tree"
                color="primary"
              />
            }
            label="Tree"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.vine}
                onChange={(e) => handleValueChange(e)}
                name="growth_form"
                value="vine"
                color="primary"
              />
            }
            label="Vine"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.undefined}
                onChange={(e) => handleValueChange(e)}
                name="growth_form"
                value="Undefined"
                color="primary"
              />
            }
            label="Undefined"
          />
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-3">
        <Typography variant="h6" gutterBottom>
          <Tooltip content={(<ul className="tip-list">
            <li>Yes: Species known to have some degree of toxicity to animals.</li>
             <li>Undefined: No information available regarding species toxicity.</li>
            </ul>)}>

          <img src={questionMark} height="20px" /> Grazing Toxicity:
        </Tooltip>
        </Typography>
      </div>
      <div className="col-md-9">
        <div className="form-check">
          <select
            name="grazing_toxicity"
            value={refinements_store.grazing_toxicity}
            className="form-select"
            onChange={(e) => handleValueChange(e)}
            style={{ width: "20%" }}
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="Undefined">Undefined</option>
          </select>
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-3">
        <Typography variant="h6" gutterBottom>
          <Tooltip content={(<ul className="tip-list">
              <li>$: $1 - $10 per ounce</li>
              <li>$$: $11 - $40 per ounce</li>
              <li>$$$: $41 - $75 per ounce</li>
              <li>$$$$: $75 or more per ounce</li>
            </ul>)}>
          <img src={questionMark} height="20px" /> Relative Price:&nbsp;
        </Tooltip>
        </Typography>
      </div>
      <div className="col-md-8">
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.$}
                onChange={(e) => handleValueChange(e)}
                name="relative_price"
                value="$"
                color="primary"
              />
            }
            label="$"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.$$}
                onChange={(e) => handleValueChange(e)}
                name="relative_price"
                value="$$"
                color="primary"
              />
            }
            label="$$"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.$$$}
                onChange={(e) => handleValueChange(e)}
                name="relative_price"
                value="$$$"
                color="primary"
              />
            }
            label="$$$"
          />
        </div>
        <div className="form-check">
          <FormControlLabel
            control={
              <Checkbox
                checked={refinements_checkboxes_store.$$$$}
                onChange={(e) => handleValueChange(e)}
                name="relative_price"
                value="$$$$"
                color="primary"
              />
            }
            label="$$$$"
          />
        </div>
      </div>
    </div>
  </div>
</React.Fragment>
  );
}

export default Refinements;
