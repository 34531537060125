import React, { useEffect, useReducer } from "react";
import { Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { cost_benefit_actions } from "../../../store/cost_benefit_slice";
import axios from "axios";
import moment from "moment";
import Tooltip from "react-tooltip-lite";
import questionMark from "../../../img/sm-red-question-mark.png";
import cancelIcon from "../../../img/cancel.png";
import ColorLegend from "./utils/ColorLegend";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapsible from 'react-collapsible';

//antd imports
import { Row, Col, Button, DatePicker, Input, Menu, Select, Modal } from "antd";

//antd icon imports
import { CaretUpFilled, CaretDownFilled, PlusOutlined, DownOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

function CostInstructions() {
  const [value, setValue] = React.useState(0);
  const [options_value, setOptionsValue] = React.useState([0]);

  const [project_location, setProjectLocation] = React.useState("");
  const [project_phase, setProjectPhase] = React.useState("");
  const [showColorLegend, setShowColorLegend] = React.useState(false);

  const [textWidth, setTextWidth] = React.useState(350);

  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //default constant values
  const cost_input_default_values = {
    0: {
      option_name: "",
      installed_vegetation_type: "",
      array_width: "",
      minimum_panel_height: "",
      row_restrictions: "",
      minimum_panel_height: "",
      array_size: "",
      desired_vegetation_array: "",
      fertilizer_required_array: "",
      soil_amendment_required_array: "",
      pre_seeding_cost: "",
      seed_mix_cost_array: "",
      seed_installation_cost: "",
      fertilizer_cost_array: "",
      soil_amendements_cost_array: "",
      decompation_or_tillage_cost: "",
      temporary_stabilization_cost: "",
      custom_input_cost_1: "",
      custom_input_cost_2: "",
      custom_input_cost_3: "",
      mowing_treatment_cost: "",
      herbicide_treatment_cost: "",
      supplemental_seeding_cost: "",
      grazing_management_service_cost: "",
      inspections_and_monitor_cost: "",
      no_of_mowing_treatments_establishment: "",
      no_of_herbicide_treatments_establishment: "",
      no_of_supplemental_seeding_establishment: "",
      no_of_grazing_management_establishment: "",
      no_of_inspections_and_monitoring_establishment: "",
      no_of_mowing_treatments_maintenance: "",
      no_of_herbicide_treatments_maintenance: "",
      no_of_supplemental_seeding_maintenance: "",
      no_of_grazing_management_maintenance: "",
      no_of_inspections_and_monitoring_maintenance: ""
    },
    1: {
      option_name: "",
      installed_vegetation_type: "",
      array_width: "",
      minimum_panel_height: "",
      row_restrictions: "",
      minimum_panel_height: "",
      array_size: "",
      desired_vegetation_array: "",
      fertilizer_required_array: "",
      soil_amendment_required_array: "",
      pre_seeding_cost: "",
      seed_mix_cost_array: "",
      seed_installation_cost: "",
      fertilizer_cost_array: "",
      soil_amendements_cost_array: "",
      decompation_or_tillage_cost: "",
      temporary_stabilization_cost: "",
      custom_input_cost_1: "",
      custom_input_cost_2: "",
      custom_input_cost_3: "",
      mowing_treatment_cost: "",
      herbicide_treatment_cost: "",
      supplemental_seeding_cost: "",
      grazing_management_service_cost: "",
      inspections_and_monitor_cost: "",
      no_of_mowing_treatments_establishment: "",
      no_of_herbicide_treatments_establishment: "",
      no_of_supplemental_seeding_establishment: "",
      no_of_grazing_management_establishment: "",
      no_of_inspections_and_monitoring_establishment: "",
      no_of_mowing_treatments_maintenance: "",
      no_of_herbicide_treatments_maintenance: "",
      no_of_supplemental_seeding_maintenance: "",
      no_of_grazing_management_maintenance: "",
      no_of_inspections_and_monitoring_maintenance: ""
    },
    2: {
      option_name: "",
      installed_vegetation_type: "",
      array_width: "",
      minimum_panel_height: "",
      row_restrictions: "",
      minimum_panel_height: "",
      array_size: "",
      desired_vegetation_array: "",
      fertilizer_required_array: "",
      soil_amendment_required_array: "",
      pre_seeding_cost: "",
      seed_mix_cost_array: "",
      seed_installation_cost: "",
      fertilizer_cost_array: "",
      soil_amendements_cost_array: "",
      decompation_or_tillage_cost: "",
      temporary_stabilization_cost: "",
      custom_input_cost_1: "",
      custom_input_cost_2: "",
      custom_input_cost_3: "",
      mowing_treatment_cost: "",
      herbicide_treatment_cost: "",
      supplemental_seeding_cost: "",
      grazing_management_service_cost: "",
      inspections_and_monitor_cost: "",
      no_of_mowing_treatments_establishment: "",
      no_of_herbicide_treatments_establishment: "",
      no_of_supplemental_seeding_establishment: "",
      no_of_grazing_management_establishment: "",
      no_of_inspections_and_monitoring_establishment: "",
      no_of_mowing_treatments_maintenance: "",
      no_of_herbicide_treatments_maintenance: "",
      no_of_supplemental_seeding_maintenance: "",
      no_of_grazing_management_maintenance: "",
      no_of_inspections_and_monitoring_maintenance: ""
    }
  };

  const [cost_input_values, setCostInputValues] = React.useState(
    cost_input_default_values
  );

  const lookup_store = useSelector(
    (state) => state.cost_benefit_slice.cost_references
  );

  const [states, setStates] = React.useState([]);

  //redux constants
  const dispatch = useDispatch();
  const options_value_store = useSelector(
    (state) => state.cost_benefit_slice.selected_options
  );

  const cost_input_values_store = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs
  );

  const cost_information_values_store = useSelector(
    (state) => state.cost_benefit_slice.cost_information
  );

  const option_names_default_values = {
    0: "",
    1: "",
    2: ""
  };

  const [option_names, setOptionNames] = React.useState(
    option_names_default_values
  );

  const handleInputChange = (event, index) => {
    const name = event.target.name;
    let value = event.target.value;
    const event_index = index;

    const text_transformation = [
      "pre_seeding_cost",
      "seed_mix_cost_array",
      "seed_installation_cost",
      "fertilizer_cost_array",
      "soil_amendements_cost_array",
      "decompation_or_tillage_cost",
      "temporary_stabilization_cost",
      "custom_input_cost_1",
      "custom_input_cost_2",
      "custom_input_cost_3",
      "mowing_treatment_cost",
      "herbicide_treatment_cost",
      "supplemental_seeding_cost",
      "grazing_management_service_cost"
    ];

    if (text_transformation.includes(name)) {
      //check if the value has a $ in it, if it does, then remove the $ and then set the value
      if (value.includes("$")) {
        value = parseFloat(value.replace("$", ""));
      }
    }

    setCostInputValues({
      ...cost_input_values,
      [event_index]: {
        ...cost_input_values[event_index],
        [name]: value
      }
    });
    //send a dispatch to the store to update the selected_options
    //dispatch(cost_benefit_actions.set_cost_inputs(cost_input_values));
  };
  useEffect(() => {
    setOptionsValue(options_value_store);
  }, []);

  useEffect(() => {
    setCostInputValues(cost_input_values_store);
    //update the option names with the option names in the store
    if (cost_information_values_store) {
      const option_names_copy = { ...option_names };
      if (cost_information_values_store.user_defined_vegetation_0) {
        option_names_copy[0] =
          cost_information_values_store.user_defined_vegetation_0;
      }
      if (cost_information_values_store.user_defined_vegetation_1) {
        option_names_copy[1] =
          cost_information_values_store.user_defined_vegetation_1;
      }
      if (cost_information_values_store.user_defined_vegetation_2) {
        option_names_copy[2] =
          cost_information_values_store.user_defined_vegetation_2;
      }
      setOptionNames(option_names_copy);
    }
  }, []);

  const handleDateDefault = (props) => {
    if (props) {
      const date = moment(props).format("YYYY-MM-DD");
      return moment(date);
    }
  };

  const handleSelectChange = (props) => {
    const value = props[0];
    const index = props[1];
    const name = props[2];
    setCostInputValues({
      ...cost_input_values,
      [index]: {
        ...cost_input_values[index],
        [name]: value
      }
    });
  };

  const handleCurrencyChange = (curr) => {
    //check if $ is at the beginning of the string, then return the same , else return $ + string
    if (curr) {
      if (curr[0] == "$") {
        return curr;
      } else {
        return "$" + curr;
      }
    }
  };

  const handleOptionClick = (event) => {
    if (options_value.length < 3) {
      let value = 0;
      if (options_value.length == 0) {
        value = 0;
      } else {
        value = Math.max(...options_value);
      }
      //update the options_value array
      setOptionsValue([...options_value, value + 1]);
    }
    //send a dispatch to the store to update the selected_options
    dispatch(
      cost_benefit_actions.set_selected_options(options_value.length + 1)
    );
  };

  const handleCancel = (index) => {
    if (options_value.length > 1) {
      //remove the index from the options_value array
      const options_value_copy = [...options_value];
      options_value_copy.splice(index, 1);
      setOptionsValue(options_value_copy);
      dispatch(
        cost_benefit_actions.set_selected_options(options_value_copy.length)
      );
    } else {
      window.alert("Atleast one option is required");
    }
  };

  const handleVegetationTypeChange = (props) => {
    const value = props[0];
    const index = props[1];
    const name = props[2];
    setCostInputValues({
      ...cost_input_values,
      [index]: {
        ...cost_input_values[index],
        [name]: value,
        pre_seeding_cost: lookup_store[value].pre_seeding_herbicide_treatment,
        seed_mix_cost_array: lookup_store[value].seed_cost,
        seed_installation_cost: lookup_store[value].install_costs,
        fertilizer_cost_array: lookup_store[value].fertilizer_costs,
        soil_amendements_cost_array: lookup_store[value].soil_amendment_costs,
        decompation_or_tillage_cost:
          lookup_store[value].decompaction_or_tillage_costs,
        temporary_stabilization_cost: lookup_store[value].stabilization_costs,
        no_of_mowing_treatments_establishment:
          lookup_store[value].number_of_mowing_treatments_annually,
        no_of_herbicide_treatments_establishment:
          lookup_store[value].number_of_herbicide_treatments_annually,
        no_of_supplemental_seeding_establishment:
          lookup_store[value].number_of_supplemental_seedings_annually,
        no_of_grazing_management_establishment:
          lookup_store[value].grazing_management_services_annually,
        no_of_inspections_and_monitoring_establishment:
          lookup_store[value].inspections_and_monitoring_annually,
        no_of_mowing_treatments_maintenance:
          lookup_store[value].number_of_mowing_treatments_annually,
        no_of_herbicide_treatments_maintenance:
          lookup_store[value].number_of_herbicide_treatments_annually,
        no_of_supplemental_seeding_maintenance:
          lookup_store[value].number_of_supplemental_seedings_annually,
        no_of_grazing_management_maintenance:
          lookup_store[value].grazing_management_services_annually,
        no_of_inspections_and_monitoring_maintenance:
          lookup_store[value].inspections_and_monitoring_annually
      }
    });
  };

  const handleColorLegend = () => {
    setShowColorLegend(!showColorLegend);
  };

  useEffect(() => {
    dispatch(cost_benefit_actions.set_cost_inputs(cost_input_values));
  }, [cost_input_values]);

  useEffect(() => {
    options_value.length == 3
      ? (document.getElementById("add__option__button").disabled = true)
      : (document.getElementById("add__option__button").disabled = false);
    dispatch(cost_benefit_actions.set_selected_options(options_value));
  }, [options_value]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setTextWidth(screenWidth <= 1366 ? 230 : 350);
    };

    handleResize();
    //add event listener
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      {showColorLegend && (
        <Modal
          title="Color Legend"
          visible={true}
          onCancel={() => {
            setShowColorLegend(false);
          }}
          footer={null}
        >
          <ColorLegend />
        </Modal>
      )}
      {cost_input_values_store && cost_input_values && (
        <div>
          <>
            <div>
              <div className="row">
                <div className="d-flex justify-content-end">
                  <div className="m-2">
                    <Button
                      size="medium"
                      onClick={handleOptionClick}
                      type="primary"
                      shape="round"
                      icon={<PlusOutlined />}
                      id="add__option__button"
                    >
                      Add Another Option Column
                    </Button>
                  </div>
                  <div className="m-2">
                    <Button
                      size="medium"
                      onClick={handleColorLegend}
                      type="primary"
                      shape="round"
                    >
                      Color Legend
                    </Button>
                  </div>
                </div>
                <Row className="m-2">
                  <Col className="user__input__items">
                    <div className="sectiontext">
                      Input Site Infrastructure Details
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Reference name for comparison scenarios.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Option Name:
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Pre Vegetation is seeded or planted prior to the
                              installation of pilings and racking systems.
                            </li>
                            <li>
                              Post Vegetation is seeded or planted after the
                              piling and racking systems installation.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Is Vegetation
                        Installed Pre/Post Construction?
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              The minimum width of the vegetated row in between
                              panels as measured from the leading edge of the
                              panel in a row to the top edge of the panel in the
                              adjacent row.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Width between
                        arrays (ft):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Are there physical restrictions that prevent
                              equipment or vehicles from freely mowing from one
                              end of the array row to the other side?{" "}
                            </li>
                            <li>
                              Common end row restrictions may include cabling,
                              conduit, or other above-ground infrastructure
                              intersecting vegetated rows.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Are End of Row
                        Restrictions Planned?
                      </Tooltip>
                    </div>
                  </Col>

                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="optionheader">
                            {option_names[index] &&
                            option_names[index] != "" ? (
                              option_names[index]
                            ) : (
                              <div className="row">
                                <div className="col-md-9">
                                  <Text key={index}>OPTION {item + 1}</Text>
                                </div>
                                <div className="col-md-3">
                                  <img
                                    src={cancelIcon}
                                    id="cancel__icon"
                                    onClick={() => {
                                      handleCancel(index);
                                    }}
                                  ></img>
                                </div>
                              </div>
                            )}
                            {/*this generates the OPTION(x) header at the top of each column*/}
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              name="option_name"
                              className="gold__accent__4 antd__input"
                              size="small"
                              style={{ width: textWidth }}
                              onBlur={(e) => handleInputChange(e, index)}
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index].option_name
                                  ? cost_input_values_store[index].option_name
                                  : ""
                              }
                            />
                          </div>
                          <div className="m-2">
                            <Select
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .installed_vegetation_type
                                  ? cost_input_values_store[index]
                                      .installed_vegetation_type
                                  : "Select Phase"
                              }
                              className="gold__accent__4"
                              style={{
                                width: textWidth,
                                backgroundColor: "lightblue"
                              }}
                              onChange={(value) =>
                                handleSelectChange([
                                  value,
                                  index,
                                  "installed_vegetation_type"
                                ])
                              }
                              name="installed_vegetation_type"
                              key={index}
                            >
                              <Select.Option value="Pre">Pre</Select.Option>
                              <Select.Option value="Post">Post</Select.Option>
                            </Select>
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              name="array_width"
                              className="gold__accent__4 antd__input"
                              style={{ width: textWidth }}
                              onBlur={(e) => handleInputChange(e, index)}
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index].array_width
                                  ? cost_input_values_store[index].array_width
                                  : ""
                              }
                            />
                          </div>

                          <div className="m-2">
                            <Select
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .row_restrictions
                                  ? cost_input_values_store[index]
                                      .row_restrictions
                                  : ""
                              }
                              style={{
                                width: textWidth
                              }}
                              onChange={(value) =>
                                handleSelectChange([
                                  value,
                                  index,
                                  "row_restrictions"
                                ])
                              }
                              name="row_restrictions"
                              key={index}
                            >
                              <Select.Option value="Yes">Yes</Select.Option>
                              <Select.Option value="No">No</Select.Option>
                            </Select>
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                {/* <div className="sectiontext">Infrastructure</div>

                <Row>
                  <Col className="user__input__items">
                      <div>
                              <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Refers to the leading edge panel height, which is typically the lowest elevation of the panel edge above the ground surface.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Minimum Panel Height (inches):
                      </Tooltip>
                      </div>
                  </Col>

                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="m-2">
                            <TextField
                              key={index}
                              name="minimum_panel_height"
                              size="small"
                              className="gold__accent__4"
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index]
                                  .minimum_panel_height
                              }
                              style={{ width: textWidth }}
                              onBlur={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row> */}
                {/* <div className="d-flex justify-content-center">
                  <Title level={3}>ARRAY AREA</Title>
                </div> */}
                <div className="sectiontext my-2">
                  Select Planned Vegetation Types and Size for Evaluation
                </div>
                <Row>
                  <Col className="user__input__items">
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Acres vegetated within the array portion of the
                              project area.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Size
                        (acres):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <>
                            <span
                              style={{
                                fontStyle: "italic",
                                fontSize: "13px",
                                color: "white"
                              }}
                            >
                              **This table and vegetation types are conceptual
                              only. These types are used to supply default
                              reference costs associated with each vegetation
                              community and associated management.
                            </span>

                            <TableContainer className="tip-list">
                              <Table style={{ border: 1 }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Vegetation Type Descriptions
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      Typical Grass Cover
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      Typical Forb Cover
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      Primary Management Regime
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Turfgrass maintained with frequent mowing
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      &gt;90%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      &gt;10%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      mowing every 2-4 weeks
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Turfgrass maintained with regular mowing
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      &gt;90%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      &lt;10%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      mowing every 4-6 weeks
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Grass with some forbs maintained with
                                      periodic mowing
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      80-90%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      10-20%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      mowing every 4-8 weeks
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Grass with some forbs maintained with
                                      grazing
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      80-90%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      10-20%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      prescribed grazing regimes
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Mixed grass and forbs maintained with
                                      various methods
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      40-60%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      40-60%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      mixed use of herbicide and mowing
                                      treatments
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ color: "white" }}>
                                      Arid rangeland with mixed management
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      60-80%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      20-40%
                                    </TableCell>
                                    <TableCell style={{ color: "white" }}>
                                      prescribed grazing regimes or targeted
                                      herbicide use
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        }
                      >
                        <img src={questionMark} height="20px" /> Desired
                        Vegetation Type:
                      </Tooltip>
                    </div>
                    {/* <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Is fertilizer required or necessary due to site
                              conditions?
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Fertilizer
                        req/necessary:
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Are soil amendments required or necessary due to
                              site conditions? Soil amendments may include
                              additions of sand, organic matter, or other
                              additions mixed within the soil surface to enhance
                              nutrient availability, soil drainage, or soil
                              structure.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Soil amendments
                        req/necessary:
                      </Tooltip>
                    </div> */}
                  </Col>
                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="m-2">
                            <input
                              key={index}
                              name="array_size"
                              size="small"
                              className="gold__accent__4 antd__input"
                              style={{ width: textWidth }}
                              onBlur={(e) => handleInputChange(e, index)}
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index].array_size
                                  ? cost_input_values_store[index].array_size
                                  : ""
                              }
                            />
                          </div>
                          <div className="m-2">
                            <Select
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .desired_vegetation_array
                                  ? cost_input_values_store[index]
                                      .desired_vegetation_array
                                  : "Select Vegetation"
                              }
                              style={{
                                width: textWidth
                              }}
                              onChange={(value) =>
                                handleVegetationTypeChange([
                                  value,
                                  index,
                                  "desired_vegetation_array"
                                ])
                              }
                              name="desired_vegetation_array"
                              key={index}
                            >
                              <Select.Option value="turfgrass_maintained_with_frequent_mowing">
                                Turfgrass maintained with frequent mowing
                              </Select.Option>
                              <Select.Option value="turfgrass_maintained_with_regular_mowing">
                                Turfgrass maintained with regular mowing
                              </Select.Option>
                              <Select.Option value="grass_with_some_forbs_maintained_with_periodic_mowing">
                                Grass with some forbs maintained with periodic mowing
                              </Select.Option>
                              <Select.Option value="grass_with_some_forbs_maintained_with_grazing">
                                Grass with some forbs maintained with grazing
                              </Select.Option>
                              <Select.Option value="mixed_grass_and_forbs_maintained_with_various_methods">
                                Mixed grass and forbs maintained with various methods
                              </Select.Option>
                              <Select.Option value="arid_rangeland_with_mixed_management">
                                Arid rangeland with mixed management
                              </Select.Option>
                              <Select.Option value="user_defined">
                                User-defined
                              </Select.Option>
                            </Select>
                          </div>
                          {/* <div className="m-2">
                            <Select
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .fertilizer_required_array
                                  ? cost_input_values_store[index]
                                      .fertilizer_required_array
                                  : " "
                              }
                              onChange={(value) =>
                                handleSelectChange([
                                  value,
                                  index,
                                  "fertilizer_required_array"
                                ])
                              }
                              style={{
                                width: textWidth
                              }}
                              name="fertilizer_required_array"
                              key={index}
                            >
                              <Select.Option value="Yes">Yes</Select.Option>
                              <Select.Option value="No">No</Select.Option>
                            </Select>
                          </div>
                          <div className="m-2">
                            <Select
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .soil_amendments_required_array
                                  ? cost_input_values_store[index]
                                      .soil_amendments_required_array
                                  : " "
                              }
                              style={{
                                width: textWidth
                              }}
                              onChange={(value) =>
                                handleSelectChange([
                                  value,
                                  index,
                                  "soil_amendments_required_array"
                                ])
                              }
                              name="soil_amendments_required_array"
                              key={index}
                            >
                              <Select.Option value="Yes">Yes</Select.Option>
                              <Select.Option value="No">No</Select.Option>
                            </Select>
                          </div> */}
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                <div className="sectiontext">
                                  Verify or Edit PER ACRE Vegetation Services Costs
                </div>

                <Row>
                  <Col className="user__input__items">
                    <div className="d-flex">
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Custom Inputs allow for additional user-defined
                              costs that are not included in the list of common
                              costs already provided.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />{" "}
                        <Input
                          allowClear
                          prefix="Custom Input 1:"
                          suffix="($/Acre)"
                          style={{ width: 260 }}
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex">
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Custom Inputs allow for additional user-defined
                              costs that are not included in the list of common
                              costs already provided.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />{" "}
                        <Input
                          allowClear
                          prefix="Custom Input 2:"
                          suffix="($/Acre)"
                          style={{ width: 260 }}
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex">
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              Custom Inputs allow for additional user-defined
                              costs that are not included in the list of common
                              costs already provided.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />{" "}
                        <Input
                          allowClear
                          prefix="Custom Input 3:"
                          suffix="($/Acre)"
                          style={{ width: 260 }}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="gold__accent__4 antd__input"
                              name="custom_input_cost_1"
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .custom_input_cost_1
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .custom_input_cost_1
                                    )
                                  : ""
                              }
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="gold__accent__4 antd__input"
                              name="custom_input_cost_2"
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .custom_input_cost_2
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .custom_input_cost_2
                                    )
                                  : ""
                              }
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="gold__accent__4 antd__input"
                              name="custom_input_cost_3"
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .custom_input_cost_3
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .custom_input_cost_3
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <div>&nbsp;</div>
                <Collapsible trigger=<span className="sectiontext"><CaretDownFilled /> Additional PER ACRE Vegetation Services Costs</span>
                    triggerWhenOpen=<span className="sectiontext"><CaretUpFilled /> Additional PER ACRE Vegetation Services Costs</span>>
                <Row>
                    <Col className="user__input__items">
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            Estimate the percentage of the total site
                                            requiring pre-seeding weed control via herbicide,
                                            mowing, or other vegetation management treatments.
                                        </li>
                                        <li>
                                            Weed pressure can severely impact vegetation
                                            establishment. Pre-seeding weed control may be
                                            required when sites are allowed to vegetated prior
                                            to the desired construction seed installation.
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Pre-Seeding
                                Herbicide Cost ($/Acre):
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            The per-acre cost of the seed mix being installed
                                            onsite. Excludes the installation costs, which are
                                            added in the next row.
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Seed Mix Cost
                                ($ per Acre):
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            The per-acre cost of seed mix installation
                                            services. Excludes the seed mix cost, which are
                                            added in the row above.
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Seed
                                Installation Cost ($/Acre):
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            Is fertilizer required or necessary due to site
                                            conditions?
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Fertilizer Cost
                                ($/Acre):
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            Are soil amendments required or necessary due to
                                            site conditions? Soil amendments may include
                                            additions of sand, organic matter, or other
                                            additions mixed within the soil surface to enhance
                                            nutrient availability, soil drainage, or soil
                                            structure.
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Soil Amendments
                                Cost ($/Acre):
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            Estimate the percentage of the total site
                                            requiring decompaction via soil discing, tilling,
                                            or ripping.
                                        </li>
                                        <li>
                                            Compaction can severely impact vegetation
                                            establishment. Decompaction may be required when
                                            pre-construction grading or mobilization has
                                            compacted soils onsite.
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Decompaction or
                                Tillage Cost ($/Acre):
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip
                                content={
                                    <ul className="tip-list">
                                        <li>
                                            Estimated cost to install temporary erosion
                                            controls in conjunction with seeding plans to
                                            prevent soil erosion and sedimentation.
                                        </li>
                                    </ul>
                                }
                            >
                                <img src={questionMark} height="20px" /> Temporary
                                Stabilization Cost ($/Acre):
                            </Tooltip>
                        </div>
                    </Col>
                    <Col className="user_input_text">
                        {options_value.map((item, index) => {
                            return (
                                <div key={index} className="flex">
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            name="pre_seeding_cost"
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index].pre_seeding_cost
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .pre_seeding_cost
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            name="seed_mix_cost_array"
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index]
                                                        .seed_mix_cost_array
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .seed_mix_cost_array
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            name="seed_installation_cost"
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index]
                                                        .seed_installation_cost
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .seed_installation_cost
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            name="fertilizer_cost_array"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index]
                                                        .fertilizer_cost_array
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .fertilizer_cost_array
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            name="soil_amendements_cost_array"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index]
                                                        .soil_amendements_cost_array
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .soil_amendements_cost_array
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            name="decompation_or_tillage_cost"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index]
                                                        .decompation_or_tillage_cost
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .decompation_or_tillage_cost
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                    <div className="m-2">
                                        <input
                                            key={index}
                                            size="small"
                                            className="green__accent__6__lighter__60 antd__input"
                                            name="temporary_stabilization_cost"
                                            style={{ width: textWidth }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            value={
                                                cost_input_values_store &&
                                                    cost_input_values_store[index] &&
                                                    cost_input_values_store[index]
                                                        .temporary_stabilization_cost
                                                    ? handleCurrencyChange(
                                                        cost_input_values_store[index]
                                                            .temporary_stabilization_cost
                                                    )
                                                    : handleCurrencyChange(String(0))
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </Col>
                </Row>
                </Collapsible>
                <Collapsible trigger=<span className="sectiontext"><CaretDownFilled /> Establishment and Maintenance Treatment Costs</span>
                    triggerWhenOpen=<span className="sectiontext"><CaretUpFilled /> Establishment and Maintenance Treatment Costs</span>>
                <Row>
                  <Col className="user__input__items">
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Per-acre cost of a single mowing treatment.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Mowing
                        Treatments ($/Acre):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Per-acre cost of a single herbicide application treatment.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Herbicide
                        Treatments ($/Acre):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Per-acre cost of a single supplemental seeding treatment. Unlike rows above, this INCLUDES both seed and installation costs.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Supplemental Seedings (Seed and Labor) ($/Acre):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Per-acre cost of grazing services.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Grazing Management Services ($/Acre):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>Per-acre cost of conducting a single vegetation inspection and monitoring visit.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Inspections and Monitoring ($/Acre):
                      </Tooltip>
                    </div>
                  </Col>

                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="mowing_treatment_cost"
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .mowing_treatment_cost
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .mowing_treatment_cost
                                    )
                                  : handleCurrencyChange(String(200))
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="herbicide_treatment_cost"
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .herbicide_treatment_cost
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .herbicide_treatment_cost
                                    )
                                  : handleCurrencyChange(String(200))
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="supplemental_seeding_cost"
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .supplemental_seeding_cost
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .supplemental_seeding_cost
                                    )
                                  : handleCurrencyChange(String(500))
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="grazing_management_service_cost"
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .grazing_management_service_cost
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .grazing_management_service_cost
                                    )
                                  : handleCurrencyChange(String(500))
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="inspections_and_monitor_cost"
                              defaultValue={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .inspections_and_monitor_cost
                                  ? handleCurrencyChange(
                                      cost_input_values_store[index]
                                        .inspections_and_monitor_cost
                                    )
                                  : handleCurrencyChange(String(20))
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                <div className="sectiontextInnerItems">
                  Verify or Edit Annual ESTABLISHMENT PHASE Vegetation Services
                </div>

                <Row>
                  <Col className="user__input__items">
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>The number of mowing treatments expected annually.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Mowing
                        Treatments (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>The number of herbicide applications expected annually.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Herbicide
                        Treatments (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>The number of supplemental seedings expected annually.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Supplemental Seedings (Seed and Labor) (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>The number of grazing management service events expected annually.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Grazing Management Services (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>The number of vegetation inspections and monitoring events expected annually.</li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Inspections and Monitoring (Number Completed):
                      </Tooltip>
                    </div>
                  </Col>

                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_mowing_treatments_establishment"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_mowing_treatments_establishment
                                  ? cost_input_values_store[index]
                                      .no_of_mowing_treatments_establishment
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_herbicide_treatments_establishment"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_herbicide_treatments_establishment
                                  ? cost_input_values_store[index]
                                      .no_of_herbicide_treatments_establishment
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_supplemental_seeding_establishment"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_supplemental_seeding_establishment
                                  ? cost_input_values_store[index]
                                      .no_of_supplemental_seeding_establishment
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_grazing_management_establishment"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_grazing_management_establishment
                                  ? cost_input_values_store[index]
                                      .no_of_grazing_management_establishment
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_inspections_and_monitoring_establishment"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_inspections_and_monitoring_establishment
                                  ? cost_input_values_store[index]
                                      .no_of_inspections_and_monitoring_establishment
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                <div className="sectiontextInnerItems">
                  Verify or Edit Annual MAINTENANCE PHASE Vegetation Services
                </div>

                <Row>
                  <Col className="user__input__items">
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              The number of mowing treatments expected annually.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Mowing
                        Treatments (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              The number of herbicide applications expected
                              annually.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" /> Herbicide
                        Treatments (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              The number of supplemental seedings expected
                              annually.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Supplemental Seedings (Seed and Labor) (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              The number of grazing management service events
                              expected annually.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Grazing Management Services (Number Completed):
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        content={
                          <ul className="tip-list">
                            <li>
                              The number of vegetation inspections and monitoring
                              events expected annually.
                            </li>
                          </ul>
                        }
                      >
                        <img src={questionMark} height="20px" />
                        Inspections and Monitoring (Number Completed):
                      </Tooltip>
                    </div>
                  </Col>

                  <Col className="user_input_text">
                    {options_value.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_mowing_treatments_maintenance"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_mowing_treatments_maintenance
                                  ? cost_input_values_store[index]
                                      .no_of_mowing_treatments_maintenance
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_herbicide_treatments_maintenance"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_herbicide_treatments_maintenance
                                  ? cost_input_values_store[index]
                                      .no_of_herbicide_treatments_maintenance
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_supplemental_seeding_maintenance"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_supplemental_seeding_maintenance
                                  ? cost_input_values_store[index]
                                      .no_of_supplemental_seeding_maintenance
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_grazing_management_maintenance"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_grazing_management_maintenance
                                  ? cost_input_values_store[index]
                                      .no_of_grazing_management_maintenance
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                          <div className="m-2">
                            <input
                              key={index}
                              size="small"
                              className="green__accent__6__lighter__60 antd__input"
                              name="no_of_inspections_and_monitoring_maintenance"
                              value={
                                cost_input_values_store &&
                                cost_input_values_store[index] &&
                                cost_input_values_store[index]
                                  .no_of_inspections_and_monitoring_maintenance
                                  ? cost_input_values_store[index]
                                      .no_of_inspections_and_monitoring_maintenance
                                  : 0
                              }
                              style={{ width: textWidth }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                </Collapsible>
              </div>
            </div>
          </>
        </div>
      )}
    </React.Fragment>
  );
}

export default CostInstructions;