import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CostInstructions from "./cost_benefit_components/CostInstructions";
import CostRefinements from "./cost_benefit_components/CostRefinements";
import CostAnalysisResults from "./cost_benefit_components/CostAnalysisResults";
import CostInformation from "./cost_benefit_components/CostInformation";
import CostInstructionsBuffer from "./cost_benefit_components/CostInstructionsBuffer";
import CostSummary from "./cost_benefit_components/CostSummary";
import { useDispatch, useSelector } from "react-redux";
import "../../css/main.css";
import { cost_benefit_actions } from "../../store/cost_benefit_slice";
import CostReferences from "./cost_benefit_components/CostReferences";
import CostResults from "./cost_benefit_components/CostResults";
import { Tune } from "@mui/icons-material";
import { Alert, Modal, Table, Button as btn } from "antd";

const cost_benefit_steps = [
  "Project Information",
  "User Input",
  "Summary"
];

function CostBenefit() {
  const [activeStep, setActiveStep] = React.useState(0);

  const dispatch = useDispatch();

  const options_store = useSelector(
    (state) => state.cost_benefit_slice.selected_options
  );

  const cost_input_array = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs
  );
  const cost_input_buffer = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs_buffer
  );

  const [visibleModal, setVisibleModal] = React.useState(false);

  const activeStepContent = (activeStep) => {
    //console.log('active step', activeStep);
    switch (activeStep) {
      case 0:
        return <CostInformation />;
      case 1:
        return <CostInstructions />;
      // case 2:
      //   return <CostInstructionsBuffer />;
      case 2:
        return <CostResults />;
      case "Reset":
        handleReset();
        break;
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    // if (activeStep === 2) {
    //   console.log('active step', activeStep)
    //   handleDetailedSummaryCheck();
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //add redux dispatch cost_store
    setActiveStep(0);
    dispatch(cost_benefit_actions.reset_cost_slice());
  };

  const handleDetailedSummaryCheck = () => {
    /*
      check the following properties in the cost_input_array and cost_input_buffer for all the keys
      1. array_size in cost_input_array
      2. buffer_size in cost_input_buffer
      3. desired_vegetation_array in cost_input_array
      4. desired_vegetation_array in cost_input_buffer

      If any of the above properties are empty, then show a warning message to the user to fill in the required fields in a modal
    */

    options_store.length >= 1 &&
    options_store.map((option, index) => {
      if (
        cost_input_array[index].array_size === "" ||
        cost_input_array[index].desired_vegetation_array === ""
      ) {
        setVisibleModal(true);
      }
    }
    );

  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Cost Comparison Tool
        </Typography>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Box>
            <Stepper activeStep={activeStep}>
              {cost_benefit_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel key={index} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <div className="costbenefitnavbuttons">
            <Button
              disabled={activeStep === 0}
              variant="contained"
              color="primary"
              onClick={handleBack}
              className="btn btn-primary m-3"
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className="btn btn-primary m-3"
            >
              {activeStep === cost_benefit_steps.length - 1 ? "Reset" : "Next"}
            </Button>
          </div>
          <Box m={10}>
            {activeStep === cost_benefit_steps.length
              ? activeStepContent("Reset")
              : activeStepContent(activeStep)}
          </Box>
          <div className="costbenefitnavbuttons mb-2">
            <Button
              disabled={activeStep === 0}
              variant="contained"
              color="primary"
              onClick={handleBack}
              className="btn btn-primary m-3"
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className="btn btn-primary m-3"
            >
              {activeStep === cost_benefit_steps.length - 1 ? "Reset" : "Next"}
            </Button>
            {/* Antd Modal */}
            <Modal
              title="Detailed Summary Warning"
              open={visibleModal}
              className="table__modal__container"
              onCancel={() => {
                setVisibleModal(false);
                // Add any additional actions you want to perform here.
                setActiveStep(1);
              }}
              footer={[
                <Button
                  key="close"
                  onClick={() => {
                    setVisibleModal(false);
                    // Add any additional actions you want to perform here.
                    setActiveStep(1);
                  }}
                >
                  Close
                </Button>
              ]}
              width="fit-content" // Set the width to fit the content
              bodyStyle={{
                height: "fit-content",
                maxHeight: "80vh",
                overflowY: "auto"
              }} // Set the height to fit the content with max height and vertical scrolling
            >
              <Alert
                message="Please check if the following properties are filled in the User Input(Array) and User Input(Buffer) sections"
                type="warning"
                showIcon
              />

              {options_store.length >= 1 && (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Property</th>
                      {options_store.map((option, index) => (
                        <th key={index}>Option {index + 1}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Array Size</td>
                      {options_store.map((option, index) => (
                        <td
                          key={index}
                          className={
                            cost_input_array[index].array_size === ""
                              ? "not-filled"
                              : ""
                          }
                        >
                          {cost_input_array[index].array_size &&
                          cost_input_array[index].array_size !== ""
                            ? cost_input_array[index].array_size
                            : "N/A"}
                        </td>
                      ))}
                    </tr>
                    {/* <tr>
                      <td>Buffer Size</td>
                      {options_store.map((option, index) => (
                        <td
                          key={index}
                          className={
                            cost_input_buffer[index].array_size === ""
                              ? "not-filled"
                              : ""
                          }
                        >
                          {cost_input_buffer[index].array_size &&
                          cost_input_buffer[index].array_size !== ""
                            ? cost_input_buffer[index].array_size
                            : "N/A"}
                        </td>
                      ))}
                    </tr> */}
                    <tr>
                      <td>Desired Vegetation Type Array</td>
                      {options_store.map((option, index) => (
                        <td
                          key={index}
                          className={
                            cost_input_array[index].desired_vegetation_array ===
                            ""
                              ? "not-filled"
                              : ""
                          }
                        >
                          {cost_input_array[index].desired_vegetation_array &&
                          cost_input_array[index].desired_vegetation_array !==
                            ""
                            ? cost_input_array[index].desired_vegetation_array
                            : "N/A"}
                        </td>
                      ))}
                    </tr>
                    {/* <tr>
                      <td>Desired Vegetation Type Buffer</td>
                      {options_store.map((option, index) => (
                        <td
                          key={index}
                          className={
                            cost_input_buffer[index]
                              .desired_vegetation_array === ""
                              ? "not-filled"
                              : ""
                          }
                        >
                          {cost_input_buffer[index].desired_vegetation_array &&
                          cost_input_buffer[index].desired_vegetation_array !==
                            ""
                            ? cost_input_buffer[index].desired_vegetation_array
                            : "N/A"}
                        </td>
                      ))}
                    </tr> */}
                  </tbody>
                </table>
              )}
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CostBenefit;
