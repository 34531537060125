import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StatesList from "./StatesList";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { seed_selection_actions } from "../../../store/seed_selection_slice";
import { Dropdown } from "primereact/dropdown";
import "../../../css/main.css";
import plantingZoneImage from "../../../img/planting_zones.png";
import questionMark from "../../../img/sm-red-question-mark.png";
import api from "../../../axiosConfig";
import Tooltip from "react-tooltip-lite";
import { Input } from "antd";
import { Modal, Box } from "@mui/material";

function SeedInitialInputs() {
  // Constants used for the component
  const intital_inputs_default_values = {
    organization_name: "",
    planting_zones: "",
    native_preference: [],
    panel_height: "",
    seed_commercial_availability: []
  };

  const [input_values, setInputValues] = React.useState(
    intital_inputs_default_values
  );

  const [plantingZone, setPlantingZone] = React.useState("");
  const plantingZones = [
    { name: "Array Zone", code: "AZ" },
    { name: "Buffer Lands", code: "BL" },
    { name: "Perimeter Zone", code: "PZ" },
    { name: "Buffer Lands", code: "BL" },
    { name: "Other", code: "OT" }
  ];

  // Redux
  const dispatch = useDispatch();
  const initial_inputs_store = useSelector(
    (state) => state.seed_selection.initital_inputs
  );
  const initial_inputs_checkboxes = useSelector(
    (state) => state.seed_selection.initial_inputs_checkboxes
  );

  const handleValueChange = (e) => {
    const { name, value } = e.target;

    //console.log("name", name);
    //console.log("value", value);

    if (Array.isArray(input_values[name])) {
      if (!input_values[name].includes(value)) {
        setInputValues({
          ...input_values,
          [name]: [...input_values[name], value]
        });
        dispatch(
          seed_selection_actions.update_initial_inputs_checkboxes({
            name,
            value
          })
        );
      } else {
        setInputValues({
          ...input_values,
          [name]: input_values[name].filter((item) => item !== value)
        });
        dispatch(
          seed_selection_actions.update_initial_inputs_checkboxes({
            name,
            value
          })
        );
      }
    } else {
      setInputValues({ ...input_values, [name]: value });
    }
  };

  const handleSelectAll = (e) => {
    //select all
    const { name, value } = e.target;
    //console.log("name", name);
    //console.log("value", value);
    let dispatch_map = {
      "Very Common": "very_common",
      Common: "common",
      Uncommon: "uncommon",
      Rare: "rare",
      "Very Rare or Unavailable": "very_rare_unavailable"
    };
    //send a dispatch to update the select all checkbox
    dispatch(
      seed_selection_actions.update_initial_inputs_checkboxes({
        name,
        value
      })
    );
    if (value === "all") {
      if (e.target.checked) {
        //console.log('all is checked')
        setInputValues({
          ...input_values,
          [name]: [
            "Very Common",
            "Common",
            "Uncommon",
            "Rare",
            "Very Rare or Unavailable"
          ]
        });
        //send dispatch to update all checkboxes
        Object.keys(dispatch_map).forEach((key) => {
          dispatch(
            seed_selection_actions.update_initial_inputs_checkboxes({
              name: "seed_commercial_availability",
              value: dispatch_map[key]
            })
          );
        });
      } else {
        //console.log('all is unchecked')
        //if option is checked then uncheck all other options (Very Common, Common, Uncommon, Rare, Very Rare or Unavailable)
        setInputValues({
          ...input_values,
          [name]: []
        });
        dispatch(
          seed_selection_actions.update_initial_inputs_checkboxes({
            name,
            value
          })
        );
      }
    }
  };

  useEffect(() => {
    setInputValues(initial_inputs_store);
  }, []);

  useEffect(() => {
    //console.log("input_values", input_values)
    dispatch(seed_selection_actions.set_initial_inputs(input_values));
  }, [input_values]);

  // Modal disclaimer popup
  const modalStyle = {
    fontFamily: "Arial",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 600,
    border: "2px solid black",
    boxShadow: 14,
    padding: 5,
    textAlign: "center",
    background: "white"
  };

  const [displayPopUp, setDisplayPopUp] = useState(true);

  const closePopUp = () => {
    sessionStorage.setItem("seenSeedPopUp", true);
    setDisplayPopUp(false);
  };

  useEffect(() => {
    let returningUser = sessionStorage.getItem("seenSeedPopUp");
    setDisplayPopUp(!returningUser);
  }, []);

  return (
    <React.Fragment>
      <strong>
        Hover over the <img src={questionMark} height="20px" /> for descriptions
      </strong>

      <div className="container-fluid initial_inputs p-4">
        <div>
          {displayPopUp && (
            <Modal
              open={true}
              onClose={closePopUp}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <div>
                  <h6>
                    <i>
                      <strong>Disclaimer:</strong> The Seed Selection Tool is
                      intended for use by site planners and vegetation managers
                      involved in decision making for solar project site
                      vegetation planning. <br />
                      <br />
                      Outputs of this tool are generated by the user-determined
                      inputs and limited by the selected variables included.
                      Real-world applications may be more complex and require
                      additional considerations for constraints and complexities
                      not accounted for in this tool's interface. <br />
                      <br />
                      This tool was created by the University of Illinois
                      Chicago and Stantec with support of the U.S. Department of
                      Energy's Solar Energy Technologies Office. None of the
                      developers, contributors, administrators, or anyone else
                      connected with this tool, in any way whatsoever, can be
                      responsible for your use of the information contained
                      within or yielded from. <br />
                      <br />
                    </i>
                  </h6>
                </div>
                <strong>
                  <h6>
                    <font color="Blue">
                      As a user of this tool, I accept all responsibility and
                      liability for the accuracy and application of the inputs
                      and outputs.
                    </font>
                  </h6>
                </strong>
                <br />
                <button onClick={closePopUp}>
                  &nbsp; &nbsp; Agree and Continue &nbsp; &nbsp;{" "}
                </button>
              </Box>
            </Modal>
          )}
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <Typography variant="h6" gutterBottom>
                    Project Name:
                  </Typography>
                  <Input
                    name="organization_name"
                    defaultValue={
                      (initial_inputs_store &&
                        initial_inputs_store.organization_name) ||
                      ""
                    }
                    onChange={(e) => handleValueChange(e)}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <Typography variant="h6" gutterBottom>
                    Site Location:
                  </Typography>
                  <StatesList />
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-md-4">
                <Typography variant="h6" gutterBottom>
                  {" "}
                  <Tooltip
                    content={
                      <ul className="tip-list">
                        <li
                          className="d-flex justify-content-center mb-2 mr-2"
                          style={{ listStyleType: "none", fontSize: "20px" }}
                        >
                          This information is used for project planting area
                          reference only.
                        </li>
                        <li>
                          Array Zone is the vegetation under and around the
                          collection of solar panels included in a solar
                          project.
                        </li>
                        <li>
                          Perimeter Zone is the vegetation established around
                          the exterior perimeter of security fences on a solar
                          project.
                        </li>
                        <li>
                          Buffer Lands are areas of vegetation outside of array
                          and perimeter zones that may include additional
                          habitat areas, visual buffers, or other vegetated
                          areas.
                        </li>
                        <li>
                          Other lands may be other areas vegetated that do not
                          fall under array, perimeter, or buffer zones.
                        </li>
                      </ul>
                    }
                  >
                    <img src={questionMark} height="20px" /> Project Planting
                    Area:&nbsp;
                  </Tooltip>
                </Typography>
              </div>
              <div className="col-md-8">
                <select
                  className="form-select"
                  name="planting_zones"
                  defaultValue={
                    initial_inputs_store && initial_inputs_store.planting_zones
                      ? initial_inputs_store.planting_zones
                      : ""
                  }
                  onChange={(e) => handleValueChange(e)}
                  style={{ width: "60%" }}
                >
                  <option value=""> </option>
                  <option value="Array Zone">Array Zone</option>
                  <option value="Buffer Lands">Buffer Lands</option>
                  <option value="Perimeter Zone">Perimeter Zone</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="mb-3">
              <Typography variant="h6" gutterBottom>
                <Tooltip
                  content={
                    <ul className="tip-list">
                      <li>
                        Native: Will only return species indigenous to the area
                        and adapted to the local climate.
                      </li>
                      <li>
                        Non-Native: Will only return species not indigenous to
                        the area, but adapted to the local climate. May be
                        aggressive or invasive if conditions exist.
                      </li>
                    </ul>
                  }
                >
                  <img src={questionMark} height="20px" /> Native Preference:
                </Tooltip>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.native}
                      onChange={(e) => handleValueChange(e)}
                      name="native_preference"
                      color="primary"
                      value="Native"
                    />
                  }
                  label="Native"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.non_native}
                      onChange={(e) => handleValueChange(e)}
                      name="native_preference"
                      color="primary"
                      value="non_native"
                    />
                  }
                  label="Non-Native"
                />
              </FormGroup>
            </div>

            <div className="mb-3 row">
              <div className="col-md-4">
                <Typography variant="h6" gutterBottom>
                  <Tooltip
                    content={
                      <ul className="tip-list">
                        <li>
                          Panel height refers to the leading edge panel height,
                          which is typically the lowest elevation of the panel
                          edge above the ground surface.
                        </li>
                      </ul>
                    }
                  >
                    <img src={questionMark} height="20px" /> Maximum Vegetation
                    Height (feet):&nbsp;
                  </Tooltip>
                </Typography>{" "}
              </div>
              <div className="col-md-8">
                <Input
                  name="panel_height"
                  onBlur={(e) => handleValueChange(e)}
                  defaultValue={
                    initial_inputs_store && initial_inputs_store.panel_height
                      ? initial_inputs_store.panel_height
                      : ""
                  }
                  style={{ width: "50%" }}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="col-md-4">
                <Typography variant="h6" gutterBottom>
                  <Tooltip
                    content={
                      <ul className="tip-list">
                        <li>
                          Very Common: Readily available from multiple
                          sources(Found on 90% or more of seed vendor
                          availability lists)
                        </li>
                        <li>
                          Common: Available from multiple sources but may be
                          limited seasonally or by supply. (Found on 75% of seed
                          vendor availability lists)
                        </li>
                        <li>
                          Uncommon: Available but may be limited due to fewer
                          suppliers, low supply or seasonal variability. (Found
                          on 50% of seed vendor availability lists)
                        </li>
                        <li>
                          Rare: Not commonly available. May require specialized
                          procurement or contract growing. (Found on 25% or less
                          of seed vendor availability lists)
                        </li>
                        <li>
                          Very Rare or Unavailable: Stock rarely available.
                        </li>
                      </ul>
                    }
                  >
                    <img src={questionMark} height="20px" /> Seed Commercial
                    Availability:
                  </Tooltip>{" "}
                </Typography>
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.very_common}
                      onChange={(e) => handleValueChange(e)}
                      name="seed_commercial_availability"
                      color="primary"
                      value="very_common"
                    />
                  }
                  label="Very Common"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.common}
                      onChange={(e) => handleValueChange(e)}
                      name="seed_commercial_availability"
                      color="primary"
                      value="Common"
                    />
                  }
                  label="Common"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.uncommon}
                      onChange={(e) => handleValueChange(e)}
                      name="seed_commercial_availability"
                      color="primary"
                      value="Uncommon"
                    />
                  }
                  label="Uncommon"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.rare}
                      onChange={(e) => handleValueChange(e)}
                      name="seed_commercial_availability"
                      color="primary"
                      value="Rare"
                    />
                  }
                  label="Rare"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={initial_inputs_checkboxes.very_rare_unavailable}
                      onChange={(e) => handleValueChange(e)}
                      name="seed_commercial_availability"
                      color="primary"
                      value="very_rare_unavailable"
                    />
                  }
                  label="Very Rare or Unavailable"
                />
              </FormGroup>
            </div>
          </div>

          <div className="col-md-6">
            <strong>
              Use this as a visual reference for various sections of a solar
              site
            </strong>
            <img
              src={plantingZoneImage}
              alt="planting zone image"
              style={{ width: "90%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SeedInitialInputs;
