import { createSlice } from "@reduxjs/toolkit";
import { cost_detailed_summary_total_default_values } from "../components/seto_tools/cost_benefit_components/utils/CostDefaultValues";
import { cost_detailed_summmary_array_default_values } from "../components/seto_tools/cost_benefit_components/utils/CostDefaultValues";
import { cost_detailed_summmary_buffer_default_values } from "../components/seto_tools/cost_benefit_components/utils/CostDefaultValues";

const cost_benefit_slice = createSlice({
  name: "cost_benefit",
  initialState: {
    selected_options: [0],
    cost_results_page_render: false,
    yearly_cost_rendered: false,
    cost_information: {
      date: null,
      project_name: "",
      project_location: "",
      user_name: "",
      notes: "",
      establishment_phase: 3,
      maintenance_phase: 22,
      assumed_increase_end_row: 40,
      assumed_increase_narrow_row: 5,
      assumed_post_construction_cost: 150,
      user_defined_vegetation: [],
    },
    benefits_count:{
      1: 0,
      2: 0,
      3: 0
    },
    totalProjectCostGraph: null,
    totalProjectCostGraphNPV: null,
    installationCostGraph: null,
    capOpxGraph: null,
    cost_inputs: {
      0: {
        option_name: "",
        installed_vegetation_type: "",
        array_width: "",
        minimum_panel_height: "",
        row_restrictions: "",
        minimum_panel_height: "",
        array_size: "",
        desired_vegetation_array: "",
        fertilizer_required_array: "",
        soil_amendment_required_array: "",
        pre_seeding_cost: "",
        seed_mix_cost_array: "",
        seed_installation_cost: "",
        fertilizer_cost_array: "",
        soil_amendements_cost_array: "",
        decompation_or_tillage_cost: "",
        temporary_stabilization_cost: "",
        custom_input_cost_1: 0,
        custom_input_cost_2: 0,
        custom_input_cost_3: 0,
        mowing_treatment_cost: 200,
        herbicide_treatment_cost: 200,
        supplemental_seeding_cost: 500,
        grazing_management_service_cost: 500,
        inspections_and_monitor_cost: 20,
        no_of_mowing_treatments_establishment: "",
        no_of_herbicide_treatments_establishment: "",
        no_of_supplemental_seeding_establishment: "",
        no_of_grazing_management_establishment: "",
        no_of_inspections_and_monitoring_establishment: "",
        no_of_mowing_treatments_maintenance: "",
        no_of_herbicide_treatments_maintenance: "",
        no_of_supplemental_seeding_maintenance: "",
        no_of_grazing_management_maintenance: "",
        no_of_inspections_and_monitoring_maintenance: "",
      },
      1: {
        option_name: "",
        installed_vegetation_type: "",
        array_width: "",
        minimum_panel_height: "",
        row_restrictions: "",
        minimum_panel_height: "",
        array_size: "",
        desired_vegetation_array: "",
        fertilizer_required_array: "",
        soil_amendment_required_array: "",
        pre_seeding_cost: "",
        seed_mix_cost_array: "",
        seed_installation_cost: "",
        fertilizer_cost_array: "",
        soil_amendements_cost_array: "",
        decompation_or_tillage_cost: "",
        temporary_stabilization_cost: "",
        custom_input_cost_1: 0,
        custom_input_cost_2: 0,
        custom_input_cost_3: 0,
        mowing_treatment_cost: 200,
        herbicide_treatment_cost: 200,
        supplemental_seeding_cost: 500,
        grazing_management_service_cost: 500,
        inspections_and_monitor_cost: 20,
        no_of_mowing_treatments_establishment: "",
        no_of_herbicide_treatments_establishment: "",
        no_of_supplemental_seeding_establishment: "",
        no_of_grazing_management_establishment: "",
        no_of_inspections_and_monitoring_establishment: "",
        no_of_mowing_treatments_maintenance: "",
        no_of_herbicide_treatments_maintenance: "",
        no_of_supplemental_seeding_maintenance: "",
        no_of_grazing_management_maintenance: "",
        no_of_inspections_and_monitoring_maintenance: "",
      },
      2: {
        option_name: "",
        installed_vegetation_type: "",
        array_width: "",
        minimum_panel_height: "",
        row_restrictions: "",
        minimum_panel_height: "",
        array_size: "",
        desired_vegetation_array: "",
        fertilizer_required_array: "",
        soil_amendment_required_array: "",
        pre_seeding_cost: "",
        seed_mix_cost_array: "",
        seed_installation_cost: "",
        fertilizer_cost_array: "",
        soil_amendements_cost_array: "",
        decompation_or_tillage_cost: "",
        temporary_stabilization_cost: "",
        custom_input_cost_1: 0,
        custom_input_cost_2: 0,
        custom_input_cost_3: 0,
        mowing_treatment_cost: 200,
        herbicide_treatment_cost: 200,
        supplemental_seeding_cost: 500,
        grazing_management_service_cost: 500,
        inspections_and_monitor_cost: 20,
        no_of_mowing_treatments_establishment: "",
        no_of_herbicide_treatments_establishment: "",
        no_of_supplemental_seeding_establishment: "",
        no_of_grazing_management_establishment: "",
        no_of_inspections_and_monitoring_establishment: "",
        no_of_mowing_treatments_maintenance: "",
        no_of_herbicide_treatments_maintenance: "",
        no_of_supplemental_seeding_maintenance: "",
        no_of_grazing_management_maintenance: "",
        no_of_inspections_and_monitoring_maintenance: "",
      },
    },
    cost_inputs_buffer: {
      0: {
        option_name: "",
        installed_vegetation_type: "",
        array_width: "",
        minimum_panel_height: "",
        row_restrictions: "",
        minimum_panel_height: "",
        array_size: "",
        desired_vegetation_array: "",
        fertilizer_required_array: "",
        soil_amendment_required_array: "",
        pre_seeding_cost: "",
        seed_mix_cost_array: "",
        seed_installation_cost: "",
        fertilizer_cost_array: "",
        soil_amendements_cost_array: "",
        decompation_or_tillage_cost: "",
        temporary_stabilization_cost: "",
        custom_input_cost_1: 0,
        custom_input_cost_2: 0,
        custom_input_cost_3: 0,
        mowing_treatment_cost: 200,
        herbicide_treatment_cost: 200,
        supplemental_seeding_cost: 500,
        grazing_management_service_cost: 500,
        inspections_and_monitor_cost: 20,
        no_of_mowing_treatments_establishment: "",
        no_of_herbicide_treatments_establishment: "",
        no_of_supplemental_seeding_establishment: "",
        no_of_grazing_management_establishment: "",
        no_of_inspections_and_monitoring_establishment: "",
        no_of_mowing_treatments_maintenance: "",
        no_of_herbicide_treatments_maintenance: "",
        no_of_supplemental_seeding_maintenance: "",
        no_of_grazing_management_maintenance: "",
        no_of_inspections_and_monitoring_maintenance: "",
      },
      1: {
        option_name: "",
        installed_vegetation_type: "",
        array_width: "",
        minimum_panel_height: "",
        row_restrictions: "",
        minimum_panel_height: "",
        array_size: "",
        desired_vegetation_array: "",
        fertilizer_required_array: "",
        soil_amendment_required_array: "",
        pre_seeding_cost: "",
        seed_mix_cost_array: "",
        seed_installation_cost: "",
        fertilizer_cost_array: "",
        soil_amendements_cost_array: "",
        decompation_or_tillage_cost: "",
        temporary_stabilization_cost: "",
        custom_input_cost_1: 0,
        custom_input_cost_2: 0,
        custom_input_cost_3: 0,
        mowing_treatment_cost: 200,
        herbicide_treatment_cost: 200,
        supplemental_seeding_cost: 500,
        grazing_management_service_cost: 500,
        inspections_and_monitor_cost: 20,
        no_of_mowing_treatments_establishment: "",
        no_of_herbicide_treatments_establishment: "",
        no_of_supplemental_seeding_establishment: "",
        no_of_grazing_management_establishment: "",
        no_of_inspections_and_monitoring_establishment: "",
        no_of_mowing_treatments_maintenance: "",
        no_of_herbicide_treatments_maintenance: "",
        no_of_supplemental_seeding_maintenance: "",
        no_of_grazing_management_maintenance: "",
        no_of_inspections_and_monitoring_maintenance: "",
      },
      2: {
        option_name: "",
        installed_vegetation_type: "",
        array_width: "",
        minimum_panel_height: "",
        row_restrictions: "",
        minimum_panel_height: "",
        array_size: "",
        desired_vegetation_array: "",
        fertilizer_required_array: "",
        soil_amendment_required_array: "",
        pre_seeding_cost: "",
        seed_mix_cost_array: "",
        seed_installation_cost: "",
        fertilizer_cost_array: "",
        soil_amendements_cost_array: "",
        decompation_or_tillage_cost: "",
        temporary_stabilization_cost: "",
        custom_input_cost_1: 0,
        custom_input_cost_2: 0,
        custom_input_cost_3: 0,
        mowing_treatment_cost: 200,
        herbicide_treatment_cost: 200,
        supplemental_seeding_cost: 500,
        grazing_management_service_cost: 500,
        inspections_and_monitor_cost: 20,
        no_of_mowing_treatments_establishment: "",
        no_of_herbicide_treatments_establishment: "",
        no_of_supplemental_seeding_establishment: "",
        no_of_grazing_management_establishment: "",
        no_of_inspections_and_monitoring_establishment: "",
        no_of_mowing_treatments_maintenance: "",
        no_of_herbicide_treatments_maintenance: "",
        no_of_supplemental_seeding_maintenance: "",
        no_of_grazing_management_maintenance: "",
        no_of_inspections_and_monitoring_maintenance: "",
      },
    },
    cost_refinements: {},
    cost_detailed_summary_array_values: cost_detailed_summmary_array_default_values,
    cost_detailed_summary_buffer_values: cost_detailed_summmary_buffer_default_values,
    cost_references:{
        turfgrass_maintained_with_frequent_mowing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 250,
            install_costs: 250,
            fertilizer_costs: 20,
            soil_amendment_costs: 500,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 10,
            number_of_herbicide_treatments_annually: 0,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        turfgrass_maintained_with_regular_mowing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 250,
            install_costs: 250,
            fertilizer_costs: 20,
            soil_amendment_costs: 500,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 6,
            number_of_herbicide_treatments_annually: 1,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        grass_with_some_forbs_maintained_with_periodic_mowing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 300,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 0,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 3,
            number_of_herbicide_treatments_annually: 2,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        grass_with_some_forbs_maintained_with_grazing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 300,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 0,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 0,
            number_of_herbicide_treatments_annually: 1,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 1,
            inspections_and_monitoring_annually: 4
        },
        mixed_grass_and_forbs_maintained_with_various_methods: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 450,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 25,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 1,
            number_of_herbicide_treatments_annually: 3,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        arid_rangeland_with_mixed_management: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 450,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 25,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 0,
            number_of_herbicide_treatments_annually: 2,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        user_defined: {
            pre_seeding_herbicide_treatment: 0,
            seed_cost: 0,
            install_costs: 0,
            fertilizer_costs: 0,
            soil_amendment_costs: 0,
            decompaction_or_tillage_costs: 0,
            stabilization_costs: 0,
            number_of_mowing_treatments_annually: 0,
            number_of_herbicide_treatments_annually: 0,
            number_of_supplemental_seedings_annually: 0,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 0
        }
    },
    installation_cost_results: {
      0: 0,
      1: 0,
      2: 0,
    },
    total_cost_npv:{
      1: 0,
      2: 0,
      3: 0
    },
    nvp_discount_rates:{
      1: 4.99,
      2: 4.99,
      3: 4.99
    },
    net_present_value:{
      1: 0,
      2: 0,
      3: 0
    },
    annual_cost_yearly:{
      1: [Array(30).fill(0)],
      2: [Array(30).fill(0)],
      3: [Array(30).fill(0)],
    },
    cummulative_cost_yearly:{
      1: [Array(30).fill(0)],
      2: [Array(30).fill(0)],
      3: [Array(30).fill(0)],
    },
    cummulative_discount_factor:{
      1: [Array(30).fill(0)],
      2: [Array(30).fill(0)],
      3: [Array(30).fill(0)],
    },
    cost_total_values : cost_detailed_summary_total_default_values,
  },
  reducers: {
    set_selected_options: (state, action) => {
      //console.log("store action", action);
      //console.log("store", action.payload);
      state.selected_options = action.payload;
    },
    set_cost_results_page_render: (state, action) => {
      state.cost_results_page_render = action.payload;
    },
    set_yearly_cost_rendered: (state, action) => {
      state.yearly_cost_rendered = action.payload;
    },
    set_total_project_cost_graph: (state, action) => {
      state.totalProjectCostGraph = action.payload;
    },
    set_total_project_cost_graph_npv: (state, action) => {
      state.totalProjectCostGraphNPV = action.payload;
    },
    set_installation_cost_graph: (state, action) => {
      state.installationCostGraph = action.payload;
    },
    set_cap_opx_graph: (state, action) => {
      state.capOpxGraph = action.payload;
    },
    set_cost_inputs: (state, action) => {
      state.cost_inputs = action.payload;
    },
    set_cost_information: (state, action) => {
      state.cost_information = action.payload;
    },
    set_cost_inputs_buffer: (state, action) => {
      state.cost_inputs_buffer = action.payload;
    },
    set_cost_refinements: (state, action) => {
      state.cost_refinements = action.payload;
    },
    set_cost_references: (state, action) => {
        state.cost_references = action.payload;
    },
    set_cost_total_values: (state, action) => {
        state.cost_total_values = action.payload;
    },
    set_total_cost_npv: (state, action) => {
        state.total_cost_npv = action.payload;
    },
    set_nvp_discount_rates: (state, action) => {
        state.nvp_discount_rates = action.payload;
    },
    set_annual_cost_yearly: (state, action) => {
        state.annual_cost_yearly = action.payload;
    },
    set_cummulative_cost_yearly: (state, action) => {
        state.cummulative_cost_yearly = action.payload;
    },
    set_cummulative_discount_factor: (state, action) => {
        state.cummulative_discount_factor = action.payload;
    },
    set_net_present_value: (state, action) => {
        state.net_present_value = action.payload;
    },
    set_cost_detailed_summary_array_values: (state, action) => {
        state.cost_detailed_summary_array_values = action.payload;
    },
    set_cost_detailed_summary_buffer_values: (state, action) => {
        state.cost_detailed_summary_buffer_values = action.payload;
    },
    set_cost_detailed_summary_total_values: (state, action) => {
        state.cost_detailed_summary_total_values = action.payload;
    },
    set_benefits_count: (state, action) => {
        state.benefits_count = action.payload;
    },
    set_installation_cost: (state, action) => {
      if (state.cost_results == null) {
        state.cost_results = {
          0: 0,
          1: 0,
          2: 0,
        };
      } else {
        console.log(state.cost_results);
      }

      state.cost_results.installation_cost_results = action.payload;
    },
    reset_cost_slice: (state, action) => {
      state.totalProjectCostGraph = null;
      state.totalProjectCostGraphNPV = null;
      state.cost_results_page_render = false;
      state.yearly_cost_rendered = false;
      state.installationCostGraph = null;
      state.capOpxGraph = null;
      //console.log("reset cost slice");
      state.cost_information = {
        date: null,
        project_name: "",
        project_location: "",
        user_name: "",
        notes: "",
        establishment_phase: 3,
        maintenance_phase: 22,
        assumed_increase_end_row: 40,
        assumed_increase_narrow_row: 5,
        assumed_post_construction_cost: 150,
        user_defined_vegetation_0: "",
        user_defined_vegetation_1: "",
        user_defined_vegetation_2: "",
      };
      state.cost_inputs = {
        0: {
          option_name: "",
          installed_vegetation_type: "",
          array_width: "",
          minimum_panel_height: "",
          row_restrictions: "",
          minimum_panel_height: "",
          array_size: "",
          desired_vegetation_array: "",
          fertilizer_required_array: "",
          soil_amendment_required_array: "",
          pre_seeding_cost: "",
          seed_mix_cost_array: "",
          seed_installation_cost: "",
          fertilizer_cost_array: "",
          soil_amendements_cost_array: "",
          decompation_or_tillage_cost: "",
          temporary_stabilization_cost: "",
          custom_input_cost_1: 0,
          custom_input_cost_2: 0,
          custom_input_cost_3: 0,
          mowing_treatment_cost: 200,
          herbicide_treatment_cost: 200,
          supplemental_seeding_cost: 500,
          grazing_management_service_cost: 500,
          inspections_and_monitor_cost: 20,
          no_of_mowing_treatments_establishment: "",
          no_of_herbicide_treatments_establishment: "",
          no_of_supplemental_seeding_establishment: "",
          no_of_grazing_management_establishment: "",
          no_of_inspections_and_monitoring_establishment: "",
          no_of_mowing_treatments_maintenance: "",
          no_of_herbicide_treatments_maintenance: "",
          no_of_supplemental_seeding_maintenance: "",
          no_of_grazing_management_maintenance: "",
          no_of_inspections_and_monitoring_maintenance: "",
        },
        1: {
          option_name: "",
          installed_vegetation_type: "",
          array_width: "",
          minimum_panel_height: "",
          row_restrictions: "",
          minimum_panel_height: "",
          array_size: "",
          desired_vegetation_array: "",
          fertilizer_required_array: "",
          soil_amendment_required_array: "",
          pre_seeding_cost: "",
          seed_mix_cost_array: "",
          seed_installation_cost: "",
          fertilizer_cost_array: "",
          soil_amendements_cost_array: "",
          decompation_or_tillage_cost: "",
          temporary_stabilization_cost: "",
          custom_input_cost_1: 0,
          custom_input_cost_2: 0,
          custom_input_cost_3: 0,
          mowing_treatment_cost: 200,
          herbicide_treatment_cost: 200,
          supplemental_seeding_cost: 500,
          grazing_management_service_cost: 500,
          inspections_and_monitor_cost: 20,
          no_of_mowing_treatments_establishment: "",
          no_of_herbicide_treatments_establishment: "",
          no_of_supplemental_seeding_establishment: "",
          no_of_grazing_management_establishment: "",
          no_of_inspections_and_monitoring_establishment: "",
          no_of_mowing_treatments_maintenance: "",
          no_of_herbicide_treatments_maintenance: "",
          no_of_supplemental_seeding_maintenance: "",
          no_of_grazing_management_maintenance: "",
          no_of_inspections_and_monitoring_maintenance: "",
        },
        2: {
          option_name: "",
          installed_vegetation_type: "",
          array_width: "",
          minimum_panel_height: "",
          row_restrictions: "",
          minimum_panel_height: "",
          array_size: "",
          desired_vegetation_array: "",
          fertilizer_required_array: "",
          soil_amendment_required_array: "",
          pre_seeding_cost: "",
          seed_mix_cost_array: "",
          seed_installation_cost: "",
          fertilizer_cost_array: "",
          soil_amendements_cost_array: "",
          decompation_or_tillage_cost: "",
          temporary_stabilization_cost: "",
          custom_input_cost_1: 0,
          custom_input_cost_2: 0,
          custom_input_cost_3: 0,
          mowing_treatment_cost: 200,
          herbicide_treatment_cost: 200,
          supplemental_seeding_cost: 500,
          grazing_management_service_cost: 500,
          inspections_and_monitor_cost: 20,
          no_of_mowing_treatments_establishment: "",
          no_of_herbicide_treatments_establishment: "",
          no_of_supplemental_seeding_establishment: "",
          no_of_grazing_management_establishment: "",
          no_of_inspections_and_monitoring_establishment: "",
          no_of_mowing_treatments_maintenance: "",
          no_of_herbicide_treatments_maintenance: "",
          no_of_supplemental_seeding_maintenance: "",
          no_of_grazing_management_maintenance: "",
          no_of_inspections_and_monitoring_maintenance: "",
        },
      };
      state.cost_inputs_buffer = {
        0: {
          option_name: "",
          installed_vegetation_type: "",
          array_width: "",
          minimum_panel_height: "",
          row_restrictions: "",
          minimum_panel_height: "",
          array_size: "",
          desired_vegetation_array: "",
          fertilizer_required_array: "",
          soil_amendment_required_array: "",
          pre_seeding_cost: "",
          seed_mix_cost_array: "",
          seed_installation_cost: "",
          fertilizer_cost_array: "",
          soil_amendements_cost_array: "",
          decompation_or_tillage_cost: "",
          temporary_stabilization_cost: "",
          custom_input_cost_1: 0,
          custom_input_cost_2: 0,
          custom_input_cost_3: 0,
          mowing_treatment_cost: 200,
          herbicide_treatment_cost: 200,
          supplemental_seeding_cost: 500,
          grazing_management_service_cost: 500,
          inspections_and_monitor_cost: 20,
          no_of_mowing_treatments_establishment: "",
          no_of_herbicide_treatments_establishment: "",
          no_of_supplemental_seeding_establishment: "",
          no_of_grazing_management_establishment: "",
          no_of_inspections_and_monitoring_establishment: "",
          no_of_mowing_treatments_maintenance: "",
          no_of_herbicide_treatments_maintenance: "",
          no_of_supplemental_seeding_maintenance: "",
          no_of_grazing_management_maintenance: "",
          no_of_inspections_and_monitoring_maintenance: "",
        },
        1: {
          option_name: "",
          installed_vegetation_type: "",
          array_width: "",
          minimum_panel_height: "",
          row_restrictions: "",
          minimum_panel_height: "",
          array_size: "",
          desired_vegetation_array: "",
          fertilizer_required_array: "",
          soil_amendment_required_array: "",
          pre_seeding_cost: "",
          seed_mix_cost_array: "",
          seed_installation_cost: "",
          fertilizer_cost_array: "",
          soil_amendements_cost_array: "",
          decompation_or_tillage_cost: "",
          temporary_stabilization_cost: "",
          custom_input_cost_1: 0,
          custom_input_cost_2: 0,
          custom_input_cost_3: 0,
          mowing_treatment_cost: 200,
          herbicide_treatment_cost: 200,
          supplemental_seeding_cost: 500,
          grazing_management_service_cost: 500,
          inspections_and_monitor_cost: 20,
          no_of_mowing_treatments_establishment: "",
          no_of_herbicide_treatments_establishment: "",
          no_of_supplemental_seeding_establishment: "",
          no_of_grazing_management_establishment: "",
          no_of_inspections_and_monitoring_establishment: "",
          no_of_mowing_treatments_maintenance: "",
          no_of_herbicide_treatments_maintenance: "",
          no_of_supplemental_seeding_maintenance: "",
          no_of_grazing_management_maintenance: "",
          no_of_inspections_and_monitoring_maintenance: "",
        },
        2: {
          option_name: "",
          installed_vegetation_type: "",
          array_width: "",
          minimum_panel_height: "",
          row_restrictions: "",
          minimum_panel_height: "",
          array_size: "",
          desired_vegetation_array: "",
          fertilizer_required_array: "",
          soil_amendment_required_array: "",
          pre_seeding_cost: "",
          seed_mix_cost_array: "",
          seed_installation_cost: "",
          fertilizer_cost_array: "",
          soil_amendements_cost_array: "",
          decompation_or_tillage_cost: "",
          temporary_stabilization_cost: "",
          custom_input_cost_1: 0,
          custom_input_cost_2: 0,
          custom_input_cost_3: 0,
          mowing_treatment_cost: 200,
          herbicide_treatment_cost: 200,
          supplemental_seeding_cost: 500,
          grazing_management_service_cost: 500,
          inspections_and_monitor_cost: 20,
          no_of_mowing_treatments_establishment: "",
          no_of_herbicide_treatments_establishment: "",
          no_of_supplemental_seeding_establishment: "",
          no_of_grazing_management_establishment: "",
          no_of_inspections_and_monitoring_establishment: "",
          no_of_mowing_treatments_maintenance: "",
          no_of_herbicide_treatments_maintenance: "",
          no_of_supplemental_seeding_maintenance: "",
          no_of_grazing_management_maintenance: "",
          no_of_inspections_and_monitoring_maintenance: "",
        },
      };
      (state.cost_refinements = {}),
        (state.cost_references = {
        turfgrass_maintained_with_frequent_mowing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 250,
            install_costs: 250,
            fertilizer_costs: 20,
            soil_amendment_costs: 500,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 10,
            number_of_herbicide_treatments_annually: 0,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        turfgrass_maintained_with_regular_mowing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 250,
            install_costs: 250,
            fertilizer_costs: 20,
            soil_amendment_costs: 500,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 6,
            number_of_herbicide_treatments_annually: 1,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        grass_with_some_forbs_maintained_with_periodic_mowing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 300,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 0,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 3,
            number_of_herbicide_treatments_annually: 2,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        grass_with_some_forbs_maintained_with_grazing: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 300,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 0,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 0,
            number_of_herbicide_treatments_annually: 1,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 1,
            inspections_and_monitoring_annually: 4
        },
        mixed_grass_and_forbs_maintained_with_various_methods: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 450,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 25,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 1,
            number_of_herbicide_treatments_annually: 3,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        arid_rangeland_with_mixed_management: {
            pre_seeding_herbicide_treatment: 200,
            seed_cost: 450,
            install_costs: 250,
            fertilizer_costs: 0,
            soil_amendment_costs: 25,
            decompaction_or_tillage_costs: 100,
            stabilization_costs: 200,
            number_of_mowing_treatments_annually: 0,
            number_of_herbicide_treatments_annually: 2,
            number_of_supplemental_seedings_annually: 1,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 4
        },
        user_defined: {
            pre_seeding_herbicide_treatment: 0,
            seed_cost: 0,
            install_costs: 0,
            fertilizer_costs: 0,
            soil_amendment_costs: 0,
            decompaction_or_tillage_costs: 0,
            stabilization_costs: 0,
            number_of_mowing_treatments_annually: 0,
            number_of_herbicide_treatments_annually: 0,
            number_of_supplemental_seedings_annually: 0,
            grazing_management_services_annually: 0,
            inspections_and_monitoring_annually: 0
        }
        });
        state.cost_total_values = cost_detailed_summary_total_default_values;
        state.cost_detailed_summary_array_values = cost_detailed_summmary_array_default_values;
        state.cost_detailed_summary_buffer_values = cost_detailed_summmary_buffer_default_values;
        state.benefits_count = {
          1: 0,
          2: 0,
          3: 0
        };
        (state.installation_cost_results = {
          0: 0,
          1: 0,
          2: 0,
        });
        state.total_cost_npv = {
          1: 0,
          2: 0,
          3: 0
        };
        state.nvp_discount_rates = {
          1: 4.99,
          2: 4.99,
          3: 4.99
        };
        state.net_present_value = {
          1: 0,
          2: 0,
          3: 0
        };
        state.annual_cost_yearly = {
          1: [Array(30).fill(0)],
          2: [Array(30).fill(0)],
          3: [Array(30).fill(0)],
        };
        state.cummulative_cost_yearly = {
          1: [Array(30).fill(0)],
          2: [Array(30).fill(0)],
          3: [Array(30).fill(0)],
        };
        state.cummulative_discount_factor = {
          1: [Array(30).fill(0)],
          2: [Array(30).fill(0)],
          3: [Array(30).fill(0)],
        };
      state.selected_options = [0];
    },
  },
});

export const cost_benefit_actions = cost_benefit_slice.actions;
export default cost_benefit_slice;
