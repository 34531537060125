import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link
} from "@react-pdf/renderer";

import phase_logo from "../../../../img/PHASE_noimages.jpg";
import stantec_logo from "../../../../img/stantec.png";
import uic_erc_logo from "../../../../img/uic_erc_logo.jpg";
import uic_logo from "../../../../img/uic_rgb.jpg";
import GraphWrapper from "../graphs/TotalProjectCost";
import BarGraphInstallationCostWrapper from "../graphs/InstallationCost";
import BarGraphCapOpxCostWrapper from "../graphs/CapOpx";

// Register the "Oswald" font
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf"
});

//create tick symbol from material ui icon

const tickSymbol = "X";

// Create styles for the pdf component
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Times-Roman"
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Times-Roman",
    textDecoration: "underline",
    textAlign: "center"
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman"
  },
  image: {
    height: 75,
    width: 130,
    marginLeft: "auto",
    marginRight: "auto",
    objectFit: "contain",
    objectPosition: "center"
  },
  graphImage: {
    height: "auto",
    width: "80%",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: "auto",
    marginRight: "auto"
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  disclaimer: {
    fontSize: 13,
    margin: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    wordWrap: "break-word",
    whiteSpace: "nowrap"
  },
  table: {
    width: "100%",
    border: "1px solid #000",
    borderCollapse: "collapse",
    margin: 10,
    marginBottom: 20,
    fontSize: 8,
    fontFamily: "Times-Roman",
    textAlign: "center",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "table",
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    borderLeft: 0,
    borderTop: 0
  },

  // Styles for table rows
  tableRow: {
    flexDirection: "row"
  },

  // Styles for table header cells
  tableHeaderCell: {
    flex: 1,
    backgroundColor: "#f2f2f2",
    padding: 5,
    border: "1px solid #000"
  },

  // Styles for table data cells
  tableCell: {
    flex: 1,
    padding: 5,
    border: "1px solid #000",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    flexDirection: "row"
  },
  tableCellText: {
    fontSize: 8,
    fontFamily: "Times-Roman",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word"
  },
  tableCellHeader: {
    fontSize: 8,
    fontFamily: "Times-Roman",
    fontWeight: "bold"
  },
  logoContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 10
  }
});

function GeneratePdf(props) {
  /*
        Component to generate pdf for the cost benefit component

        @props:

        cost_summary_values: Object - contains the cost summary values
        benefitsCount: Object - contains the benefits count for each option
        cost_input_array: Object - contains the cost input array for each option
        cost_input_buffer: Object - contains the cost input buffer for each option
        cost_information_store: Object - contains the cost information store
        total_npv_cost: Object - contains the total npv cost for each option
        InstallationCostGraph: String - contains the installation cost graph
        CapOpxCostGraph: String - contains the capex and opex cost graph
        TotalProjectCostGraph: String - contains the total project cost graph
        BenefitCountGraph: String - contains the benefit count graph

        mapping for the parameterColumn to the props of GeneratePdf for each option

        @params:
        "Custom Option Name": props.cost_summary_values[index].option_name? props.cost_summary_values[index].option_name : "N/A"",
        "Array Vegetation Acres": props.cost_summary_values[index].array_cover? props.cost_summary_values[index].array_cover : "N/A",
        "Buffer Vegetation Acres": props.cost_summary_values[index].buffer_cover? props.cost_summary_values[index].buffer_cover : "N/A",
        "Installation Cost": props.cost_summary_values[index].installation_cost? props.cost_summary_values[index].installation_cost : "N/A",
        "Installation Unit Cost ($/Acre)": props.cost_summary_values[index].installation_unit_cost? props.cost_summary_values[index].installation_unit_cost : "N/A",
        "Establishment Cost": props.cost_summary_values[index].establishment_cost? props.cost_summary_values[index].establishment_cost : "N/A",
        "Establishment Unit Cost ($/Acre)": props.cost_summary_values[index].establishment_unit_cost? props.cost_summary_values[index].establishment_unit_cost : "N/A",
        "CAPEX Cost (Installation + Establishment)": props.cost_summary_values[index].capex_cost? props.cost_summary_values[index].capex_cost : "N/A",
        "CAPEX Cost (Installation + Establishment; $/Acre)": props.cost_summary_values[index].capex_cost_unit? props.cost_summary_values[index].capex_cost_unit : "N/A",
        "OPEX Cost (Maintenance)": props.cost_summary_values[index].opex_cost? props.cost_summary_values[index].opex_cost : "N/A",
        "OPEX Cost (Maintenance; $/Acre)": props.cost_summary_values[index].opex_unit_cost? props.cost_summary_values[index].opex_unit_cost : "N/A",
        "OPEX Cost (Maintenance; $/Acre/Year)": props.cost_summary_values[index].opex_unit_cost_year? props.cost_summary_values[index].opex_unit_cost_year : "N/A",
        "Total Project Cost": props.cost_summary_values[index].total_project_cost? props.cost_summary_values[index].total_project_cost : "N/A",
        "Total Project Cost NPV": props.cost_summary_values[index].total_project_cost_npv? props.cost_summary_values[index].total_project_cost_npv : "N/A",
        "Expected NPDES Closure within 1 Year of Construction": props.cost_summary_values[index].expected_npdes_closure? props.cost_summary_values[index].expected_npdes_closure : "N/A",
        "Lowest Install Cost": props.cost_summary_values[index].lowest_per_acre_installation_cost? props.cost_summary_values[index].lowest_per_acre_installation_cost : "N/A",
        "Lowest Establish Cost": props.cost_summary_values[index].lowest_per_acre_establishment_cost? props.cost_summary_values[index].lowest_per_acre_establishment_cost : "N/A",
        "Lowest Total Project Cost": props.cost_summary_values[index].lowest_total_project_cost? props.cost_summary_values[index].lowest_total_project_cost : "N/A",
        "Community Acceptance (User-Enteted)": props.cost_summary_values[index].community_acceptance? props.cost_summary_values[index].community_acceptance : "N/A",
        "Supports Biodiversity ESG Goals (User-Entered)": props.cost_summary_values[index].biodiversity_esg_goals? props.cost_summary_values[index].biodiversity_esg_goals : "N/A",
        "Pollinator Habitat Uplift (User-Entered)": props.cost_summary_values[index].pollinator_habitat_uplift? props.cost_summary_values[index].pollinator_habitat_uplift : "N/A",
        "Enhanced Soil Health (User-Entered)": props.cost_summary_values[index].enhanced_soil? props.cost_summary_values[index].enhanced_soil : "N/A",
        "Carbon Sequestration (User-Entered)": props.cost_summary_values[index].carbon_sequestration? props.cost_summary_values[index].carbon_sequestration : "N/A",
        "Count of Benefits": props.cost_summary_values[index].count_of_benefits? props.cost_summary_values[index].count_of_benefits : "N/A",

  */

  const [graphImages, setGraphImages] = React.useState({
    GraphWrapperImage: null,
    BarGraphInstallationCostWrapperImage: null,
    BarGraphCapOpxCostWrapperImage: null
  });

  // Define a function to create a table-like structure

  const renderTable = (data, columns) => (
    <View>
      {/* Table Header */}
      <View style={styles.tableRow}>
        {columns.map((column, index) => (
          <View key={index} style={styles.tableHeaderCell}>
            <Text style={styles.tableCellHeader}>{column.title}</Text>
          </View>
        ))}
      </View>

      {/* Table Rows */}
      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {columns.map((column, colIndex) => (
            <View key={colIndex} style={styles.tableCell}>
              <Text style={styles.tableCellText}>{row[column.dataIndex]}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );

  const parameterColumn = [
    "Custom Option Name",
    "Planted Acres",
    "Installation Cost",
    "Installation Unit Cost ($/Acre)",
    "Establishment Cost",
    "Establishment Unit Cost ($/Acre)",
    "CAPEX Cost                                                   (Installation + Establishment)",
    "CAPEX Cost                                                   (Installation + Establishment; $/Acre)",
    "CAPEX Cost                                                   (Installation + Establishment; $/Acre/Year)",
    "OPEX Cost (Maintenance)",
    "OPEX Cost (Maintenance; $/Acre)",
    "OPEX Cost (Maintenance; $/Acre/Year)",
    "Total Project Cost",
    "Expected NPDES Closure within 1 Year of Construction",
    "Lowest Per Acre Installation Cost",
    "Lowest Establishment Cost",
    "Lowest Total Project Cost",
    "Community Acceptance                   (User-Entered)",
    "Supports Biodiversity ESG Goals (User-Entered)",
    "Pollinator Habitat Uplift              (User-Entered)",
    "Enhanced Soil Health (User-Entered)",
    "Carbon Sequestration (User-Entered)",
    "Count of Benefits"
  ];

  const organizationColumns = [
    "Project Name",
    "Project Location",
    "Project Duration (Establishment Phase)",
    "Project Duration (Maintenance Phase)",
    "Vegetation cost increase assumed for end row restrictions (%)",
    "Vegetation cost increase assumed for narrow row widths (%)",
    "Assumed installation cost increase for post-construction seeding (%)"
  ];

  const organizationDataMapping = {
    "Project Name": props.cost_information_store.project_name
      ? props.cost_information_store.project_name
      : "N/A",
    "Project Location": props.cost_information_store.project_location
      ? props.cost_information_store.project_location
      : "N/A",
    "Project Duration (Establishment Phase)": props.cost_information_store
      .establishment_phase
      ? props.cost_information_store.establishment_phase
      : "N/A",
    "Project Duration (Maintenance Phase)": props.cost_information_store
      .maintenance_phase
      ? props.cost_information_store.maintenance_phase
      : "N/A",
    "Vegetation cost increase assumed for end row restrictions (%)": props
      .cost_information_store.assumed_increase_end_row
      ? props.cost_information_store.assumed_increase_end_row
      : "N/A",
    "Vegetation cost increase assumed for narrow row widths (%)": props
      .cost_information_store.assumed_increase_narrow_row
      ? props.cost_information_store.assumed_increase_narrow_row
      : "N/A",
    "Assumed installation cost increase for post-construction seeding (%)":
      props.cost_information_store.assumed_post_construction_cost
        ? props.cost_information_store.assumed_post_construction_cost
        : "N/A"
  };

  const costSummaryValues = props.cost_summary_values
    ? props.cost_summary_values
    : {};

  let costSummaryMapping = {};

  const convertToCurrency = (value) => {
    if (value !== null && value !== undefined && typeof value === "number") {
      return parseFloat(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    } else {
      return null;
    }
  };

  if (typeof costSummaryValues === "object" && costSummaryValues !== null) {
    costSummaryMapping = Object.values(costSummaryValues).reduce(
      (acc, item, index) => {
        acc[index] = {
          "Custom Option Name": item.option_name || " ",
          "Planted Acres": item.array_cover || " ",
          "Installation Cost":
            convertToCurrency(item.installation_cost) !== null
              ? convertToCurrency(item.installation_cost)
              : " ",
          "Installation Unit Cost ($/Acre)":
            convertToCurrency(item.installation_unit_cost) !== null
              ? convertToCurrency(item.installation_unit_cost)
              : " ",
          "Establishment Cost":
            convertToCurrency(item.establishment_cost) !== null
              ? convertToCurrency(item.establishment_cost)
              : " ",
          "Establishment Unit Cost ($/Acre)":
            convertToCurrency(item.establishment_unit_cost) !== null
              ? convertToCurrency(item.establishment_unit_cost)
              : " ",
          "CAPEX Cost                                                   (Installation + Establishment)":
            convertToCurrency(item.capex_cost) !== null
              ? convertToCurrency(item.capex_cost)
              : " ",
          "CAPEX Cost                                                   (Installation + Establishment; $/Acre)":
            convertToCurrency(item.capex_unit_cost) !== null
              ? convertToCurrency(item.capex_unit_cost)
              : " ",
          "CAPEX Cost                                                   (Installation + Establishment; $/Acre/Year)":
            convertToCurrency(item.capex_unit_cost_year) !== null
              ? convertToCurrency(item.capex_unit_cost_year)
              : " ",
          "OPEX Cost (Maintenance)":
            convertToCurrency(item.opex_cost) !== null
              ? convertToCurrency(item.opex_cost)
              : " ",
          "OPEX Cost (Maintenance; $/Acre)":
            convertToCurrency(item.opex_unit_cost) !== null
              ? convertToCurrency(item.opex_unit_cost)
              : " ",
          "OPEX Cost (Maintenance; $/Acre/Year)":
            convertToCurrency(item.opex_unit_cost_year) !== null
              ? convertToCurrency(item.opex_unit_cost_year)
              : " ",
          "Total Project Cost":
            convertToCurrency(item.total_project_cost) !== null
              ? convertToCurrency(item.total_project_cost)
              : "-",
          "Expected NPDES Closure within 1 Year of Construction":
            item.expected_npdes_closure === "\u2713" ? tickSymbol : "-",
          "Lowest Per Acre Installation Cost":
            item.lowest_per_acre_installation_cost === "\u2713"
              ? tickSymbol
              : "-",
          "Lowest Establishment Cost":
            item.lowest_per_acre_establishment_cost === "\u2713"
              ? tickSymbol
              : "-",
          "Lowest Total Project Cost":
            item.lowest_total_project_cost === "\u2713" ? tickSymbol : "-",
          "Community Acceptance                   (User-Entered)":
            item.community_acceptance === "\u2713" ? tickSymbol : "-",
          "Supports Biodiversity ESG Goals (User-Entered)":
            item.biodiversity_esg_goals === "\u2713" ? tickSymbol : "-",
          "Pollinator Habitat Uplift              (User-Entered)":
            item.pollinator_habitat_uplift === "\u2713" ? tickSymbol : "-",
          "Enhanced Soil Health (User-Entered)":
            item.enhanced_soil === "\u2713" ? tickSymbol : "-",
          "Carbon Sequestration (User-Entered)":
            item.carbon_sequestration === "\u2713" ? tickSymbol : "-"
        };
        return acc;
      },
      {}
    );
  } else {
    console.error("costSummaryValues is not an object");
  }

  //add the following to the costMapping
  // set benefitsCount[1] as costMapping[1].Count of Benefits and so on

  costSummaryMapping = Object.values(costSummaryMapping).map((item, index) => {
    item["Count of Benefits"] = props.benefitsCount[index + 1]
      ? props.benefitsCount[index + 1]
      : "N/A";
    return item;
  });

  /*
        generate the tableData according to this format

        [
            {
                CostAnalysisParameters: parameterColumn
            }
            {
                Option1: dataOption1
            }
            {
                Option2: dataOption2
            }
            {
                Option3: dataOption3
            }
        ]
  */

  const dataOption1 = costSummaryMapping[0] ? costSummaryMapping[0] : {};
  const dataOption2 = costSummaryMapping[1] ? costSummaryMapping[1] : {};
  const dataOption3 = costSummaryMapping[2] ? costSummaryMapping[2] : {};

  return (
    <>
      <Document>
        <Page style={styles.body} wrap={false}>
          <Text style={styles.header} fixed>
            ~ Accessed on {new Date().toLocaleString("en-US").split(",")[0]} ~
          </Text>
          <View style={styles.logoContainer}>
            <Link src="https://phase.erc.uic.edu/Tools">
              <Image style={styles.image} src={phase_logo} />
            </Link>
            <Link src="https://erc.uic.edu">
              <Image style={styles.image} src={uic_erc_logo} />
            </Link>
            <Link src="https://www.stantec.com/en">
              <Image style={styles.image} src={stantec_logo} />
            </Link>
          </View>
          <Text style={styles.subtitle}>Acknowledgement</Text>
          <Text style={styles.disclaimer}>
            This material is based upon work supported by the U.S. Department of
            Energy’s Office of Energy Efficiency and Renewable Energy (EERE)
            under the{" "}
            <Link
              src="https://www.energy.gov/eere/solar/"
              style={{ color: "blue" }}
            >
              "Solar Energy Technologies Office"(SETO){" "}
            </Link>
            award number DE-EE0009371.
          </Text>
          <Text style={styles.subtitle}>Organization Information</Text>
          {renderTable(
            organizationColumns.map((column) => ({
              "Organization Information Parameter": column,
              Value: organizationDataMapping[column]
            })),
            [
              {
                title: "Organization Information Parameter",
                dataIndex: "Organization Information Parameter"
              },
              {
                title: "Value",
                dataIndex: "Value"
              }
            ]
          )}
          <Text style={styles.subtitle}>Cost Analysis Results</Text>
          {renderTable(
            parameterColumn.map((parameter) => ({
              "Cost Analysis Parameter": parameter,
              "Option 1": dataOption1[parameter],
              "Option 2": dataOption2[parameter],
              "Option 3": dataOption3[parameter]
            })),
            [
              {
                title: "Cost Analysis Parameter",
                dataIndex: "Cost Analysis Parameter"
              },
              {
                title: "Option 1",
                dataIndex: "Option 1"
              },
              {
                title: "Option 2",
                dataIndex: "Option 2"
              },
              {
                title: "Option 3",
                dataIndex: "Option 3"
              }
            ]
          )}
          <Text style={styles.subtitle}>Total Project Cost Graph</Text>
          <Image src={props.TotalProjectCostGraph} style={styles.graphImage} />
          <Text style={styles.subtitle}>Installation Cost Graph</Text>
          <Image src={props.InstallationCostGraph} style={styles.graphImage} />
          <Text style={styles.subtitle}>CAPEX and OPEX Cost Graph</Text>
          <Image src={props.CapOpxCostGraph} style={styles.graphImage} />
          <Text style={styles.subtitle}>Benefit Count Graph</Text>
          <Image src={props.BenefitCountGraph} style={styles.graphImage} />
          <Text style={styles.subtitle}>Disclaimer</Text>
          <Text style={styles.disclaimer}>
            The Cost Comparison Tool is intended for use by site planners and
            vegetation managers involved in decision making for solar project
            site vegetation planning. Outputs of this tool are generated by the
            user-determined inputs and limited by the selected variables
            included. Real-world applications may be more complex and require
            additional considerations for constraints and complexities not
            accounted for in this tool's interface. This tool was created by the
            University of Illinois Chicago and Stantec with support of the U.S.
            Department of Energy's Solar Energy Technologies Office. None of the
            developers, contributors, administrators, or anyone else connected
            with this tool, in any way whatsoever, can be responsible for your
            use of the information contained within or yielded from.{" "}
          </Text>
        </Page>
      </Document>
    </>
  );
}

export default GeneratePdf;
