import React,{ useEffect } from 'react'
import { Card , CardImg ,CardBody , CardTitle , CardText, Button} from 'reactstrap'
import '../css/tools.css'
import { useNavigate } from 'react-router-dom';
import pollinator_scorecard from '../img/sm-cost_benefit_tool.png'
import cost_benefit from '../img/cost-comparision-tool-cropped.png';
import seed_selection from '../img/sm-seed_selection_tool.png';
import score_card from '../img/sm-score_card.png';
import { seed_selection_actions } from '../store/seed_selection_slice';
import { cost_benefit_actions } from '../store/cost_benefit_slice';
import { useDispatch, useSelector } from 'react-redux';

function Tools() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(seed_selection_actions.reset_seed_selection());
    dispatch(cost_benefit_actions.reset_cost_slice());
  }, []);

  return (
    <>
          <div className='container-fluid'>


              <div className="row tools-container">

                  <div className='col-3 justify-content-center'>
                      <Card className="cardsize" onClick={() => navigate('/PollinatorImplementManual')} style={{ cursor: "pointer" }}>
                          <CardImg
                              alt="Planting Manual Tool Image"
                              src={pollinator_scorecard}
                              className="tool-image-wrapper"
                          />
                          <CardBody>
                              <CardTitle tag="h5">
                                  Pollinator Planting<br /> Implementation Manual
                              </CardTitle>
                              <CardText>
                                  A comprehensive primer on pollinator vegetation establishment and
                                  management at large-scale solar facilities, describing overall goals
                                  and key considerations for planning, design, installation, and maintenance.
                              </CardText>
                          </CardBody>
                      </Card>
                  </div>

                  <div className='col-3 justify-content-center'>
                      <Card className="cardsize" onClick={() => navigate('/CostComparison')} style={{ cursor: "pointer" }}>
                          <CardImg
                              alt="Cost-Benefit Tool Image"
                              src={cost_benefit}
                              className="tool-image-wrapper"
                          />
                          <CardBody>
                              <CardTitle tag="h5">
                                  Cost Comparison<br /> Tool
                              </CardTitle>
                              <CardText>
                                  An online tool that allows users to conduct a planning level cost-benefit analysis
                                  of different vegetation configurations on utility-scale solar facilities.
                              </CardText>
                          </CardBody>
                      </Card>
                  </div>

            <div className='col-3 justify-content-center'>
                      <Card className="cardsize" onClick={() => navigate('/SeedSelection')} style={{ cursor: "pointer" }}>
                    <CardImg
                      alt="Seed Selection Tool Image"
                              src={seed_selection}
                              className="tool-image-wrapper"
                    />
                  <CardBody>
                    <CardTitle tag="h5">
                                  Solar Seed<br /> Selection Tool
                    </CardTitle>
                    <CardText>
                                  An online tool that allows users to identify a suite of suitable native and non-native species
                                  suitable for utility-scale solar plantings. A walkthrough of the seed selection tool can be found <a
                                  rel='noreferrer' target='_blank'
                                  style={{ color: 'blue' }}
                                  href="https://www.youtube.com/watch?v=mjHwmjrW80A&ab_channel=EnergyResourcesCenter">here</a>.
                    </CardText>
                  </CardBody>
                </Card>
                  </div>

            <div className='col-3 justify-content-center'>
                      <Card className="cardsize" onClick={() => navigate('/HabitatAssessModule')} style={{ cursor: "pointer" }}>
                    <CardImg
                              alt="Scorecard Image"
                              className="tool-image-wrapper"
                              src={score_card}
                    />
                  <CardBody>
                    <CardTitle tag="h5">
                                  Habitat Assessment<br /> Module
                    </CardTitle>
                    <CardText>
                                  Updated guidance for using the Rights-of-Way as Habitat Working Group's pollinator scorecard to
                                  evaluate established pollinator plantings at utility-scale solar facilities.
                    </CardText>
                  </CardBody>
                </Card>
                  </div>

        </div>
       </div>
    </>
  )
}

export default Tools

