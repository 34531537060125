import React from 'react'
import "../../../../css/tools.css"

function ColorLegend() {
  return (
    <>
        <div className="row">
            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th scope="col">Color</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="gold__accent__4"></td>
                        <td>User entries</td>
                    </tr>
                    {/* <tr>
                        <td className="blue__accent__1__lighter__60"></td>
                        <td>Reference data lookup values</td>
                    </tr> */}
                    <tr>
                        <td className="green__accent__6__lighter__60"></td>
                        <td>Reference data lookup return cells</td>
                    </tr>
                    {/* <tr>
                        <td className="light__grey__background__2"></td>
                        <td>Text Headings and Information</td>
                    </tr>
                    <tr>
                        <td className="orange__accent__2__lighter__60"></td>
                        <td>Default values, user may edit where appropriate</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    </>
  )
}

export default ColorLegend