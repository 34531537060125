import {configureStore,combineReducers} from '@reduxjs/toolkit';
import {persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import auth_slice from './auth_slice';
import seed_selection_slice from './seed_selection_slice';
import cost_benefit_slice from './cost_benefit_slice';

const reducers = combineReducers({
    // reducers
    auth: auth_slice.reducer,
    seed_selection: seed_selection_slice.reducer,
    cost_benefit_slice: cost_benefit_slice.reducer
});

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});


export default store;
