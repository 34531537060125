import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import api from "../../../axiosConfig";
//import { Map } from '@esri/react-arcgis';

import { setDefaultOptions, loadModules } from "esri-loader";
import FormGroup from "@mui/material/FormGroup";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { seed_selection_actions } from "../../../store/seed_selection_slice";
import "../../../css/main.css";
import { Typography, Alert, Cascader, Spin } from "antd";

const { SHOW_PARENT } = Cascader;
const { Title } = Typography;
//

setDefaultOptions({ css: true, version: "4.27" });

//const mapHeight = "50px";
//const mapHeight = 'margin: "5px", width: "500px", height: "500px"';

function SeedEcoRegions() {
  const [divMapStyle, setDivMapStyle] = React.useState([]);
  const [divCheckListStyle, setDivCheckListStyle] = React.useState([]);
  const [ecoRegionsTable, setEcoRegionsTable] = React.useState([]);
  const [myEcoRegionsLayer, setMyEcoRegionsLayer] = useState(null);
  const [mapView, setMapView] = useState(null);
  const [mapClickRegion, setMapClickRegion] = React.useState([]);
  const selected_state = useSelector(
    (state) => state.seed_selection.selected_state
  );
  const [loading, setLoading] = React.useState(false);
  const [clickCounter, setClickCounter] = React.useState(0);
  const dispatch = useDispatch();

  //const selected_ecoregions_store = useSelector(state => state.seed_selection.gis_ecoRegions);

  const selected_ecoregions_store = useSelector(
    (state) => state.seed_selection.gis_ecoRegions
  );

  const handleCheckboxEcoRegion = (e) => {
    //console.log('Checkbox event name:', e.target.name);
    let temp_selected_ecoregions = [...selected_ecoregions_store]; // Create a shallow copy of the array
    //console.log('b4 :',temp_selected_ecoregions)
    if (temp_selected_ecoregions.includes(e.target.name)) {
      //console.log('item already exists in the array', e.target.name);
      temp_selected_ecoregions = temp_selected_ecoregions.filter(
        (item) => item !== e.target.name
      );
    } else {
      //console.log('item does not exist in the array', e.target.name);
      temp_selected_ecoregions.push(e.target.name);
    }
    //console.log('a4 :',temp_selected_ecoregions);
    dispatch(
      seed_selection_actions.set_gis_ecoRegions(temp_selected_ecoregions)
    );
  };

  //{{ margin: "5px", width: '50px', height: { mapHeight }}
  useEffect((e) => {
    setLoading(true);
    setDivMapStyle({
      margin: "5px",
      width: document.body.scrollWidth / 2 - 20 + "px",
      height: document.body.scrollHeight / 2 - 20 + "px"
    });
    setDivCheckListStyle({
      overflowY: "scroll",
      margin: "5px",
      width: document.body.scrollWidth / 3 + "px",
      height: document.body.scrollHeight / 2 - 20 + "px",
      listStyle: "none"
    });

    //dispatch(seed_selection_actions.set_gis_ecoRegions([]));

    loadModules([
      "esri/layers/FeatureLayer",
      "esri/views/MapView",
      "esri/Map",
      "esri/Graphic"
    ])
      .then(([FeatureLayer, MapView, Map, Graphic]) => {
        const ecoRegionsLayer = new FeatureLayer({
          url: "https://services.arcgis.com/GL0fWlNkwysZaKeV/arcgis/rest/services/seto_eco_regions/FeatureServer/0"
        });
        setMyEcoRegionsLayer(ecoRegionsLayer);
        const StatesLayer = new FeatureLayer({
          url: "https://services.arcgis.com/GL0fWlNkwysZaKeV/arcgis/rest/services/seto_states/FeatureServer/0"
        });

        const map = new Map({
          basemap: "streets-vector"
        });
        const view = new MapView({
          map: map,
          container: "mapSeto",
          center: [-104, 38],
          zoom: 3
        });
        setMapView(view);
        map.add(StatesLayer);
        const query = {
          where: "",
          outFields: ["L3_KEY"],
          returnGeometry: false
        };

        if (selected_state !== undefined && selected_state !== null) {
          const query = {
            where: "STATE_ABBR = '" + selected_state + "'",
            outFields: ["STATE_ABBR"],
            returnGeometry: true,
            spatialReference: {
              wkid: 102100
            }
          };
          StatesLayer.queryFeatures(query).then(function (response) {
            const attractionsGeometries = response.features.map(function (
              feature
            ) {
              view.goTo(feature.geometry.extent);
            });
          });
        }

        const promise = ecoRegionsLayer
          .queryFeatures(query)
          .then(function (response) {
            convertFeatureSetToRows(response, query);
          });

        view.on("click", (event) => {
          view
            .hitTest(event.screenPoint, { include: ecoRegionsLayer })
            .then(function (response) {
              if (response.results.length > 0) {
                var graphic = response.results.filter(function (result) {
                  // check if the graphic belongs to the layer of interest
                  return result.graphic.layer === ecoRegionsLayer;
                })[0].graphic; // do something with the result graphic

                const fillSymbol = {
                  type: "simple-fill", // autocasts as new SimpleFillSymbol()
                  color: [255, 255, 77, 0.5],
                  outline: {
                    // autocasts as new SimpleLineSymbol()
                    color: [255, 255, 255],
                    width: 3
                  }
                };
                const graphic2 = new Graphic({
                  geometry: graphic.geometry,
                  symbol: fillSymbol
                });
                view.graphics.add(graphic2);
                setClickCounter(clickCounter + 1);
                setMapClickRegion([clickCounter, graphic.attributes.L3_KEY]);
              }
            });
        });
        map.add(ecoRegionsLayer);
      })
      .catch((err) => console.error(err));

    const convertFeatureSetToRows = (featureSet, query) => {
      const graphics = featureSet.features;

      const allitems = [];

      graphics.forEach((result, index) => {
        const attributes = result.attributes;
        attributes.isChecked = false;
        if (
          selected_ecoregions_store !== undefined &&
          selected_ecoregions_store !== null
        ) {
          if (selected_ecoregions_store.includes(attributes.L3_KEY)) {
            attributes.isChecked = true;
          }
        }
        allitems.push(attributes);
      });
      setEcoRegionsTable(allitems);
    };
    const updateDimensions = (e) => {
      setDivMapStyle({
        margin: "5px",
        width: document.body.scrollWidth / 2 - 20 + "px",
        height: document.body.scrollHeight / 2 - 20 + "px"
      });
    };
    window.addEventListener("resize", updateDimensions);
    setLoading(false);
  }, []);

  //@param ecoRegionsTable
  const reRenderMap = (eTable) => {
    loadModules([
      "esri/layers/FeatureLayer",
      "esri/views/MapView",
      "esri/geometry/Extent",
      "esri/Graphic"
    ])
      .then(([FeatureLayer, MapView, Extent, Graphic]) => {
        var strKeys = "";
        eTable.map((item) => {
          if (item.isChecked === true) {
            //console.log('true item in render Map',item);
            if (strKeys.length > 0) {
              strKeys = strKeys + ", '" + item.L3_KEY + "'";
            } else {
              strKeys = "'" + item.L3_KEY + "'";
            }
            //setSelectedEcoRegions([...SelectedEcoRegions, item.L3_KEY]);

            //console.log(item.L3_KEY);
          }
        });
        //console.log(strKeys);
        if (strKeys.trim() != "") {
          const fillSymbol = {
            type: "simple-fill", // autocasts as new SimpleFillSymbol()
            color: [255, 255, 77, 0.5],
            outline: {
              // autocasts as new SimpleLineSymbol()
              color: [255, 255, 255],
              width: 3
            }
          };

          //console.log("L3_KEY in (" + SelectedEcoRegions.join(",") + ")")
          const query = {
            where: "L3_KEY in (" + strKeys + ")",
            outFields: ["L3_KEY"],
            returnGeometry: true,
            spatialReference: {
              wkid: 102100
            }
          };
          myEcoRegionsLayer.queryFeatures(query).then(function (response) {
            var newExtent = new Extent({
              spatialReference: {
                wkid: 102100
              },
              xmax: 0,
              ymax: 0,
              ymin: 0,
              xmin: 0
            });
            //console.log(mapView.graphics);
            mapView.graphics.map((item) => {
              //console.log(item);
              mapView.graphics.remove(item);
            });

            //mapView.graphics.clear;

            // console.log(response);
            const attractionsGeometries = response.features.map(function (
              feature
            ) {
              if (newExtent.xmin === 0) {
                newExtent = feature.geometry.extent;
              } else {
                if (
                  Math.abs(newExtent.xmin) >
                  Math.abs(feature.geometry.extent.xmin)
                ) {
                  newExtent.xmin = feature.geometry.extent.xmin;
                }
                if (
                  Math.abs(newExtent.xmax) <
                  Math.abs(feature.geometry.extent.xmax)
                ) {
                  newExtent.xmax = feature.geometry.extent.xmax;
                }
                if (newExtent.ymin > feature.geometry.extent.ymin) {
                  newExtent.ymin = feature.geometry.extent.ymin;
                }
                if (newExtent.ymax < feature.geometry.extent.ymax) {
                  newExtent.ymax = feature.geometry.extent.ymax;
                }
              }
              const graphic = new Graphic({
                geometry: feature.geometry,
                symbol: fillSymbol
              });
              //console.log(graphic);
              mapView.graphics.add(graphic);
            });
            if (newExtent.xmin != 0) {
              mapView.goTo(newExtent.expand(1.2));
            }
          });
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    //console.log('yp tracking 1 inside ecoRegionsTbl ',selected_ecoregions_store)
    if (!loading) {
      reRenderMap(ecoRegionsTable);
    }
  }, [loading, ecoRegionsTable]);

  useEffect(() => {
    //console.log('testing',mapClickRegion)
    if (mapClickRegion.length > 0 && mapClickRegion[1] != undefined) {
      handleCheckboxEcoRegion({ target: { name: mapClickRegion[1] } });
    }
  }, [mapClickRegion]);

  useEffect(() => {
    //console.log('selected_ecoregions_store changed', selected_ecoregions_store);
    if (
      selected_ecoregions_store !== undefined &&
      selected_ecoregions_store !== null
    ) {
      //console.log('set checkboxes after ecoRegions store changes', selected_ecoregions_store, selected_ecoregions_store.length);
      var tempTable = [...ecoRegionsTable];
      tempTable.map((item) => {
        item.isChecked = false;
        if (selected_ecoregions_store.includes(item.L3_KEY)) {
          item.isChecked = true;
        }
      });
      //console.log('b4 setting ecoregionstbl')
      setEcoRegionsTable(tempTable);
      //reRenderMap(tempTable);
    } else if (selected_ecoregions_store === null) {
      //console.log('set checkboxes after ecoRegions store changes', selected_ecoregions_store, selected_ecoregions_store.length);
      var tempTable = [...ecoRegionsTable];
      tempTable.map((item) => {
        item.isChecked = false;
      });
      //console.log('b4 setting ecoregionstbl')
      setEcoRegionsTable(tempTable);
    }
  }, [selected_ecoregions_store]);

  return (
    <>
      {loading ? (
        <div className="container-fluid">
          <Spin />
        </div>
      ) : (
        <div className="container-fluid">
          <Title level={3}>Please Select Ecoregion:</Title>
          <table>
            <tbody>
              <tr>
                <td valign="top">
                  <div id="mapSeto" style={divMapStyle}></div>
                </td>
                <td>
                  <div className="black-border">
                    {ecoRegionsTable && ecoRegionsTable.length > 0 ? (
                      <ul style={divCheckListStyle}>
                        {ecoRegionsTable.map((item, index) => {
                          return (
                            <li key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item.isChecked}
                                    name={item.L3_KEY}
                                    onChange={(e) => handleCheckboxEcoRegion(e)}
                                  />
                                }
                                label={item.L3_KEY}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Spin />
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>{" "}
        </div>
      )}
    </>
  );
}

export default SeedEcoRegions;
