import React, { Component } from 'react';
import { Container } from 'reactstrap';
import TopNavMenu from '../components/TopNavMenu';
import { Outlet } from 'react-router-dom';
import MainBox from '../components/boxes/MainBox'
import SubHeader from '../components/SubHeader';
//import SubNavBar from '../components/SubNavBar';
import Footer from '../components/Footer';
import LoadingBarComponent from './seto_tools/cost_benefit_components/utils/LoadingBar';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div>
            {/* <TopNavMenu /> */}
            <MainBox>
                <SubHeader />

                <br />
                        <Container fluid >
                            {this.props.children}
                </Container>

                <Outlet />

                <Footer />

            </MainBox>

        </div>
    );
  }
}
