import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../store/index";
import { Provider } from "react-redux";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Count of Benefits types by Option",
      font: {
        size: 16
      }
    }
  },
  scales: {
    x: {
      barPercentage: 0.4, // decrease bar width
      categoryPercentage: 0.4 // decrease category label width
    },
    y: {
      ticks: {
        beginAtZero: true,
        stepSize: 1
      }
    }
  }
};

const handleGenerateData = (data) => {
  let labels = ["Benefits Count"];
  let dataset1 = [];
  let dataset2 = [];
  let dataset3 = [];

  dataset1.push(data[1]);
  dataset2.push(data[2]);
  dataset3.push(data[3]);

  let datasets = [
    {
      label: "Option 1",
      data: dataset1,
      borderColor: "rgb(242,112,51)",
      backgroundColor: "rgba(242, 112, 51, 0.8)"
    },
    {
      label: "Option 2",
      data: dataset2,
      borderColor: "rgb(0, 85, 191)",
      backgroundColor: "rgba(0, 85, 191, 0.8)"
    },
    {
      label: "Option 3",
      data: dataset3,
      borderColor: "rgb(0, 122, 45)",
      backgroundColor: "rgba(0, 122, 45, 0.8)"
    }
  ];
  data = {
    labels,
    datasets
  };
  return data;
};

function BarGraphBenefitsCount() {
  //constants for the component

  const [graphData, setGraphData] = React.useState();

  //redux constants
  const benefits_count_store = useSelector(
    (state) => state.cost_benefit_slice.benefits_count
  );

  useEffect(() => {
    let data = handleGenerateData(benefits_count_store);
    //console.log('graph data ue',data)
    setGraphData(data);
  }, [benefits_count_store]);

  //if grapphData is not null, then render the graph
  if (graphData) {
    return <Bar data={graphData} options={options} id="graphBenefitCount" />;
  } else {
    return <div>Loading...</div>;
  }
}

const BarGraphBenefitsCountWrapper = () => {
  return (
    <Provider store={store}>
      <BarGraphBenefitsCount />
    </Provider>
  );
};

export default BarGraphBenefitsCountWrapper;
