import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { seed_selection_actions } from '../../../store/seed_selection_slice';
import { useEffect } from 'react';
import api from '../../../axiosConfig';


function SoilMoistureList(){
    const dispatch = useDispatch();
    const selected_soil_moisture = useSelector(state => state.seed_selection.planting_zone_filters.soil_moisture);
    const [soil_moisture, setSoilMoisture] = React.useState([]);
    const [soil_moisture_default, setSoilMoistureDefault] = React.useState('');

    useEffect(() => {
        //if the soil moisture list is empty, get the list from the server
        setSoilMoistureDefault(selected_soil_moisture);
        if (soil_moisture.length == 0) {
            api
            .get('/SoilMoistures')
            .then(response => {
                //add 0 index to the array to make the first option empty
                response.data.unshift({ soilMoisture1: '' });
                setSoilMoisture(response.data);
            }).catch(error => console.log(error.response));
        }
    }, []);

    useEffect(() => {
        //setSoilMoistureDefault(selected_soil_moisture);
        if(!soil_moisture_default){
            setSoilMoistureDefault(selected_soil_moisture);
        }
    }, [selected_soil_moisture]);

    useEffect(() => {
        dispatch(seed_selection_actions.set_selected_soil_moisture(soil_moisture_default));
    }, [soil_moisture_default]);

    const handleSelectChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        //console.log(event.target.options[selectedIndex]);
        //console.log(event.target.value);
        setSoilMoistureDefault(event.target.value);
    }

    return (
        <div>
            <select onChange={handleSelectChange} defaultValue={selected_soil_moisture?selected_soil_moisture:''}>
                {soil_moisture.map(function (data, key) {
                    return (
                        <option key={key} value={data.soilMoisture1}>{data.soilMoisture1}</option>)
                })}

            </select>
        </div>
    )
}


export default SoilMoistureList