import React, { useEffect } from "react";
import { Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { cost_benefit_actions } from "../../../store/cost_benefit_slice";
import { LoadingOutlined } from '@ant-design/icons';
import { LineChart, Line, CartesianGrid, XAxis, YAxis,Tooltip } from 'recharts';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

//antd imports
import { Row, Col, Input , Spin  } from "antd";

const { Title } = Typography;

function CostAnalysisResults() {

  //const default values for the cost analysis results

  const [loadingInstallCost, setLoadingInstallCost] = React.useState(false);

  const cost_analysis_defaults = {
    1:{
      "option_name": "",
      "array_cover": "",
      "buffer_cover": "",
      "installation_cost": "",
      "installation_unit_cost": "",
      "establishment_cost": "",
      "establishment_unit_cost": "",
      "capex_cost": "",
      "capex_unit_cost": "",
      "opex_cost": "",
      "opex_unit_cost": "",
      "opex_unit_cost_year": "",
      "total_project_cost": "",
      "total_project_cost_npv": "",
      "expected_npdes_closure": "",
      "lowest_per_acre_installation_cost": "",
      "lowest_per_acre_establishment_cost": "",
      "lowest_total_project_cost": "",
      "community_acceptance": "",
      "biodiversity_esg_goals": "",
      "pollinator_habitat_uplift": "",
      "enhanced_soil": "",
      "carbon_sequestration": "",
      "count_of_benefits": "",
    },
    2:{
      "option_name": "",
      "array_cover": "",
      "buffer_cover": "",
      "installation_cost": "",
      "installation_unit_cost": "",
      "establishment_cost": "",
      "establishment_unit_cost": "",
      "capex_cost": "",
      "capex_unit_cost": "",
      "opex_cost": "",
      "opex_unit_cost": "",
      "opex_unit_cost_year": "",
      "total_project_cost": "",
      "total_project_cost_npv": "",
      "expected_npdes_closure": "",
      "lowest_per_acre_installation_cost": "",
      "lowest_per_acre_establishment_cost": "",
      "lowest_total_project_cost": "",
      "community_acceptance": "",
      "biodiversity_esg_goals": "",
      "pollinator_habitat_uplift": "",
      "enhanced_soil": "",
      "carbon_sequestration": "",
      "count_of_benefits": "",
    },
    3:
    {
      "option_name": "",
      "array_cover": "",
      "buffer_cover": "",
      "installation_cost": "",
      "installation_unit_cost": "",
      "establishment_cost": "",
      "establishment_unit_cost": "",
      "capex_cost": "",
      "capex_unit_cost": "",
      "opex_cost": "",
      "opex_unit_cost": "",
      "opex_unit_cost_year": "",
      "total_project_cost": "",
      "total_project_cost_npv": "",
      "expected_npdes_closure": "",
      "lowest_per_acre_installation_cost": "",
      "lowest_per_acre_establishment_cost": "",
      "lowest_total_project_cost": "",
      "community_acceptance": "",
      "biodiversity_esg_goals": "",
      "pollinator_habitat_uplift": "",
      "enhanced_soil": "",
      "carbon_sequestration": "",
      "count_of_benefits": "",
    }
  }

  const [cost_analysis_results, setCostAnalysisResults] = React.useState(cost_analysis_defaults);

  let array_cost = {
    0:{
      "pre_seeding": 0,
      "seedbed_prep": 0,
      "fertilizer_supply": 0,
      "soil_amendment": 0,
      "seed_supply": 0,
      "seed_installation": 0
    },
    1:{
      "pre_seeding": 0,
      "seedbed_prep": 0,
      "fertilizer_supply": 0,
      "soil_amendment": 0,
      "seed_supply": 0,
      "seed_installation": 0
    },
    2:{
      "pre_seeding": 0,
      "seedbed_prep": 0,
      "fertilizer_supply": 0,
      "soil_amendment": 0,
      "seed_supply": 0,
      "seed_installation": 0
    }
  }
  let buffer_cost = {
    0:{
      "pre_seeding": 0,
      "seedbed_prep": 0,
      "fertilizer_supply": 0,
      "soil_amendment": 0,
      "seed_supply": 0,
      "seed_installation": 0,
      "contingency": 0
    },
    1:{
      "pre_seeding": 0,
      "seedbed_prep": 0,
      "fertilizer_supply": 0,
      "soil_amendment": 0,
      "seed_supply": 0,
      "seed_installation": 0,
      "contingency": 0
    },
    2:{
      "pre_seeding": 0,
      "seedbed_prep": 0,
      "fertilizer_supply": 0,
      "soil_amendment": 0,
      "seed_supply": 0,
      "seed_installation": 0,
      "contingency": 0
    }
  }

  //boolean constants for the cost analysis results

  const[installation_cost_bool, setInstallationCost] = React.useState(Array(3).fill(false));

  const[installation_cost_values, setInstallationCostValues] = React.useState({
    0: 0,
    1: 0,
    2: 0
  });

  let installation_costs_dict={
    0:0,
    1:0,
    2:0
  }

  let installation_unit_costs_dict={
    0:0,
    1:0,
    2:0
  }

  let array_mowing_cost_dict={
    0:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    1:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    2:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    }
  }

  let buffer_mowing_cost_dict={
    0:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    1:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    2:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    }
  }

  let array_herbicide_cost_dict={
    0:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    1:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    2:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    }
  }


  let buffer_herbicide_cost_dict={
    0:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    1:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    },
    2:{
      1:0,
      2:0,
      3:0,
      4:0,
      5:0,
      6:0,
      7:0,
      8:0,
      9:0,
      10:0
    }
  }

  const[installation_unit_cost, setInstallationUnitCost] = React.useState({
    0: 0,
    1: 0,
    2: 0
  });

  const[array_mowing_cost_values, setMowingCostValues] = React.useState(array_mowing_cost_dict);
  const[buffer_mowing_cost_values, setBufferMowingCostValues] = React.useState(buffer_mowing_cost_dict);
  const[array_herbicide_cost_values, setArrayHerbicideCostValues] = React.useState(array_herbicide_cost_dict);
  const[buffer_herbicide_cost_values, setBufferHerbicideCostValues] = React.useState(buffer_herbicide_cost_dict);


  const[calculate_y1_y3_flag, setCalculateY1Y3Flag] = React.useState(false);
  const[calculate_y4_y10_flag, setCalculateY4Y10Flag] = React.useState(false);

  let y1_y3_values_dict={
    0:0,
    1:0,
    2:0
  }

  let y4_y10_values_dict={
    0:0,
    1:0,
    2:0
  }

  const[y1_y3_value, setY1Y3Value] = React.useState(y1_y3_values_dict);
  const[y4_y10_value, setY4Y10Value] = React.useState(y4_y10_values_dict);

  const total_10_year_dict = {
    0: 0,
    1: 0,
    2: 0,
  }

  let annualized_maintenance_cost_dict={
    0:0,
    1:0,
    2:0
  }
  const [total_10_year, setTotal10Year] = React.useState(total_10_year_dict);


  const[annualized_maintenance_cost, setAnnualizedMaintenanceCost] = React.useState(annualized_maintenance_cost_dict);


  let lowest_installation_cost_dict={
    0:0,
    1:0,
    2:0
  }

  const[lowest_installation_cost, setLowestInstallationCost] = React.useState(lowest_installation_cost_dict);

  let lowest_establishment_cost_dict={
    0:0,
    1:0,
    2:0
  }

  const[lowest_establishment_cost, setLowestEstablishmentCost] = React.useState(lowest_establishment_cost_dict);

  let lowest_30_year_dict = {0:0, 1:0, 2:0}
  const[lowest_30_year, setLowest30Year] = React.useState(lowest_30_year_dict);

  let total_30_year_dict = {
    0: {
      1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0
    },
    1:{
      1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0
    },
    2:{
      1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0
    }
  }


  let total_30_year_npv_dict = {
    0:{
      1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0
    },
    1:{
      1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0
    },
    2:{
      1:0,2:0,3:0,4:0,5:0,6:0,7:0,8:0,9:0,10:0,11:0,12:0,13:0,14:0,15:0,16:0,17:0,18:0,19:0,20:0,21:0,22:0,23:0,24:0,25:0,26:0,27:0,28:0,29:0,30:0
    }
  }

  let total_30_year_npv_value_dict = {
    0:0,
    1:0,
    2:0
  }

  const[total_30_year_npv_value, setTotal30YearNPVValue] = React.useState(total_30_year_npv_value_dict);

  const[total_30_year_npv, setTotal30YearNPV] = React.useState(total_30_year_npv_dict);

  let npv_discount_rate_dict = {
    0:1.00,1:0.95,2:0.91,3:0.86,4:0.82,5:0.78,6:0.75,7:0.71,8:0.68,9:0.65,10:0.61,11:0.59,12:0.56,13:0.53,14:0.51,15:0.48,16:0.46,17:0.44,18:0.42,19:0.40,20:0.38,21:0.36,22:0.34,23:0.33,24:0.31,25:0.30,26:0.28,27:0.27,28:0.26,29:0.24,30:0.23
  }

  const[total_30_year, setTotal30Year] = React.useState(total_30_year_dict);

  let total_30_year_value_dict={
    0:0,
    1:0,
    2:0
  }

  const[total_30_year_value, setTotal30YearValue] = React.useState(total_30_year_value_dict);

  //redux constants
  const dispatch = useDispatch();
  const options_store = useSelector(
    (state) => state.cost_benefit_slice.selected_options
  );

  const cost_inputs_store = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs
  );

  const cost_refinements_store = useSelector(
    (state) => state.cost_benefit_slice.cost_refinements
  );

  const installation_cost_store = useSelector(
    (state) => state.cost_benefit_slice.installation_cost_results
  );

  // create a new dummy array with the same length as the options_store integer
    // and fill it with 1's
  const selected_options_store = Array(options_store).fill(1);

  useEffect(() => {
    //console.log("cost inputs", cost_inputs_store);

  }, [cost_inputs_store]);

  useEffect(() => {
    //console.log('ue cost benefits_ cost inputs',cost_inputs_store)
    //console.log("ue cost refinements cost results", cost_refinements_store);
  },[]);

  useEffect(() => {
    //console.log("ue cost installations cost store results", installation_cost_store);
    handleInstallationUnitCost();
  }, [installation_cost_store]);

  // function to calculate the cost analysis results

  const handleBenefitAnalysis = () => {
    //console.log('benefit analysis')
    //console.log('cost inputs ben_analysis',cost_inputs_store)
    //console.log('options store length',selected_options_store.length)
    //console.log('use state',annual_vegetation_corrective_action)

    //for each option, calculate the cost analysis results
    selected_options_store.map((option, index) => {
      //console.log('index benefits',index)

    })
  }

  const handleInstallationUnitCost = () => {

    selected_options_store.map((option, index) => {
        let installation_cost = parseInt(installation_cost_values[index]);
        let total_size = parseInt(cost_inputs_store[index]["array_size"]) + parseInt(cost_inputs_store[index]["buffer_size"]);
        let installation_unit_cost = installation_cost / total_size;

        installation_unit_costs_dict[index] = installation_unit_cost;
        setInstallationUnitCost(installation_unit_costs_dict);


    })


  }

  const handleYear_1_3_MaintenanceCost = () => {

    let array_mowing_acerage = 500;
    let array_mowing_unit_cost = 100;

    let array_herbicide_acerage = 500;
    let array_herbicide_unit_cost = 150;


    //buffer
    let buffer_mowing_acerage = 80;
    let buffer_mowing_unit_cost = 100;

    let buffer_herbicide_acerage = 80;
    let buffer_herbicide_unit_cost = 150;


    selected_options_store.map((option, index) => {
       //for all keys of array_mowing_cost_values

      let maintenance_intensity = cost_inputs_store[index]["expect_maintainance_intensity"].toLowerCase();
      for (let key in array_mowing_cost_values[index]) {
        let calculation_unit = cost_refinements_store["herbicide_frequency"][key][maintenance_intensity]
        let array_mowing_cost = array_mowing_acerage * array_mowing_unit_cost * calculation_unit;
        let array_herbicide_cost = array_herbicide_acerage * array_herbicide_unit_cost * calculation_unit;
        let buffer_mowing_cost = buffer_mowing_acerage * buffer_mowing_unit_cost * calculation_unit;
        let buffer_herbicide_cost = buffer_herbicide_acerage * buffer_herbicide_unit_cost * calculation_unit;


        array_mowing_cost_dict[index][key] = array_mowing_cost;
        setMowingCostValues(array_mowing_cost_dict);

        array_herbicide_cost_dict[index][key] = array_herbicide_cost;
        setArrayHerbicideCostValues(array_herbicide_cost_dict);

        buffer_mowing_cost_dict[index][key] = buffer_mowing_cost;
        setBufferMowingCostValues(buffer_mowing_cost_dict);

        buffer_herbicide_cost_dict[index][key] = buffer_herbicide_cost;
        setBufferHerbicideCostValues(buffer_herbicide_cost_dict);
      }
    })
    setCalculateY1Y3Flag(true);
    setCalculateY4Y10Flag(true);
  }


  useEffect(() => {
    if(calculate_y1_y3_flag){
      selected_options_store.map((option, index) => {
        let y1_y3_mowing_cost = array_mowing_cost_values[index][1] + buffer_mowing_cost_values[index][1] + array_mowing_cost_values[index][2] + buffer_mowing_cost_values[index][2] + array_mowing_cost_values[index][3] + buffer_mowing_cost_values[index][3];
        let y1_y3_herbicide_cost = array_herbicide_cost_values[index][1] + buffer_herbicide_cost_values[index][1] + array_herbicide_cost_values[index][2] + buffer_herbicide_cost_values[index][2] + array_herbicide_cost_values[index][3] + buffer_herbicide_cost_values[index][3];
        let y1_y3_total_cost = y1_y3_mowing_cost + y1_y3_herbicide_cost;
        y1_y3_values_dict[index] = y1_y3_total_cost;
        setY1Y3Value(y1_y3_values_dict);
      })
    }


  }, [calculate_y1_y3_flag]);

  useEffect(() => {
    if(calculate_y4_y10_flag){
      selected_options_store.map((option, index) => {
        let y4_y10_mowing_cost = array_mowing_cost_values[index][4] + buffer_mowing_cost_values[index][4] + array_mowing_cost_values[index][5] + buffer_mowing_cost_values[index][5] + array_mowing_cost_values[index][6] + buffer_mowing_cost_values[index][6] + array_mowing_cost_values[index][7] + buffer_mowing_cost_values[index][7] + array_mowing_cost_values[index][8] + buffer_mowing_cost_values[index][8] + array_mowing_cost_values[index][9] + buffer_mowing_cost_values[index][9] + array_mowing_cost_values[index][10] + buffer_mowing_cost_values[index][10];
        let y4_y10_herbicide_cost = array_herbicide_cost_values[index][4] + buffer_herbicide_cost_values[index][4] + array_herbicide_cost_values[index][5] + buffer_herbicide_cost_values[index][5] + array_herbicide_cost_values[index][6] + buffer_herbicide_cost_values[index][6] + array_herbicide_cost_values[index][7] + buffer_herbicide_cost_values[index][7] + array_herbicide_cost_values[index][8] + buffer_herbicide_cost_values[index][8] + array_herbicide_cost_values[index][9] + buffer_herbicide_cost_values[index][9] + array_herbicide_cost_values[index][10] + buffer_herbicide_cost_values[index][10];
        let y4_y10_total_cost = y4_y10_mowing_cost + y4_y10_herbicide_cost;
        y4_y10_values_dict[index] = y4_y10_total_cost;
        setY4Y10Value(y4_y10_values_dict);
      })
    }
  }, [calculate_y4_y10_flag]);


  useEffect(() => {
    handleAnnualMaintenanceCost();
    handleTotal10Year();
    handleTotal30Year();
    handleTotal30YearNVP();
    handleLowestCost();
  }, [y4_y10_value]);

  const handleTotal30YearNVP = () => {
    //console.log("handleTotal30YearNVP");
    selected_options_store.map((option, index) => {
      total_30_year_npv_dict[index] = total_30_year_dict[index]
      //for all keys of total_30_year_npv_dict
      for (let key in total_30_year_npv_dict[index]) {
        let discount_factor = npv_discount_rate_dict[key];
        total_30_year_npv_dict[index][key] = total_30_year_npv_dict[index][key]*discount_factor;
      }
      let total_npv_value = total_30_year_npv_dict[index][1] + total_30_year_npv_dict[index][2] + total_30_year_npv_dict[index][3] + total_30_year_npv_dict[index][4] + total_30_year_npv_dict[index][5] + total_30_year_npv_dict[index][6] + total_30_year_npv_dict[index][7] + total_30_year_npv_dict[index][8] + total_30_year_npv_dict[index][9] + total_30_year_npv_dict[index][10]+ total_30_year_npv_dict[index][11]+ total_30_year_npv_dict[index][12]+ total_30_year_npv_dict[index][13]+ total_30_year_npv_dict[index][14]+ total_30_year_npv_dict[index][15]+ total_30_year_npv_dict[index][16]+ total_30_year_npv_dict[index][17]+ total_30_year_npv_dict[index][18]+ total_30_year_npv_dict[index][19]+ total_30_year_npv_dict[index][20]+ total_30_year_npv_dict[index][21]+ total_30_year_npv_dict[index][22]+ total_30_year_npv_dict[index][23]+ total_30_year_npv_dict[index][24]+ total_30_year_npv_dict[index][25]+ total_30_year_npv_dict[index][26]+ total_30_year_npv_dict[index][27]+ total_30_year_npv_dict[index][28]+ total_30_year_npv_dict[index][29]+ total_30_year_npv_dict[index][30];
      total_30_year_npv_value_dict[index] = total_npv_value;
      setTotal30YearNPVValue(total_30_year_npv_value_dict);
    })
  }

  const handleTotal30Year = () => {
    //console.log("handleTotal30Year");
    selected_options_store.map((option, index) => {
      //for first 10 keys
      total_30_year_dict[index][0] = installation_cost_values[index]
      for(let i = 1; i < 11; i++){
        total_30_year_dict[index][i] = total_30_year_dict[index][i-1] + array_mowing_cost_values[index][i] + buffer_mowing_cost_values[index][i] + array_herbicide_cost_values[index][i] + buffer_herbicide_cost_values[index][i];
      }
      //for next 20 keys
      let additive_const = ((array_mowing_cost_values[index][4] + buffer_mowing_cost_values[index][4] + array_herbicide_cost_values[index][4] + buffer_herbicide_cost_values[index][4] + array_mowing_cost_values[index][5] + buffer_mowing_cost_values[index][5] + array_herbicide_cost_values[index][5] + buffer_herbicide_cost_values[index][5] + array_mowing_cost_values[index][6] + buffer_mowing_cost_values[index][6] + array_herbicide_cost_values[index][6] + buffer_herbicide_cost_values[index][6] + array_mowing_cost_values[index][7] + buffer_mowing_cost_values[index][7] + array_herbicide_cost_values[index][7] + buffer_herbicide_cost_values[index][7] + array_mowing_cost_values[index][8] + buffer_mowing_cost_values[index][8] + array_herbicide_cost_values[index][8] + buffer_herbicide_cost_values[index][8] + array_mowing_cost_values[index][9] + buffer_mowing_cost_values[index][9] + array_herbicide_cost_values[index][9] + buffer_herbicide_cost_values[index][9] + array_mowing_cost_values[index][10] + buffer_mowing_cost_values[index][10] + array_herbicide_cost_values[index][10] + buffer_herbicide_cost_values[index][10])/6)*0.6;

      for(let i = 11; i < 31; i++){
        total_30_year_dict[index][i] = total_30_year_dict[index][i-1] + additive_const;
      }
      setTotal30Year(total_30_year_dict);

      let total_30_year_cost;
      total_30_year_cost = total_30_year_dict[index][30];

      total_30_year_value_dict[index] = total_30_year_cost;
      setTotal30YearValue(total_30_year_value_dict);


    })

  }


  const handleTotal10Year = () => {
    selected_options_store.map((option, index) => {
      let total_10_year_cost = y1_y3_value[index] + y4_y10_value[index] + installation_cost_values[index];
      total_10_year_dict[index] = total_10_year_cost;
      setTotal10Year(total_10_year_dict);
    })
  }

  const handleLowestCost = () => {


    let min_y1_y3 = 9999999999;
    let min_establishment_cost = 9999999999;
    let min_total_30_year_cost = 9999999999;
    Object.keys(y1_y3_value).map((key, index) => {

      if(y1_y3_value[key]>0){
        min_y1_y3 = Math.min(min_y1_y3, y1_y3_value[key]);
      }
    })
    Object.keys(installation_cost_values).map((key, index) => {
      if(installation_cost_values[key]>0){
        min_establishment_cost = Math.min(min_establishment_cost, installation_cost_values[key]);
      }
    })

    Object.keys(total_30_year_value_dict).map((key, index) => {
      if(total_30_year_value_dict[key]>0){
        min_total_30_year_cost = Math.min(min_total_30_year_cost, total_30_year_value_dict[key]);
      }
    })

    selected_options_store.map((option, index) => {
      lowest_installation_cost_dict[index] = min_y1_y3;
      lowest_establishment_cost_dict[index] = min_establishment_cost;
      lowest_30_year_dict[index] = min_total_30_year_cost;
    })
    setLowestInstallationCost(lowest_installation_cost_dict);
    setLowestEstablishmentCost(lowest_establishment_cost_dict);
    setLowest30Year(lowest_30_year_dict);

  }

  const handleAnnualMaintenanceCost = () => {
    selected_options_store.map((option, index) => {
      let array_mowing_acerage_const = 500;
      let buffer_mowing_acerage_const = 80;


      let calculate_const = (array_mowing_acerage_const + buffer_mowing_acerage_const)/10;

      let annual_maintenance_cost = (y1_y3_value[index] + y4_y10_value[index])/calculate_const;

      annualized_maintenance_cost_dict[index] = annual_maintenance_cost;
      setAnnualizedMaintenanceCost(annualized_maintenance_cost_dict);
    }
    )
  }




  const handleInstallationCost = () => {

    //for each option, calculate the cost analysis results
    selected_options_store.map((option, index) => {
      let array_quantity= (parseInt(cost_inputs_store[index]["array_size"]) * ((parseInt(cost_inputs_store[index]["pre_seeding_cost"]))/100))
      let array_unit_cost;
      if(cost_inputs_store[index]["row_restrictions"] === "Yes"){
        array_unit_cost = (parseInt(cost_refinements_store["weed_control_costs"]["end_restriction"]))
      }
      else{
        if(cost_inputs_store[index]["phase_of_construction"] === "Pre"){
          array_unit_cost = (parseInt(cost_refinements_store["weed_control_costs"]["pre"]))
        }
        else if(cost_inputs_store[index]["phase_of_construction"] === "Post"){
          array_unit_cost = (parseInt(cost_refinements_store["weed_control_costs"]["post"]))
        }
        else{
          array_unit_cost = 0;
        }
      }

      //buffer

      let buffer_quantity = (parseInt(cost_inputs_store[index]["buffer_size"]) * ((parseInt(cost_inputs_store[index]["pre_seeding_cost"]))/100))
      let buffer_unit_cost;

      if(cost_inputs_store[index]["phase_of_construction"] === "Pre"){
        buffer_unit_cost = (parseInt(cost_refinements_store["weed_control_costs"]["pre"]))
      }
      else if(cost_inputs_store[index]["phase_of_construction"] === "Post"){
        buffer_unit_cost = (parseInt(cost_refinements_store["weed_control_costs"]["post"]))
      }
      else{
        buffer_unit_cost = 0;
      }
      array_cost[index]["pre_seeding"] = array_quantity * array_unit_cost;
      buffer_cost[index]["pre_seeding"] = buffer_quantity * buffer_unit_cost;


      //seedbed prep

      let array_quantity_seedbed = (parseInt(cost_inputs_store[index]["array_size"]) * ((parseInt(cost_inputs_store[index]["seedbed_preparation_cost"]))/100))
      let array_unit_cost_seedbed;


      if(cost_inputs_store[index]["phase_of_construction"] === "Pre"){
        array_unit_cost_seedbed = (parseInt(cost_refinements_store["weed_control_costs"]["pre"]))
      }
      else if(cost_inputs_store[index]["phase_of_construction"] === "Post"){
        array_unit_cost_seedbed = (parseInt(cost_refinements_store["weed_control_costs"]["post"]))
      }
      else{
        array_unit_cost_seedbed = 0;
      }


      //buffer

      let buffer_quantity_seedbed = (parseInt(cost_inputs_store[index]["buffer_size"]) * ((parseInt(cost_inputs_store[index]["seedbed_preparation_cost"]))/100))
      let buffer_unit_cost_seedbed;


      if(cost_inputs_store[index]["phase_of_construction"] === "Pre"){
        buffer_unit_cost_seedbed = (parseInt(cost_refinements_store["seed_bed_costs"]["pre"]))
      }
      else if(cost_inputs_store[index]["phase_of_construction"] === "Post"){
        buffer_unit_cost_seedbed = (parseInt(cost_refinements_store["seed_bed_costs"]["post"]))
      }
      else{
        buffer_unit_cost_seedbed = 0;
      }


      array_cost[index]["seedbed_prep"] = array_quantity_seedbed * array_unit_cost_seedbed;
      buffer_cost[index]["seedbed_prep"] = buffer_quantity_seedbed * buffer_unit_cost_seedbed;


      //fertilizer supply array
      let array_fertilizer_supply_quantity;
      let array_fertilizer_supply_unit_cost = 150;

      if(cost_inputs_store[index]["fertilizer_required_array"] === "Yes"){
        array_fertilizer_supply_quantity = (parseInt(cost_inputs_store[index]["array_size"]))
      }
      else{
        array_fertilizer_supply_quantity = 0;
      }


      //fertilizer supply buffer
      let buffer_fertilizer_supply_quantity;
      let buffer_fertilizer_supply_unit_cost = 150;

      if(cost_inputs_store[index]["fertilizer_required_buffer"] === "Yes"){
        buffer_fertilizer_supply_quantity = (parseInt(cost_inputs_store[index]["buffer_size"]))
      }
      else{
        buffer_fertilizer_supply_quantity = 0;
      }


      array_cost[index]["fertilizer_supply"] = array_fertilizer_supply_quantity * array_fertilizer_supply_unit_cost;
      buffer_cost[index]["fertilizer_supply"] = buffer_fertilizer_supply_quantity * buffer_fertilizer_supply_unit_cost;

      //soil_amendment - array
      let array_soil_amendment_quantity;
      let array_soil_amendment_unit_cost = 75;

      if(cost_inputs_store[index]["soil_amendments_required_array"] === "Yes"){
        array_soil_amendment_quantity = (parseInt(cost_inputs_store[index]["array_size"]))
      }
      else{
        array_soil_amendment_quantity = 0;
      }

      //soil_amendment - buffer
      let buffer_soil_amendment_quantity;
      let buffer_soil_amendment_unit_cost = 75;

      if(cost_inputs_store[index]["soil_amendment_required_buffer"] === "Yes"){
        buffer_soil_amendment_quantity = (parseInt(cost_inputs_store[index]["buffer_size"]))
      }
      else{
        buffer_soil_amendment_quantity = 0;
      }


      array_cost[index]["soil_amendment"] = array_soil_amendment_quantity * array_soil_amendment_unit_cost;
      buffer_cost[index]["soil_amendment"] = buffer_soil_amendment_quantity * buffer_soil_amendment_unit_cost;


      //seed supply - array
      let array_seed_supply_quantity = (parseInt(cost_inputs_store[index]["array_size"]))
      let array_seed_supply_unit_cost = (parseInt(cost_inputs_store[index]["seed_mix_cost_array"]))


      //seed supply - buffer
      let buffer_seed_supply_quantity = (parseInt(cost_inputs_store[index]["buffer_size"]))
      let buffer_seed_supply_unit_cost = (parseInt(cost_inputs_store[index]["seed_mix_cost_buffer"]))


      array_cost[index]["seed_supply"] = array_seed_supply_quantity * array_seed_supply_unit_cost;
      buffer_cost[index]["seed_supply"] = buffer_seed_supply_quantity * buffer_seed_supply_unit_cost;


      //seed_installation - array
      let array_seed_installation_quantity = (parseInt(cost_inputs_store[index]["array_size"]))
      let array_seed_installation_unit_cost;

      if(cost_inputs_store[index]["row_restrictions"] === "Yes"){
        array_seed_installation_unit_cost = (parseInt(cost_refinements_store["seed_install_costs"]["end_restriction"]))
      }
      else{
        if(cost_inputs_store[index]["phase_of_construction"] === "Pre"){
          array_seed_installation_unit_cost = (parseInt(cost_refinements_store["seed_install_costs"]["pre"]))
        }
        else if(cost_inputs_store[index]["phase_of_construction"] === "Post"){
          array_seed_installation_unit_cost = (parseInt(cost_refinements_store["seed_install_costs"]["post"]))
        }
      }


      //seed_installation - buffer
      let buffer_seed_installation_quantity = (parseInt(cost_inputs_store[index]["buffer_size"]))
      let buffer_seed_installation_unit_cost;

      if(cost_inputs_store[index]["row_restrictions"] === "Yes"){
        buffer_seed_installation_unit_cost = (parseInt(cost_refinements_store["seed_install_costs"]["end_restriction"]))
      }

      else{
        if(cost_inputs_store[index]["phase_of_construction"] === "Pre"){
          buffer_seed_installation_unit_cost = (parseInt(cost_refinements_store["seed_install_costs"]["pre"]))
        }
        else if(cost_inputs_store[index]["phase_of_construction"] === "Post"){
          buffer_seed_installation_unit_cost = (parseInt(cost_refinements_store["seed_install_costs"]["post"]))
        }
      }


      array_cost[index]["seed_installation"] = array_seed_installation_quantity * array_seed_installation_unit_cost;
      buffer_cost[index]["seed_installation"] = buffer_seed_installation_quantity * buffer_seed_installation_unit_cost;


      //contingecy
      let contingency_quantity = 5;

      let total_cost_before_contingency = array_cost[index]["soil_amendment"] + buffer_cost[index]["soil_amendment"] + array_cost[index]["seed_supply"] + buffer_cost[index]["seed_supply"] + array_cost[index]["seed_installation"] + buffer_cost[index]["seed_installation"]


      let contingency_unit_cost = total_cost_before_contingency*0.05;

      let total_cost_after_contingency = total_cost_before_contingency + contingency_unit_cost;



      //set installation_cost_values at index of index to total_cost_after_contingency

      installation_costs_dict[index] = total_cost_after_contingency;


      //set installation_cost_values at index of index to total_cost_after_contingency
      setInstallationCostValues(installation_costs_dict)


      //send a dispatch to update the installation cost values in the store
      //dispatch(cost_benefit_actions.set_installation_cost(installation_cost_values));





    })
  }

  //useEffect to run benefit_analysis
  useEffect(() => {
    //handleBenefitAnalysis();
    handleInstallationCost();
    handleYear_1_3_MaintenanceCost();
    //handleInstallationUnitCost();

    dispatch(cost_benefit_actions.set_installation_cost(installation_cost_values));
  }, []);

  useEffect(() => {
    //console.log('ue installation_cost_values',installation_cost_values)
    //console.log('handle unit installation')
    handleInstallationUnitCost();
  },[installation_cost_values]);




  useEffect(() => {

    if(cost_inputs_store&&cost_refinements_store&&selected_options_store){
      // console.log('useEffect installation costs init')
      // //for all the options calculate the installation cost
      // let installation_cost;
      // let array_len = selected_options_store.length;
      // console.log("cost_inputs_use", cost_inputs_store)
      // console.log('array_len', array_len)
      // for(let i = 1; i < parseInt(array_len)+1; i++){
      //   console.log('useEffect installation costs loop', i)
      //   installation_cost = handleCostCalculation(cost_inputs_store[i])[2];
      //   console.log('use effect installation_cost',installation_cost)
      //   cost_analysis_results[i]["installation_cost"] = installation_cost;
      // }
      setLoadingInstallCost(true);
    }
  }, [cost_inputs_store, cost_refinements_store, selected_options_store])


  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }


 const graph_data_30_year_0 =[
    {name: 'Y1', value: total_30_year[0][1]},{name: 'Y2', value: total_30_year[0][2]},{name: 'Y3', value: total_30_year[0][3]},{name: 'Y4', value: total_30_year[0][4]},{name: 'Y5', value: total_30_year[0][5]},{name: 'Y6', value: total_30_year[0][6]},{name: 'Y7', value: total_30_year[0][7]},{name: 'Y8', value: total_30_year[0][8]},{name: 'Y9', value: total_30_year[0][9]},{name: 'Y10', value: total_30_year[0][10]},{name: 'Y11', value: total_30_year[0][11]},{name: 'Y12', value: total_30_year[0][12]},{name: 'Y13', value: total_30_year[0][13]},{name: 'Y14', value: total_30_year[0][14]},{name: 'Y15', value: total_30_year[0][15]},{name: 'Y16', value: total_30_year[0][16]},{name: 'Y17', value: total_30_year[0][17]},{name: 'Y18', value: total_30_year[0][18]},{name: 'Y19', value: total_30_year[0][19]},{name: 'Y20', value: total_30_year[0][20]},{name: 'Y21', value: total_30_year[0][21]},{name: 'Y22', value: total_30_year[0][22]},{name: 'Y23', value: total_30_year[0][23]},{name: 'Y24', value: total_30_year[0][24]},{name: 'Y25', value: total_30_year[0][25]},{name: 'Y26', value: total_30_year[0][26]},{name: 'Y27', value: total_30_year[0][27]},{name: 'Y28', value: total_30_year[0][28]},{name: 'Y29', value: total_30_year[0][29]},{name: 'Y30', value: total_30_year[0][30]}
 ];

  const graph_data_30_year_1 =[
    {name: 'Y1', value: total_30_year[1][1]},{name: 'Y2', value: total_30_year[1][2]},{name: 'Y3', value: total_30_year[1][3]},{name: 'Y4', value: total_30_year[1][4]},{name: 'Y5', value: total_30_year[1][5]},{name: 'Y6', value: total_30_year[1][6]},{name: 'Y7', value: total_30_year[1][7]},{name: 'Y8', value: total_30_year[1][8]},{name: 'Y9', value: total_30_year[1][9]},{name: 'Y10', value: total_30_year[1][10]},{name: 'Y11', value: total_30_year[1][11]},{name: 'Y12', value: total_30_year[1][12]},{name: 'Y13', value: total_30_year[1][13]},{name: 'Y14', value: total_30_year[1][14]},{name: 'Y15', value: total_30_year[1][15]},{name: 'Y16', value: total_30_year[1][16]},{name: 'Y17', value: total_30_year[1][17]},{name: 'Y18', value: total_30_year[1][18]},{name: 'Y19', value: total_30_year[1][19]},{name: 'Y20', value: total_30_year[1][20]},{name: 'Y21', value: total_30_year[1][21]},{name: 'Y22', value: total_30_year[1][22]},{name: 'Y23', value: total_30_year[1][23]},{name: 'Y24', value: total_30_year[1][24]},{name: 'Y25', value: total_30_year[1][25]},{name: 'Y26', value: total_30_year[1][26]},{name: 'Y27', value: total_30_year[1][27]},{name: 'Y28', value: total_30_year[1][28]},{name: 'Y29', value: total_30_year[1][29]},{name: 'Y30', value: total_30_year[1][30]}
  ];

  const graph_data_30_year_2 =[
    {name: 'Y1', value: total_30_year[2][1]},{name: 'Y2', value: total_30_year[2][2]},{name: 'Y3', value: total_30_year[2][3]},{name: 'Y4', value: total_30_year[2][4]},{name: 'Y5', value: total_30_year[2][5]},{name: 'Y6', value: total_30_year[2][6]},{name: 'Y7', value: total_30_year[2][7]},{name: 'Y8', value: total_30_year[2][8]},{name: 'Y9', value: total_30_year[2][9]},{name: 'Y10', value: total_30_year[2][10]},{name: 'Y11', value: total_30_year[2][11]},{name: 'Y12', value: total_30_year[2][12]},{name: 'Y13', value: total_30_year[2][13]},{name: 'Y14', value: total_30_year[2][14]},{name: 'Y15', value: total_30_year[2][15]},{name: 'Y16', value: total_30_year[2][16]},{name: 'Y17', value: total_30_year[2][17]},{name: 'Y18', value: total_30_year[2][18]},{name: 'Y19', value: total_30_year[2][19]},{name: 'Y20', value: total_30_year[2][20]},{name: 'Y21', value: total_30_year[2][21]},{name: 'Y22', value: total_30_year[2][22]},{name: 'Y23', value: total_30_year[2][23]},{name: 'Y24', value: total_30_year[2][24]},{name: 'Y25', value: total_30_year[2][25]},{name: 'Y26', value: total_30_year[2][26]},{name: 'Y27', value: total_30_year[2][27]},{name: 'Y28', value: total_30_year[2][28]},{name: 'Y29', value: total_30_year[2][29]},{name: 'Y30', value: total_30_year[2][30]}
  ];

  const graph_data = {
    0: graph_data_30_year_0,
    1: graph_data_30_year_1,
    2: graph_data_30_year_2
  }

  //console.log('graph_data', graph_data);

  //if loadinginstallcost is true then render the page else render a spinner
  if(loadingInstallCost){
    return (
      <React.Fragment>
        <Title level={3}> Cost Analysis Results </Title>
        <div className="container-fluid">
          <>
            <Row>
              {/*///////////////////////////////////////option 1 description columns///////////////////////////////////*/}
              <Col md={7}>
                <div className="flex">
                  <div className="m-2">
                    <label>&nbsp;</label>
                  </div>
                  <div className="m-2">
                    <label>Custom Option Name</label>
                  </div>
                  <div className="m-2">
                    <label>Array Cover</label>
                  </div>
                  <div className="m-2">
                    <label>Buffer Cover</label>
                  </div>

                  <div className="sectiontext">Costs</div>
                  <div className="m-1">
                    <label>Installation Cost</label>
                  </div>
                  <div className="m-1">
                    <label>Installation Unit Cost ($/Acre)</label>
                  </div>
                  <div className="m-1">
                    <label>Maintenance Y1-3 Cost</label>
                  </div>
                  <div className="m-1">
                    <label>Maintenance Y4-10 Cost</label>
                  </div>
                  <div className="m-2">
                    <label>Annualized Maintenance Cost ($/Acre/Year)</label>
                  </div>
                  <div className="m-2">
                    <label>Total 10 Year Cost</label>
                  </div>
                  <div className="m-2">
                    <label>30-Year Total Cost</label>
                  </div>
                  <div className="m-2">
                    <label>30-Year NPV Cost</label>
                  </div>

                  <div className="sectiontext">Benefits</div>
                  <div className="m-1">
                    <label>
                      Expected NPDES Closure within 1 Year of Construction
                    </label>
                  </div>
                  <div className="m-1">
                    <label>Lowest Install Cost</label>
                  </div>
                  <div className="m-1">
                    <label>Lowest Establishment Cost</label>
                  </div>
                  <div className="m-1">
                    <label>Lowest Total 30-Year Cost</label>
                  </div>
                  <div className="m-1">
                    <label>Annual Vegetation and Corrective Action Plans</label>
                  </div>
                  <div className="m-1">
                    <label>Community Acceptance (User-Entered)</label>
                  </div>
                  <div className="m-1">
                    <label>Supports Biodiversity ESG Goals (User-Entered)</label>
                  </div>
                  <div className="m-1">
                    <label>Pollinator Habitat Uplift</label>
                  </div>
                  <div className="m-1">
                    <label>Enhanced Soil Health and Carbon Sequestration</label>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <Space size="middle">
                  {selected_options_store.map((item, index) => {
                    return (
                      <div key={index} className="flex">
                        <div className="optionheader">
                          <label key={index}>Option {index + 1}</label>
                        </div>
                        <div className="m-1">
                          <Input id="option_name" defaultValue={cost_inputs_store[index]&&cost_inputs_store[index]['option_name']} />
                        </div>
                        <div className="m-1">
                          <Input id="array_cover" defaultValue={cost_inputs_store[index]&&cost_inputs_store[index]['desired_vegetation_array']} />
                        </div>
                        <div className="m-1">
                          <Input id="buffer_cover" defaultValue={cost_inputs_store[index]&&cost_inputs_store[index]['desired_vegetation_buffer']} />
                        </div>
                        <div className="m-1">&nbsp;</div>
                        <div className="m-1">
                          <Input id="installation_cost" value={installation_cost_values[index]?currencyFormat(installation_cost_values[index]):"Not defined"} />
                        </div>
                        <div className="m-1">
                          <Input id="installation_unit_cost" value={installation_unit_cost[index]?currencyFormat(installation_unit_cost[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="maintenance_y1_y3" value={y1_y3_value[index]?currencyFormat(y1_y3_value[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="maintenance_y4_y10" value={y4_y10_value[index]?currencyFormat(y4_y10_value[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="annual_maintenance_cost" value={annualized_maintenance_cost[index]?currencyFormat(annualized_maintenance_cost[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="total_10_year" value={total_10_year[index]?currencyFormat(total_10_year[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="total_30_year" value={total_30_year_value[index]?currencyFormat(total_30_year_value[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="total_30_year_npv" value={total_30_year_npv_value[index]?currencyFormat(total_30_year_npv_value[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">&nbsp;</div>
                        <div className="m-1">
                          <Input id="expected_npdes" value={cost_inputs_store[index]&&(cost_inputs_store["phase_of_construction"]=="Pre" || cost_inputs_store["desired_vegetation_array"]=="Turf/ Cool Season")?"X":"-"}/>
                        </div>

                        <div className="m-1">
                          <Input id="lowest_install_cost" value={lowest_installation_cost[index]?currencyFormat(lowest_installation_cost[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="lowest_establishment" value={lowest_establishment_cost[index]?currencyFormat(lowest_establishment_cost[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="lowest_total_30y" value={lowest_30_year[index]?currencyFormat(lowest_30_year[index]):"Not defined"}/>
                        </div>
                        <div className="m-1">
                          <Input id="annual_vegetation_cost" value={cost_inputs_store[index]&&cost_inputs_store[index]['annual_vegetation_monitoring']=="Yes"?"X":" "}/>
                        </div>
                        <div className="m-1">
                          <Input id="community_acceptance"/>
                        </div>
                        <div className="m-1">
                          <Input id="biodiversity_esg"/>
                        </div>
                        <div className="m-1">
                          <Input id="pollinator_habitat_uplift" value={cost_inputs_store[index]&&cost_inputs_store[index]['desired_vegetation_array']==("Pollinator"||"Hybrid")?"X":"-"}/>
                        </div>
                        <div className="m-1">
                          <Input id="enhanced_soil_health" value={cost_inputs_store[index]&&cost_inputs_store[index]['desired_vegetation_array']==("Pollinator"||"Hybrid")?"X":"-"}/>
                        </div>
                        <div className="m-1">
                            <LineChart width={350} height={200} data={graph_data[index]} >
                              <Line type="monotone" dataKey="value" stroke="#8884d8" />
                              <CartesianGrid stroke="#ccc" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                            </LineChart>
                        </div>
                      </div>
                    );
                  })}
                </Space>
              </Col>
            </Row>
          </>
        </div>
      </React.Fragment>
    );
  }
  else{
    return(
      <div className="container-fluid">
        <Spin indicator={antIcon} />
      </div>
    )
  }


}

export default CostAnalysisResults;
