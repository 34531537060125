import React from "react";
import deptenergylogo from "./../img/deptenergy.png";
import { Link } from "react-router-dom";
import "../css/main.css";

const Footer = () => {
  return (
    <>
      <center>
        <div className="footer mt-4">
          <br /> <br />
          <a
            href="https://www.energy.gov/eere/solar/"
            style={{ color: "blue" }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={deptenergylogo} height="40px" /> &nbsp; Development of the
            PHASE website was funded through the U.S. Department of Energy's
            Office of Energy Efficiency and Renewable Energy (EERE) under the
            Solar Energy Technologies Office Award Number DE-EE0009371. The
            views expressed herein do not necessarily represent the views of the
            U.S.Department of Energy or the United States Government.
          </a>
          <br />
        </div>
      </center>
    </>
  );
};

export default Footer;
