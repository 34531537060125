import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link
} from "@react-pdf/renderer";
import phase_logo from "../../../img/PHASE_noimages.jpg";
import stantec_logo from "../../../img/stantec.png";
import uic_erc_logo from "../../../img/uic_erc_logo.jpg";
import uic_logo from "../../../img/uic_rgb.jpg";

import { Spin, Table } from "antd";

/*
props:

countDict
selected_state
initial_inputs
AvailableDict
axios_post_body_data

*/

// Register the "Oswald" font
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf"
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Times-Roman"
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Times-Roman",
    textDecoration: "underline",
    textAlign: "center"
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman"
  },
  image: {
    height: 75,
    width: 130,
    marginLeft: "auto",
    marginRight: "auto",
    objectFit: "contain",
    objectPosition: "center"
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  disclaimer: {
    fontSize: 13,
    margin: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    wordWrap: "break-word",
    whiteSpace: "nowrap",
  },
  table: {
    width: "100%",
    border: "1px solid #000",
    borderCollapse: "collapse",
    margin: 10,
    marginBottom: 20,
    fontSize: 8,
    fontFamily: "Times-Roman",
    textAlign: "center",
    display: "table",
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    borderLeft: 0,
    borderTop: 0
  },

  // Styles for table rows
  tableRow: {
    flexDirection: "row"
  },

  // Styles for table header cells
  tableHeaderCell: {
    flex: 1,
    backgroundColor: "#f2f2f2",
    padding: 5,
    border: "1px solid #000"
  },

  // Styles for table data cells
  tableCell: {
    flex: 1,
    padding: 5,
    border: "1px solid #000"
  },

  tableCellText: {
    fontSize: 8,
    fontFamily: "Times-Roman"
  },

  tableCellHeader: {
    fontSize: 8,
    fontFamily: "Times-Roman",
    fontWeight: "bold"
  },
  logoContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 10
  }
});

function SeedResultsPdf(props) {
  // Define a function to create a table-like structure
  const renderTable = (data, columns) => (
    <View>
      {/* Table Header */}
      <View style={styles.tableRow}>
        {columns.map((column, index) => (
          <View key={index} style={styles.tableHeaderCell}>
            <Text style={styles.tableCellHeader}>{column.title}</Text>
          </View>
        ))}
      </View>

      {/* Table Rows */}
      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {columns.map((column, colIndex) => (
            <View key={colIndex} style={styles.tableCell}>
              <Text style={styles.tableCellText}>{row[column.dataIndex]}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );

  const handleOnClick = (url) => {
    window.open(url, "_blank");
  }

  return (
    <>
      <Document>
        <Page style={styles.body} wrap={false}>
          <Text style={styles.header} fixed>
            ~ Accessed on {new Date().toLocaleString("en-US").split(",")[0]} ~
          </Text>
          <View style={styles.logoContainer}>
            <Link src="https://phase.erc.uic.edu/Tools">
              <Image style={styles.image} src={phase_logo} />
            </Link>
            <Link src="https://erc.uic.edu">
              <Image style={styles.image} src={uic_erc_logo} />
            </Link>
            <Link src="https://www.stantec.com/en">
              <Image style={styles.image} src={stantec_logo} />
            </Link>
          </View>
          <Text style={styles.subtitle}>Acknowledgement</Text>
          <Text style={styles.disclaimer}>
            This material is based upon work supported by the U.S. Department of
            Energy’s Office of Energy Efficiency and Renewable Energy (EERE)
            under the{" "}
            <Link
              src="https://www.energy.gov/eere/solar/"
              style={{ color: "blue" }}
            >
              "Solar Energy Technologies Office"(SETO){" "}
            </Link>
            award number DE-EE0009371.
          </Text>
          <Text style={styles.subtitle}>Project Information</Text>
          {renderTable(
            [
              {
                ProjectName: props.initial_inputs.organization_name
                  ? props.initial_inputs.organization_name
                  : "N/A",
                ProjectLocation: props.selected_state
                  ? props.selected_state
                  : "N/A",
                ProjectPlantingArea: props.initial_inputs.planting_zones
                  ? props.initial_inputs.planting_zones
                  : "N/A"
              }
            ],
            [
              { title: "Project Name", dataIndex: "ProjectName" },
              { title: "Project Location", dataIndex: "ProjectLocation" },
              {
                title: "Project Planting Area",
                dataIndex: "ProjectPlantingArea"
              }
            ]
          )}
          <Text style={styles.subtitle}>Selected Inputs</Text>
          {renderTable(
            Object.keys(props.countDict).map((key) => {
              let modifiedValue = String(props.axios_post_body_data[key]);
              let modifiedValueArray = modifiedValue.split(",");
              let fixedValue = modifiedValueArray.map((value) => {
                switch (key) {
                  case "SeedCommercialAvailability":
                    switch (String(value).trim()) {
                      case "very_common":
                        return "Very Common";
                      case "very_rare":
                        return "Very Rare";
                      case "very_rare_unavailable":
                        return "Very Rare/Unavailable";
                      default:
                        return String(value).trim();
                    }
                  case "SunExposure":
                    switch (String(value).trim()) {
                      case "pshade":
                        return "Partial Shade";
                      default:
                        return value.trim();
                    }
                  case "GrowthSimplified":
                    switch (String(value).trim()) {
                      case "forb":
                        return "Forb/Herb";
                      case "shrub":
                        return "Shrub";
                      case "tree":
                        return "Tree";
                      case "grass":
                        return "Grass";
                      default:
                        return String(value).trim();
                    }
                  case "BenefitsToPollinators":
                    switch (String(value).trim()) {
                      case "larval":
                        return "Larval Food";
                      case "nesting":
                        return "Nesting and Structure (Bees)";
                      case "adult":
                        return "Adult Food";
                      default:
                        return String(value).trim();
                    }
                  // Add other switch cases as before
                  default:
                    return String(value).trim();
                }
              });
              return {
                Parameter: props.AvailableDict[key],
                Value: fixedValue.join(", "),
                Count: props.countDict[key]
              };
            }),
            [
              { title: "Parameter", dataIndex: "Parameter" },
              { title: "Selected Value(s)", dataIndex: "Value" },
              { title: "Available Count", dataIndex: "Count" }
            ]
          )}
          <Text style={styles.subtitle}>Disclaimer</Text>
          <Text style={styles.disclaimer}>
            The Seed Selection Tool is intended for use by site planners and
            vegetation managers involved in decision making for solar project
            site vegetation planning. Outputs of this tool are generated by the
            user-determined inputs and limited by the selected variables
            included. Real-world applications may be more complex and require
            additional considerations for constraints and complexities not
            accounted for in this tool's interface. This tool was created by the
            University of Illinois Chicago and Stantec with support of the U.S.
            Department of Energy's Solar Energy Technologies Office. None of the
            developers, contributors, administrators, or anyone else connected
            with this tool, in any way whatsoever, can be responsible for your
            use of the information contained within or yielded from.{" "}
          </Text>
        </Page>
      </Document>
    </>
  );
}

export default SeedResultsPdf;
