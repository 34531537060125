import { Link } from 'react-router-dom';
import React from 'react'
import phase_logo from '../img/PHASE_noimages.jpg';
import { submenuItems } from '../components/submenuItems';
import SubMenuItem from '../components/SubMenuItem';
import '../css/main.css'

const SubHeader = () => {
    return (
        <>
            <Link to="/" className='logoandtext'>
                <img src={phase_logo} className='phaselogo' />
            </Link>
            <ul className="menus">
                {submenuItems.map((menu, index) => {
                    const depthLevel = 0;
                    return (

                        <SubMenuItem
                            items={menu}
                            key={index}
                            depthLevel={depthLevel} />
                    );
                })}
            </ul></>

    );
};

export default SubHeader;