import React, { useEffect } from "react";
import { Input, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { cost_benefit_actions } from "../../../store/cost_benefit_slice";


const { Title } = Typography;

function CostRefinements() {
  const [value, setValue] = React.useState(0);

  //redux
  const dispatch = useDispatch();
  const cost_refinements_store = useSelector(
    (state) => state.cost_benefit_slice.cost_refinements
  );

  const selected_options = useSelector(
    (state) => state.cost_benefit_slice.selected_options
  );


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cost_refinements_default_values = {
    seed_costs:{
      turf_cool_season: 250,
      pollinator:450,
      hybrid: 400,
    },
    seed_bed_costs:{
      pre:25,
      post:250,
      end_restriction:450
    },
    seed_install_costs:{
      pre:20,
      post:250,
      end_restriction:450
    },
    weed_control_costs:{
      pre:20,
      post:250,
      end_restriction:450
    },
    mowing_costs:{
      end_restriction:250,
      no_end_restriction:100,
    },
    mowing_frequency:{
      1:{
        high:3,
        mid:2,
        low:2
      },
      2:{
        high:3,
        mid:2,
        low:2
      },
      3:{
        high:2,
        mid:2,
        low:1
      },
      4:{
        high:2,
        mid:1,
        low:0
      },
      5:{
        high:1,
        mid:0,
        low:1
      },
      6:{
        high:1,
        mid:1,
        low:0
      },
      7:{
        high:0,
        mid:0,
        low:1
      },
      8:{
        high:1,
        mid:1,
        low:0
      },
      9:{
        high:0,
        mid:0,
        low:1
      },
      10:{
        high:1,
        mid:1,
        low:0
      }
    },
    herbicide_frequency:{
      1:{
        high:2,
        mid:2,
        low:2
      },
      2:{
        high:2,
        mid:2,
        low:1
      },
      3:{
        high:2,
        mid:1,
        low:1
      },
      4:{
        high:2,
        mid:1,
        low:0
      },
      5:{
        high:1,
        mid:1,
        low:1
      },
      6:{
        high:1,
        mid:0,
        low:0
      },
      7:{
        high:1,
        mid:1,
        low:1
      },
      8:{
        high:0,
        mid:0,
        low:0
      },
      9:{
        high:1,
        mid:1,
        low:1
      },
      10:{
        high:0,
        mid:0,
        low:0
      }
    }
  }

  const [cost_refinements, setCostRefinements] = React.useState(cost_refinements_default_values);

  const handleInputChange = (event,pos) => {
    //console.log(event.target.name);
    //console.log(pos);
    //console.log(event.target.value);
    setCostRefinements({
      ...cost_refinements,
      [event.target.name]: {
        ...cost_refinements[event.target.name],
        [pos]: parseInt(event.target.value)
      }
    });

    dispatch(cost_benefit_actions.set_cost_refinements(cost_refinements));

  }


  const handleFrequencyChange = (event,pos,inner_pos) => {
    setCostRefinements({
      //mowing_frequency{
      //  pos:{
      //    inner_pos: event.target.value
      //}
      //}
      ...cost_refinements,
      [event.target.name]: {
        ...cost_refinements[event.target.name],
        [pos]: {
          ...cost_refinements[event.target.name][pos],
          [inner_pos]: parseInt(event.target.value)
        }
      }
    });

    dispatch(cost_benefit_actions.set_cost_refinements(cost_refinements));

  }

  useEffect(() => {
    //console.log('use effect cost refinements comp',cost_refinements);
    //send a dispatch to the store
    dispatch(cost_benefit_actions.set_cost_refinements(cost_refinements));
  }, [cost_refinements]);

  useEffect(() => {
    //console.log('use effect cost refinement store comp',cost_refinements_store);
  }, [cost_refinements_store]);

  useEffect(() => {
    //console.log('selected options cost refinements',selected_options);
    //send a dispatch to the store
    dispatch(cost_benefit_actions.set_cost_refinements(cost_refinements));
  }, []);

  return (
    <React.Fragment>
      <Title level={3}>Cost Adjustments Worksheet (Optional)</Title>
      <br />
      <div className="container-fluid">
        <>
          <div className="cb-firstrow">
            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Seed Costs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Turf/Cool Season &nbsp;&nbsp;</td>
                  <td>
                    <Input name="seed_costs" defaultValue={250} onBlur={(value) => handleInputChange(value,'turf_cool_season')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>Pollinator</td>
                  <td>
                    <Input name="seed_costs" defaultValue={450} onBlur={(value) => handleInputChange(value,'pollinator')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>Hybrid</td>
                  <td>
                    <Input name="seed_costs" defaultValue={400} onBlur={(value) => handleInputChange(value,'hybrid')}></Input>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Seedbed Prep Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pre</td>
                  <td>
                    {" "}
                    <Input name="seed_bed_costs" defaultValue={25.0} onBlur={(value) => handleInputChange(value,'pre')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>Post</td>
                  <td>
                    {" "}
                    <Input name="seed_bed_costs" defaultValue={250.0} onBlur={(value) => handleInputChange(value,'post')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>End Restriction</td>
                  <td>
                    {" "}
                    <Input name="seed_bed_costs" defaultValue={450.0} onBlur={(value) => handleInputChange(value,'end_restriction')}></Input>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Seed Install Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pre</td>
                  <td>
                    {" "}
                    <Input name="seed_install_costs" defaultValue={20.0} onBlur={(value) => handleInputChange(value,'pre')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>Post</td>
                  <td>
                    {" "}
                    <Input name="seed_install_costs" defaultValue={250.0} onBlur={(value) => handleInputChange(value,'post')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>End Restriction</td>
                  <td>
                    {" "}
                    <Input name="seed_install_costs" defaultValue={450.0} onBlur={(value) => handleInputChange(value,'end_restriction')}></Input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="cb-secondrow">
            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Mowing Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>End Restriction</td>
                  <td>
                    {" "}
                    <Input name="mowing_costs" defaultValue={250.0} onBlur={(value) => handleInputChange(value,'end_restriction')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>No End Restriction</td>
                  <td>
                    {" "}
                    <Input name="mowing_costs" defaultValue={100.0} onBlur={(value) => handleInputChange(value,'no_end_restriction')}></Input>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Weed Control Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pre</td>
                  <td>
                    {" "}
                    <Input name="weed_control_costs" defaultValue={25.0} onBlur={(value) => handleInputChange(value,'pre')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>Post</td>
                  <td>
                    {" "}
                    <Input name="weed_control_costs" defaultValue={250.0} onBlur={(value) => handleInputChange(value,'post')}></Input>
                  </td>
                </tr>
                <tr>
                  <td>End Restriction</td>
                  <td>
                    {" "}
                    <Input name="weed_control_costs" defaultValue={450.0} onBlur={(value) => handleInputChange(value,'end_restriction')}></Input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="cb-thirdrow">
            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Mowing Frequency</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> </td>
                  <td>High</td>
                  <td>Mid</td>
                  <td>Low</td>
                </tr>
                <tr>
                  <td>Year 1</td>
                  <td>
                    <input name="mowing_frequency" defaultValue={3} onBlur={(value) => handleFrequencyChange(value,'1','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'1','mid')}/>
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'1','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 2</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="3" onBlur={(value) => handleFrequencyChange(value,'2','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'2','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'2','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 3</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'3','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'3','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'3','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 4</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'4','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'4','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'4','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 5</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'5','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'5','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'5','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 6</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'6','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'6','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'6','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 7</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'7','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'7','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'7','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 8</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'8','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'8','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'8','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 9</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'9','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'9','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'9','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 10</td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'10','high')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'10','mid')} />
                  </td>
                  <td>
                    <input name="mowing_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'10','low')} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="cb-thirdrow">
            <table className="cb-table">
              <thead colSpan="2">
                <tr>
                  <th colSpan="2">Herbicide Frequency</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> </td>
                  <td>High</td>
                  <td>Mid</td>
                  <td>Low</td>
                </tr>
                <tr>
                  <td>Year 1</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'1','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'1','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'1','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 2</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'2','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'2','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'2','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 3</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'3','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'3','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'3','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 4</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="2" onBlur={(value) => handleFrequencyChange(value,'4','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'4','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'4','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 5</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'5','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'5','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'5','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 6</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'6','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'6','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'6','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 7</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'7','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'7','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'7','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 8</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'8','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'8','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'8','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 9</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'9','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'9','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="1" onBlur={(value) => handleFrequencyChange(value,'9','low')} />
                  </td>
                </tr>
                <tr>
                  <td>Year 10</td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'10','high')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'10','mid')} />
                  </td>
                  <td>
                    <input name="herbicide_frequency" defaultValue="0" onBlur={(value) => handleFrequencyChange(value,'10','low')} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>
    </React.Fragment>
  );
}

export default CostRefinements;
