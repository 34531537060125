const cost_detailed_summmary_array_default_values = {
  1: {
    pre_seeding: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_mix_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_installation_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    fertilizer_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    soil_amendment_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    decompaction_or_tillage_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    temporary_stabilization_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_1_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_2_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_3_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    }
  },
  2: {
    pre_seeding: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_mix_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_installation_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    fertilizer_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    soil_amendment_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    decompaction_or_tillage_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    temporary_stabilization_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_1_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_2_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_3_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    }
  },
  3: {
    pre_seeding: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_mix_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_installation_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    fertilizer_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    soil_amendment_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    decompaction_or_tillage_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    temporary_stabilization_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_1_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_2_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_3_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    }
  }
};

const complexity_multiplier_default_values = {
  1: {
    post_con: 0,
    narrow_row: 0,
    end_of_row_restricted: 0
  },
  2: {
    post_con: 0,
    narrow_row: 0,
    end_of_row_restricted: 0
  },
  3: {
    post_con: 0,
    narrow_row: 0,
    end_of_row_restricted: 0
  }
};

let annualCostDefaultValues = {
    1: [Array(30).fill(0)],
    2: [Array(30).fill(0)],
    3: [Array(30).fill(0)]
}

let cummulativeCostDefaultValues = {
    1: [Array(30).fill(0)],
    2: [Array(30).fill(0)],
    3: [Array(30).fill(0)]
}

let totalNPVDefaultValues = {
    1: 0,
    2: 0,
    3: 0
}

const cost_detailed_summmary_buffer_default_values = {
  1: {
    pre_seeding: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_mix_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_installation_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    fertilizer_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    soil_amendment_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    decompaction_or_tillage_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    temporary_stabilization_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_1_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_2_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_3_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    }
  },
  2: {
    pre_seeding: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_mix_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_installation_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    fertilizer_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    soil_amendment_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    decompaction_or_tillage_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    temporary_stabilization_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_1_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_2_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_3_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    }
  },
  3: {
    pre_seeding: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_mix_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    seed_installation_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    fertilizer_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    soil_amendment_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    decompaction_or_tillage_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    temporary_stabilization_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_1_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_2_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    custom_input_3_cost: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_mowing_treatments_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_herbicide_applications_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    no_of_supplemental_seedings_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    grazing_management_services_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_establishment: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    },
    inspections_and_monitoring_maintenance: {
      quantity: "",
      complexity_multiplier: "",
      unit_cost: "",
      total_cost: ""
    }
  }
};

const cost_detailed_summary_total_default_values = {
  1: {
    array: {
      total_cost_installation_phase: "",
      total_array_establishment_cost: "",
      total_array_maintenance_cost: ""
    },
    buffer: {
      total_cost_installation_phase: "",
      total_buffer_establishment_cost: "",
      total_buffer_maintenance_cost: ""
    }
  },
  2: {
    array: {
      total_cost_installation_phase: "",
      total_array_establishment_cost: "",
      total_array_maintenance_cost: ""
    },
    buffer: {
      total_cost_installation_phase: "",
      total_buffer_establishment_cost: "",
      total_buffer_maintenance_cost: ""
    }
  },
  3: {
    array: {
      total_cost_installation_phase: "",
      total_array_establishment_cost: "",
      total_array_maintenance_cost: ""
    },
    buffer: {
      total_cost_installation_phase: "",
      total_buffer_establishment_cost: "",
      total_buffer_maintenance_cost: ""
    }
  }
};

export {
  cost_detailed_summmary_array_default_values,
  cost_detailed_summmary_buffer_default_values,
  cost_detailed_summary_total_default_values,
  complexity_multiplier_default_values,
  annualCostDefaultValues,
  cummulativeCostDefaultValues,
  totalNPVDefaultValues
};
