import React from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { seed_selection_actions } from '../../../store/seed_selection_slice';
import { useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from 'react-select'
import api from '../../../axiosConfig';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function StatesList() {
    //constants used for the component
    const [states, setStates] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState('');


    //redux
    const dispatch = useDispatch();
    const selected_state = useSelector(state => state.seed_selection.selected_state);

    //functions
    const handleValueChange = (props) =>{
        const { name, value } = props.target;
        //console.log(props);
        //set the value of selected state in the redux store
        setSelectedState(value);
        //console.log(value);
        dispatch(seed_selection_actions.set_selected_state(value));

    }
    //useEffect
    useEffect(() => {
        api
            .get('/States')
            .then(response => {
                //console.log(response)
                setStates(response.data);
            }).catch(error => console.log(error.response));
    } , []);

    useEffect(() => {
        //console.log('selected state in ii page',selected_state)
        setSelectedState(selected_state);
    } , [selected_state]);

    const handleSelectChange = (event) =>{
        var selectedIndex = event.target.options.selectedIndex;
        //console.log(event.target.options[selectedIndex]);
        //console.log(event.target.value);
        dispatch(seed_selection_actions.set_selected_state(event.target.value));
    }

    return (

    <div>
            <select onChange={handleSelectChange} value={selected_state}
            style={{ width: "60%" }}
            >
                <option value="">-- Select a state --</option>
                {states.map(function (data, key) {
                    return (
                        <option key={key} value={data.abbr}>{data.state1}</option>)
                })}

            </select>
    </div>
    )
}

export default StatesList