function complexityMultiplier(
  index,
  complexity_multiplier_values_copy,
  cost_detailed_summary_array_values,
  cost_detailed_summary_buffer_values,
  cost_detailed_summary_total_values,
  cost_information_store,
  cost_input_array_size,
  cost_input_buffer_size
) {
  /*
        @params:
            - index: selected option index
            - complexity_multiplier_values_copy : array of objects
            - cost_detailed_summary_array_values : array of objects
            - cost_detailed_summary_buffer_values : array of objects
            - cost_detailed_summary_total_values : array of objects
            - cost_information_store : array of objects
            - cost_input_array_size : number
            - cost_input_buffer_size : number

        @returns:
            - cost_detailed_summary_array_values_copy : array of objects : updated array values
            - cost_detailed_summary_buffer_values_copy : array of objects : updated buffer values
            - cost_detailed_summary_total_values_copy : array of objects : updated total values
    */

  //update the values in the cost_detailed_summary_array_values_copy and cost_detailed_summary_buffer_values_copy
  let cost_detailed_summary_array_values_copy = {
    ...cost_detailed_summary_array_values
  };

  let cost_detailed_summary_buffer_values_copy = {
    ...cost_detailed_summary_buffer_values
  };

  const complexityMultiplierValuePostCon = (
    isNaN(complexity_multiplier_values_copy[index + 1].post_con) ||
    isNaN(complexity_multiplier_values_copy[index + 1].narrow_row) ||
    isNaN(complexity_multiplier_values_copy[index + 1].end_of_row_restricted)
    ? 0 : complexity_multiplier_values_copy[index + 1].post_con +
    complexity_multiplier_values_copy[index + 1].narrow_row +
    complexity_multiplier_values_copy[index + 1].end_of_row_restricted
  )

  const complexityMultiplierValue = (
    isNaN(complexity_multiplier_values_copy[index + 1].narrow_row) ||
    isNaN(complexity_multiplier_values_copy[index + 1].end_of_row_restricted)
    ? 0 :
    complexity_multiplier_values_copy[index + 1].narrow_row +
    complexity_multiplier_values_copy[index + 1].end_of_row_restricted
  )

  cost_detailed_summary_array_values_copy[index + 1] = {
    ...cost_detailed_summary_array_values_copy[index + 1],
    pre_seeding: {
      ...cost_detailed_summary_array_values_copy[index + 1].pre_seeding,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
    total_cost:
      complexityMultiplierValuePostCon> 0?
      parseInt(cost_detailed_summary_array_values_copy[index + 1].pre_seeding
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].pre_seeding
        .quantity) + parseInt(
          cost_detailed_summary_array_values_copy[index + 1].pre_seeding
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].pre_seeding
          .unit_cost
        ): cost_detailed_summary_array_values_copy[index + 1].pre_seeding
        .total_cost
    },
    seed_mix_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
          .unit_cost
        ): cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
        .total_cost
    },
    seed_installation_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .seed_installation_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
          .unit_cost
        ): cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
        .total_cost
    },
    fertilizer_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
          .unit_cost
        ): cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
        .total_cost
    },
    soil_amendment_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
          .unit_cost
        ):
        cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
        .total_cost
    },
    decompaction_or_tillage_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .decompaction_or_tillage_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].decompaction_or_tillage_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].decompaction_or_tillage_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].decompaction_or_tillage_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].decompaction_or_tillage_cost
          .unit_cost
        ):
        cost_detailed_summary_array_values_copy[index + 1].decompaction_or_tillage_cost
        .total_cost
    },
    temporary_stabilization_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .temporary_stabilization_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].temporary_stabilization_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].temporary_stabilization_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].temporary_stabilization_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].temporary_stabilization_cost
          .unit_cost
        ):
        cost_detailed_summary_array_values_copy[index + 1].temporary_stabilization_cost
        .total_cost
    },
    custom_input_1_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
          .unit_cost
        ):
        cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
        .total_cost
    },
    custom_input_2_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
          .unit_cost
        ):
        cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
        .total_cost
    },
    custom_input_3_cost: {
      ...cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
      complexityMultiplierValuePostCon> 0?
      (cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
        .quantity) + (
          cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
          .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
          .unit_cost
        ):
        cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
        .total_cost
    },
    no_of_mowing_treatments_establishment: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .no_of_mowing_treatments_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_establishment
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_establishment
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_establishment
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_establishment
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_establishment
        .total_cost
    },
    no_of_mowing_treatments_maintenance: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .no_of_mowing_treatments_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_maintenance
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_maintenance
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_maintenance
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_maintenance
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].no_of_mowing_treatments_maintenance
        .total_cost
    },
    no_of_herbicide_applications_establishment: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .no_of_herbicide_applications_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_establishment
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_establishment
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_establishment
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_establishment
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_establishment
        .total_cost
    },
    no_of_herbicide_applications_maintenance: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .no_of_herbicide_applications_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_maintenance
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_maintenance
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_maintenance
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_maintenance
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].no_of_herbicide_applications_maintenance
        .total_cost
    },
    no_of_supplemental_seedings_establishment: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .no_of_supplemental_seedings_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_establishment
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_establishment
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_establishment
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_establishment
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_establishment
        .total_cost
    },
    no_of_supplemental_seedings_maintenance: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .no_of_supplemental_seedings_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_maintenance
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_maintenance
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_maintenance
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_maintenance
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].no_of_supplemental_seedings_maintenance
        .total_cost
    },
    grazing_management_services_establishment: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .grazing_management_services_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_establishment
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_establishment
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_establishment
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_establishment
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_establishment
        .total_cost
    },
    grazing_management_services_maintenance: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .grazing_management_services_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_maintenance
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_maintenance
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_maintenance
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_maintenance
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].grazing_management_services_maintenance
        .total_cost
    },
    inspections_and_monitoring_establishment: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .inspections_and_monitoring_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_establishment
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_establishment
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_establishment
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_establishment
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_establishment
        .total_cost
    },
    inspections_and_monitoring_maintenance: {
      ...cost_detailed_summary_array_values_copy[index + 1]
        .inspections_and_monitoring_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
      total_cost:
      complexityMultiplierValue> 0?
      (cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_maintenance
        .unit_cost * cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_maintenance
        .quantity * cost_input_array_size) + (
          cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_maintenance
          .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_maintenance
          .unit_cost * cost_input_array_size
        ):
        cost_detailed_summary_array_values_copy[index + 1].inspections_and_monitoring_maintenance
        .total_cost
    }
  };

  cost_detailed_summary_buffer_values_copy[index + 1] = {
    ...cost_detailed_summary_buffer_values_copy[index + 1],
    pre_seeding: {
      ...cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
      total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding
          .total_cost
    },
    seed_mix_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
          .total_cost
    },
    seed_installation_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .seed_installation_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
          .total_cost
    },
    fertilizer_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
          .total_cost
    },
    soil_amendment_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .soil_amendment_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
          .total_cost
    },
    decompaction_or_tillage_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .decompaction_or_tillage_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].decompaction_or_tillage_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].decompaction_or_tillage_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].decompaction_or_tillage_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].decompaction_or_tillage_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].decompaction_or_tillage_cost
          .total_cost
    },
    temporary_stabilization_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .temporary_stabilization_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].temporary_stabilization_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].temporary_stabilization_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].temporary_stabilization_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].temporary_stabilization_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].temporary_stabilization_cost
          .total_cost
    },
    custom_input_cost_1: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .custom_input_cost_1,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
          .total_cost
    },
    custom_input_2_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .custom_input_2_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
          .total_cost
    },
    custom_input_3_cost: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .custom_input_3_cost,
      complexity_multiplier:
        complexityMultiplierValuePostCon,
        total_cost:
        complexityMultiplierValuePostCon> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
          .quantity) + (
            cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
            .quantity * (complexityMultiplierValuePostCon)/100 * cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
            .unit_cost
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
    },
    no_of_mowing_treatments_establishment: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_mowing_treatments_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_establishment
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_establishment
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_establishment
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_establishment
            .unit_cost * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_establishment
          .total_cost
    },
    no_of_mowing_treatments_maintenance: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_mowing_treatments_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_maintenance
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_maintenance
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_maintenance
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_maintenance
            .unit_cost * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].no_of_mowing_treatments_maintenance
          .total_cost
    },
    no_of_herbicide_applications_establishment: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_herbicide_applications_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_establishment
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_establishment
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_establishment
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_establishment
            .unit_cost  * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_establishment
          .total_cost
    },
    no_of_herbicide_applications_maintenance: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_herbicide_applications_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_maintenance
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_maintenance
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_maintenance
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_maintenance
            .unit_cost  * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].no_of_herbicide_applications_maintenance
          .total_cost
    },
    no_of_supplemental_seedings_establishment: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_supplemental_seedings_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_establishment
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_establishment
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_establishment
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_establishment
            .unit_cost * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_establishment
          .total_cost
    },
    no_of_supplemental_seedings_maintenance: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_supplemental_seedings_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_maintenance
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_maintenance
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_maintenance
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_maintenance
            .unit_cost * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].no_of_supplemental_seedings_maintenance
          .total_cost
    },
    grazing_management_services_establishment: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .grazing_management_services_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_establishment
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_establishment
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_establishment
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_establishment
            .unit_cost  * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_establishment
          .total_cost
    },
    grazing_management_services_maintenance: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .grazing_management_services_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_maintenance
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_maintenance
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_maintenance
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_maintenance
            .unit_cost  * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].grazing_management_services_maintenance
          .total_cost
    },
    inspections_and_monitoring_establishment: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .inspections_and_monitoring_establishment,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_establishment
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_establishment
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_establishment
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_establishment
            .unit_cost  * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_establishment
          .total_cost
    },
    inspections_and_monitoring_maintenance: {
      ...cost_detailed_summary_buffer_values_copy[index + 1]
        .inspections_and_monitoring_maintenance,
      complexity_multiplier:
        complexityMultiplierValue,
        total_cost:
        complexityMultiplierValue> 0?
        (cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_maintenance
          .unit_cost * cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_maintenance
          .quantity * cost_input_buffer_size) + (
            cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_maintenance
            .quantity * (complexityMultiplierValue)/100 * cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_maintenance
            .unit_cost  * cost_input_buffer_size
          ):
          cost_detailed_summary_buffer_values_copy[index + 1].inspections_and_monitoring_maintenance
          .total_cost
    }
  };

  let cost_detailed_summary_total_values_copy = {
    ...cost_detailed_summary_total_values
  };

  //add the value of sum of all the total costs until custom_input_3_cost for the cost_detailed_summary_array_values_copy and add it to cost_detailed_summary_total_default_values.array.total_cost_installation_phase
  let total_installation_cost_array =
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].pre_seeding.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].seed_mix_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].seed_installation_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].fertilizer_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].soil_amendment_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .decompaction_or_tillage_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .temporary_stabilization_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].custom_input_1_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].custom_input_2_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1].custom_input_3_cost
        .total_cost
    );

  let total_installation_cost_buffer =
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].pre_seeding.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].seed_mix_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].seed_installation_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].fertilizer_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].soil_amendment_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .decompaction_or_tillage_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .temporary_stabilization_cost.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].custom_input_cost_1
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].custom_input_2_cost
        .total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1].custom_input_3_cost
        .total_cost
    );

  let total_array_establishment_cost =
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_mowing_treatments_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_herbicide_applications_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_supplemental_seedings_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .grazing_management_services_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .inspections_and_monitoring_establishment.total_cost
    );

  let total_array_maintenance_cost =
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_mowing_treatments_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_herbicide_applications_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .no_of_supplemental_seedings_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .grazing_management_services_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_array_values_copy[index + 1]
        .inspections_and_monitoring_maintenance.total_cost
    );

  let total_buffer_establishment_cost =
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_mowing_treatments_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_herbicide_applications_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_supplemental_seedings_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .grazing_management_services_establishment.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .inspections_and_monitoring_establishment.total_cost
    );

  let total_buffer_maintenance_cost =
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_mowing_treatments_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_herbicide_applications_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .no_of_supplemental_seedings_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .grazing_management_services_maintenance.total_cost
    ) +
    parseInt(
      cost_detailed_summary_buffer_values_copy[index + 1]
        .inspections_and_monitoring_maintenance.total_cost
    );

  cost_detailed_summary_total_values_copy = {
    ...cost_detailed_summary_total_values_copy,
    [index + 1]: {
      array: {
        total_cost_installation_phase: total_installation_cost_array,
        total_array_establishment_cost:
          total_array_establishment_cost *
          cost_information_store.establishment_phase,
        total_array_maintenance_cost:
          total_array_maintenance_cost *
          cost_information_store.maintenance_phase
      },
      buffer: {
        total_cost_installation_phase: total_installation_cost_buffer,
        total_buffer_establishment_cost:
          total_buffer_establishment_cost *
          cost_information_store.establishment_phase,
        total_buffer_maintenance_cost:
          total_buffer_maintenance_cost *
          cost_information_store.maintenance_phase
      }
    }
  };

  return [
    cost_detailed_summary_array_values_copy,
    cost_detailed_summary_buffer_values_copy,
    cost_detailed_summary_total_values_copy
  ];
}

export default complexityMultiplier;
