import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import { Spin, Table } from "antd";
import Button from "@mui/material/Button";
import { Alert, Space, Progress } from "antd";
import { CSVLink } from "react-csv";
import api from "../../../axiosConfig";
import "../../../css/tools.css";
import { margin } from "@mui/system";
import questionMark from "../../../img/sm-red-question-mark.png";
import seedMap from "../../../img/seed_map.png";
import SeedTableModal from "./SeedTableModal";
import SeedResultsPdf from "./SeedResultsPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function Results() {
  //redux
  const dispatch = useDispatch();
  const selected_state = useSelector(
    (state) => state.seed_selection.selected_state
  );

  const ecoRegions = useSelector(
    (state) => state.seed_selection.gis_ecoRegions
  );
  const initial_inputs = useSelector(
    (state) => state.seed_selection.initital_inputs
  );
  const planting_zone_filters = useSelector(
    (state) => state.seed_selection.planting_zone_filters
  );
  const refinements = useSelector((state) => state.seed_selection.refinements);
  const additional_refinements = useSelector(
    (state) => state.seed_selection.additional_refinements
  );

  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [csvData, setCsvData] = React.useState([]);
  const [countDict, setCountDict] = React.useState({});
  const [filterResults, setFilterResults] = React.useState([]);
  const [filterList, setFilterList] = React.useState({});
  const [selectedInputData, setSelectedInputData] = React.useState([]);
  const [loadingBarProgress, setLoadingBarProgress] = React.useState(0);
  const twoColors = {
    "0%": "#108ee9",
    "100%": "#87d068"
  };
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  //date object
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const monthDateYearTime =
    month + "_" + day + "_" + year + "_" + hour + minute;

  const showModal = (record) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const month_dict = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
  };

  const AvailableDict = {
    DistributionInUsa: "State",
    NativeStatus: "Native Status",
    MaximumMatureHeightFt: "Maximum Mature Height (ft)",
    SoilTexture: "Soil Texture",
    SunExposure: "Sun Exposure",
    SoilMoisture: "Soil Moisture",
    SaltTolerance: "Salt Tolerance",
    SoilPHMin: "Soil pH Min",
    SoilPHMax: "Soil pH Max",
    Level3Ecoregions: "Level 3 Ecoregions",
    BenefitsToPollinators: "Benefits to Pollinators",
    BloomPeriodEarliest: "Bloom Period",
    GrowthSimplified: "Growth Form",
    QuickGermination: "Quick Germination",
    Toxicity: "Grazing Toxicity",
    SeedCommercialAvailability: "Seed Commercial Availability",
    RelativePrice: "Relative Price",
    All: "Total Available Results"
  };

  const data = results;

  const columns = [
    {
      title: "Scientific Name",
      dataIndex: "scientificName",
      key: "scientificName",
      width: 120
    },
    {
      title: "Accepted Symbol",
      dataIndex: "acceptedSymbol",
      key: "acceptedSymbol",
      width: 120,
      field: "acceptedSymbol",
      render: (rowData) => (
        <a
          href={`https://plants.usda.gov/home/plantProfile?symbol=${rowData}`}
          target="_blank"
        >
          {rowData}
        </a>
      )
    },
    {
      title: "Common Name",
      dataIndex: "commonName",
      key: "commonName",
      width: 120
    },
    {
      title: "Growth Habits",
      dataIndex: "growthHabit",
      key: "growthHabit",
      width: 120
    },
    {
      title: "Native Status",
      dataIndex: "nativeStatusL48",
      key: "nativeStatusL48",
      width: 120
    },
    {
      title: "Mature Height",
      dataIndex: "maximumMatureHeightFt",
      key: "maximumMatureHeightFt",
      width: 120,
      render: (value) => (value === 0.0 ? "Unspecified" : value.toFixed(1))
    },
    {
      title: "Seed Commercial Availability",
      dataIndex: "seedCommercialAvailability",
      key: "seedCommercialAvailability",
      width: 120
    }
  ];

  const CSV = [
    "seedId",
    "scientificName",
    "acceptedSymbol",
    "commonName",
    "duration",
    "growthHabit",
    "nativeStatusL48",
    "flowerColor",
    "flowerConspicious",
    "bloomPeriod",
    "bloomPeriodEarliest",
    "bloomPeriodLatest",
    "sunExposure",
    "distributionInUsa",
    "soilTexture",
    "moistureUse",
    "soilPH",
    "soilPHMin",
    "soilPHMax",
    "palatableBrowseAnimal",
    "palatableGrazeAnimal",
    "saltTolerance",
    "benefitsToPollinators",
    "matureHeightFt",
    "resproutAbility",
    "droughtTolerance",
    "fireTolerance",
    "pollinatorValue",
    "pollinators",
    "propagation",
    "seedCommercialAvailability",
    "relativePrice"
  ];

  const handleCSVData = () => {};

  const handlePDF = async () => {
    const progressBar = document.getElementById("progressBar");
    progressBar.style.display = "block";
    setLoadingBarProgress(0);
    try {
      setLoadingBarProgress(20);
      const pdfBlob = await pdf(
        <SeedResultsPdf
          AvailableDict={AvailableDict}
          axios_post_body_data={axios_post_body_data}
          selected_state={selected_state}
          initial_inputs={initial_inputs}
          countDict={countDict}
        />
      ).toBlob();
      setLoadingBarProgress(70);
      //create local date time for file name , format = month_date_year_time (eg 08_12_2021_1220)
      let dateObj = new Date();
      let month = dateObj.getMonth() + 1; //months from 1-12
      let day = dateObj.getDate();
      let year = dateObj.getFullYear();
      let hour = dateObj.getHours();
      let minute = dateObj.getMinutes();
      let mdyt = month + "_" + day + "_" + year + "_" + hour + minute;
      setLoadingBarProgress(80);

      // Blob creation was successful; proceed to download
      saveAs(pdfBlob, "SeedInputs_" + mdyt + ".pdf");
      setLoadingBarProgress(100);
      progressBar.style.display = "none";
    } catch (error) {
      // Handle any errors that occur during blob creation
      console.error("Error creating the PDF blob:", error);
    }
  };

  const axios_post_body_data = {
    DistributionInUsa: selected_state ? String(selected_state) : null,
    NativeStatus:
      initial_inputs.native_preference.toString().length > 0
        ? initial_inputs.native_preference.toString()
        : null,
    MaximumMatureHeightFt: initial_inputs.panel_height
      ? parseFloat(initial_inputs.panel_height)
      : null,
    SoilTexture:
      planting_zone_filters.soil_types.toString().length > 0
        ? planting_zone_filters.soil_types.toString()
        : null,
    SunExposure:
      planting_zone_filters.light_exposure.toString().length > 0
        ? planting_zone_filters.light_exposure.toString()
        : null,
    SoilMoisture:
      planting_zone_filters.soil_moisture.toString().length > 0
        ? planting_zone_filters.soil_moisture.toString()
        : null,
    SaltTolerance:
      planting_zone_filters.soil_tolerance.toString().length > 0
        ? planting_zone_filters.soil_tolerance.toString()
        : null,
    SoilPHMin: planting_zone_filters.soil_pH[0]
      ? String(planting_zone_filters.soil_pH[0])
      : null,
    SoilPHMax: planting_zone_filters.soil_pH[1]
      ? String(planting_zone_filters.soil_pH[1])
      : null,
    Level3Ecoregions:
      ecoRegions.toString().length > 0
        ? String(
            ecoRegions.map((item) => item.replace(/(\d+)\s+(\w+)/, "$1 $2"))
          )
        : null,
    BenefitsToPollinators:
      refinements.pollinator_benefits.toString().length > 0
        ? String(refinements.pollinator_benefits)
        : null,
    GrowthSimplified:
      refinements.growth_form.toString().length > 0
        ? String(refinements.growth_form)
        : null,
    BloomPeriodEarliest:
      refinements.bloom_period.toString().length > 0
        ? refinements.bloom_period[0].toString()
        : null,
    BloomPeriodLatest:
      refinements.bloom_period.toString().length > 0
        ? refinements.bloom_period[1].toString()
        : null,
    RelativePrice:
      refinements.relative_price.toString().length > 0
        ? String(refinements.relative_price)
        : null,
    SeedCommercialAvailability:
      initial_inputs.seed_commercial_availability.toString().length > 0
        ? String(initial_inputs.seed_commercial_availability)
        : null,
    Toxicity:
      refinements.grazing_toxicity.toString().length > 0
        ? String(refinements.grazing_toxicity)
        : null
    //QuickGermination: refinements.quick_germination.toString().length > 0 ? String(refinements.quick_germination) : null,
    //PalatableBrowseAnimal: String(additional_refinements.grazing_palatability), //need to check
    //PalatableGrazeAnimal: String(additional_refinements.grazing_tolerance), //need to check
    //need to add mowing tolerance
  };
  useEffect(() => {
    setLoading(true);
    setError(false);
    setCountDict({});
    //console.log("selected_state", selected_state);
    //console.log("axios_post_body_data", axios_post_body_data);
    api
      .post("/SeedDatums", axios_post_body_data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then((res) => {
        var table_data = res.data.res;
        var count_dict = res.data.count_dict;
        //console.log(table_data.length, typeof table_data);
        //console.log("count_dict", count_dict);
        //console.log("results", res.data.res,table_data);
        if (table_data.length > 0) {
          setResults(res.data.res);
          setTableData(res.data.res);
          setLoading(false);
          setCountDict(count_dict);
          setFilterResults(res.data.res);
        } else {
          //console.log("no results");
          setResults([]);
          setTableData([]);
          setLoading(false);
          setCountDict(count_dict);
        }
      })
      .catch((err) => {
        //console.log("error", err);
        setError(true);
        //setLoading(false);
      });
  }, []);

  useEffect(() => {
    let csv_data = results.map((row) => {
      let csv_row = {};
      CSV.forEach((column) => {
        csv_row[column] = row[column];
      });
      return csv_row;
    });
    setCsvData(csv_data);
  }, [results]);

  useEffect(() => {
    console.log("ecoRegions", ecoRegions);
  }, []);

  // useEffect(() => {
  //   console.log('isModalVisible', isModalVisible)
  // }
  // , [isModalVisible])
  return (
    <React.Fragment>
      {
        <div className="d-flex">
          <div style={{ marginRight: "1rem" }}>
            <CSVLink
              data={csvData}
              headers={CSV}
              asyncOnClick={true}
              onClick={() => {
                handleCSVData();
                return true;
              }}
              //keep the filename as SeedResults_{MonthDateYearTime}.csv --> eg SeedResults_0812231220.csv
              filename={"SeedResults_" + monthDateYearTime + ".csv"}
            >
              <button className="btn btn-primary " onClick={() => handlePDF()}>
                Download Results
              </button>
            </CSVLink>
          </div>
          <div
            id="progressBar"
            style={{
              display: "none"
            }}
          >
            <Progress
              percent={loadingBarProgress}
              strokeColor={twoColors}
              type="circle"
              size={10}
              showInfo={false}
            />
          </div>
        </div>
      }
      <div className="container-fluid seed-results justify-content-around row">
        <div className="col-md-3">
          <>
            <div className="d-flex justify-content-center">
              <Typography variant="h7" gutterBottom>
                <strong>Project Information</strong>
              </Typography>
            </div>
            <Table
              loading={loading}
              dataSource={[
                {
                  key: "project_name",
                  parameter: "Project Name",
                  value: initial_inputs.organization_name,
                  render: (value) => (value ? value : "N/A")
                },
                {
                  key: "project_location",
                  parameter: "Project Location",
                  value: selected_state,
                  render: (value) => (value ? value : "N/A")
                },
                {
                  key: "project_planting_area",
                  parameter: "Project Planting Area",
                  value: initial_inputs.planting_zones,
                  render: (value) => (value ? value : "N/A")
                }
              ]}
              columns={[
                {
                  title: "Parameter",
                  dataIndex: "parameter",
                  key: "parameter"
                },
                {
                  title: "Value",
                  dataIndex: "value",
                  key: "value"
                }
              ]}
              pagination={false}
            />
          </>
        </div>
        <div className="col-md-7">
          <>
            <div className="d-flex justify-content-center">
              <Typography variant="h7" gutterBottom>
                <strong>Selected Inputs</strong>
              </Typography>
            </div>
            <Table
              loading={loading}
              dataSource={Object.keys(countDict).map((key, index) => ({
                key: index,
                parameter: AvailableDict[key],
                value: axios_post_body_data[key],
                count: countDict[key]
              }))}
              columns={[
                {
                  title: "Parameter",
                  dataIndex: "parameter",
                  key: "parameter"
                },
                {
                  title: "Selected Value(s)",
                  dataIndex: "value",
                  key: "value",
                  render: (value, record) => {
                    switch (record.parameter) {
                      case "Seed Commercial Availability":
                        // Split the value by commas and map each part to a modified value
                        const seedAvailabilityValues = value
                          .split(",")
                          .map((part) => {
                            switch (part.trim()) {
                              case "very_common":
                                return "Very Common";
                              case "very_rare":
                                return "Very Rare";
                              case "very_rare_unavailable":
                                return "Very Rare/Unavailable";
                              default:
                                return part.trim();
                            }
                          });
                        return seedAvailabilityValues.join(", ");

                      case "Sun Exposure":
                        // Split the value by commas and map each part to a modified value
                        const sunExposureValues = value
                          .split(",")
                          .map((part) => {
                            switch (part.trim()) {
                              case "pshade":
                                return "Part Shade";
                              default:
                                return part.trim();
                            }
                          });
                        return sunExposureValues.join(", ");

                      //Growth Form
                      case "Growth Form":
                        // Split the value by commas and map each part to a modified value
                        //
                        const growthValues = value.split(",").map((part) => {
                          switch (part.trim()) {
                            case "forb":
                              return "Forb/Herb";
                            case "shrub":
                              return "Shrub";
                            case "tree":
                              return "Tree";
                            case "grass":
                              return "Grass/Graminoid";
                            default:
                              return part.trim();
                          }
                        });
                        return growthValues.join(", ");

                      case "Benefits to Pollinators":
                        //larval, nesting, adult
                        // Split the value by commas and map each part to a modified value
                        const pollinatorValues = value
                          .split(",")
                          .map((part) => {
                            switch (part.trim()) {
                              case "larval":
                                return "Larval Food";
                              case "nesting":
                                return "Nesting and Structure (Bees)";
                              case "adult":
                                return "Adult Food";
                              default:
                                return part.trim();
                            }
                          });
                        return pollinatorValues.join(", ");

                      default:
                        return value; // Return the original value for other parameters
                    }
                  }
                },
                {
                  title: "Available Count",
                  dataIndex: "count",
                  key: "count",
                  render: (count, record) => (
                    <span style={{ color: count > 0 ? "green" : "red" }}>
                      {count > 0
                        ? count
                        : count + " (Please modify this input)"}
                    </span>
                  )
                }
              ]}
              pagination={false}
            />
          </>
        </div>
        <div className="col-md-2 seed__map__container">
          <div className="d-flex flex-column justify-content-center mt-4 seed__map">
            <div className="m-4">
              <Typography variant="h7" gutterBottom>
                <strong>Need seed or plant material?</strong> Visit the
                Rights-of-Way as Habitat Working Group’s Interactive Seed Map to
                identify a plant material vendor near you!
              </Typography>
            </div>
            <div className="m-4">
              <a
                href="https://rightofway.erc.uic.edu/resources/seed-expert-map/"
                target="_blank"
              >
                <img src={seedMap} alt="seedMap" style={{ width: "100%" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <Typography variant="h7" gutterBottom>
          <strong>Seed Results</strong>
          <br />
        </Typography>
      </div>
      <span
        style={{
          marginLeft: 0,
          fontWeight: 500,
          fontStyle: "italic",
          fontSize: "15px",
          color: "darkblue"
        }}
      >
        Click row to popup detailed information
      </span>
      <Table
        className="mt-4 results__table"
        columns={columns}
        dataSource={results.map((row) => ({
          ...row,
          key: row.seedId
        }))}
        loading={loading}
        error={error}
        defaultPageSize={10}
        pageSizeOptions={[10, 20, 50, 100]}
        showPaginationBottom={true}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              showModal(record);
            }
          };
        }}
        scroll={{
          x: "max-content",
          y: "max-content"
        }}
        rowKey="seedId"
      />
      {isModalVisible && (
        <SeedTableModal record={selectedRecord} onClose={hideModal} />
      )}
    </React.Fragment>
  );
}
export default Results;
