import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { seed_selection_actions } from '../../../store/seed_selection_slice';
import questionMark from "../../../img/sm-red-question-mark.png";
import { useEffect } from 'react';
import {
    Space
} from 'antd';


function SeedAdditionalRefinements() {
    //constants used for the component

    const [checked, setChecked] = React.useState([false, false, false]);

    const additional_refinements_default_values = {
        quick_germination: [],
        grazing_palatability: "",
        grazing_tolerance: "",
        mowing_tolerance: ""
    };


    const [additional_refinements_values, setAdditionalRefinementsValues] = React.useState(additional_refinements_default_values)

    //redux
    const dispatch = useDispatch();
    const additional_refinements_store = useSelector(state => state.seed_selection.additional_refinements);
    const additional_refinements_checkboxes_store = useSelector(state => state.seed_selection.additional_refinements_checkboxes);

    const handleValueChange = (e) => {
        //console.log('input value inside refinements component',e.target.name , e.target.value);
        const { name, value } = e.target;
        if (Array.isArray(additional_refinements_values[name])) {
            if(!additional_refinements_values[name].includes(value)){
                setAdditionalRefinementsValues({ ...additional_refinements_values, [name]: [...additional_refinements_values[name], value] });
                dispatch(seed_selection_actions.update_additional_refinements_checkboxes({ name, value }));
            }
            else{
                setAdditionalRefinementsValues({ ...additional_refinements_values, [name]: additional_refinements_values[name].filter(item => item !== value) });
                dispatch(seed_selection_actions.update_additional_refinements_checkboxes({ name, value }));
            }
        }
        else {
            setAdditionalRefinementsValues({ ...additional_refinements_values, [name]: value });
        }
        //send a dispatch to the reducer to update the initial inputs
    }

    const handleCheckboxChange = (props) => {
        const arr_value = props.target.arr_value;
        //check if the arr_value exists in the array store
        if (additional_refinements_store.arr_name.includes(arr_value)) {
            return true;
        }
        else {
            return false;
        }

    }

    useEffect(() => {
        dispatch(seed_selection_actions.set_additional_refinements(additional_refinements_values));
    }, [additional_refinements_values]);

    useEffect(() => {
        setAdditionalRefinementsValues(additional_refinements_store);
    },[]);


    return (
        <React.Fragment>
            <strong><span style={{ fontSize: '30px', color: 'red' }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; * </span>Hover over the words for descriptions</strong>
            <div className='container-fluid main-container black-border'>
                <Space direction="vertical" size={10}>
                <div className='row'>
                    <div className="col-2">
                        <Typography variant="h6" gutterBottom>
                            Quick Germination:
                        </Typography>
                    </div>
                    <div className='col-10 d-flex justify-content-lg-start'>
                            <Space>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={additional_refinements_checkboxes_store.spring}
                                        onChange={e => handleValueChange(e)}
                                        name="quick_germination"
                                        value="spring"
                                        color="primary"
                                    />
                                }
                                label="Spring"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={additional_refinements_checkboxes_store.summer}
                                        onChange={e => handleValueChange(e)}
                                        name="quick_germination"
                                        value="summer"
                                        color="primary"
                                    />
                                }
                                label="Summer"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={additional_refinements_checkboxes_store.fall}
                                        onChange={e => handleValueChange(e)}
                                        name="quick_germination"
                                        value="fall"
                                        color="primary"
                                    />
                                }
                                label="Fall"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={additional_refinements_checkboxes_store.winter}
                                        onChange={e => handleValueChange(e)}
                                        name="quick_germination"
                                        value="winter"
                                        color="primary"
                                    />
                                }
                                label="Winter"
                            />
                            </Space>
                    </div>
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {/* fourcheckboxes (spring, summer, fall, winter) */}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '10' }}>
                    <Typography variant="h6" gutterBottom>
                        Grazing Palatability:&nbsp;&nbsp;
                    </Typography>
                    <select
                    defaultValue={(additional_refinements_store&&additional_refinements_store.grazing_palatability)?additional_refinements_store.grazing_palatability:""}
                    style={{ margin: "5px", width: "100px", height: "30px" }}>
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'grazing_palatability', value: "High" } })}>High</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'grazing_palatability', value: "Medium" } })}>Medium</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'grazing_palatability', value: "Low" } })}>Low</option>
                    </select>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '3' }}>
                    <Typography variant="h6" gutterBottom>
                        Grazing Tolerance:&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <select
                    defaultValue={(additional_refinements_store&&additional_refinements_store.grazing_tolerance)?additional_refinements_store.grazing_tolerance:""}
                    style={{ margin: "5px", width: "100px", height: "30px" }}>
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'grazing_tolerance', value: "High" } })}>High</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'grazing_tolerance', value: "Medium" } })}>Medium</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'grazing_tolerance', value: "Low" } })}>Low</option>
                    </select>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Typography variant="h6" gutterBottom>
                        Mowing Tolerance:&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <select
                    defaultValue={(additional_refinements_store&&additional_refinements_store.mowing_tolerance)?additional_refinements_store.mowing_tolerance:""}
                    style={{ margin: "5px", width: "100px", height: "30px" }} >
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'mowing_tolerance', value: "High" } })}>High</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'mowing_tolerance', value: "Medium" } })}>Medium</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'mowing_tolerance', value: "Low" } })}>Low</option>
                    </select>
                </Box>
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Typography variant="h6" gutterBottom>
                        Resprout Ability:&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <select style={{ margin: "5px", width: "100px", height: "30px" }} >
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'resprout_ability', value: "Yes" } })}>Yes</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'resprout_ability', value: "No" } })}>No</option>
                    </select>
                </Box> */}
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Typography variant="h6" gutterBottom>
                        Fire Tolerance:&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <select style={{ margin: "5px", width: "100px", height: "30px" }} >
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'fire_tolerance', value: "None" } })}>None</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'fire_tolerance', value: "High" } })}>High</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'fire_tolerance', value: "Medium" } })}>Medium</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'fire_tolerance', value: "Low" } })}>Low</option>
                    </select>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Typography variant="h6" gutterBottom>
                        Drought Tolerance:&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <select style={{ margin: "5px", width: "100px", height: "30px" }} >
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'drought_tolerance', value: "None" } })}>None</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'drought_tolerance', value: "High" } })}>High</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'drought_tolerance', value: "Medium" } })}>Medium</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'drought_tolerance', value: "Low" } })}>Low</option>
                    </select>
                </Box> */}
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <Typography variant="h6" gutterBottom>
                        Propogation Methods:&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <select style={{ margin: "5px", width: "100px", height: "30px" }} >
                        <option />
                        <option onClick={(e) => handleValueChange({ target: { name: 'propogation_methods', value: "None" } })}>Seed</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'propogation_methods', value: "Container" } })}>Container</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'propogation_methods', value: "Bare Root" } })}>Bare Root</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'propogation_methods', value: "Sprig" } })}>Sprig</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'propogation_methods', value: "Cutting" } })}>Cutting</option>
                        <option onClick={(e) => handleValueChange({ target: { name: 'propogation_methods', value: "Com" } })}>Com</option>
                    </select>
                </Box> */}
                </Space>
            </div>

        </React.Fragment>
    )
}

export default SeedAdditionalRefinements