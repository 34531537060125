import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Spin, Checkbox } from "antd";
import { Space, Typography } from "antd";
import GraphWrapper from "./graphs/TotalProjectCost";
import GraphNPVWrapper from "./graphs/TotalProjectCostNPV";
import BarGraphInstallationCostWrapper from "./graphs/InstallationCost";
import BarGraphCapOpxCostWrapper from "./graphs/CapOpx";
import BarGraphBenefitsCountWrapper from "./graphs/BenefitsCount";
import GenerateCSV from "./csv/GenerateCSV";
import { pdf } from "@react-pdf/renderer";
import GeneratePdf from "./pdf/GeneratePdf";
import { saveAs } from "file-saver";
import { getGraphRef } from "./graphs/TotalProjectCost";
import html2canvas from "html2canvas";
import { Table, Switch, Progress } from "antd";
import { cost_benefit_actions } from "../../../store/cost_benefit_slice";
import CostDetailedSummary from "./CostDetailedSummary";
import CostYearly from "./CostYearly";
import calculateCostDetailedSummary from "./utils/calculateCostDetailedSummary";
import { cost_detailed_summmary_array_default_values } from "./utils/CostDefaultValues";
import { cost_detailed_summmary_buffer_default_values } from "./utils/CostDefaultValues";
import { cost_detailed_summary_total_default_values } from "./utils/CostDefaultValues";
import LoadingBar from "react-top-loading-bar";

const { Title } = Typography;

function CostSummary() {
  //redux functions

  const dispatch = useDispatch();
  const options_store = useSelector(
    (state) => state.cost_benefit_slice.selected_options
  );
  const cost_totals_store = useSelector(
    (state) => state.cost_benefit_slice.cost_total_values
  );

  const cost_input_array = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs
  );

  const cost_input_buffer = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs_buffer
  );

  const cost_information_store = useSelector(
    (state) => state.cost_benefit_slice.cost_information
  );

  const total_npv_cost = useSelector(
    (state) => state.cost_benefit_slice.net_present_value
  );

  const cost_results_page_render = useSelector(
    (state) => state.cost_benefit_slice.cost_results_page_render
  );

  const yearly_cost_rendered = useSelector(
    (state) => state.cost_benefit_slice.yearly_cost_rendered
  );

  const [calculationFlag, setCalculationFlag] = React.useState(false);

  const [benefitsCount, setBenefitsCount] = React.useState({
    1: 0,
    2: 0,
    3: 0
  });

  //initialize graphs
  const InstallationCostGraph = <BarGraphInstallationCostWrapper />;
  const CapOpxCostGraph = <BarGraphCapOpxCostWrapper />;
  const TotalProjectCostGraph = <GraphWrapper />;
  const TotalProjectCostNPVGraph = <GraphNPVWrapper />;

  //graph ref values
  const totalProjectCostGraphRef = useSelector(
    (state) => state.cost_benefit_slice.totalProjectCostGraph
  );

  const totalProjectCostNPVGraphRef = useSelector(
    (state) => state.cost_benefit_slice.totalProjectCostNPVGraph
  );

  const installationCostGraphRef = useSelector(
    (state) => state.cost_benefit_slice.installationCostGraph
  );

  const capOpxCostGraphRef = useSelector(
    (state) => state.cost_benefit_slice.capOpxCostGraph
  );

  const cost_summary_default_values = {
    1: {
      option_name: "",
      array_cover: "",
      buffer_cover: "",
      installation_cost: "",
      installation_unit_cost: "",
      establishment_cost: "",
      establishment_unit_cost: "",
      capex_cost: "",
      capex_unit_cost: "",
      capex_unit_cost_year: "",
      opex_cost: "",
      opex_unit_cost: "",
      opex_unit_cost_year: "",
      total_project_cost: "",
      total_project_cost_npv: "",
      expected_npdes_closure: "",
      lowest_per_acre_installation_cost: "",
      lowest_per_acre_establishment_cost: "",
      lowest_total_project_cost: "",
      community_acceptance: "",
      biodiversity_esg_goals: "",
      pollinator_habitat_uplift: "",
      enhanced_soil: "",
      carbon_sequestration: "",
      count_of_benefits: ""
    },
    2: {
      option_name: "",
      array_cover: "",
      buffer_cover: "",
      installation_cost: "",
      installation_unit_cost: "",
      establishment_cost: "",
      establishment_unit_cost: "",
      capex_cost: "",
      capex_unit_cost: "",
      capex_unit_cost_year: "",
      opex_cost: "",
      opex_unit_cost: "",
      opex_unit_cost_year: "",
      total_project_cost: "",
      total_project_cost_npv: "",
      expected_npdes_closure: "",
      lowest_per_acre_installation_cost: "",
      lowest_per_acre_establishment_cost: "",
      lowest_total_project_cost: "",
      community_acceptance: "",
      biodiversity_esg_goals: "",
      pollinator_habitat_uplift: "",
      enhanced_soil: "",
      carbon_sequestration: "",
      count_of_benefits: ""
    },
    3: {
      option_name: "",
      array_cover: "",
      buffer_cover: "",
      installation_cost: "",
      installation_unit_cost: "",
      establishment_cost: "",
      establishment_unit_cost: "",
      capex_cost: "",
      capex_unit_cost: "",
      capex_unit_cost_year: "",
      opex_cost: "",
      opex_unit_cost: "",
      opex_unit_cost_year: "",
      total_project_cost: "",
      total_project_cost_npv: "",
      expected_npdes_closure: "",
      lowest_per_acre_installation_cost: "",
      lowest_per_acre_establishment_cost: "",
      lowest_total_project_cost: "",
      community_acceptance: "",
      biodiversity_esg_goals: "",
      pollinator_habitat_uplift: "",
      enhanced_soil: "",
      carbon_sequestration: "",
      count_of_benefits: ""
    }
  };

  const [cost_summary_values, setCostSummaryValues] = React.useState(
    cost_summary_default_values
  );

  const [loading, setLoading] = React.useState(true);

  const [loadingBarProgress, setLoadingBarProgress] = React.useState(0);
  const twoColors = {
    "0%": "#108ee9",
    "100%": "#87d068"
  };

  //functions
  const convertToCurrency = (value) => {
    let convertedString = value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
    convertedString = convertedString.split(".")[0];
    return convertedString;
  };

  const handleCostSummaryValues = (cost_detailed_summary_total_values) => {
    /*
      set the cost summary values for each option as

      array_cover --> cost_input_array[index].array_size
      buffer_cover --> parseInt(cost_input_buffer[index].array_size)
      installation_cost --> cost_totals_store[index + 1].array.total_cost_installation_phase + cost_totals_store[index + 1].buffer.total_cost_installation_phase
      installation_unit_cost --> (cost_totals_store[index + 1].array.total_cost_installation_phase + cost_totals_store[index + 1].buffer.total_cost_installation_phase) / (cost_input_array[index].array_size + parseInt(cost_input_buffer[index].array_size))
      establishment_cost --> cost_totals_store[index + 1].array.total_array_establishment_cost + cost_totals_store[index + 1].buffer.total_buffer_establishment_cost
      establishment_unit_cost --> (cost_totals_store[index + 1].array.total_array_establishment_cost + cost_totals_store[index + 1].buffer.total_buffer_establishment_cost) / (cost_input_array[index].array_size + parseInt(cost_input_buffer[index].array_size))
      capex_cost --> (cost_totals_store[index + 1].array.total_cost_installation_phase + cost_totals_store[index + 1].buffer.total_cost_installation_phase) + (cost_totals_store[index + 1].array.total_array_establishment_cost + cost_totals_store[index + 1].buffer.total_buffer_establishment_cost)
      capex_unit_cost --> ((cost_totals_store[index + 1].array.total_cost_installation_phase + cost_totals_store[index + 1].buffer.total_cost_installation_phase) + (cost_totals_store[index + 1].array.total_array_establishment_cost + cost_totals_store[index + 1].buffer.total_buffer_establishment_cost)) / (cost_input_array[index].array_size + parseInt(cost_input_buffer[index].array_size))
      capex_unit_cost_year --> ((cost_totals_store[index + 1].array.total_cost_installation_phase + cost_totals_store[index + 1].buffer.total_cost_installation_phase) + (cost_totals_store[index + 1].array.total_array_establishment_cost + cost_totals_store[index + 1].buffer.total_buffer_establishment_cost)) / (cost_input_array[index].array_size + parseInt(cost_input_buffer[index].array_size)) / cost_information_store.establishment_phase
      opex_cost --> cost_totals_store[index + 1].array.total_maintenance_cost + cost_totals_store[index + 1].buffer.total_maintenance_cost
      opex_unit_cost --> (cost_totals_store[index + 1].array.total_maintenance_cost + cost_totals_store[index + 1].buffer.total_maintenance_cost) / (cost_input_array[index].array_size + parseInt(cost_input_buffer[index].array_size))
      opex_unit_cost_year --> (cost_totals_store[index + 1].array.total_maintenance_cost + cost_totals_store[index + 1].buffer.total_maintenance_cost) / (cost_input_array[index].array_size + parseInt(cost_input_buffer[index].array_size)) / cost_information_store.maintenance_phase
      total_project_cost --> (cost_totals_store[index + 1].array.total_cost_installation_phase + cost_totals_store[index + 1].buffer.total_cost_installation_phase) + (cost_totals_store[index + 1].array.total_array_establishment_cost + cost_totals_store[index + 1].buffer.total_buffer_establishment_cost) + (cost_totals_store[index + 1].array.total_maintenance_cost + cost_totals_store[index + 1].buffer.total_maintenance_cost)
      expected_npdes_closure --> if(cost_input_array[index].installed_vegetation_type == 'pre') 'X' else ''

    */

    let cost_summary_values_copy = { ...cost_summary_values };

    options_store.forEach((index) => {
      const arraySize = parseInt(cost_input_array[index].array_size);
      const bufferArraySize = parseInt(cost_input_buffer[index].array_size);
      const installationCostArray =
        cost_detailed_summary_total_values[index + 1].array
          .total_cost_installation_phase;
      const establishmentCostArray =
        cost_detailed_summary_total_values[index + 1].array
          .total_array_establishment_cost;
      const maintenanceCostArray =
        cost_detailed_summary_total_values[index + 1].array
          .total_array_maintenance_cost;

      cost_summary_values_copy = {
        ...cost_summary_values_copy,
        [index + 1]: {
          ...cost_summary_values_copy[index + 1],
          option_name: cost_input_array[index].option_name,
          array_cover: arraySize,
          buffer_cover: bufferArraySize,
          installation_cost: installationCostArray,
          installation_unit_cost: installationCostArray / arraySize,
          establishment_cost: establishmentCostArray,
          establishment_unit_cost: establishmentCostArray / arraySize,
          capex_cost: installationCostArray + establishmentCostArray,
          capex_unit_cost:
            (installationCostArray + establishmentCostArray) / arraySize,
          capex_unit_cost_year: parseInt(
            (installationCostArray + establishmentCostArray) /
              arraySize /
              cost_information_store.establishment_phase
          ),
          opex_cost: maintenanceCostArray,
          opex_unit_cost: maintenanceCostArray / arraySize,
          opex_unit_cost_year: parseInt(
            maintenanceCostArray /
              arraySize /
              cost_information_store.maintenance_phase
          ),
          total_project_cost:
            installationCostArray +
            establishmentCostArray +
            maintenanceCostArray,
          total_project_cost_npv: total_npv_cost[index + 1],
          expected_npdes_closure:
            cost_input_array[index].installed_vegetation_type === "pre"
              ? "X"
              : ""
        }
      };
    });
    setCostSummaryValues(cost_summary_values_copy);
    setCalculationFlag(true);
  };

  const handleBenefitSummaryValues = () => {
    /*
      set benefits values as
      expected_npdes_closure --> if(cost_input_array[index].installed_vegetation_type == 'pre') 'X' else ''
      lowest_per_acre_installation_cost --> if(cost_summary_values[index+1] == min(cost_summary_values[1].installation_unit_cost, cost_summary_values[2].installation_unit_cost, cost_summary_values[3].installation_unit_cost)) 'X' else ''
      lowest_per_acre_establishment_cost --> if(cost_summary_values[index+1] == min(cost_summary_values[1].establishment_unit_cost, cost_summary_values[2].establishment_unit_cost, cost_summary_values[3].establishment_unit_cost)) 'X' else ''
      lowest_total_project_cost --> if(cost_summary_values[index+1] == min(cost_summary_values[1].total_project_cost, cost_summary_values[2].total_project_cost, cost_summary_values[3].total_project_cost)) 'X' else ''

      for all the variables if the value is 'X' increase the count of benefits[index+1] by 1
      dont alter the values of the other variables in the cost_summary_values just update the above 4 variables
    */
    setCalculationFlag(false);
    let benefits_count_copy = { ...benefitsCount };

    //calculate the min values for each of the variables and set the values use parseInt for all the values, if the value is NaN set it to Number.MAX_SAFE_INTEGER
    let min_var = {
      installation_unit_cost: Math.min(
        cost_summary_values[1].installation_unit_cost !== NaN &&
          cost_summary_values[1].installation_unit_cost !== ""
          ? parseInt(cost_summary_values[1].installation_unit_cost)
          : Number.MAX_SAFE_INTEGER,
        cost_summary_values[2].installation_unit_cost !== NaN &&
          cost_summary_values[2].installation_unit_cost !== ""
          ? parseInt(cost_summary_values[2].installation_unit_cost)
          : Number.MAX_SAFE_INTEGER,
        cost_summary_values[3].installation_unit_cost !== NaN &&
          cost_summary_values[3].installation_unit_cost !== ""
          ? parseInt(cost_summary_values[3].installation_unit_cost)
          : Number.MAX_SAFE_INTEGER
      ),
      establishment_unit_cost: Math.min(
        cost_summary_values[1].establishment_unit_cost !== NaN &&
          cost_summary_values[1].establishment_unit_cost !== ""
          ? parseInt(cost_summary_values[1].establishment_unit_cost)
          : Number.MAX_SAFE_INTEGER,
        cost_summary_values[2].establishment_unit_cost !== NaN &&
          cost_summary_values[2].establishment_unit_cost !== ""
          ? parseInt(cost_summary_values[2].establishment_unit_cost)
          : Number.MAX_SAFE_INTEGER,
        cost_summary_values[3].establishment_unit_cost !== NaN &&
          cost_summary_values[3].establishment_unit_cost !== ""
          ? parseInt(cost_summary_values[3].establishment_unit_cost)
          : Number.MAX_SAFE_INTEGER
      ),
      total_project_cost: Math.min(
        cost_summary_values[1].total_project_cost !== NaN &&
          cost_summary_values[1].total_project_cost !== ""
          ? parseInt(cost_summary_values[1].total_project_cost)
          : Number.MAX_SAFE_INTEGER,
        cost_summary_values[2].total_project_cost !== NaN &&
          cost_summary_values[2].total_project_cost !== ""
          ? parseInt(cost_summary_values[2].total_project_cost)
          : Number.MAX_SAFE_INTEGER,
        cost_summary_values[3].total_project_cost !== NaN &&
          cost_summary_values[3].total_project_cost !== ""
          ? parseInt(cost_summary_values[3].total_project_cost)
          : Number.MAX_SAFE_INTEGER
      )
    };

    //set the values for the variables
    //console.log('cost_input_array', cost_input_array)
    let cost_summary_values_copy = { ...cost_summary_values };
    options_store.forEach((item, index) => {
      //set the values for the variables
      cost_summary_values_copy = {
        ...cost_summary_values_copy,
        [index + 1]: {
          ...cost_summary_values_copy[index + 1],
          expected_npdes_closure:
            cost_input_array[index].installed_vegetation_type === "Pre"
              ? "\u2713"
              : "",
          lowest_per_acre_installation_cost:
            parseInt(cost_summary_values[index + 1].installation_unit_cost) ===
            min_var.installation_unit_cost
              ? "\u2713"
              : "",
          lowest_per_acre_establishment_cost:
            parseInt(cost_summary_values[index + 1].establishment_unit_cost) ===
            min_var.establishment_unit_cost
              ? "\u2713"
              : "",
          lowest_total_project_cost:
            parseInt(cost_summary_values[index + 1].total_project_cost) ===
            min_var.total_project_cost
              ? "\u2713"
              : ""
        }
      };
    });
    setCostSummaryValues(cost_summary_values_copy);
    handleCountOfBenefits();
  };

  const handleCountOfBenefits = () => {
    //reset all the values to 0
    let benefits_count_copy = {
      1: 0,
      2: 0,
      3: 0
    };
    let checkProperties = [
      "expected_npdes_closure",
      "lowest_per_acre_installation_cost",
      "lowest_per_acre_establishment_cost",
      "lowest_total_project_cost",
      "community_acceptance",
      "biodiversity_esg_goals",
      "pollinator_habitat_uplift",
      "enhanced_soil",
      "carbon_sequestration"
    ];
    options_store.forEach((item, index) => {
      /*
        for each index , perform the following
        1. for each property in checkProperties check if the value is 'X' or 'x' if yes increase the count of benefits[index+1] by 1
      */

      checkProperties.forEach((property) => {
        if (
          String(cost_summary_values[index + 1][property]) === "\u2713" ||
          String(cost_summary_values[index + 1][property]) === "\u2713"
        ) {
          benefits_count_copy = {
            ...benefits_count_copy,
            [index + 1]: benefits_count_copy[index + 1] + 1
          };
        }
      });
    });
    setBenefitsCount(benefits_count_copy);
    //setLoading(false);
  };

  const handleBenefitCalculationCheckbox = (e, index) => {
    let cost_summary_values_copy = { ...cost_summary_values };
    cost_summary_values_copy = {
      ...cost_summary_values_copy,
      [index]: {
        ...cost_summary_values_copy[index],
        [e.target.name]: e.target.checked ? "\u2713" : ""
      }
    };
    setCostSummaryValues(cost_summary_values_copy);
    setCalculationFlag(true);
    //handleCountOfBenefits();
  };

  const handleBenefitCalculation = (e, index) => {
    let cost_summary_values_copy = { ...cost_summary_values };
    cost_summary_values_copy = {
      ...cost_summary_values_copy,
      [index]: {
        ...cost_summary_values_copy[index],
        [e.target.name]: e.target.value
      }
    };
    setCostSummaryValues(cost_summary_values_copy);
    setCalculationFlag(true);
    //handleCountOfBenefits();
  };

  const handlePDF = async () => {
    const progressBarContainer = document.getElementById("progressBar");
    progressBarContainer.style.display = "block";
    setLoadingBarProgress(0);
    //set loading bar
    setLoadingBarProgress(10);
    const totalProjectGraphContainer = document.getElementById(
      "graphTotalProjectCost"
    );
    const totalProjectCanvas = await html2canvas(totalProjectGraphContainer);

    // Convert the canvas to a blob
    const totalProjectCostBlob = await new Promise((resolve) => {
      totalProjectCanvas.toBlob((blob) => {
        resolve(blob);
      });
    });
    setLoadingBarProgress(20);
    const totalProjectCostUrl = await html2canvas(
      totalProjectGraphContainer
    ).then(function (canvas) {
      let graphImageTotalProjectCost = canvas.toDataURL();
      return graphImageTotalProjectCost;
    });

    // const graphTotalProjectCostNPVContainer = document.getElementById(
    //   "graphTotalProjectCostNPV"
    // );
    // const graphTotalProjectCostNPVCanvas = await html2canvas(
    //   graphTotalProjectCostNPVContainer
    // );

    // // Convert the canvas to a blob
    // const graphTotalProjectCostNPVBlob = await new Promise((resolve) => {
    //   graphTotalProjectCostNPVCanvas.toBlob((blob) => {
    //     resolve(blob);
    //   });
    // });

    // const graphTotalProjectCostNPVUrl = await html2canvas(
    //   graphTotalProjectCostNPVContainer
    // ).then(function (canvas) {
    //   let graphImageTotalProjectCost = canvas.toDataURL();
    //   return graphImageTotalProjectCost;
    // });

    const graphBenefitCountContainer =
      document.getElementById("graphBenefitCount");

    const graphBenefitCountCanvas = await html2canvas(
      graphBenefitCountContainer
    );

    // Convert the canvas to a blob
    const graphBenefitCountBlob = await new Promise((resolve) => {
      graphBenefitCountCanvas.toBlob((blob) => {
        resolve(blob);
      });
    });

    setLoadingBarProgress(30);

    const graphBenefitCountUrl = await html2canvas(
      graphBenefitCountContainer
    ).then(function (canvas) {
      let graphImageTotalProjectCost = canvas.toDataURL();
      return graphImageTotalProjectCost;
    });

    const InstallationCostGraphContainer = document.getElementById(
      "graphInstallationCost"
    );
    const InstallationCostGraphCanvas = await html2canvas(
      InstallationCostGraphContainer
    );

    // Convert the canvas to a blob
    const InstallationCostGraphBlob = await new Promise((resolve) => {
      InstallationCostGraphCanvas.toBlob((blob) => {
        resolve(blob);
      });
    });

    const InstallationCostGraphUrl = await html2canvas(
      InstallationCostGraphContainer
    ).then(function (canvas) {
      let graphImageTotalProjectCost = canvas.toDataURL();
      return graphImageTotalProjectCost;
    });

    setLoadingBarProgress(40);

    const CapOpxCostGraphContainer = document.getElementById("graphCapOpx");
    const CapOpxCostGraphCanvas = await html2canvas(CapOpxCostGraphContainer);

    // Convert the canvas to a blob
    const CapOpxCostGraphBlob = await new Promise((resolve) => {
      CapOpxCostGraphCanvas.toBlob((blob) => {
        resolve(blob);
      });
    });

    const CapOpxCostGraphUrl = await html2canvas(CapOpxCostGraphContainer).then(
      function (canvas) {
        let graphImageTotalProjectCost = canvas.toDataURL();
        return graphImageTotalProjectCost;
      }
    );

    setLoadingBarProgress(50);

    try {
      const pdfBlob = await pdf(
        <GeneratePdf
          cost_summary_values={cost_summary_values}
          benefitsCount={benefitsCount}
          cost_input_array={cost_input_array}
          cost_input_buffer={cost_input_buffer}
          cost_information_store={cost_information_store}
          total_npv_cost={total_npv_cost}
          InstallationCostGraph={InstallationCostGraphUrl}
          CapOpxCostGraph={CapOpxCostGraphUrl}
          TotalProjectCostGraph={totalProjectCostUrl}
          BenefitCountGraph={graphBenefitCountUrl}
        />
      ).toBlob();

      setLoadingBarProgress(80);
      //create local date time for file name , format = month_date_year_time (eg 08_12_2021_1220)
      let dateObj = new Date();
      let month = dateObj.getMonth() + 1; //months from 1-12
      let day = dateObj.getDate();
      let year = dateObj.getFullYear();
      let hour = dateObj.getHours();
      let minute = dateObj.getMinutes();
      let mdyt = month + "_" + day + "_" + year + "_" + hour + minute;

      // Blob creation was successful; proceed to download
      saveAs(pdfBlob, "CostSummary" + mdyt + ".pdf");
      setLoadingBarProgress(100);
      setLoadingBarProgress(0);
      progressBarContainer.style.display = "none";
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (calculationFlag) {
      handleBenefitSummaryValues();
    }
  }, [cost_summary_values, calculationFlag]);

  useEffect(() => {
    //update redux store with the benefits count
    dispatch(cost_benefit_actions.set_benefits_count(benefitsCount));
  }, [benefitsCount]);

  useEffect(() => {
    handleCountOfBenefits();
  }, [calculationFlag]);

  useEffect(() => {
    const initComponent = async () => {
      try {
        const {
          cost_detailed_summary_total_values,
          cost_detailed_summary_array_values,
          cost_detailed_summary_buffer_values,
          complexity_multiplier_values,
          annualCost,
          cummulativeCost,
          totalNPV
        } = await calculateCostDetailedSummary(
          cost_input_array,
          cost_input_buffer,
          options_store,
          cost_information_store
        );
        //call the handleCostSummaryValues function
        handleCostSummaryValues(cost_detailed_summary_total_values);

        //send all the dispatches
        dispatch(
          cost_benefit_actions.set_cost_detailed_summary_array_values(
            cost_detailed_summary_array_values
          )
        );

        dispatch(
          cost_benefit_actions.set_cost_detailed_summary_buffer_values(
            cost_detailed_summary_buffer_values
          )
        );

        dispatch(
          cost_benefit_actions.set_cost_total_values(
            cost_detailed_summary_total_values
          )
        );

        dispatch(cost_benefit_actions.set_annual_cost_yearly(annualCost));
        dispatch(
          cost_benefit_actions.set_cummulative_cost_yearly(cummulativeCost)
        );
        dispatch(cost_benefit_actions.set_total_cost_npv(totalNPV));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    initComponent();

    //on component unmount reset the cost_resets_page_render to false
    return () => {
      dispatch(cost_benefit_actions.set_cost_results_page_render(false));
      dispatch(cost_benefit_actions.set_yearly_cost_rendered(false));
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
          {/* <div style={{ display: "none" }}>
            <CostDetailedSummary />
          </div>
          {cost_results_page_render ? (
            <div style={{ display: "none" }}>
              <CostYearly />
            </div>
          ) : null} */}
        </div>
      ) : (
        <div>
          <div>
            {/* <LoadingBarComponent progress={loadingBarProgress} /> */}
          </div>
          <div className="d-flex justify-content-start">
            <div
              style={{
                marginRight: "1rem"
              }}
            >
              {/* <GenerateCSV /> */}
            </div>
            <div className="d-flex flex-start">
              <button
                className="btn btn-primary"
                onClick={() => handlePDF()}
                style={
                  {
                    marginRight: "1rem"                 
                  }
                }
              >
                Download Results
              </button>
              <div id="progressBar"
                style={
                  {
                    display:"none"
                  }
                }
              >
                <Progress
                  percent={loadingBarProgress}
                  strokeColor={twoColors}
                  type="circle"
                  size={10}
                  showInfo={false}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Title
              level={3}
              style={{
                fontWeight: "bold"
              }}
            >
              {" "}
              Cost Analysis Results{" "}
            </Title>
          </div>

          <div className="container-fluid">
            <Table
              loading={loading}
              bordered
              size="small"
              pagination={false}
              columns={[
                {
                  title: "",
                  dataIndex: "option_name",
                  key: "option_name",
                  width: "40%"
                },
                {
                  title: "Option 1",
                  dataIndex: "option_1",
                  key: "option_1",
                  width: "20%"
                },
                {
                  title: "Option 2",
                  dataIndex: "option_2",
                  key: "option_2",
                  width: "20%"
                },
                {
                  title: "Option 3",
                  dataIndex: "option_3",
                  key: "option_3",
                  width: "20%"
                }
              ]}
              dataSource={[
                {
                  key: "1",
                  option_name: "Custom Option Name",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].option_name
                      ? cost_summary_values[1].option_name
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].option_name
                      ? cost_summary_values[2].option_name
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].option_name
                      ? cost_summary_values[3].option_name
                      : "-"
                },
                {
                  key: "2",
                  option_name: "Planted Acres",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].array_cover
                      ? cost_summary_values[1].array_cover
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].array_cover
                      ? cost_summary_values[2].array_cover
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].array_cover
                      ? cost_summary_values[3].array_cover
                      : "-"
                }
                // {
                //   key: "3",
                //   option_name: "Buffer Vegetation Acres",
                //   option_1:
                //     cost_summary_values &&
                //     cost_summary_values[1] &&
                //     cost_summary_values[1].buffer_cover
                //       ? cost_summary_values[1].buffer_cover
                //       : "",
                //   option_2:
                //     cost_summary_values &&
                //     cost_summary_values[2] &&
                //     cost_summary_values[2].buffer_cover
                //       ? cost_summary_values[2].buffer_cover
                //       : "",
                //   option_3:
                //     cost_summary_values &&
                //     cost_summary_values[3] &&
                //     cost_summary_values[3].buffer_cover
                //       ? cost_summary_values[3].buffer_cover
                //       : ""
                // }
              ]}
            />

            <div className="d-flex justify-content-center">
              <Title
                level={4}
                style={{
                  fontWeight: "bold"
                }}
              >
                {" "}
                Costs{" "}
              </Title>
            </div>

            <Table
              loading={loading}
              bordered
              size="small"
              pagination={false}
              columns={[
                {
                  title: "",
                  dataIndex: "option_name",
                  key: "option_name",
                  width: "40%"
                },
                {
                  title: "Option 1",
                  dataIndex: "option_1",
                  key: "option_1",
                  width: "20%"
                },
                {
                  title: "Option 2",
                  dataIndex: "option_2",
                  key: "option_2",
                  width: "20%"
                },
                {
                  title: "Option 3",
                  dataIndex: "option_3",
                  key: "option_3",
                  width: "20%"
                }
              ]}
              dataSource={[
                {
                  key: "1",
                  option_name: "Installation Cost",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].installation_cost
                      ? convertToCurrency(
                          cost_summary_values[1].installation_cost
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].installation_cost
                      ? convertToCurrency(
                          cost_summary_values[2].installation_cost
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].installation_cost
                      ? convertToCurrency(
                          cost_summary_values[3].installation_cost
                        )
                      : "-"
                },
                {
                  key: "2",
                  option_name: "Installation Unit Cost ($/Acre)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].installation_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[1].installation_unit_cost
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].installation_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[2].installation_unit_cost
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].installation_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[3].installation_unit_cost
                        )
                      : "-"
                },
                {
                  key: "3",
                  option_name: "Establishment Cost",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].establishment_cost
                      ? convertToCurrency(
                          cost_summary_values[1].establishment_cost
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].establishment_cost
                      ? convertToCurrency(
                          cost_summary_values[2].establishment_cost
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].establishment_cost
                      ? convertToCurrency(
                          cost_summary_values[3].establishment_cost
                        )
                      : "-"
                },
                {
                  key: "4",
                  option_name: "Establishment Unit Cost ($/Acre)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].establishment_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[1].establishment_unit_cost
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].establishment_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[2].establishment_unit_cost
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].establishment_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[3].establishment_unit_cost
                        )
                      : "-"
                },
                {
                  key: "5",
                  option_name: "CAPEX Cost (Installation + Establishment)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].capex_cost
                      ? convertToCurrency(cost_summary_values[1].capex_cost)
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].capex_cost
                      ? convertToCurrency(cost_summary_values[2].capex_cost)
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].capex_cost
                      ? convertToCurrency(cost_summary_values[3].capex_cost)
                      : "-"
                },
                {
                  key: "6",
                  option_name:
                    "CAPEX Cost (Installation + Establishment; $/Acre)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].capex_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[1].capex_unit_cost
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].capex_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[2].capex_unit_cost
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].capex_unit_cost
                      ? convertToCurrency(
                          cost_summary_values[3].capex_unit_cost
                        )
                      : "-"
                },
                {
                  key: "7",
                  option_name: "OPEX Cost (Maintenance)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].opex_cost
                      ? convertToCurrency(cost_summary_values[1].opex_cost)
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].opex_cost
                      ? convertToCurrency(cost_summary_values[2].opex_cost)
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].opex_cost
                      ? convertToCurrency(cost_summary_values[3].opex_cost)
                      : "-"
                },
                {
                  key: "8",
                  option_name: "OPEX Cost (Maintenance; $/Acre)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].opex_unit_cost
                      ? convertToCurrency(cost_summary_values[1].opex_unit_cost)
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].opex_unit_cost
                      ? convertToCurrency(cost_summary_values[2].opex_unit_cost)
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].opex_unit_cost
                      ? convertToCurrency(cost_summary_values[3].opex_unit_cost)
                      : "-"
                },
                {
                  key: "9",
                  option_name: "OPEX Cost (Maintenance; $/Acre/Year)",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].opex_unit_cost_year
                      ? convertToCurrency(
                          cost_summary_values[1].opex_unit_cost_year
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].opex_unit_cost_year
                      ? convertToCurrency(
                          cost_summary_values[2].opex_unit_cost_year
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].opex_unit_cost_year
                      ? convertToCurrency(
                          cost_summary_values[3].opex_unit_cost_year
                        )
                      : "-"
                },
                {
                  key: "10",
                  option_name: "Total Project Cost",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].total_project_cost
                      ? convertToCurrency(
                          cost_summary_values[1].total_project_cost
                        )
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].total_project_cost
                      ? convertToCurrency(
                          cost_summary_values[2].total_project_cost
                        )
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].total_project_cost
                      ? convertToCurrency(
                          cost_summary_values[3].total_project_cost
                        )
                      : "-"
                }
              ]}
            />

            <div className="d-flex justify-content-center">
              <Title
                level={4}
                style={{
                  fontWeight: "bold"
                }}
              >
                {" "}
                Benefits{" "}
              </Title>
            </div>

            {/* create the following table

              Row 1 : Expected NPDES Closure within 1 Year of Construction (X if yes) | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].expected_npdes_closure?cost_summary_values[index + 1].expected_npdes_closure: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].expected_npdes_closure?cost_summary_values[index + 1].expected_npdes_closure: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].expected_npdes_closure?cost_summary_values[index + 1].expected_npdes_closure: '-'}
              Row 2 : Lowest Install Cost (X if yes) | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_per_acre_installation_cost?cost_summary_values[index + 1].lowest_per_acre_installation_cost: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_per_acre_installation_cost?cost_summary_values[index + 1].lowest_per_acre_installation_cost: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_per_acre_installation_cost?cost_summary_values[index + 1].lowest_per_acre_installation_cost: '-'}
              Row 3 : Lowest Establishment Cost (X if yes) | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_per_acre_establishment_cost?cost_summary_values[index + 1].lowest_per_acre_establishment_cost: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_per_acre_establishment_cost?cost_summary_values[index + 1].lowest_per_acre_establishment_cost: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_per_acre_establishment_cost?cost_summary_values[index + 1].lowest_per_acre_establishment_cost: '-'}
              Row 4 : Lowest Total Project Cost (X if yes) | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_total_project_cost?cost_summary_values[index + 1].lowest_total_project_cost: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_total_project_cost?cost_summary_values[index + 1].lowest_total_project_cost: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].lowest_total_project_cost?cost_summary_values[index + 1].lowest_total_project_cost: '-'}
              Row 5 : Community Acceptance (User-Entered) | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].community_acceptance?cost_summary_values[index + 1].community_acceptance: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].community_acceptance?cost_summary_values[index + 1].community_acceptance: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].community_acceptance?cost_summary_values[index + 1].community_acceptance: '-'}
              Row 6 : Supports Biodiversity ESG Goals (User-Entered) | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].biodiversity_esg_goals?cost_summary_values[index + 1].biodiversity_esg_goals: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].biodiversity_esg_goals?cost_summary_values[index + 1].biodiversity_esg_goals: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].biodiversity_esg_goals?cost_summary_values[index + 1].biodiversity_esg_goals: '-'}
              Row 7 : Pollinator Habitat Uplift | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].pollinator_habitat_uplift?cost_summary_values[index + 1].pollinator_habitat_uplift: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].pollinator_habitat_uplift?cost_summary_values[index + 1].pollinator_habitat_uplift: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].pollinator_habitat_uplift?cost_summary_values[index + 1].pollinator_habitat_uplift: '-'}
              Row 8 : Enhanced Soil Health | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].enhanced_soil_health?cost_summary_values[index + 1].enhanced_soil_health: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].enhanced_soil_health?cost_summary_values[index + 1].enhanced_soil_health: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].enhanced_soil_health?cost_summary_values[index + 1].enhanced_soil_health: '-'}
              Row 9 : Carbon Sequestration | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].carbon_sequestration?cost_summary_values[index + 1].carbon_sequestration: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].carbon_sequestration?cost_summary_values[index + 1].carbon_sequestration: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].carbon_sequestration?cost_summary_values[index + 1].carbon_sequestration: '-'}
              Row 10 : Count of Benefits | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].count_of_benefits?cost_summary_values[index + 1].count_of_benefits: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].count_of_benefits?cost_summary_values[index + 1].count_of_benefits: '-'} | {cost_summary_values && cost_summary_values[index + 1] && cost_summary_values[index + 1].count_of_benefits?cost_summary_values[index + 1].count_of_benefits: '-'}

          */}

            <Table
              loading={loading}
              bordered
              size="small"
              pagination={false}
              columns={[
                {
                  title: "",
                  dataIndex: "option_name",
                  key: "option_name",
                  width: "40%"
                },
                {
                  title: "Option 1",
                  dataIndex: "option_1",
                  key: "option_1",
                  width: "20%"
                },
                {
                  title: "Option 2",
                  dataIndex: "option_2",
                  key: "option_2",
                  width: "20%"
                },
                {
                  title: "Option 3",
                  dataIndex: "option_3",
                  key: "option_3",
                  width: "20%"
                }
              ]}
              dataSource={[
                {
                  key: "1",
                  option_name:
                    "Expected NPDES Closure within 1 Year of Construction",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].expected_npdes_closure
                      ? cost_summary_values[1].expected_npdes_closure
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].expected_npdes_closure
                      ? cost_summary_values[2].expected_npdes_closure
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].expected_npdes_closure
                      ? cost_summary_values[3].expected_npdes_closure
                      : "-"
                },
                {
                  key: "2",
                  option_name: "Lowest Per-Acre Install Cost",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].lowest_per_acre_installation_cost
                      ? cost_summary_values[1].lowest_per_acre_installation_cost
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].lowest_per_acre_installation_cost
                      ? cost_summary_values[2].lowest_per_acre_installation_cost
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].lowest_per_acre_installation_cost
                      ? cost_summary_values[3].lowest_per_acre_installation_cost
                      : "-"
                },
                {
                  key: "3",
                  option_name: "Lowest Establishment Cost",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].lowest_per_acre_establishment_cost
                      ? cost_summary_values[1]
                          .lowest_per_acre_establishment_cost
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].lowest_per_acre_establishment_cost
                      ? cost_summary_values[2]
                          .lowest_per_acre_establishment_cost
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].lowest_per_acre_establishment_cost
                      ? cost_summary_values[3]
                          .lowest_per_acre_establishment_cost
                      : "-"
                },
                {
                  key: "4",
                  option_name: "Lowest Total Project Cost",
                  option_1:
                    cost_summary_values &&
                    cost_summary_values[1] &&
                    cost_summary_values[1].lowest_total_project_cost
                      ? cost_summary_values[1].lowest_total_project_cost
                      : "-",
                  option_2:
                    cost_summary_values &&
                    cost_summary_values[2] &&
                    cost_summary_values[2].lowest_total_project_cost
                      ? cost_summary_values[2].lowest_total_project_cost
                      : "-",
                  option_3:
                    cost_summary_values &&
                    cost_summary_values[3] &&
                    cost_summary_values[3].lowest_total_project_cost
                      ? cost_summary_values[3].lowest_total_project_cost
                      : "-"
                },
                {
                  key: "5",
                  option_name: "Community Acceptance (User-Entered)",
                  option_1: (
                    <Checkbox
                      name="community_acceptance"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[1] &&
                        cost_summary_values[1].community_acceptance === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 1);
                      }}
                    ></Checkbox>
                  ),
                  option_2: (
                    <Checkbox
                      name="community_acceptance"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[2] &&
                        cost_summary_values[2].community_acceptance === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 2);
                      }}
                    ></Checkbox>
                  ),
                  option_3: (
                    <Checkbox
                      name="community_acceptance"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[3] &&
                        cost_summary_values[3].community_acceptance === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 3);
                      }}
                    ></Checkbox>
                  )
                },
                {
                  key: "6",
                  option_name: "Supports Biodiversity ESG Goals (User-Entered)",
                  option_1: (
                    <Checkbox
                      name="biodiversity_esg_goals"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[1] &&
                        cost_summary_values[1].biodiversity_esg_goals ===
                          "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 1);
                      }}
                    ></Checkbox>
                  ),
                  option_2: (
                    <Checkbox
                      name="biodiversity_esg_goals"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[2] &&
                        cost_summary_values[2].biodiversity_esg_goals ===
                          "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 2);
                      }}
                    ></Checkbox>
                  ),
                  option_3: (
                    <Checkbox
                      name="biodiversity_esg_goals"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[3] &&
                        cost_summary_values[3].biodiversity_esg_goals ===
                          "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 3);
                      }}
                    ></Checkbox>
                  )
                },
                {
                  key: "7",
                  option_name: "Pollinator Habitat Uplift (User-Entered)",
                  option_1: (
                    <Checkbox
                      name="pollinator_habitat_uplift"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[1] &&
                        cost_summary_values[1].pollinator_habitat_uplift ===
                          "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 1);
                      }}
                    ></Checkbox>
                  ),
                  option_2: (
                    <Checkbox
                      name="pollinator_habitat_uplift"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[2] &&
                        cost_summary_values[2].pollinator_habitat_uplift ===
                          "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 2);
                      }}
                    ></Checkbox>
                  ),
                  option_3: (
                    <Checkbox
                      name="pollinator_habitat_uplift"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[3] &&
                        cost_summary_values[3].pollinator_habitat_uplift ===
                          "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 3);
                      }}
                    ></Checkbox>
                  )
                },
                {
                  key: "8",
                  option_name: "Enhanced Soil Health (User-Entered)",
                  option_1: (
                    <Checkbox
                      name="enhanced_soil"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[1] &&
                        cost_summary_values[1].enhanced_soil === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 1);
                      }}
                    ></Checkbox>
                  ),
                  option_2: (
                    <Checkbox
                      name="enhanced_soil"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[2] &&
                        cost_summary_values[2].enhanced_soil === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 2);
                      }}
                    ></Checkbox>
                  ),
                  option_3: (
                    <Checkbox
                      name="enhanced_soil"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[3] &&
                        cost_summary_values[3].enhanced_soil === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 3);
                      }}
                    ></Checkbox>
                  )
                },
                {
                  key: "9",
                  option_name: "Carbon Sequestration (User-Entered)",
                  option_1: (
                    <Checkbox
                      name="carbon_sequestration"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[1] &&
                        cost_summary_values[1].carbon_sequestration === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 1);
                      }}
                    ></Checkbox>
                  ),
                  option_2: (
                    <Checkbox
                      name="carbon_sequestration"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[2] &&
                        cost_summary_values[2].carbon_sequestration === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 2);
                      }}
                    ></Checkbox>
                  ),
                  option_3: (
                    <Checkbox
                      name="carbon_sequestration"
                      checked={
                        cost_summary_values &&
                        cost_summary_values[3] &&
                        cost_summary_values[3].carbon_sequestration === "\u2713"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        handleBenefitCalculationCheckbox(e, 3);
                      }}
                    ></Checkbox>
                  )
                },
                {
                  key: "10",
                  option_name: "Count of Benefits",
                  option_1:
                    benefitsCount && benefitsCount[1] && benefitsCount[1]
                      ? benefitsCount[1]
                      : 0,
                  option_2:
                    benefitsCount && benefitsCount[2] && benefitsCount[2]
                      ? benefitsCount[2]
                      : 0,
                  option_3:
                    benefitsCount && benefitsCount[3] && benefitsCount[3]
                      ? benefitsCount[3]
                      : 0
                }
              ]}
            />
          </div>

          <div className="container-fluid">
            <div className="row mt-4">
              <div className="d-flex justify-content-center">
                <Title
                  level={4}
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  {" "}
                  Cost Analysis Graphs{" "}
                </Title>
              </div>
              <div className="col-6" id="graphWrapper">
                <GraphWrapper />
              </div>
              <div className="col-6">
                <BarGraphBenefitsCountWrapper />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <BarGraphInstallationCostWrapper />
              </div>
              <div className="col-6">
                <BarGraphCapOpxCostWrapper />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CostSummary;
