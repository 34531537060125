import { createSlice } from "@reduxjs/toolkit";

const auth_slice = createSlice({
    name: "auth",
    initialState: {
        bearer_token: "",
    },
    reducers: {
        set_bearer_token: (state, action) => {
            //console.log('set bearer token reducer called')
            //console.log('payload in reducer',action.payload)
            state.bearer_token = action.payload;
        }
    }
}
);

export const auth_actions = auth_slice.actions;
export default auth_slice;
