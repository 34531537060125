import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import store from "../../../../store/index";
import { Provider } from "react-redux";
import html2canvas from "html2canvas";
import { cost_benefit_actions } from "../../../../store/cost_benefit_slice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "30 Year Total Project Cost",
      font: {
        size: 18
      }
    }
  },
  scales: {
    y: {
      ticks: {
        callback: function (value, index, values) {
          return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    }
  }
};

const handleGenerateData = (data) => {
  let labels = Array.from({ length: 30 }, (_, i) => i + 1);
  let datasets = [
    {
      label: "Option 1",
      data: data[1].filter((value) => value !== 0),
      borderColor: "rgb(242,112,51)",
      backgroundColor: "rgba(242, 112, 51, 0.8)"
    },
    {
      label: "Option 2",
      data: data[2].filter((value) => value !== 0),
      borderColor: "rgb(0, 85, 191)",
      backgroundColor: "rgba(0, 85, 191, 0.8)"
    },
    {
      label: "Option 3",
      data: data[3].filter((value) => value !== 0),
      borderColor: "rgb(0, 122, 45)",
      backgroundColor: "rgba(0, 122, 45, 0.8)"
    }
  ];

  return { labels, datasets };
};

function Graph() {
  const [graphData, setGraphData] = useState();
  const chartRef = useRef(null);
  const dispatch = useDispatch();

  const cummulative_cost_yearly_store = useSelector(
    (state) => state.cost_benefit_slice.cummulative_cost_yearly
  );

  const cost_results_page_render = useSelector(
    (state) => state.cost_benefit_slice.cost_results_page_render
  );

  useEffect(() => {
    let data = handleGenerateData(cummulative_cost_yearly_store);
    setGraphData(data);
  }, [cummulative_cost_yearly_store]);

  const getChartRef = () => chartRef;

  if (graphData) {
    return (
      <Line
        ref={chartRef}
        data={graphData}
        options={options}
        id="graphTotalProjectCost"
      />
    );
  } else {
    return <div>Loading...</div>;
  }
}

const GraphWrapper = () => {
  return (
    <Provider store={store}>
      <Graph />
    </Provider>
  );
};

export const getGraphRef = () => {
  return Graph.getChartRef();
};

export default GraphWrapper;
