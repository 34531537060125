import React from 'react'

function Help() {
  return (
    <>
      <div className='container-fluid'>
         <p>
            Help Component
         </p>
      </div>
    </>
  )
}

export default Help