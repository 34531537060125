import React, { useState, useEffect } from "react";
import { Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { cost_benefit_actions } from "../../../store/cost_benefit_slice";
import axios from "axios";
import moment from "moment";
import questionMark from "../../../img/sm-red-question-mark.png";
import Tooltip from "react-tooltip-lite";
import ColorLegend from "./utils/ColorLegend";
import TextField from "@mui/material/TextField";
import { Modal, Box } from "@mui/material";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../css/main.css";
import "../../../css/tools.css";
import Collapsible from "react-collapsible";

//antd imports
import { Row, Col, Button, Input, DatePicker, Menu, Select } from "antd";

//antd icon imports
import {
  CaretUpFilled,
  CaretDownFilled,
  PlusOutlined,
  DownOutlined
} from "@ant-design/icons";

const { Text, Title } = Typography;

function CostInformation() {
  const dispatch = useDispatch();
  const cost_input_values_store = useSelector(
    (state) => state.cost_benefit_slice.cost_information
  );

  //console.log('cost_input_values_store',cost_input_values_store)

  //values
  const cost_information_default_values = {
    date: null,
    project_name: "",
    project_location: "",
    user_name: "",
    notes: "",
    establishment_phase: 3,
    maintenance_phase: 22,
    assumed_increase_end_row: 40,
    assumed_increase_narrow_row: 5,
    assumed_post_construction_cost: 150,
    user_defined_vegetation_0: "",
    user_defined_vegetation_1: "",
    user_defined_vegetation_2: ""
  };

  const [cost_information_values, setCostInformationValues] = React.useState(
    cost_information_default_values
  );

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setCostInformationValues({ ...cost_information_values, [name]: value });
  };

  //useEffects to set the default values
  useEffect(() => {
    setCostInformationValues(cost_input_values_store);
  }, []);

  useEffect(() => {
    dispatch(
      cost_benefit_actions.set_cost_information(cost_information_values)
    );
  }, [cost_information_values]);

  useEffect(() => {
    //console.log("cost_input_values_store", cost_input_values_store);
  }, [cost_input_values_store]);

  // Modal disclaimer popup
  const modalStyle = {
    fontFamily: "Arial",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 600,
    border: "2px solid black",
    boxShadow: 14,
    padding: 5,
    textAlign: "center",
    background: "white"
  };
  const [displayPopUp, setDisplayPopUp] = useState(true);
  const closePopUp = () => {
    sessionStorage.setItem("seenCostPopUp", true);
    //reset redux store
    dispatch(cost_benefit_actions.reset_cost_slice());
    setDisplayPopUp(false);
  };
  useEffect(() => {
    let returningUser = sessionStorage.getItem("seenCostPopUp");
    setDisplayPopUp(!returningUser);
  }, []);

  return (
    <>
      <strong>
        Hover over the <img src={questionMark} height="20px" /> for descriptions
      </strong>{" "}
      <br /> <br />
      {displayPopUp && (
        <Modal
          open={true}
          onClose={closePopUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div>
              <h6>
                <i>
                  <strong>Disclaimer:</strong> The Cost Comparison Tool is
                  intended for use by site planners and vegetation managers
                  involved in decision making for solar project site vegetation
                  planning. <br />
                  <br />
                  Outputs of this tool are generated by the user-determined
                  inputs and limited by the selected variables included.
                  Real-world applications may be more complex and require
                  additional considerations for constraints and complexities not
                  accounted for in this tool's interface. <br />
                  <br />
                  This tool was created by the University of Illinois Chicago
                  and Stantec with support of the U.S. Department of Energy's
                  Solar Energy Technologies Office. None of the developers,
                  contributors, administrators, or anyone else connected with
                  this tool, in any way whatsoever, can be responsible for your
                  use of the information contained within or yielded from.{" "}
                  <br />
                  <br />
                </i>
              </h6>
            </div>
            <strong>
              <h6>
                <font color="Blue">
                  As a user of this tool, I accept all responsibility and
                  liability for the accuracy and application of the inputs and
                  outputs.
                </font>
              </h6>
            </strong>
            <br />
            <button onClick={closePopUp}>
              &nbsp; &nbsp; Agree and Continue &nbsp; &nbsp;{" "}
            </button>
          </Box>
        </Modal>
      )}
      <Table style={{ width: "40%" }}>
        <Tbody>
          <Tr>
            <Td>&nbsp;&nbsp;</Td>
            <Td>
              <ColorLegend />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Table className="responsive_table" style={{ width: "60%" }}>
        <Thead>
          <Tr>
            <Th
              colSpan="2"
              style={{ padding: "5px" }}
              className="light__grey__background__2"
            >
              Project Information
            </Th>
          </Tr>
        </Thead>

        <Tbody className="">
          <Tr>
            <Td style={{ width: "80%" }}>Date</Td>
            <Td style={{ width: "20%" }}>
              <DatePicker
                name="date"
                size="large"
                className="gold__accent__4"
                style={{ width: "100%" }}
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
                //defaultValue={handleDateDefault(cost_input_values_store[index].date?cost_input_values_store[index].date:null)}
              />
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>User-defined project name.</li>
                    <li>Used for reference.</li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> Project Name:
              </Tooltip>
            </Td>
            <Td>
              <TextField
                name="project_name"
                size="small"
                className="gold__accent__4"
                style={{ width: 300 }}
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
                defaultValue={
                  (cost_input_values_store &&
                    cost_input_values_store.project_name) ||
                  ""
                }
              />
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>User-defined location information.</li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> Project Location:
              </Tooltip>
            </Td>
            <Td>
              <TextField
                name="project_location"
                className="gold__accent__4"
                size="small"
                style={{ width: 300 }}
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
                defaultValue={
                  cost_input_values_store &&
                  cost_input_values_store.project_location
                    ? cost_input_values_store.project_location
                    : ""
                }
              />
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>Name of person completing the analysis.</li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> User Name:
              </Tooltip>
            </Td>
            <Td>
              <TextField
                name="user_name"
                size="small"
                className="gold__accent__4"
                style={{ width: 300 }}
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
                defaultValue={
                  cost_input_values_store && cost_input_values_store.user_name
                    ? cost_input_values_store.user_name
                    : ""
                }
              />
            </Td>
          </Tr>

          <Tr>
            <Td>
              {" "}
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>
                      Enter additional details regarding the analysis that may
                      be helpful for reference.
                    </li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> Notes:
              </Tooltip>
            </Td>
            <Td>
              <TextField
                name="notes"
                size="small"
                className="gold__accent__4"
                style={{ width: 300 }}
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
                defaultValue={
                  cost_input_values_store && cost_input_values_store.notes
                    ? cost_input_values_store.notes
                    : ""
                }
              />
            </Td>
          </Tr>
        </Tbody>
        <Thead>
          <Tr>
            <Th
              colSpan="2"
              style={{ padding: "5px" }}
              className="light__grey__background__2"
            >
              Project Duration
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td>
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>
                      The establishment phase of vegetation typically begins
                      once the desired seed is installed and germinates until
                      the point of permit closures and/or a developer's
                      contractual obligations have been met.
                    </li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> Establishment Phase
                (Years):
              </Tooltip>
            </Td>
            <Td>
              <TextField
                name="establishment_phase"
                className="gold__accent__4"
                size="small"
                style={{ width: 300 }}
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
                defaultValue={
                  cost_input_values_store &&
                  cost_input_values_store.establishment_phase
                    ? cost_input_values_store.establishment_phase
                    : 3
                }
              />
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Tooltip
                content={
                  <ul className="tip-list">
                    <li>
                      The maintenance phase begins once permits have been closed
                      and a developer's contractual obligations have been met.
                    </li>
                    <li>
                      The maintenance phase will continue throughout the
                      duration of the facility's lifespan.
                    </li>
                  </ul>
                }
              >
                <img src={questionMark} height="20px" /> Maintenance Phase
                (Years):
              </Tooltip>
            </Td>
            <Td>
              <TextField
                name="maintenance_phase"
                className="gold__accent__4"
                size="small"
                style={{ width: 300 }}
                defaultValue={
                  cost_input_values_store &&
                  cost_input_values_store.maintenance_phase
                    ? cost_input_values_store.maintenance_phase
                    : 22
                }
                placeholder=""
                onBlur={(e) => handleInputChange(e)}
              />
            </Td>
          </Tr>
        </Tbody>

        <Collapsible
          trigger=<Thead>
            <Tr>
              <Th
                colSpan="2"
                style={{ padding: "5px 140px 5px 5px" }}
                className="light__grey__background__2"
              >
                <CaretDownFilled /> Vegetation Cost Increases Assumed Due to
                Site Layout
              </Th>
            </Tr>
          </Thead>
          triggerWhenOpen=<Thead>
            <Tr>
              <Th
                colSpan="2"
                style={{ padding: "5px 140px 5px 5px" }}
                className="light__grey__background__2"
              >
                <CaretUpFilled /> Vegetation Cost Increases Assumed Due to Site
                Layout
              </Th>
            </Tr>
          </Thead>
        >
          <Tbody>
            <Tr>
              <Td>
                {" "}
                <Tooltip
                  content={
                    <ul className="tip-list">
                      <li>
                        Are there physical restrictions that prevent equipment
                        or vehicles from freely moving from one end of the array
                        row to the other side?
                      </li>
                      <li>
                        Common end row restrictions may include cabling,
                        conduit, or other above-ground infrastructure
                        intersecting vegetated rows.
                      </li>
                    </ul>
                  }
                >
                  <img src={questionMark} height="20px" /> Assumed increase for
                  end of row restrictions (%):
                </Tooltip>
              </Td>
              <Td>
                <TextField
                  name="assumed_increase_end_row"
                  className="green__accent__6__lighter__60"
                  size="small"
                  style={{ width: 300 }}
                  defaultValue={
                    cost_input_values_store &&
                    cost_input_values_store.assumed_increase_end_row
                      ? cost_input_values_store.assumed_increase_end_row
                      : 40
                  }
                  placeholder=""
                  onBlur={(e) => handleInputChange(e)}
                />
              </Td>
            </Tr>

            <Tr>
              <Td>
                <Tooltip
                  content={
                    <ul className="tip-list">
                      <li>
                        The minimum width of the vegetated row in between panels
                        as measured from the leading edge of the panel in a row
                        to the top edge of the panel in the adjacent row.
                      </li>
                      <li>
                        Narrower widths result in constrained access for
                        equipment and personnel, which can slow down the pace of
                        maintenance activities.
                      </li>
                    </ul>
                  }
                >
                  <img src={questionMark} height="20px" /> Assumed increased for
                  narrow row widths (%):
                </Tooltip>
              </Td>
              <Td>
                <TextField
                  name="assumed_increase_narrow_row"
                  size="small"
                  className="green__accent__6__lighter__60"
                  style={{ width: 300 }}
                  defaultValue={
                    cost_input_values_store &&
                    cost_input_values_store.assumed_increase_narrow_row
                      ? cost_input_values_store.assumed_increase_narrow_row
                      : 5
                  }
                  placeholder=""
                  onBlur={(e) => handleInputChange(e)}
                />
              </Td>
            </Tr>

            <Tr>
              <Td>
                <Tooltip
                  content={
                    <ul className="tip-list">
                      <li>
                        Pre-Vegetation is seeded or planted prior to the
                        installation of pilings and racking systems.
                      </li>
                      <li>
                        Post-Vegetation is seeded or planted after the piling
                        and racking systems installation.
                      </li>
                      <li>
                        Seed installed pre-construction is typically more
                        cost-effective due to lack of obstructions for equipment
                        to navigate around during installation.
                      </li>
                    </ul>
                  }
                >
                  <img src={questionMark} height="20px" /> Assumed installation
                  cost increase for post-construction seeding (%):
                </Tooltip>
              </Td>
              <Td>
                <TextField
                  name="assumed_post_construction_cost"
                  size="small"
                  className="green__accent__6__lighter__60"
                  style={{ width: 300 }}
                  defaultValue={
                    cost_input_values_store &&
                    cost_input_values_store.assumed_post_construction_cost
                      ? cost_input_values_store.assumed_post_construction_cost
                      : 150
                  }
                  placeholder=""
                  onBlur={(e) => handleInputChange(e)}
                />
              </Td>
            </Tr>
          </Tbody>
        </Collapsible>
      </Table>
    </>
  );
}

export default CostInformation;
