import calculateCostSummaryValues from "./calculateCostSummaryValues";
import complexityMultiplier from "./complexityMultiplier";
import { cost_detailed_summmary_array_default_values } from "./CostDefaultValues";
import { cost_detailed_summmary_buffer_default_values } from "./CostDefaultValues";
import { cost_detailed_summary_total_default_values } from "./CostDefaultValues";
import { complexity_multiplier_default_values } from "./CostDefaultValues";
import { annualCostDefaultValues } from "./CostDefaultValues";
import { cummulativeCostDefaultValues } from "./CostDefaultValues";
import { totalNPVDefaultValues } from "./CostDefaultValues";

async function calculateCostDetailedSummary(
  cost_input_array,
  cost_input_buffer,
  options_store,
  cost_information_store
) {
  // Initialize the detailed summary values
  let cost_detailed_summary_array_values = {
    ...cost_detailed_summmary_array_default_values
  };
  let cost_detailed_summary_buffer_values = {
    ...cost_detailed_summmary_buffer_default_values
  };
  let cost_detailed_summary_total_values = {
    ...cost_detailed_summary_total_default_values
  };
  let complexity_multiplier_values = {
    ...complexity_multiplier_default_values
  };

  // Initialize annualCost and cummulativeCost as mutable arrays
  let annualCost = {};
  let cummulativeCost = {};

  // Populate annualCost and cummulativeCost with mutable arrays based on default values
  for (let key in annualCostDefaultValues) {
    annualCost[key] = [...annualCostDefaultValues[key]];
    cummulativeCost[key] = [...cummulativeCostDefaultValues[key]];
  }

  let totalNPV = { ...totalNPVDefaultValues };

  // Helper function to handle the installation phase costs
  const handleCostInstallationPhase = async () => {
    for (let index = 0; index < options_store.length; index++) {
      const [updatedArrayValues, updatedBufferValues, updatedTotalValues] =
        await calculateCostSummaryValues(
          index,
          cost_detailed_summary_array_values,
          cost_detailed_summary_buffer_values,
          cost_detailed_summary_total_values,
          cost_input_array,
          cost_input_buffer,
          cost_information_store
        );
      // Update the summary values
      cost_detailed_summary_array_values[index + 1] =
        updatedArrayValues[index + 1];
      cost_detailed_summary_buffer_values[index + 1] =
        updatedBufferValues[index + 1];
      cost_detailed_summary_total_values[index + 1] =
        updatedTotalValues[index + 1];
    }
  };

  // Helper function to handle complexity multiplier calculations
  const handleComplexityMultiplier = () => {
    options_store.forEach((option, index) => {
      const costInput = cost_input_array[index];
      const multiplierValues = complexity_multiplier_values[index + 1];

      // Set the multiplier values based on conditions
      multiplierValues.post_con =
        costInput.installed_vegetation_type === "Post"
          ? cost_information_store.assumed_post_construction_cost
          : 0;

      multiplierValues.narrow_row =
        costInput.array_width <= 15
          ? cost_information_store.assumed_increase_narrow_row
          : 0;

      multiplierValues.end_of_row_restricted =
        costInput.row_restrictions === "Yes"
          ? cost_information_store.assumed_increase_end_row
          : 0;
    });

    options_store.forEach((option, index) => {
      const arraySize = parseInt(cost_input_array[index].array_size);
      const bufferSize = parseInt(
        cost_detailed_summary_buffer_values[index + 1].array_size
      );

      const [updatedArrayValues, updatedBufferValues, updatedTotalValues] =
        complexityMultiplier(
          index,
          complexity_multiplier_values,
          cost_detailed_summary_array_values,
          cost_detailed_summary_buffer_values,
          cost_detailed_summary_total_values,
          cost_information_store,
          arraySize,
          bufferSize
        );

      // Update the summary values
      cost_detailed_summary_array_values[index + 1] =
        updatedArrayValues[index + 1];
      cost_detailed_summary_buffer_values[index + 1] =
        updatedBufferValues[index + 1];
      cost_detailed_summary_total_values[index + 1] =
        updatedTotalValues[index + 1];
    });
  };

  const handleYearCalculation = () => {
    let establishment_period = parseInt(
      cost_information_store.establishment_phase
    );
    let maintenance_period = parseInt(cost_information_store.maintenance_phase);
    let yearLength = 30;

    options_store.map((option, index) => {
      Array.from(Array(yearLength).keys()).map((year) => {
        if (year === 0) {
          annualCost[index + 1][year] =
            cost_detailed_summary_total_values[index + 1].array
              .total_cost_installation_phase +
            cost_detailed_summary_total_values[index + 1].array
              .total_array_establishment_cost /
              establishment_period;
          cummulativeCost[index + 1][year] = annualCost[index + 1][year];
        } else if (year > 0 && year <= establishment_period) {
          annualCost[index + 1][year] =
            cost_detailed_summary_total_values[index + 1].array
              .total_array_establishment_cost / establishment_period;
          cummulativeCost[index + 1][year] =
            annualCost[index + 1][year] + cummulativeCost[index + 1][year - 1];
        } else if (
          year > establishment_period &&
          year <= establishment_period + maintenance_period - 1
        ) {
          annualCost[index + 1][year] =
            cost_detailed_summary_total_values[index + 1].array
              .total_array_maintenance_cost / maintenance_period;
          cummulativeCost[index + 1][year] =
            annualCost[index + 1][year] + cummulativeCost[index + 1][year - 1];
        } else {
          annualCost[index + 1][year] = "Out of Scope";
          cummulativeCost[index + 1][year] = 0;
        }
      });
      totalNPV = {
        ...totalNPV,
        [index + 1]:
          cummulativeCost[index + 1][
            establishment_period + maintenance_period - 1
          ]
      };
    });
  };

  // Execute the installation phase and complexity multiplier calculations
  await handleCostInstallationPhase();
  handleComplexityMultiplier();
  handleYearCalculation();

  // Return the updated summary values
  return {
    cost_detailed_summary_total_values,
    cost_detailed_summary_array_values,
    cost_detailed_summary_buffer_values,
    complexity_multiplier_values,
    annualCost,
    cummulativeCost,
    totalNPV
  };
}

export default calculateCostDetailedSummary;
