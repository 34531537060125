import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import store from '../../../../store/index'
import {Provider} from 'react-redux';
import html2canvas from 'html2canvas';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '30 Year NPV Project Cost',
    },
  },
};

const handleGenerateData = (data) => {
    /*
        data = {
            1: array of 30 values,
            2: array of 30 values,
            3: array of 30 values
        }

        set labels = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,...,30]
        set datasets = [
            {
                label: 'Option 1',
                data: all the values in data[1] excluding zero values,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Option 2',
                data: all the values in data[2] excluding zero values,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Option 3',
                data: all the values in data[3] excluding zero values,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ]
    */

    let labels = [];
    let datasets = [];
    let dataset1 = {};
    let dataset2 = {};
    let dataset3 = {};

    for (let i = 1; i <= 30; i++) {
        labels.push(i);
    }

    // convert data[1] into an array of values
    let data1 = [];
    let data2 = [];
    let data3 = [];

    Object.keys(data[1]).forEach((key) => {
        data1.push(data[1][key]);
        data2.push(data[2][key]);
        data3.push(data[3][key]);
    });

    dataset1 = {
        label: 'Option 1',
        data: data1.filter((value) => value !== 0),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }

    dataset2 = {
        label: 'Option 2',
        data: data2.filter((value) => value !== 0),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }

    dataset3 = {
        label: 'Option 3',
        data: data3.filter((value) => value !== 0),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
    }

    datasets.push(dataset1);
    datasets.push(dataset2);
    datasets.push(dataset3);

    data = {
        labels,
        datasets
    }

    return data;
}



function GraphNPV() {

  //constants for the component

  const [graphData, setGraphData] = React.useState();

  //redux constants
  const cummulative_cost_yearly_store = useSelector(
    (state) => state.cost_benefit_slice.cummulative_discount_factor,
    );

    useEffect(() => {
        let data = handleGenerateData(cummulative_cost_yearly_store)
        setGraphData(data);
    }, [cummulative_cost_yearly_store]);

    // useEffect(() => {
    //     console.log('saving graph image in TotalProjectCost')
    //     //save as image
    //     let graphElementTotalProjectCostNPV = document.getElementById('graphTotalProjectCostNPV');
    //     html2canvas(graphElementTotalProjectCostNPV).then(function (canvas) {
    //         let graphImageTotalProjectCostNPV = canvas.toDataURL();
    //         if(sessionStorage.getItem("GraphNPVWrapper")){
    //             sessionStorage.removeItem("GraphNPVWrapper");
    //         }
    //         sessionStorage.setItem("GraphNPVWrapper", graphImageTotalProjectCostNPV);
    //     }
    //     );
    // }, [graphData]);


    //if grapphData is not null, then render the graph
    if (graphData) {
        return (
            <Line data={graphData} options={options} id='graphTotalProjectCostNPV' />
        )
    }
    else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}

const GraphNPVWrapper = () => {
    return (
        <Provider store={store}>
        <GraphNPV />
        </Provider>
    )
    }

export default GraphNPVWrapper
