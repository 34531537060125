import React from 'react'
import { useSelector } from 'react-redux'

function GenerateYearlyData() {

    /*
        function to generate yearly data for the cost benefit component from redux store
    */

    const annual_cost_yearly_store = useSelector(
        (state) => state.cost_benefit_slice.annual_cost_yearly
    );

    const cummulative_cost_yearly_store = useSelector(
        (state) => state.cost_benefit_slice.cummulative_cost_yearly
    );

    var returnData = {}

    for(let index=1;index<=3;index++){
        returnData[index] = {}

        annual_cost_yearly_store[index].forEach((value,year)=>{
            returnData[index][year] = {
                "Year": year?String(year):" ",
                "Annual": value?String(value):" ",
                "Total": cummulative_cost_yearly_store[index][year]?String(cummulative_cost_yearly_store[index][year]):" "
            }
        }
        )
    }

    return returnData;

}

export default GenerateYearlyData