import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Spin } from "antd";
import { Space, Typography } from "antd";
import { cost_detailed_summmary_array_default_values } from "./utils/CostDefaultValues";
import { cost_detailed_summmary_buffer_default_values } from "./utils/CostDefaultValues";
import { cost_detailed_summary_total_default_values } from "./utils/CostDefaultValues";
import { cost_benefit_actions } from "../../../store/cost_benefit_slice";
import "../../../css/index.css";
import "../../../css/home.css";
import "../../../css/main.css";
import "../../../css/tools.css";
const { Title } = Typography;
import complexityMultiplier from "./utils/complexityMultiplier";
import calculateCostSummaryValues from "./utils/calculateCostSummaryValues";
import questionMark from "../../../img/sm-red-question-mark.png";
import { Tabs, TabsProps } from "antd";
import Tooltip from "react-tooltip-lite";
import GenerateCostYearly from "./utils/GenerateCostYearly";

function CostDetailedSummary() {
  //redux constants
  const options_store = useSelector(
    (state) => state.cost_benefit_slice.selected_options
  );
  const dispatch = useDispatch();

  const cost_input_array = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs
  );
  const cost_input_buffer = useSelector(
    (state) => state.cost_benefit_slice.cost_inputs_buffer
  );

  const cost_information_store = useSelector(
    (state) => state.cost_benefit_slice.cost_information
  );

  //const values for the component
  const [
    cost_detailed_summary_array_values,
    setCostDetailedSummaryArrayValues
  ] = React.useState(cost_detailed_summmary_array_default_values);
  const [
    cost_detailed_summary_buffer_values,
    setCostDetailedSummaryBufferValues
  ] = React.useState(cost_detailed_summmary_buffer_default_values);

  const [
    cost_detailed_summary_total_values,
    setCostDetailedSummaryTotalValues
  ] = React.useState(cost_detailed_summary_total_default_values);

  const [complexity_multiplier_values, setComplexityMultiplierValues] =
    React.useState({
      1: {
        post_con: 0,
        narrow_row: 0,
        end_of_row_restricted: 0
      },
      2: {
        post_con: 0,
        narrow_row: 0,
        end_of_row_restricted: 0
      },
      3: {
        post_con: 0,
        narrow_row: 0,
        end_of_row_restricted: 0
      }
    });

  const [caluculation_flag, setCaluculationFlag] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  //functions for the component

  const convertToCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  };

  const handleCostInstallationPhase = () => {
    let cost_detailed_summary_array_values_main = {
      ...cost_detailed_summary_array_values
    };
    let cost_detailed_summary_buffer_values_main = {
      ...cost_detailed_summary_buffer_values
    };
    let cost_detailed_summary_total_values_main = {
      ...cost_detailed_summary_total_values
    };

    options_store.forEach((value, index) => {
      const [
        cost_detailed_summary_array_values_copy,
        cost_detailed_summary_buffer_values_copy,
        cost_detailed_summary_total_values_copy
      ] = calculateCostSummaryValues(
        index,
        cost_detailed_summary_array_values,
        cost_detailed_summary_buffer_values,
        cost_detailed_summary_total_values,
        cost_input_array,
        cost_input_buffer,
        cost_information_store
      );
      cost_detailed_summary_array_values_main[index + 1] =
        cost_detailed_summary_array_values_copy[index + 1];
      cost_detailed_summary_buffer_values_main[index + 1] =
        cost_detailed_summary_buffer_values_copy[index + 1];
      cost_detailed_summary_total_values_main[index + 1] =
        cost_detailed_summary_total_values_copy[index + 1];
    });

    setCostDetailedSummaryArrayValues(cost_detailed_summary_array_values_main);
    setCostDetailedSummaryBufferValues(
      cost_detailed_summary_buffer_values_main
    );
    setCostDetailedSummaryTotalValues(cost_detailed_summary_total_values_main);
    setCaluculationFlag(true);
  };

  const handleComplexityMultiplier = () => {
    /*
        set the values of the multipliers based on the following conditions

        1. if cost_input_array.installed_vegetation_type = "Post" , then set the value of post_con to cost_information_store.assumed_post_construction_cost else set it to 0
        2. if cost_input_array.array_width <= 15 , then set the value of narrow_row to cost_information_store.assumed_increase_narrow_row else set it to 0
        3. if cost_input_array.row_restrictions = "Yes" , then set the value of end_of_row_restricted to cost_information_store.assumed_increase_end_row else set it to 0

      */

    let complexity_multiplier_values_copy = { ...complexity_multiplier_values };

    options_store.map((option, index) => {
      if (cost_input_array[index].installed_vegetation_type === "Post") {
        complexity_multiplier_values_copy[index + 1].post_con =
          cost_information_store.assumed_post_construction_cost;
      } else {
        complexity_multiplier_values_copy[index + 1].post_con = 0;
      }

      if (cost_input_array[index].array_width <= 15) {
        complexity_multiplier_values_copy[index + 1].narrow_row =
          cost_information_store.assumed_increase_narrow_row;
      } else {
        complexity_multiplier_values_copy[index + 1].narrow_row = 0;
      }

      if (cost_input_array[index].row_restrictions === "Yes") {
        complexity_multiplier_values_copy[index + 1].end_of_row_restricted =
          cost_information_store.assumed_increase_end_row;
      } else {
        complexity_multiplier_values_copy[index + 1].end_of_row_restricted = 0;
      }
    });
    setComplexityMultiplierValues(complexity_multiplier_values_copy);

    //for each option , call the complexityMultiplier function and update the state
    let cost_detailed_summary_array_values_main = {
      ...cost_detailed_summary_array_values
    };
    let cost_detailed_summary_buffer_values_main = {
      ...cost_detailed_summary_buffer_values
    };
    let cost_detailed_summary_total_values_main = {
      ...cost_detailed_summary_total_values
    };

    options_store.map((option, index) => {
      const cost_input_array_size = parseInt(
        cost_input_array[index].array_size
      );
      const cost_input_buffer_size = parseInt(
        cost_input_buffer[index].array_size
      );

      const [
        cost_detailed_summary_array_values_copy,
        cost_detailed_summary_buffer_values_copy,
        cost_detailed_summary_total_values_copy
      ] = complexityMultiplier(
        index,
        complexity_multiplier_values_copy,
        cost_detailed_summary_array_values,
        cost_detailed_summary_buffer_values,
        cost_detailed_summary_total_values,
        cost_information_store,
        cost_input_array_size,
        cost_input_buffer_size
      );
      //update the state only for that specifc index
      //set cost_detailed_summary_array_values[index+1] = cost_detailed_summary_array_values_copy[index+1]
      //set cost_detailed_summary_buffer_values[index+1] = cost_detailed_summary_buffer_values_copy[index+1]
      //set cost_detailed_summary_total_values[index+1] = cost_detailed_summary_total_values_copy[index+1]

      cost_detailed_summary_array_values_main[index + 1] =
        cost_detailed_summary_array_values_copy[index + 1];
      cost_detailed_summary_buffer_values_main[index + 1] =
        cost_detailed_summary_buffer_values_copy[index + 1];
      cost_detailed_summary_total_values_main[index + 1] =
        cost_detailed_summary_total_values_copy[index + 1];
    });
    setCostDetailedSummaryArrayValues(cost_detailed_summary_array_values_main);
    setCostDetailedSummaryBufferValues(
      cost_detailed_summary_buffer_values_main
    );
    setCostDetailedSummaryTotalValues(cost_detailed_summary_total_values_main);
    dispatch(cost_benefit_actions.set_cost_results_page_render(true));
    setIsLoading(false);
  };

  const handleComplexityMultiplierDynamic = (value, index, name) => {
    let complexity_multiplier_values_copy = { ...complexity_multiplier_values };

    //update the values
    switch (name) {
      case "post_con":
        complexity_multiplier_values_copy[index + 1].post_con = parseInt(value);
        break;
      case "narrow_row":
        complexity_multiplier_values_copy[index + 1].narrow_row =
          parseInt(value);
        break;
      case "end_of_row_restricted":
        complexity_multiplier_values_copy[index + 1].end_of_row_restricted =
          parseInt(value);
        break;
    }

    //update the state
    setComplexityMultiplierValues(complexity_multiplier_values_copy);

    const cost_input_array_size = parseInt(cost_input_array[index].array_size);
    const cost_input_buffer_size = parseInt(
      cost_input_buffer[index].array_size
    );

    const [
      cost_detailed_summary_array_values_copy,
      cost_detailed_summary_buffer_values_copy,
      cost_detailed_summary_total_values_copy
    ] = complexityMultiplier(
      index,
      complexity_multiplier_values_copy,
      cost_detailed_summary_array_values,
      cost_detailed_summary_buffer_values,
      cost_detailed_summary_total_values,
      cost_information_store,
      cost_input_array_size,
      cost_input_buffer_size
    );
    //update the state only for that specifc index
    //set cost_detailed_summary_array_values[index+1] = cost_detailed_summary_array_values_copy[index+1]
    //set cost_detailed_summary_buffer_values[index+1] = cost_detailed_summary_buffer_values_copy[index+1]
    //set cost_detailed_summary_total_values[index+1] = cost_detailed_summary_total_values_copy[index+1]

    setCostDetailedSummaryArrayValues(cost_detailed_summary_array_values_copy);
    setCostDetailedSummaryBufferValues(
      cost_detailed_summary_buffer_values_copy
    );
    setCostDetailedSummaryTotalValues(cost_detailed_summary_total_values_copy);
  };

  //useEffects

  useEffect(() => {
      handleCostInstallationPhase();
      //handleComplexityMultiplier();
  }, []);

  useEffect(() => {
    if (caluculation_flag) {
      handleComplexityMultiplier();
    }
  }, [caluculation_flag]);

  useEffect(() => {
    dispatch(
      cost_benefit_actions.set_cost_detailed_summary_array_values(
        cost_detailed_summary_array_values
      )
    );
  }, [cost_detailed_summary_array_values]);

  useEffect(() => {
    dispatch(
      cost_benefit_actions.set_cost_detailed_summary_buffer_values(
        cost_detailed_summary_buffer_values
      )
    );
  }, [cost_detailed_summary_buffer_values]);

  useEffect(() => {
    dispatch(
      cost_benefit_actions.set_cost_total_values(
        cost_detailed_summary_total_values
      )
    );
  }, [cost_detailed_summary_total_values]);

  return (
    <>
      {caluculation_flag &&
      !isLoading &&
      cost_detailed_summary_array_values &&
      cost_detailed_summary_buffer_values &&
      complexity_multiplier_values ? (
        <>
          <div id="tabs__section">
            <Tabs defaultActiveKey="1" centered tabBarGutter={400} size="large">
              {options_store.map((index) => {
                return (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <span className="tab__text">Option {index + 1}</span>
                      </span>
                    }
                    key={index + 1}
                  >
                    <>
                      <div
                        key={index}
                        className="d-flex flex-column justify-content-center"
                      >
                        <div className="row mt-4">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Post-Con (%)</th>
                                <th>Narrow Row (%)</th>
                                <th>End of Row Restricted (%)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Tooltip
                                    content={
                                      <ul className="tip-list">
                                        <li>
                                          Complexity multipliers are applied to
                                          installation costs based on whether
                                          design configurations meet the
                                          thresholds described on the Project
                                          Information tab.{" "}
                                        </li>
                                        <li>
                                          Complexity multipliers can be edited
                                          on the Project Information tab as
                                          well.{" "}
                                        </li>
                                      </ul>
                                    }
                                  >
                                    <img src={questionMark} height="20px" />{" "}
                                    <label style={{ fontWeight: "bold" }}>
                                      Complexity Multiplier
                                    </label>
                                  </Tooltip>
                                </td>
                                <td>
                                  <Input
                                    name="post_con"
                                    disabled={true}
                                    onChange={(e) => {
                                      handleComplexityMultiplierDynamic(
                                        e.target.value,
                                        index,
                                        "post_con"
                                      );
                                    }}
                                    value={
                                      complexity_multiplier_values[index + 1]
                                        .post_con
                                        ? complexity_multiplier_values[
                                            index + 1
                                          ].post_con
                                        : 0
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name="narrow_row"
                                    disabled={true}
                                    onChange={(e) => {
                                      handleComplexityMultiplierDynamic(
                                        e.target.value,
                                        index,
                                        "narrow_row"
                                      );
                                    }}
                                    value={
                                      complexity_multiplier_values[index + 1]
                                        .narrow_row
                                        ? complexity_multiplier_values[
                                            index + 1
                                          ].narrow_row
                                        : 0
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    name="end_of_row_restricted"
                                    disabled={true}
                                    onChange={(e) => {
                                      handleComplexityMultiplierDynamic(
                                        e.target.value,
                                        index,
                                        "end_of_row_restricted"
                                      );
                                    }}
                                    value={
                                      complexity_multiplier_values[index + 1]
                                        .end_of_row_restricted
                                        ? complexity_multiplier_values[
                                            index + 1
                                          ].end_of_row_restricted
                                        : 0
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="d-flex justify-content-center">
                            <Title className="mt-4" level={5}>
                              {" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "#000000",
                                  textDecoration: "underline"
                                }}
                              >
                                INSTALLATION PHASE VEGETATION MAINTENANCE
                                SERVICES
                              </span>
                            </Title>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="d-flex justify-content-center">
                              <Title className="mt-4" level={5}>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#000000",
                                    textDecoration: "underline"
                                  }}
                                >
                                  ARRAY
                                </span>
                              </Title>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-flex justify-content-center">
                              <Title className="mt-4" level={5}>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#000000",
                                    textDecoration: "underline"
                                  }}
                                >
                                  BUFFER
                                </span>
                              </Title>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <table className="mt-4 table table-bordered">
                              <thead>
                                <tr>
                                  <th> Item </th>
                                  <th> Quantity </th>
                                  <th> Units</th>
                                  <th> Complexity Multiplier </th>
                                  <th> Unit Cost </th>
                                  <th> Total Cost </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td> Pre Seeding Herbicide </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].pre_seeding
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].pre_seeding.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>events</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].pre_seeding
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].pre_seeding.complexity_multiplier +
                                        "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].pre_seeding
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].pre_seeding.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].pre_seeding
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].pre_seeding.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Seed Mix Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].seed_mix_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_mix_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].seed_mix_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_mix_cost.complexity_multiplier +
                                        "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_mix_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].seed_mix_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_mix_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].seed_mix_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Seed Installation Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].seed_installation_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_installation_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].seed_installation_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_installation_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_installation_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].seed_installation_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].seed_installation_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].seed_installation_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Fertilizer Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].fertilizer_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].fertilizer_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].fertilizer_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].fertilizer_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].fertilizer_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].fertilizer_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].fertilizer_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].fertilizer_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Soil Amendments Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].soil_amendment_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].soil_amendment_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].soil_amendment_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].soil_amendment_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].soil_amendment_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].soil_amendment_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].soil_amendment_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].soil_amendment_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Decompaction or Tillage Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].decompaction_or_tillage_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].decompaction_or_tillage_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].decompaction_or_tillage_cost
                                            .unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].decompaction_or_tillage_cost
                                            .total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Temporary Stabilization Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].temporary_stabilization_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].temporary_stabilization_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].temporary_stabilization_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].temporary_stabilization_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].temporary_stabilization_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].temporary_stabilization_cost
                                            .unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].temporary_stabilization_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].temporary_stabilization_cost
                                            .total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Custom Input 1: ($/Acre) </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].custom_input_1_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_1_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].custom_input_1_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_1_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_1_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].custom_input_1_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_1_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].custom_input_1_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Custom Input 2: ($/Acre) </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].custom_input_2_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_2_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].custom_input_2_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_2_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_2_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].custom_input_2_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_2_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].custom_input_2_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Custom Input 3: ($/Acre) </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].custom_input_3_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_3_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_array_values &&
                                    cost_detailed_summary_array_values[
                                      index + 1
                                    ].custom_input_3_cost
                                      ? cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_3_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_3_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].custom_input_3_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].custom_input_3_cost
                                        ? cost_detailed_summary_array_values[
                                            index + 1
                                          ].custom_input_3_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Installation Total </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {convertToCurrency(
                                      cost_detailed_summary_total_values &&
                                        cost_detailed_summary_total_values[
                                          index + 1
                                        ].array.total_cost_installation_phase
                                        ? cost_detailed_summary_total_values[
                                            index + 1
                                          ].array.total_cost_installation_phase
                                        : ""
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6">
                            <table className="mt-4 table table-bordered">
                              <thead>
                                <tr>
                                  <th> Item </th>
                                  <th> Quantity </th>
                                  <th> Units</th>
                                  <th> Complexity Multiplier </th>
                                  <th> Unit Cost </th>
                                  <th> Total Cost </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td> Pre Seeding Herbicide </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].pre_seeding
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].pre_seeding.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>events</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].pre_seeding
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].pre_seeding.complexity_multiplier +
                                        "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].pre_seeding
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].pre_seeding.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].pre_seeding
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].pre_seeding.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Seed Mix Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].seed_mix_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_mix_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].seed_mix_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_mix_cost.complexity_multiplier +
                                        "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_mix_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].seed_mix_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_mix_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].seed_mix_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Seed Installation Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].seed_installation_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_installation_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].seed_installation_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_installation_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_installation_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].seed_installation_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].seed_installation_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].seed_installation_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Fertilizer Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].fertilizer_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].fertilizer_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].fertilizer_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].fertilizer_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].fertilizer_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].fertilizer_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].fertilizer_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].fertilizer_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Soil Amendments Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].soil_amendment_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].soil_amendment_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].soil_amendment_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].soil_amendment_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].soil_amendment_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].soil_amendment_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].soil_amendment_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].soil_amendment_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Decompaction or Tillage Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].decompaction_or_tillage_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].decompaction_or_tillage_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].decompaction_or_tillage_cost
                                            .unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].decompaction_or_tillage_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].decompaction_or_tillage_cost
                                            .total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Temporary Stabilization Cost </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].temporary_stabilization_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].temporary_stabilization_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].temporary_stabilization_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].temporary_stabilization_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].temporary_stabilization_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].temporary_stabilization_cost
                                            .unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].temporary_stabilization_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].temporary_stabilization_cost
                                            .total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Custom Input 1: ($/Acre) </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].custom_input_cost_1
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_cost_1.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].custom_input_cost_1
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_cost_1
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_cost_1
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].custom_input_cost_1.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_cost_1
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].custom_input_cost_1.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Custom Input 2: ($/Acre) </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].custom_input_2_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_2_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].custom_input_2_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_2_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_2_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].custom_input_2_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_2_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].custom_input_2_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Custom Input 3: ($/Acre) </td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].custom_input_3_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_3_cost.quantity
                                      : ""}{" "}
                                  </td>
                                  <td>acres</td>
                                  <td>
                                    {" "}
                                    {cost_detailed_summary_buffer_values &&
                                    cost_detailed_summary_buffer_values[
                                      index + 1
                                    ].custom_input_3_cost
                                      ? cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_3_cost
                                          .complexity_multiplier + "%"
                                      : ""}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_3_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].custom_input_3_cost.unit_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    {convertToCurrency(
                                      cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].custom_input_3_cost
                                        ? cost_detailed_summary_buffer_values[
                                            index + 1
                                          ].custom_input_3_cost.total_cost
                                        : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Installation Total </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {convertToCurrency(
                                      cost_detailed_summary_total_values &&
                                        cost_detailed_summary_total_values[
                                          index + 1
                                        ].buffer.total_cost_installation_phase
                                        ? cost_detailed_summary_total_values[
                                            index + 1
                                          ].buffer.total_cost_installation_phase
                                        : ""
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="row">
                            <div className="d-flex justify-content-center">
                              <Title className="mt-4" level={5}>
                                {" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#000000",
                                    textDecoration: "underline"
                                  }}
                                >
                                  ESTABLISHMENT PHASE VEGETATION MAINTENANCE
                                  SERVICES
                                </span>
                              </Title>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div className="d-flex justify-content-center">
                                  <Title className="mt-4" level={5}>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#000000",
                                        textDecoration: "underline"
                                      }}
                                    >
                                      ARRAY
                                    </span>
                                  </Title>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="d-flex justify-content-center">
                                  <Title className="mt-4" level={5}>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#000000",
                                        textDecoration: "underline"
                                      }}
                                    >
                                      BUFFER
                                    </span>
                                  </Title>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="row">
                                <div className="col-6">
                                  <table className="mt-4 table table-bordered">
                                    <thead>
                                      <tr>
                                        <th> Item </th>
                                        <th> Quantity </th>
                                        <th> Units</th>
                                        <th> Complexity Multiplier </th>
                                        <th> Unit Cost </th>
                                        <th> Total Cost </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {" "}
                                          Number of Mowing Treatments (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .no_of_mowing_treatments_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>events</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .no_of_mowing_treatments_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .no_of_mowing_treatments_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .no_of_mowing_treatments_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Number of Herbicide Treatments{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .no_of_herbicide_applications_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .no_of_herbicide_applications_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .no_of_herbicide_applications_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .no_of_herbicide_applications_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Number of Supplemental Seedings
                                          (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .no_of_supplemental_seedings_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .no_of_supplemental_seedings_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .no_of_supplemental_seedings_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .no_of_supplemental_seedings_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Grazing Management Services (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .grazing_management_services_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .grazing_management_services_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .grazing_management_services_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .grazing_management_services_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Inspections and Monitoring (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .inspections_and_monitoring_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_array_values &&
                                          cost_detailed_summary_array_values[
                                            index + 1
                                          ]
                                            .inspections_and_monitoring_establishment
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .inspections_and_monitoring_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_array_values &&
                                              cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                              ? cost_detailed_summary_array_values[
                                                  index + 1
                                                ]
                                                  .inspections_and_monitoring_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td> Duration Years </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {cost_information_store &&
                                          cost_information_store.establishment_phase
                                            ? cost_information_store.establishment_phase
                                            : ""}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Total Array Establishment Cost{" "}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {convertToCurrency(
                                            cost_detailed_summary_total_values &&
                                              cost_detailed_summary_total_values[
                                                index + 1
                                              ].array
                                                .total_array_establishment_cost
                                              ? cost_detailed_summary_total_values[
                                                  index + 1
                                                ].array
                                                  .total_array_establishment_cost
                                              : ""
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-6">
                                  <table className="mt-4 table table-bordered">
                                    <thead>
                                      <tr>
                                        <th> Item </th>
                                        <th> Quantity </th>
                                        <th> Units</th>
                                        <th> Complexity Multiplier </th>
                                        <th> Unit Cost </th>
                                        <th> Total Cost </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {" "}
                                          Number of Mowing Treatments (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .no_of_mowing_treatments_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>events</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .no_of_mowing_treatments_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .no_of_mowing_treatments_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .no_of_mowing_treatments_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Number of Herbicide Treatments{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .no_of_herbicide_applications_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .no_of_herbicide_applications_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .no_of_herbicide_applications_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .no_of_herbicide_applications_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Number of Supplemental Seedings
                                          (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .no_of_supplemental_seedings_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .no_of_supplemental_seedings_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .no_of_supplemental_seedings_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .no_of_supplemental_seedings_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Grazing Management Services (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .grazing_management_services_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .grazing_management_services_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .grazing_management_services_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .grazing_management_services_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Inspections and Monitoring (Annually){" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .inspections_and_monitoring_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                                .quantity
                                            : ""}{" "}
                                        </td>
                                        <td>acres</td>
                                        <td>
                                          {" "}
                                          {cost_detailed_summary_buffer_values &&
                                          cost_detailed_summary_buffer_values[
                                            index + 1
                                          ]
                                            .inspections_and_monitoring_establishment
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                                .complexity_multiplier + "%"
                                            : ""}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .inspections_and_monitoring_establishment
                                                  .unit_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                        <td>
                                          {" "}
                                          {convertToCurrency(
                                            cost_detailed_summary_buffer_values &&
                                              cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_establishment
                                              ? cost_detailed_summary_buffer_values[
                                                  index + 1
                                                ]
                                                  .inspections_and_monitoring_establishment
                                                  .total_cost
                                              : ""
                                          )}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td> Duration Years </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {cost_information_store &&
                                          cost_information_store.establishment_phase
                                            ? cost_information_store.establishment_phase
                                            : ""}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          Total Buffer Establishment Cost{" "}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {convertToCurrency(
                                            cost_detailed_summary_total_values &&
                                              cost_detailed_summary_total_values[
                                                index + 1
                                              ].buffer
                                                .total_buffer_establishment_cost
                                              ? cost_detailed_summary_total_values[
                                                  index + 1
                                                ].buffer
                                                  .total_buffer_establishment_cost
                                              : ""
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="d-flex justify-content-center">
                                <Title className="mt-4" level={5}>
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#000000",
                                      textDecoration: "underline"
                                    }}
                                  >
                                    MAINTENANCE PHASE VEGETATION MAINTENANCE
                                    SERVICES
                                  </span>
                                </Title>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="d-flex justify-content-center">
                                    <Title className="mt-4" level={5}>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#000000",
                                          textDecoration: "underline"
                                        }}
                                      >
                                        ARRAY
                                      </span>
                                    </Title>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="d-flex justify-content-center">
                                    <Title className="mt-4" level={5}>
                                      {" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#000000",
                                          textDecoration: "underline"
                                        }}
                                      >
                                        BUFFER
                                      </span>
                                    </Title>
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <table className="mt-4 table table-bordered">
                                  <thead>
                                    <tr>
                                      <th> Item </th>
                                      <th> Quantity </th>
                                      <th> Units</th>
                                      <th> Complexity Multiplier </th>
                                      <th> Unit Cost </th>
                                      <th> Total Cost </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {" "}
                                        Number of Mowing Treatments (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].no_of_mowing_treatments_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>events</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].no_of_mowing_treatments_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Number of Herbicide Treatments </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ]
                                          .no_of_herbicide_applications_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ]
                                          .no_of_herbicide_applications_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        Number of Supplemental Seedings
                                        (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ]
                                          .no_of_supplemental_seedings_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ]
                                          .no_of_supplemental_seedings_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        Grazing Management Services (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ]
                                          .grazing_management_services_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ]
                                          .grazing_management_services_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        Inspections and Monitoring (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].inspections_and_monitoring_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_array_values &&
                                        cost_detailed_summary_array_values[
                                          index + 1
                                        ].inspections_and_monitoring_maintenance
                                          ? cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_array_values &&
                                            cost_detailed_summary_array_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                            ? cost_detailed_summary_array_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Duration Years </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {cost_information_store &&
                                        cost_information_store.maintenance_phase
                                          ? cost_information_store.maintenance_phase
                                          : ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Total Array Maintenance Cost </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {convertToCurrency(
                                          cost_detailed_summary_total_values &&
                                            cost_detailed_summary_total_values[
                                              index + 1
                                            ].array.total_array_maintenance_cost
                                            ? cost_detailed_summary_total_values[
                                                index + 1
                                              ].array
                                                .total_array_maintenance_cost
                                            : ""
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-6">
                                <table className="mt-4 table table-bordered">
                                  <thead>
                                    <tr>
                                      <th> Item </th>
                                      <th> Quantity </th>
                                      <th> Units</th>
                                      <th> Complexity Multiplier </th>
                                      <th> Unit Cost </th>
                                      <th> Total Cost </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {" "}
                                        Number of Mowing Treatments (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].no_of_mowing_treatments_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>events</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].no_of_mowing_treatments_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_mowing_treatments_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_mowing_treatments_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Number of Herbicide Treatments </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ]
                                          .no_of_herbicide_applications_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ]
                                          .no_of_herbicide_applications_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_herbicide_applications_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_herbicide_applications_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        Number of Supplemental Seedings
                                        (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ]
                                          .no_of_supplemental_seedings_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ]
                                          .no_of_supplemental_seedings_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .no_of_supplemental_seedings_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .no_of_supplemental_seedings_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        Grazing Management Services (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ]
                                          .grazing_management_services_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ]
                                          .grazing_management_services_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .grazing_management_services_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .grazing_management_services_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        Inspections and Monitoring (Annually){" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].inspections_and_monitoring_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                              .quantity
                                          : ""}{" "}
                                      </td>
                                      <td>acres</td>
                                      <td>
                                        {" "}
                                        {cost_detailed_summary_buffer_values &&
                                        cost_detailed_summary_buffer_values[
                                          index + 1
                                        ].inspections_and_monitoring_maintenance
                                          ? cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                              .complexity_multiplier + "%"
                                          : ""}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_maintenance
                                                .unit_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {convertToCurrency(
                                          cost_detailed_summary_buffer_values &&
                                            cost_detailed_summary_buffer_values[
                                              index + 1
                                            ]
                                              .inspections_and_monitoring_maintenance
                                            ? cost_detailed_summary_buffer_values[
                                                index + 1
                                              ]
                                                .inspections_and_monitoring_maintenance
                                                .total_cost
                                            : ""
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Duration Years </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {cost_information_store &&
                                        cost_information_store.maintenance_phase
                                          ? cost_information_store.maintenance_phase
                                          : ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Total Buffer Maintenance Cost </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {convertToCurrency(
                                          cost_detailed_summary_total_values &&
                                            cost_detailed_summary_total_values[
                                              index + 1
                                            ].buffer
                                              .total_buffer_maintenance_cost
                                            ? cost_detailed_summary_total_values[
                                                index + 1
                                              ].buffer
                                                .total_buffer_maintenance_cost
                                            : ""
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </div>

          {/* <div className="container-fluid">
            <Space direction="vertical" size="middle">
              {options_store.map((index) => {
                return (

                );
              })}
            </Space>
          </div> */}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <Spin size="large" />
          </div>
        </>
      )}
    </>
  );
}
export default CostDetailedSummary;
