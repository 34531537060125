import { createSlice } from "@reduxjs/toolkit";

const seed_selection_slice = createSlice({
    name: "seed_selection",
    initialState: {
        selected_state: "",
        seto_map: [],
        organization_name:"",
        selected_ecoregions: [],
        gis_ecoRegions: [],
        initital_inputs: {
            planting_zones: "",
            native_preference: [],
            panel_height: "",
            seed_commercial_availability:[]
        },
        planting_zone_filters: {
            soil_types: [],
            soil_moisture: [],
            light_exposure: [],
            soil_tolerance: [],
            soil_pH: [],
            soil_pH_min: 0,
            soil_pH_max: 0,
            ecoRegions:{}
        },
        refinements: {
            pollinator_benefits: [],
            seeding_season: [],
            growth_form:[],
            bloom_period_earliest: "",
            bloom_period: [],
            bloom_period_latest: "",
            seed_availability: "",
            quick_germination: [],
            grazing_toxicity:"",
            relative_price:[]
          }
        ,
        //additional_refinements: {
        //    quick_germination: []
        //    //grazing_palatability: "",
        //    //grazing_tolerance: "",
        //    //mowing_tolerance: ""
        //},
        initial_inputs_checkboxes:{
            "native": false,
            "non_native": false,
            "common": false,
            "uncommon": false,
            "rare": false,
            "very_common": false,
            "very_rare_unavailable": false,
            "select_all": false,
            "all": false
        },
        planting_zone_filters_checkboxes:{
            "coarse": false,
            "medium": false,
            "fine": false,
            "upland_dry": false,
            "upland_moist": false,
            "saturated": false,
            "emergent_shallow": false,
            "emergent_deep": false,
            "submerged": false,
            "sun": false,
            "part": false,
            "shade": false,
            "pshade": false,
            "acidic": false,
            "neutral": false,
            "basic": false,
            "undefined": false,
            "Undefined": false,
            "dry":false,
            "moist":false,
            "wet":false,
            "low":false,
            "med":false,
            "high":false
        },
        refinements_checkboxes:{
            "spring": false,
            "summer": false,
            "fall": false,
            "winter": false,
            "vine": false,
            "tree": false,
            "forb": false,
            "shrub": false,
            "grass": false,
            "larval": false,
            "nesting": false,
            "adult": false,
            "quickspring": false,
            "quicksummer": false,
            "quickfall": false,
            "quickwinter": false,
            "$": false,
            "$$": false,
            "$$$": false,
            "$$$$": false,
            "Undefined": false
        },
        undefined_checkboxes:{
            "soil_types": false,
            "light_exposure": false,
            "soil_moisture": false,
            "salt_tolerance": false,
            "growth_form": false,
            "grazing_toxicity": false
        },
        //additional_refinements_checkboxes: {
        //    "spring": false,
        //    "summer": false,
        //    "fall": false,
        //    "winter": false,
        //},
        results: []
    },
    reducers: {
        set_initial_inputs: (state, action) => {
            state.initital_inputs = action.payload;
        },
        set_selected_state: (state, action) => {
            //console.log('set selected state reducer called')
            //console.log('payload in reducer',action.payload)
            state.selected_state = "";
            state.selected_state = action.payload;
        },
        set_seto_map: (state, action) => {
            state.seto_map = [];
            state.seto_map = action.payload;
        },
        set_planting_zone_filters: (state, action) => {
            //console.log('set planting zone filters reducer called')
            //console.log('payload in reducer',action.payload)
            state.planting_zone_filters = action.payload;
        },
        reset_ph: (state, action) => {
            //console.log('reset ph reducer called')
            state.planting_zone_filters.soil_pH = [];
        },
        set_selected_soil_moisture: (state, action) => {
            //console.log('set moisture reducer called')
            //console.log('payload in reducer',action.payload)
            state.planting_zone_filters.soil_moisture = action.payload;
        },
        set_gis_ecoRegions: (state, action) => {
            //console.log('set gis ecoRegions reducer called')
            //console.log('payload in reducer',action.payload)
            state.gis_ecoRegions = action.payload;
        },
        set_refinements: (state, action) => {
            //console.log('set refinements reducer called')
            //console.log('payload in reducer',action.payload)
            state.refinements = action.payload;
            //console.log('state in refinements reducer',state.refinements)
        },
        //set_additional_refinements: (state, action) => {
        //    //console.log('set additional refinements reducer called')
        //    //console.log('payload in reducer',action.payload)
        //    state.additional_refinements = action.payload;
        //    console.log('state in additional refinements reducer',state.additional_refinements)
        //},
        set_results: (state, action) => {
            //console.log('set results reducer called')
            //console.log('payload in reducer',action.payload)
            state.results = action.payload;
        },
        set_selected_ecoregions: (state, action) => {
            //console.log('set selected ecoregions reducer called')
            //console.log('payload in reducer',action.payload)
            state.selected_ecoregions = action.payload;
        },
        //update_additional_refinements_checkboxes: (state, action) => {
        //    state.additional_refinements_checkboxes[action.payload.value.toLowerCase()] = !state.additional_refinements_checkboxes[action.payload.value.toLowerCase()];
        //},
        update_refinements_checkboxes: (state, action) => {
            state.refinements_checkboxes[action.payload.value.toLowerCase()] = !state.refinements_checkboxes[action.payload.value.toLowerCase()];
        },
        update_planting_zone_filters_checkboxes: (state, action) => {
            if(action.payload.name == "soil_tolerance" && action.payload.value == "Medium"){
                state.planting_zone_filters_checkboxes["med"] = !state.planting_zone_filters_checkboxes["med"];
            }
            else{
                state.planting_zone_filters_checkboxes[action.payload.value.toLowerCase()] = !state.planting_zone_filters_checkboxes[action.payload.value.toLowerCase()];
            }
        },
        update_initial_inputs_checkboxes: (state, action) => {
            state.initial_inputs_checkboxes[action.payload.value.toLowerCase()] = !state.initial_inputs_checkboxes[action.payload.value.toLowerCase()];
        },
        update_undefined_checkboxes: (state, action) => {
            state.undefined_checkboxes[action.payload.name.toLowerCase()] = !state.undefined_checkboxes[action.payload.name.toLowerCase()];
        },
        reset_seed_selection: (state, action) => {
            state.selected_state = "";
            state.seto_map = [];
            state.selected_ecoregions = [];
            state.organization_name = "";
            state.initital_inputs =
            {
                planting_zones: "",
                native_preference: [],
                panel_height: "",
                seed_commercial_availability: []
            },
            state.planting_zone_filters = {
                soil_types: [],
                soil_moisture: [],
                light_exposure: [],
                soil_tolerance: [],
                soil_pH: [],
                soil_pH_min: 0,
                soil_pH_max: 0,
                ecoRegions:{}
            };
            state.gis_ecoRegions = [],
            state.refinements = {
                pollinator_benefits: [],
                seeding_season: [],
                growth_form:[],
                bloom_period_earliest: "",
                bloom_period: [],
                bloom_period_latest: "",
                seed_availability: "",
                quick_germination:[],
                grazing_toxicity:"",
                relative_price:[]
            };
            //state.additional_refinements = {
            //    quick_germination: []
            //    //grazing_palatability: "",
            //    //grazing_tolerance: "",
            //    //mowing_tolerance: ""
            //};
            state.results = [];
            //state.additional_refinements_checkboxes = {
            //    "spring": false,
            //    "summer": false,
            //    "fall": false,
            //    "winter": false
            //};
            state.refinements_checkboxes = {
                "spring": false,
                "summer": false,
                "fall": false,
                "winter": false,
                "vine": false,
                "tree": false,
                "forb": false,
                "shrub": false,
                "grass": false,
                "larval": false,
                "nesting": false,
                "adult": false,
                "quickspring":false,
                "quicksummer": false,
                "quickfall": false,
                "quickwinter": false,
                "$": false,
                "$$": false,
                "$$$": false,
                "$$$$": false
            };
            state.planting_zone_filters_checkboxes = {
                "coarse": false,
                "medium": false,
                "fine": false,
                "upland_dry": false,
                "upland_moist": false,
                "saturated": false,
                "emergent_shallow": false,
                "emergent_deep": false,
                "submerged": false,
                "sun": false,
                "part": false,
                "shade": false,
                "pshade": false,
                "acidic": false,
                "neutral": false,
                "basic": false,
                "dry":false,
                "moist":false,
                "wet":false,
                "low":false,
                "med":false,
                "high":false
            };
            state.initial_inputs_checkboxes = {
                "native": false,
                "non_native": false,
                "common": false,
                "uncommon": false,
                "rare": false,
                "very_common": false,
                "very_rare_unavailable": false,
                "all": false
            };
            state.undefined_checkboxes = {
                "soil_types": false,
                "light_exposure": false,
                "soil_moisture": false,
                "salt_tolerance": false,
                "growth_form": false,
                "grazing_toxicity": false
            };
        }
    }
}
);

export const seed_selection_actions = seed_selection_slice.actions;
export default seed_selection_slice;
