import React, { useEffect } from 'react'
import { Card, CardImg, CardBody, CardTitle, CardText, Button } from 'reactstrap'
import '../css/tools.css'
import { useNavigate } from 'react-router-dom';
import stanteclogo from "./../img/stantec.png";
import fedhighwaylogo from "./../img/fedhighway.jpg";
import usdalogo from "./../img/usda.png";
import deptenergylogo from "./../img/deptenergy.png";
import ack_card from '../img/sm-score_card.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Acknowledgements() {
    return (
        <>
            <div className='container-fluid'>

                    <Card className="cardsize" >
                        <CardImg
                            alt="Acknowledgement Image"
                            className="tool-image-wrapper"
                            src={ack_card}
                        />
                        <CardBody>
                            <CardTitle tag="h4">
                                Acknowledgements
                            </CardTitle>
                        <CardText>
                            <table >
                                <tr>
                                    <td>
                                        <Link src="https://www.energy.gov/eere/solar/solar-energy-technologies-office" ><img src={deptenergylogo} height="150px" /></Link>

                                    </td>
                                    <td>
                                        <span style={{ fontSize: 18, fontWeight: 500}}>
                                            Development of the PHASE Seed Selection Tool was funded through the U.S. Department of Energy's Solar Energy Technologies Office.
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <br /> <br />

                                <strong>About the Solar Energy Technologies Office</strong> <br />
                                The U.S. Department of Energy Solar Energy Technologies Office accelerates the advancement and deployment of solar technology in
                            support of an equitable transition to a decarbonized economy. Learn more at <Link src="energy.gov/eere/solar">energy.gov/eere/solar</Link>.

                                <p>
                                This tool is based upon work supported by the U.S. Department of Energy's Office of Energy Efficiency and Renewable Energy (EERE) under
                                the Solar Energy Technologies Office Award Number DE-EE0009371 titled Evaluation of Economic, Ecological, and Performance Impacts of
                                    Co-Located Pollinator Plantings at Large-Scale Solar Installations.
                                </p>

                                <p><strong>Disclaimer:</strong> This tool was prepared as an account of work sponsored by an agency of the United States Government.
                                Neither the United States Government nor any agency thereof, nor any of their employees, makes any warranty, express or implied,
                                or assumes any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, apparatus, product,
                                or process disclosed, or represents that its use would not infringe privately owned rights. Reference herein to any specific commercial
                                product, process, or service by trade name, trademark, manufacturer, or otherwise does not necessarily constitute or imply its endorsement,
                                recommendation, or favoring by the United States Government or any agency thereof. The views and opinions of authors expressed herein do
                                not necessarily state or reflect those of the United States Government or any agency thereof. </p>
                                <p>
                                    Species information within this seed selection tool comes from multiple sources:
                            </p>

                            <p><Link src="https://plants.usda.gov/home" ><img src={usdalogo} height="100px" /></Link>
                                <br />
                                    The U.S. Department of Agriculture's Plant List of Attributes, Names, Taxonomy, and Symbols (PLANTS) Database provides standardized
                                    information about the vascular plants, mosses, liverworts, hornworts, and lichens of the U.S. and its territories. USDA PLANTS
                                    data was used in compilation of species attributes included within this tool's database.
                                <br /><br />  The PLANTS database can be found online at: <Link src="https://plants.usda.gov/home" >https://plants.usda.gov/home</Link>
                            </p>


                            <p><Link src="http://www.nativerevegetation.org/era/"><img src={fedhighwaylogo} height="70px" /></Link>
                                <br />
                                    The Federal Highway Administration (FHWA) Ecoregional Revegetation Application (ERA) Tool is a pollinator-friendly
                                    search-and-print nationwide revegetation mapping and plant database developed for designers as a starting point for
                                    developing appropriate plant palettes and seed mixes specific to a project site. The user-friendly online tool supports
                                    custom searches to identify and generate lists of the optimal native plant species for revegetation and pollinator habitats
                                    for individual ecoregions. The ERA Tool data was used in compilation of species attributes included within this tool's database.
                                <br /><br />The tool can be found online at: <Link src="http://www.nativerevegetation.org/era/" >http://www.nativerevegetation.org/era/</Link>
                            <p>


                            <p><Link src="https://www.stantec.com/en/services/native-plant-nursery"><img src={stanteclogo} height="70px" /></Link>
                                <br />
                                    Stantec's full-service native plant nursery located in northern Indiana provides Midwest native plant and seed material.
                                    The nursery has hundreds of native plants and seeds in stock, including genotype-specific seed, and we can also provide
                                    custom seed collection to best match a project area. Working in partnership with landscape architects, engineers,
                                    contractors, builders, homeowner associations, facilities managers, and property management groups, the nursery helps
                                    integrate native plants into traditional landscapes, managed natural resources, and conducted landscape design review.
                                    Stantec's Native Plant Nursery worked with other seed vendors (listed below) and compiled commercial availability and
                                    relative price estimates included within the tool query.
                                 <br /><br />The Stantec native plant nursery can be found online at:
                                        <Link src="https://www.stantec.com/en/services/native-plant-nursery">https://www.stantec.com/en/services/native-plant-nursery</Link>
                            </p>

                                <table>
                                    <tr>
                                        <td>
                                            <p style={{ fontWeight: 500 }}>Additional information on commercial availability was compiled with <br />
                                                the support of the following nurseries and seed vendors:</p>
                                            <ul>
                                                <li>Applewood Seed Company</li>
                                                <li>Bamert Seed Company</li>
                                                <li>Comstock Seed LLC</li>
                                                <li>Earth Source Inc and Heartland Restoration</li>
                                                <li>Ernst Seeds</li>
                                                <li>Everwilde</li>
                                                <li>Flagstaff Native Plant and Seed Company</li>
                                                <li>Florida Native Wildflowers</li>
                                                <li>F.W. Schumacher Tree and Shrub Seeds </li>
                                                <li>Great Valley Seed Company</li>
                                                <li>Hamilton Native Outpost</li>
                                                <li>Kaste Seed GCP, Inc.</li>
                                                <li>Larner Seeds </li>
                                                <li>Lovelace Seeds</li>
                                                <li>Native Seed Foundation</li>
                                                <li>Native West Nursery  </li>
                                                <li>Natural Communities Nursery</li>
                                                <li>Nature's Seed  </li>
                                                <li>Northern Wildflowers</li>
                                                <li>Osenbaugh's Prairie Seed Farms</li>
                                                <li>Plants of the Southwest</li >
                                                <li>Prairie Moon Nursery</li >
                                                <li>Roundstone Native Seed </li >
                                                <li>Shooting Star Native Seeds</li >
                                                <li>Sierra Seed Supply</li >
                                                <li>Skinner Native Seeds  </li >
                                                <li>Spence Native Plant Nursery</li >
                                                <li>Southwest Seed Inc. </li >
                                                <li>Taylor Creek Restoration Nurseries </li >
                                                <li>Theodore Payne Foundation</li >
                                                <li>Western Native Seed </li >
                                            </ul>
                                            <br /><br />
                                        </td>

                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>

                                        <td>
                                            <p style={{ fontWeight: 500 }}>
                                                The following individuals and organizations contributed their knowledge <br />
                                                and information to create this tool:
                                            </p>

                                            <ul>
                                                <li>Chris Taliga, <i>Natural Resource Conservation Service National Plant Data Team</i ></li >
                                                    <li>Leah Ceperley, <i>University of Dayton</i ></li >
                                                    <li>Lexie Hain, <i>Lightsource bp</i ></li >
                                                    <li>Laura Nagy, <i>Avangrid</i ></li >
                                                    <li>James Hartsig, <i>Invenergy</i ></li >
                                                    <li>Andrea Giampoli, <i>Invenergy</i ></li >
                                                    <li>Jordan Macknick, <i>National Renewable Energy Laboratory</i ></li >
                                                    <li>Janine Crane, <i>NextEra Energy</i ></li >
                                                    <li>Jeff Smith, <i>NextEra Energy</i ></li>
                                                    <li>Chris Loebach, <i>NextEra Energy</i ></li >
                                                    <li>Rylie Yaeger, <i>U.S.Department of Energy Solar Energy Technologies Office</i ></li >
                                                    <li>Jarrod Fowler, <i>Pollinator Partnership</i ></li >
                                                    <li>Julianna Isaac, <i>Sol Systems</i ></li >
                                                    <li>Shannon Scarbrough, <i>Greenbacker </i ></li >
                                                    <li>Ben Balskus, <i>RES </i ></li >
                                                    <li>Tyler Kanczuzewski, <i>Inovateus Solar</i ></li >
                                                    <li>Michael Richard, <i>TotalEnergies Renewables USA</i ></li >
                                                    <li>Eric Johnson, <i>Evergy</i ></li >
                                                    <li>Andrew Pinger, <i>EDP Renewables North America</i ></li >
                                                    <li>Christian Thompson, <i>Alchemy Renewable Energy</i ></li >
                                            </ul>

                                                <p style={{ fontWeight: 500 }}>Tool development was completed by: </p>
                                            <ul>
                                                    <li>Ajay Marampalli, <i>University of Illinois Chicago</i ></li >
                                                    <li>Ben Campbell, <i>University of Illinois Chicago</i ></li >
                                                    <li>Indraneel Bhandari, <i>University of Illinois Chicago</i ></li >
                                                    <li>Iris Caldwell, <i>University of Illinois Chicago</i ></li >
                                                    <li>Dan Salas, <i>Stantec</i ></li >
                                                    <li>Vickie Harris, <i>Stantec</i ></li >
                                                    <li>Michael Cannon, <i>Stantec </i ></li >
                                                    <li>Jason Fritz, <i>Stantec</i ></li >
                                                    <li>Michael Friedman, <i>Stantec </i ></li >
                                                    <li>Chris Kline, <i>Stantec</i ></li >
                                            </ul>
                                        </td>
                                    </tr>
                                </table>




                                </p>
                                    </p>
                        </CardText>
                        </CardBody>
                    </Card>
                </div>

        </>
    )
}
            export default Acknowledgements