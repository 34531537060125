import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InitialInputs from "./seed_selection_components/SeedInitialInputs";
import PlantingZoneFilters from "./seed_selection_components/SeedPlantingZoneFilters";
import EcoRegions from "./seed_selection_components/SeedEcoRegions";
import Refinements from "./seed_selection_components/SeedRefinements";
import Results from "./seed_selection_components/SeedResults";
import SeedAdditionalRefinements from "./seed_selection_components/SeedAdditionalRefinements";
import { useSelector, useDispatch } from "react-redux";
import { seed_selection_actions } from "../../store/seed_selection_slice";
import "../../css/main.css";
import { Alert, Modal, Button as btn } from "antd";

const seed_selection_steps = [
  "Initial Inputs",
  "Ecoregions",
  "Planting Zone Filters",
  "Refinements",
  /*'Additional Refinements',*/ "Results"
];

function SeedSelection() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [visibleModal, setVisibleModal] = React.useState(false);

  //redux
  const dispatch = useDispatch();
  const initial_inputs = useSelector(
    (state) => state.seed_selection.initital_inputs
  );
  const selected_state = useSelector(
    (state) => state.seed_selection.selected_state
  );
  const planting_zone_filters = useSelector(
    (state) => state.seed_selection.planting_zone_filters
  );
  const refinements = useSelector((state) => state.seed_selection.refinements);
  const additional_refinements = useSelector(
    (state) => state.seed_selection.additional_refinements
  );
  const selected_ecoregions_store = useSelector(
    (state) => state.seed_selection.gis_ecoRegions
  );

  const activeStepContent = (activeStep) => {
    //console.log('active step', activeStep);
    switch (activeStep) {
      case 0:
        return <InitialInputs />;
      case 1:
        //handleEcoRegion();
        return <EcoRegions />;
      case 2:
        return <PlantingZoneFilters />;
      case 3:
        return <Refinements />;
      case 4:
        return <Results />;
      case "Reset":
        handleReset();
        break;
      default:
        return "Unknown step";
    }
  };

  //functions for the component

  const handleEcoRegion = () => {
    //console.log('selected_ecoregions_store inside seed Selection ', selected_ecoregions_store, typeof selected_ecoregions_store);
    if (selected_ecoregions_store && selected_ecoregions_store.length === 0) {
      //console.log('selected_ecoregions_store is empty');
      setVisibleModal(true);
    }
  };

  const handleNext = () => {
    if (activeStep === 1) {
      // Check if moving from "Ecoregions" page
      handleEcoRegion();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //console.log('resetting the stepper clear the values in the redux store checkpoint')
    dispatch(seed_selection_actions.reset_seed_selection());
    setActiveStep(0);
  };

  // useEffect(() => {
  //   //dispatch(seed_selection_actions.reset_seed_selection());
  //   console.log('initial inputs in seed selection comp', initial_inputs);
  // },[]);

  return (
    <div className="container-fluid">
      <div>
        <div>
          <Typography variant="h4" gutterBottom component="div"
          style={{ textAlign: "center", marginBottom: "20px"
          
        }}
          >
            Seed Selection Tool
          </Typography>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Box>
            <Stepper activeStep={activeStep}>
              {seed_selection_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          <div className="seedselectornavbuttons">
            <Button
              style={{ margin: "1px", width: "55px", height: "30px" }}
              disabled={activeStep === 0}
              variant="contained"
              color="primary"
              onClick={handleBack}
              className="btn btn-primary m-4"
            >
              Back
            </Button>
            <Button
              style={{ margin: "1px", width: "55px", height: "30px" }}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className="btn btn-primary m-4"
            >
              {activeStep === seed_selection_steps.length - 1
                ? "Reset"
                : "Next"}
            </Button>
          </div>
          <Box m={4}>
            {activeStep === seed_selection_steps.length
              ? activeStepContent("Reset")
              : activeStepContent(activeStep)}
          </Box>
        </div>
      </div>
      <div className="seedselectornavbuttons">
        {" "}
        {/*add another set of buttons at bottom of screen for user convenience*/}
        <Button
          style={{ margin: "1px", width: "55px", height: "30px" }}
          disabled={activeStep === 0}
          variant="contained"
          color="primary"
          onClick={handleBack}
          className="btn btn-primary m-4"
        >
          Back
        </Button>
        <Button
          style={{ margin: "1px", width: "55px", height: "30px" }}
          variant="contained"
          color="primary"
          onClick={handleNext}
          className="btn btn-primary m-4"
        >
          {activeStep === seed_selection_steps.length - 1 ? "Reset" : "Next"}
        </Button>
        {/* Antd Modal */}
        <Modal
          title="Ecoregions Warning"
          open={visibleModal}
          onCancel={() => {
            setVisibleModal(false);
            // Add any additional actions you want to perform here.
            setActiveStep(1);
          }}
          footer={[
            <Button key="close" onClick={() => {
              setVisibleModal(false);
              // Add any additional actions you want to perform here.
              setActiveStep(1);
            }}>
              Close
            </Button>
          ]}
        >
          <Alert
            message="No Ecoregions are selected. Please select at least one."
            type="warning"
            showIcon
          />
        </Modal>
      </div>
    </div>
  );
}

export default SeedSelection;
